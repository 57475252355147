import { DataTableBackend } from '@components/common';
import { getParams, setParams, useFilterSearchParams } from '@hooks/useFilterSearchParams';
import { useService } from '@hooks/useService';
import { FilterKey } from '@models/filter';
import { SortOrder } from '@models/sorting';
import { ExtendedColumn } from '@models/table';
import { Stack, Typography, TypographyProps } from '@mui/material';
import { AuditTrail, auditTrailService, filterService, loadingSpinnerOverlayService } from '@services/index';
import { downloadFileFromContent } from '@utils/Download';
import { history } from '@utils/Router/history.const';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ExtendedComponent } from 'src/types';
import { AuditTrailChangeAccordion } from './AuditTrailChangeAccordion/AuditTrailChangeAccordion';
import { Observable } from 'rxjs';
import { PagedResult } from '@models/request-response';

const DEFAULT_SORT_COLUMN = 'createTime';
const TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const Cell: ExtendedComponent<TypographyProps> = ({ sx, ...props }) => (
	<Typography variant="pg-s" sx={{ color: 'text.primary', wordBreak: 'break-word', width: '100%', ...sx }} {...props} />
);

export const NmrPipelineAudit = () => {
	const { id = '' } = useParams<{ id: string }>();
	const { searchParams, filtering, setSearchParams, setFiltering } = useFilterSearchParams(DEFAULT_SORT_COLUMN, SortOrder.DESC);
	const [typedString, setTypedString] = useState('');
	const [isSearchParamsInitialized, setIsSearchParamsInitialized] = useState<boolean>(false);

	const { t } = useTranslation('admin');
	const tableRef = useRef<HTMLElement>(null);
	const { data: filterData } = useService(() => filterService.auditLogsFilterOptions());
	const to = new Date(Date.now());
	const from = new Date(Date.now());
	from.setMonth(to.getMonth() - 3);

	useEffect(() => {
		const currentFrom = searchParams.getAll(FilterKey.FROM);
		const currentTo = searchParams.getAll(FilterKey.TO);

		setParams(searchParams).setEntityId(id);
		setTypedString(getParams(searchParams).getQuery());

		setParams(searchParams).setEntities('NmrDrmOrder,NmrDrmWave');
		if ((!currentFrom || currentFrom.length === 0) && (!currentTo || currentTo.length === 0) && history.action !== 'POP') {
			searchParams.set(FilterKey.DATE_RANGE, `${from.toISOString()},${to.toISOString()}`);
			searchParams.set(FilterKey.FROM, from.toISOString());
			searchParams.set(FilterKey.TO, to.toISOString());
		}
		setSearchParams(searchParams);
		setIsSearchParamsInitialized(true);
	}, []);

	const { data: auditTrailData, loading } = useService(() => {
		if (isSearchParamsInitialized) {
			return auditTrailService.getAllWithParams(searchParams);
		}
		return new Observable<PagedResult<AuditTrail>>();
	}, [searchParams, isSearchParamsInitialized]);

	const columns = useMemo<ExtendedColumn<AuditTrail>[]>(
		() => [
			{
				accessor: 'createTime',
				Header: <Tr.Admin path="drm-pipeline.audit-trail.when" />,
				Cell: ({ value }) => <Cell>{DateUtils.getFormattedDate(value, 'dd/MM/yyyy HH:mm:ss')}</Cell>,
			},
			{
				accessor: 'user',
				Header: <Tr.Admin path="drm-pipeline.audit-trail.who" />,
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{value || '-'}</Cell>,
			},
			{
				accessor: 'title',
				Header: <Tr.Admin path="drm-pipeline.audit-trail.where" />,
				Cell: ({ value, row }) => (
					<Cell sx={{ maxWidth: 200 }}>
						{value || '-'}
						<br />
						{`(ID: ${row.original.entityId})`}
					</Cell>
				),
			},
			{
				accessor: 'action',
				Header: <Tr.Admin path="drm-pipeline.audit-trail.action" />,
				Cell: ({ value }) => <Cell sx={{ maxWidth: 150 }}>{value ? value : '-'}</Cell>,
			},
			{
				accessor: 'change',
				Header: <Tr.Admin path="drm-pipeline.audit-trail.what" />,
				disableSortBy: true,
				Cell: ({ value }) => <AuditTrailChangeAccordion changes={value ?? ''} />,
			},
		],
		[searchParams],
	);

	const onSaveAsPdf = async (title: string) => {
		loadingSpinnerOverlayService.increment();

		RxUtils.promisify(auditTrailService.getReport(searchParams), (res) => downloadFileFromContent(res, title));
		loadingSpinnerOverlayService.decrement();
	};

	return (
		<Stack data-testid="audit-trail-wrapper-id">
			<DataTableBackend
				columns={columns}
				data={auditTrailData?.data || []}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: auditTrailData?.totalPages ?? 1 }}
				searchText={typedString}
				searchPlaceholder={t('drm-pipeline.audit-trail.search-message')}
				searchFieldProps={{ sx: { maxWidth: 450, width: 450 }, 'aria-label': 'ongoing-search-id' }}
				onSearchTextChange={setTypedString}
				headerCellProps={{ sx: { minWidth: 60 } }}
				sx={{
					visibility: loading ? 'hidden' : 'visible',
					marginX: 2,
				}}
				showExportButton={true}
				exportButtonText={t('drm-pipeline.audit-trail.export-pdf')}
				onExportButtonClick={() =>
					tableRef?.current &&
					onSaveAsPdf(`${t('drm-pipeline.audit-trail.title')}_${DateUtils.getFormattedDate(new Date(), TIME_FORMAT)}.pdf`)
				}
				tableRef={tableRef}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</Stack>
	);
};
