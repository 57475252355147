import { Grid, Stack } from '@mui/material';
import { GenericCard, OverflowText } from '@components/common';
import { DeviceCalibrations } from '@components/portal';
import { DataTestId, mainTitleSpacer, Tr } from '@utils';
import { DeviceSpecification } from '../DeviceSpecification';
import { ExistingDeviceHeader } from './DeviceHeaders';
import { useDeviceOutlet } from '@hooks';

export const DeviceDetails = () => {
	const { deviceData, refreshDeviceData } = useDeviceOutlet();

	const color = !deviceData || deviceData?.calibrationData?.isActive ? 'primary.main' : 'grey.500';
	return (
		<Stack height={'100%'} data-testid={DataTestId.getStaticTestId('device-details-wrapper-id')}>
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<OverflowText enableTooltip={true} variant="h1" sx={{ color: color, width: '100%', maxWidth: 'calc(100% - 25rem)' }}>
					{deviceData?.calibrationData?.name ?? <Tr.Portal path="device-management.device-details" />}
				</OverflowText>
				<ExistingDeviceHeader calibrationData={deviceData?.calibrationData} refresh={() => refreshDeviceData?.()} />
			</Stack>
			<form style={{ height: '100%' }}>
				<Grid data-testid="device-wrapper-id" container columnSpacing={4} height={'100%'}>
					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<DeviceCalibrations recalibration={deviceData} refresh={() => refreshDeviceData?.()} />
						</GenericCard>
					</Grid>
					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<DeviceSpecification recalibration={deviceData} />
						</GenericCard>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
};
