import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import { TableButton } from '@components/portal';
import { Tr } from '@utils/Translation';

interface IResetSuccessfullyProps {
	gotoLogin: () => void;
}

export const ResetSuccessfully: FC<IResetSuccessfullyProps> = ({ gotoLogin }) => {
	return (
		<Stack sx={{ alignItems: 'center' }}>
			<Box
				sx={{
					width: '7.5rem',
					height: '7.5rem',
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex',
					backgroundColor: 'rgba(1, 136, 76, 0.1)',
					borderRadius: '50%',
				}}
			>
				<CheckIcon color="primary" sx={{ width: '3rem', height: '3rem' }} />
			</Box>
			<Typography variant="h3" sx={{ marginTop: '1.5rem', fontSize: '1rem' }}>
				<Tr.Login path="reset-successfully.password-reset" />
			</Typography>
			<Typography variant="pg-m" sx={{ fontSize: '0.87rem', fontWeight: 500, marginTop: '0.5rem' }}>
				<Tr.Login path="reset-successfully.desc" />
			</Typography>
			<TableButton.GotoAnalysis
				data-testid="goto-login-test-id"
				sx={{ marginTop: '2rem', width: '7rem' }}
				onClick={() => gotoLogin()}
			>
				<Tr.Login path="reset-successfully.go-to-login" />
			</TableButton.GotoAnalysis>
		</Stack>
	);
};
