import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IrDevice, irDeviceService } from '@services';
import { IrAddDevice } from '@components/irportal';
import { RxUtils } from '@utils/Rx';

export const IrRecalibrate = () => {
	const { id } = useParams();
	const [recalibrateData, setRecalibrateData] = useState<IrDevice>();
	useEffect(() => {
		if (id) {
			RxUtils.promisify(irDeviceService.getDevice(+id), (data) => {
				setRecalibrateData(data);
			});
		}
	}, [id]);
	return <IrAddDevice deviceData={{ calibrationData: recalibrateData, id: id ? +id : null }} />;
};
