import { useEffect, useState } from 'react';
import { fromJCAMP } from 'nmr-parser';
import { NmrDeviceNamesResponse, NmrDeviceCalibration, notificationService } from '@services';
import { useTranslation } from 'react-i18next';
import { differenceInBusinessDays } from 'date-fns';
import { IRecalibrationType, findMissingSmallestInteger } from './DeviceUtils';
import { checkFieldsSame, getMatchFiles, greaterThanThreshold, isEqualMaxFileSize, isEqualHealthCheckSize } from '@utils/Device';
import { FormikApiType } from '@components/common';
import { FileStatus } from '@models/file';

const MAX_FILE_LENGTH = 6;
const ONE_WEEK = 7;
const SUPPORTED_DEVICES = ['bruker', 'jeol'];

export interface IFileStateType {
	file: File;
	fileName: string;
	receiverGain: string;
	measurementDevice: string;
	manufacturer: string;
	probeId: string;
	frequency: string;
	solventName: string;
	fileStatus: FileStatus;
}

export interface DeviceFileHookParams {
	newFile: File[];
	formik: FormikApiType;
	deviceData?: IRecalibrationType;
	deviceNamesResponse?: NmrDeviceNamesResponse;
	isHealthCheck?: boolean;
}

// TODO: Make this hooks general
export const useDeviceFileHook = ({ newFile, formik, deviceData, deviceNamesResponse, isHealthCheck = false }: DeviceFileHookParams) => {
	const [files, setFiles] = useState<IFileStateType[]>([]);
	const [flag, setFlag] = useState(false);
	const [canCalibrate, setCanCalibrate] = useState(false);
	const [outOfRangeFiles, setOutOfRangeFiles] = useState<string[]>([]);
	const { t } = useTranslation('portal');
	const removeFile = (index: number) => {
		setFiles(
			files
				.filter((_, fileIndex) => index !== fileIndex)
				.map((file) => {
					return { ...file, fileStatus: FileStatus.VALID };
				}),
		);
	};

	const removeAllFiles = () => {
		setFiles([]);
	};

	const setFileStatus = (index: number, status: FileStatus) => ((files[`${index}`].fileStatus = status), setFlag(!flag));

	const checkFilesValidity = () => {
		files.forEach((_, index) => setFileStatus(index, FileStatus.INVALID));

		files.forEach((file) => {
			const matchedFiles = getMatchFiles(file, files);
			if (greaterThanThreshold(matchedFiles)) {
				matchedFiles.forEach((fileIndex) => setFileStatus(fileIndex, FileStatus.VALID));
			}
		});
	};

	const generateCalibrationName = (calibrations: NmrDeviceCalibration[] | undefined): string => {
		const prefix = t('device-management.calibration') + '_';
		const defaultName = `${prefix}1`;
		if (calibrations === undefined || calibrations.length === 0) return defaultName;

		// False Positive
		// eslint-disable-next-line
		const regex = new RegExp(`(${prefix})(?<count>[0-9]+)`);
		const lastCalibration = calibrations
			.filter(({ name }) => regex.test(name))
			.sort((a, b) => a.name.localeCompare(b.name))
			.pop();

		if (!lastCalibration) return defaultName;
		let count: number = +(lastCalibration.name.match(regex)?.groups?.count || 0);
		count++;

		return `${prefix}${count}`;
	};

	useEffect(() => {
		const expireFiles: string[] = [];
		const notSupportedFile: string[] = [];
		newFile.forEach(async (file) => {
			try {
				await file.text().then((data) => {
					const meta = fromJCAMP(data.replaceAll('$$ ##$', '##').replaceAll('\n$$', '\n##$$empty'))[0].meta;

					meta.PROBHD = Array.isArray(meta.PROBHD) ? meta.PROBHD[0] : meta.PROBHD;
					meta.RG = Array.isArray(meta.RG) ? meta.RG[0] : meta.RG;
					const { RG, PROBHD, ORIGIN, TITLE, LONGDATE, DATE, TIME, ...others } = meta;

					const diffDate = differenceInBusinessDays(Date.now(), new Date(LONGDATE ?? DATE ?? TIME));
					const isExpire = diffDate > ONE_WEEK || isNaN(diffDate);
					isExpire && expireFiles.push(file.name);

					const manufacturerName = ORIGIN.split(' ')?.[0]?.trim()?.toLowerCase();
					const isSupported = SUPPORTED_DEVICES.includes(manufacturerName);
					!isSupported && notSupportedFile.push(file.name);

					!isExpire &&
						isSupported &&
						setFiles((current) => {
							if (current.length < MAX_FILE_LENGTH) {
								const hasNoGain = !RG;
								hasNoGain &&
									notificationService.sendError(
										t('device-management.no-receiver-gain-error', {
											filename: file.name,
										}),
									);

								const hasSameGain = current.some((currentData) => currentData.receiverGain === RG);
								hasSameGain &&
									notificationService.sendError(
										t('device-management.same-file-error', {
											filename: file.name,
										}),
									);
								return !hasSameGain && !hasNoGain
									? [
											...current,
											{
												file: file,
												fileName: file.name,
												receiverGain: RG || '',
												frequency: others['.OBSERVEFREQUENCY'] || '',
												manufacturer: ORIGIN || '',
												probeId: PROBHD || '',
												measurementDevice: TITLE,
												solventName: others['.SOLVENTNAME'] || '',
												fileStatus: FileStatus.VALID,
											},
										]
									: current;
							}
							return current;
						});
				});
			} catch (e) {
				notificationService.sendError(t('file-not-parsed', { filename: file.name }));
			}
		});
		// TODO: find better solution
		const timeout = setTimeout(() => {
			expireFiles.length &&
				notificationService.sendError(
					t(`device-management.expire-file-error-${expireFiles.length > 1 ? 'multi' : 'single'}`, {
						filename: expireFiles.join(', '),
					}),
				);

			notSupportedFile.length &&
				notificationService.sendError(
					t(`device-management.unsupported-manufacturer-${notSupportedFile.length > 1 ? 'multi' : 'single'}`, {
						filename: notSupportedFile.join(', '),
					}),
				);
		}, 50);
		return () => {
			clearTimeout(timeout);
		};
	}, [newFile]);

	useEffect(() => {
		if (files.length > 0) {
			let name = '';
			let counter = 1;
			const getLastPart = (str: string) => str.substring(str.lastIndexOf('_') + 1);

			if (!deviceData && files[0]) {
				const { frequency, manufacturer } = files[0];
				name = `${manufacturer.split(' ')[0]}_${Math.round(+frequency / 10) * 10}_`;
				const deviceNames = deviceNamesResponse?.deviceNames?.filter((n) => n.startsWith(name) && !isNaN(+getLastPart(n)));
				if (deviceNames && deviceNames?.length > 0) {
					counter = findMissingSmallestInteger(deviceNames.map((device) => getLastPart(device)));
				}

				if (!formik.values.deviceName) formik.setFieldValue('deviceName', `${name}${counter}`);
			}

			if (!formik.values.calibrationName) {
				const calibrations = deviceData?.calibrationData?.nmrDeviceCalibrations;
				const calibrationName = generateCalibrationName(calibrations);
				formik.setFieldValue('calibrationName', calibrationName);
			}
			formik.validateForm();

			if (checkFieldsSame(files[0], files)) {
				setCanCalibrate(true);
			} else {
				setCanCalibrate(false);
				if ((!deviceData?.calibrationData || checkFieldsSame(deviceData.calibrationData, files)) && isEqualMaxFileSize(files)) {
					checkFilesValidity();
				}
			}
		}

		if (deviceData?.calibrationData) {
			const { frequency, manufacturer, name, probeId } = deviceData.calibrationData;
			formik.setFieldValue('deviceName', name);

			if ((isHealthCheck && isEqualHealthCheckSize(files)) || (!isHealthCheck && isEqualMaxFileSize(files))) {
				let isDeviceMatching = true;
				files.forEach((file, index) => {
					if (!checkFieldsSame({ frequency, manufacturer, probeId }, [file])) {
						isDeviceMatching = false;
						setCanCalibrate(false);
						setFileStatus(index, FileStatus.INVALID);
					}
				});
				if (isDeviceMatching) {
					setCanCalibrate(true);
				}
			} else {
				setCanCalibrate(false);
			}
		}
	}, [deviceData, files]);

	useEffect(() => {
		if (canCalibrate) formik.validateForm();
	}, [canCalibrate]);
	return {
		files,
		removeFile,
		removeAllFiles,
		canCalibrate,
		outOfRangeFiles,
		setOutOfRangeFiles,
	};
};
