import { DataTableBackend, OverflowText } from '@components/common';
import { TableButton } from '@components/portal';
import { getParams, useFilterSearchParams } from '@hooks/index';
import { useService } from '@hooks/useService';
import { ExtendedColumn, PageRoutes, SortOrder } from '@models';
import { Link, Stack, Typography } from '@mui/material';
import { Substance, substanceService } from '@services/core/substance';
import { Tr } from '@utils/Translation';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';

export const SubstanceDatabase: FC = () => {
	const navigate = useNavigate();
	const { searchParams } = useFilterSearchParams('', SortOrder.RESET);

	const [typedString, setTypedString] = useState('');
	const { t } = useTranslation('admin');

	const { data: requestData } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		return substanceService.searchWithUrlParams(searchParams);
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<Substance>[]>(
		() => [
			{
				id: 'name',
				accessor: (originalRow) => originalRow.name,
				Header: <Tr.Admin path="substance-management.name" />,
				Cell: ({ value, row }: PropsWithChildren<CellProps<Substance>>) => (
					<OverflowText
						enableTooltip={true}
						sx={{
							color: 'primary.main',
							minWidth: 100,
						}}
						variant="pg-s"
					>
						<Link
							underline="hover"
							variant="body1"
							onClick={() => {
								navigate(`/admin/${PageRoutes.SUBSTANCE_MANAGEMENT}/${PageRoutes.SUBSTANCE_DETAILS}/${row.original.id}`);
							}}
							sx={{ cursor: 'pointer' }}
						>
							{value?.toLocaleString()}
						</Link>
					</OverflowText>
				),
			},
			{
				id: 'casNumber',
				accessor: (originalRow) => originalRow.casNumber,
				Header: <Tr.Admin path="substance-management.cas-number" />,
				Cell: ({ value }: PropsWithChildren<CellProps<Substance>>) => <Typography variant="pg-s">{value?.toString()}</Typography>,
			},
			{
				id: 'view',
				disableSortBy: true,
				Header: <></>,
				Cell: ({ row }) => (
					<Stack direction="row" justifyContent="end" alignItems="center" pr={4}>
						<TableButton.ViewSummary
							href={`/admin/${PageRoutes.SUBSTANCE_MANAGEMENT}/${PageRoutes.SUBSTANCE_DETAILS}/${row.original.id}`}
							data-testid={`substance-management.view-details-${row.index}-id`}
							sx={{ width: '7rem' }}
						>
							<Tr.Admin path="substance-management.view-details" />
						</TableButton.ViewSummary>
					</Stack>
				),
			},
		],
		[requestData],
	);

	return (
		<DataTableBackend
			data={requestData?.data || []}
			columns={columns as any}
			sorting={{
				sortBy: getParams(searchParams).getSortBy(),
				sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
			}}
			pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: requestData?.totalPages ?? 1 }}
			searchText={typedString}
			onSearchTextChange={setTypedString}
			searchPlaceholder={t('substance-management.substance-database-search-placeholder')}
			searchFieldProps={{ sx: { minWidth: 600, width: 600 }, 'aria-label': 'erm-reqs-search-id' }}
			sx={{ marginX: 2 }}
		/>
	);
};
