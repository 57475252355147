import { FC, useRef, useState } from 'react';
import Slider, { ResponsiveObject, Settings } from 'react-slick';
import { DataTestId, Tr } from '@utils';
import { useService } from '@hooks';
import { Box, IconButton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { ANALYSIS_CARD_WIDTH, MyAnalysesCard } from './MyAnalysesCard';
import { PortalPageRoutes } from '@models/router';
import { AnalysisHistoryTabs } from '@models/analysis';
import { useNavigate } from 'react-router-dom';
import WestRounded from '@mui/icons-material/WestRounded';
import EastRounded from '@mui/icons-material/EastRounded';
import { nmrAnalysisService } from '@services/nmr';

// To turn responsive from optional to required
// TO DO: SEPERATE THIS PART FROM THIS FILE
const settings: Omit<Settings, 'responsive'> & { responsive: ResponsiveObject[] } = {
	dots: false,
	draggable: false,
	arrows: false,
	infinite: false,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	touchThreshold: 10,
	responsive: [
		{
			breakpoint: 2000,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1600,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1400,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1250,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
	],
};

/*	Ws: Width of Slider
	Wc: Width of a card
	Nc: Count of cards shown
	Ws + (Ws - Nc * Wc) / (Nc - 1) */
const getWidthCalc = (nc: number) => `calc(100% + ((100% - ${nc * ANALYSIS_CARD_WIDTH}rem) / ${nc - 1}))`;

export const MyAnalyses: FC = () => {
	const [sliderIndex, setSliderIndex] = useState(0);
	const navigate = useNavigate();

	const responsiveStateList = settings.responsive.map((responsiveConfig) =>
		useMediaQuery((theme: Theme) => theme.breakpoints.up(responsiveConfig.breakpoint)),
	);

	const sliderCapacity = responsiveStateList.filter((state) => state).length + 2;

	const handleClick = () => {
		navigate(`../${PortalPageRoutes.HISTORY}/${AnalysisHistoryTabs.ONGOING_ANALYSIS}`);
	};

	const sliderRef = useRef<Slider>(null);

	const { data: analysisList, loading } = useService(() =>
		nmrAnalysisService.getAll({ completed: false, pageIndex: 1, pageSize: 7, sortBy: 'LastActivityDate' }),
	);
	const sliderMinIndex = 0;
	const sliderMaxIndex = analysisList?.data?.length ? analysisList?.data?.length - 1 : 1;

	const sliderBeforeChange = (_prev: number, next: number) => {
		setSliderIndex(next);
	};

	const sliderSettings = { ...settings, beforeChange: sliderBeforeChange };

	const canSlideNext = () => sliderIndex + sliderCapacity <= sliderMaxIndex;
	const canSlidePrev = () => sliderIndex > sliderMinIndex;

	const isCardShown = (idx: number) => sliderIndex <= idx && idx < sliderIndex + sliderCapacity;

	return !loading ? (
		<>
			{analysisList?.data &&
				(analysisList.data.length ? (
					<Box sx={{ width: getWidthCalc(sliderCapacity) }}>
						<Slider ref={sliderRef} {...sliderSettings}>
							{analysisList?.data?.map((analysis, idx) => (
								<Box
									key={analysis.id}
									data-testid={DataTestId.getTestId('dasboard-card')}
									sx={{
										transition: 'opacity, visibility',
										transitionDuration: '0.2s',
										opacity: isCardShown(idx) ? 1 : 0,
										visibility: isCardShown(idx) ? 'visible' : 'hidden',
									}}
								>
									<MyAnalysesCard analysis={analysis} dataTestId={`dashboard-myanalysis-card-view-${idx}-id`} />
								</Box>
							))}
						</Slider>
					</Box>
				) : (
					<Typography variant="h2" textAlign="center" color="grey.700">
						<Tr.Portal path="dashboard.my-analyses.no-analysis-yet" />
					</Typography>
				))}

			<Stack
				data-testid="my-analyses-wrapper-id"
				direction="row"
				justifyContent="space-between"
				sx={{ display: 'inline-flex', alignItems: 'center' }}
			>
				{analysisList?.data?.length ? (
					<Stack direction="row">
						<IconButton
							data-testid="slide-left-button-id"
							onClick={() => canSlidePrev() && sliderRef.current?.slickPrev()}
							disabled={!canSlidePrev()}
							sx={{ marginTop: '0.75rem' }}
						>
							<WestRounded
								visibility={canSlidePrev() ? 'visible' : 'hidden'}
								sx={{
									fontSize: '2rem',
									color: 'primary.main',
								}}
							/>
						</IconButton>
						<IconButton
							data-testid="slide-right-button-id"
							sx={{
								marginLeft: '0.125rem',
								marginRight: '0.375rem',
								marginTop: '0.75rem',
							}}
							disabled={!canSlideNext()}
							onClick={() => canSlideNext() && sliderRef.current?.slickNext()}
						>
							<EastRounded
								visibility={canSlideNext() ? 'visible' : 'hidden'}
								sx={{ fontSize: '2rem', color: 'primary.main' }}
							/>
						</IconButton>
					</Stack>
				) : null}
				{analysisList?.data && analysisList.data.length > 0 && (
					<Typography
						onClick={handleClick}
						data-testid="dashboard-view-all-id"
						variant="h6"
						sx={{
							mt: '0.5rem',
							color: 'primary.main',
							'&:hover': {
								cursor: 'pointer',
								textDecoration: 'underline',
							},
						}}
					>
						<Tr.Portal path="dashboard.my-analyses.view-all" />
					</Typography>
				)}
			</Stack>
		</>
	) : (
		<></>
	);
};
