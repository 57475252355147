import { DrmPipelineFlag } from '@components/admin/DrmPipelineFlag';
import { OverflowText } from '@components/common';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { NmrAdminPermission } from '@models/user';
import { Button, Stack, Typography } from '@mui/material';
import { alertService } from '@services/index';
import { NmrDrmOrder, NmrDrmOrderRejectionSeverity, NmrDrmOrderStatus, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { userSelector } from '@store/slices/common.slice';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RejectDrmOrder } from '../RejectDrmOrder';

type TDrmOrderDetailsHeaderProps = {
	nmrDrmOrder?: NmrDrmOrder;
	refreshData?: () => void;
};

export const DrmOrderDetailsHeader: FC<TDrmOrderDetailsHeaderProps> = ({ nmrDrmOrder, refreshData }) => {
	const { t: translate } = useTranslation('admin');
	const user = useSelector(userSelector);

	const canReject =
		(nmrDrmOrder?.qcInspector?.id === user?.username && nmrDrmOrder?.status === NmrDrmOrderStatus.QC_INSPECTION) ||
		(nmrDrmOrder?.qaReviewer?.id === user?.username && nmrDrmOrder?.status === NmrDrmOrderStatus.QA_REVIEW);
	const [rejectionPopupVisible, setRejectionPopupVisible] = useState(false);

	const isDeclined = nmrDrmOrder?.status === NmrDrmOrderStatus.DECLINED;
	const isReleased = nmrDrmOrder?.status === NmrDrmOrderStatus.RELEASED;
	const hasReactivatePermission = useHasPermissions(NmrAdminPermission.REACTIVATE_DRM_ORDER);

	const onReactivateClicked = () => {
		alertService.send({
			titleText: translate('drm-order-details.confirmation'),
			content: <Typography>{translate('drm-order-details.reactivate-drm-order.explanation')}</Typography>,
			closeTextComponent: translate('drm-order-details.cancel'),
			confirmTextComponent: translate('drm-order-details.reactivate-drm-order.confirm'),
			confirmTextHeader: translate('drm-order-details.reactivate-drm-order.title'),
			onConfirm: () =>
				nmrDrmOrder?.id &&
				RxUtils.promisify(
					nmrDrmOrderService.postTransition(nmrDrmOrder.id, {
						status: NmrDrmOrderStatus.DRAFT,
					}),
					() => refreshData?.(),
				),
			onClose: () => null,
		});
	};

	const onRejected = (rejectionReasons: string[], severity: string, details: string) => {
		RxUtils.promisify(
			nmrDrmOrderService.postTransition(nmrDrmOrder?.id ?? 0, {
				status: NmrDrmOrderStatus.IN_PRODUCTION,
				rejectReasons: rejectionReasons,
				severity: severity as NmrDrmOrderRejectionSeverity,
				comment: details,
			}),
			() => (setRejectionPopupVisible(false), refreshData?.()),
		);
	};

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={4}>
			<OverflowText variant="h1" color={isDeclined ? 'grey.500' : 'text.primary'} paddingRight={1} enableTooltip={true}>
				{' '}
				{nmrDrmOrder?.name}{' '}
			</OverflowText>
			{!!nmrDrmOrder && !isDeclined && !isReleased && (
				<DrmPipelineFlag
					order={nmrDrmOrder}
					tooltipEnabled={nmrDrmOrder.status !== NmrDrmOrderStatus.RELEASED}
					refreshData={refreshData}
				/>
			)}
			{!!nmrDrmOrder && isDeclined && hasReactivatePermission && (
				<Button
					data-testid="reactivate-nmr-drmorder-button-id"
					variant="contained"
					sx={{
						margin: 'auto auto 0 0',
					}}
					disableElevation
					onClick={onReactivateClicked}
				>
					<Tr.Admin path="drm-order-details.reactivate-drm-order.button-text" />
				</Button>
			)}
			{!!nmrDrmOrder && !isDeclined && canReject && (
				<Button
					data-testid="reactivate-nmr-drmorder-button-id"
					variant="outlined"
					sx={{
						margin: 'auto auto 0 12px',
					}}
					disableElevation
					onClick={() => setRejectionPopupVisible(true)}
				>
					<Tr.Admin path="drm-order-details.reject-drm-order.reject-button-text" />
				</Button>
			)}
			{rejectionPopupVisible && (
				<RejectDrmOrder visible={rejectionPopupVisible} onCancel={() => setRejectionPopupVisible(false)} onConfirm={onRejected} />
			)}
		</Stack>
	);
};
