import { createRef, forwardRef, Fragment, ReactNode, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTestId, DateUtils, Tr } from '@utils';
import { getParams, setParams, useDRMDetail, useFilterSearchParams, useService } from '@hooks';
import { ExtendedColumn, QualitativeOrQuantitative, SortOrder } from '@models';
import { FavoriteReference, favoriteReferencesService } from '@services';
import { Link, Stack, Typography } from '@mui/material';
import { DataTableBackend, OverflowText } from '@components/common';
import { DRMDetail } from '@components/portal/DRMDetail';

interface IIFavoritesTableAction {
	renderer: (template: FavoriteReference) => ReactNode;
}

export interface IFavoritesTableRef {
	refetch: () => void;
}

export interface IFavoritesTableProps {
	actions?: IIFavoritesTableAction[];
	leftActions?: IIFavoritesTableAction[];
	measurementType: QualitativeOrQuantitative;
	pageSize?: number;
}

const tableFirstColumnSelector = {
	thead: {
		tr: {
			th: {
				'&:first-child': {
					maxWidth: '56px',
					width: '56px',
				},
			},
		},
	},
};

export const FavoritesTable = forwardRef<IFavoritesTableRef, IFavoritesTableProps>(
	({ pageSize = 15, actions, leftActions, measurementType = QualitativeOrQuantitative.QUALITATIVE }, ref) => {
		const { searchParams } = useFilterSearchParams('', SortOrder.RESET);
		const { resetDetail: resetDrmDetail, setDRMDetail, ...drmDetailProps } = useDRMDetail();

		const { t, ready } = useTranslation('portal');
		const translate = (path: string) => (ready ? t(path) : '');

		const [searchTextfieldValue, setSearchTextfieldValue] = useState('');

		const { data: templates, trigger: refetch } = useService(() => {
			setSearchTextfieldValue(getParams(searchParams).getQuery());
			setParams(searchParams).setPageSize(pageSize.toString());
			setParams(searchParams).setMeasurementType(measurementType);

			return favoriteReferencesService.getAllWithParams(searchParams);
		}, [searchParams]);

		useImperativeHandle<unknown, IFavoritesTableRef>(ref, () => ({ refetch }), [refetch]);

		const columns = useMemo<ExtendedColumn<FavoriteReference>[]>(
			() => [
				{
					id: 'fav-icon',
					accessor: 'name',
					Header: <></>,
					disableSortBy: true,
					Cell: ({ value, row }) =>
						leftActions?.length ? (
							<Stack
								direction="row"
								spacing={1}
								data-testid={DataTestId.getStaticTestId(`fav-icon-${row.index}-id`)}
								maxWidth="32px"
								width="32px"
							>
								{leftActions.map(({ renderer }, i) => (
									<Fragment key={`left-action-${value}-${i}`}>{renderer(row.original)}</Fragment>
								))}
							</Stack>
						) : null,
				},
				{
					accessor: 'name',
					Header: <Tr.Portal path="favourites.name" />,
					disableSortBy: true,
					Cell: ({ value, row }) => (
						<Typography variant="pg-s" data-testid={DataTestId.getStaticTestId(`fav-name-${row.index}-id`)}>
							{value || ''}
						</Typography>
					),
				},
				{
					accessor: 'date',
					id: 'CreateTime',
					Header: <Tr.Portal path="favourites.date" />,
					Cell: ({ value }) => <Typography variant="pg-s">{DateUtils.getFormattedDate(value, 'dd/MM/yyyy')}</Typography>,
				},
				{
					accessor: 'referenceMaterialName',
					disableSortBy: true,
					Header: <Tr.Portal path="favourites.reference" />,
					Cell: ({ value, row }) => (
						<OverflowText
							enableTooltip={true}
							variant="pg-s"
							sx={{
								color: 'primary.main',
								minWidth: 100,
							}}
						>
							<Link
								data-testid={DataTestId.getStaticTestId(`substance-link-${row.index}-id`)}
								key={`substance-${value}-${row.original.referenceMaterialId}`}
								underline="hover"
								variant="body1"
								onClick={() => {
									setDRMDetail({
										visible: true,
										drmId: row.original.referenceMaterialId,
									});
								}}
								sx={{ cursor: 'pointer' }}
							>
								{value}
							</Link>
						</OverflowText>
					),
				},
				{
					accessor: 'id',
					disableSortBy: true,
					Cell: ({ value, row }) =>
						actions?.length ? (
							<Stack direction="row" justifyContent="flex-end" spacing={1}>
								{actions.map(({ renderer }, i) => (
									<Fragment key={`action-${value}-${i}`}>{renderer(row.original)}</Fragment>
								))}
							</Stack>
						) : null,
				},
			],
			[actions],
		);

		const pageIndex = +getParams(searchParams).getPageIndex();
		if (templates?.data.length === 0 && pageIndex > 1) {
			setParams(searchParams).setPageSize((pageSize - 1).toString());
		}
		const drmDetailRef = createRef<HTMLDivElement>();
		return (
			<>
				<DataTableBackend
					data={templates?.data || []}
					columns={columns}
					headerCellProps={{ sx: { minWidth: '48px' } }}
					sorting={{
						sortBy: getParams(searchParams).getSortBy(),
						sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
					}}
					pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: templates?.totalPages ?? 1 }}
					searchText={searchTextfieldValue}
					searchPlaceholder={translate('favourites.searchbox-placeholder')}
					onSearchTextChange={setSearchTextfieldValue}
					searchFieldProps={{ sx: { maxWidth: 500, width: 500 }, 'aria-label': 'fav-search-text-id' }}
					sx={tableFirstColumnSelector}
				/>

				{useMemo(
					() => (
						<DRMDetail onCloseClick={() => resetDrmDetail()} ref={drmDetailRef} {...drmDetailProps} />
					),
					[drmDetailProps],
				)}
			</>
		);
	},
);
