import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Divider, StackProps, SxProps, Stack, Typography } from '@mui/material';
import { GenericCard, OverflowText } from '@components/common';
import { IrDRMDetail, TableButton, IDrmSpectrumDataType } from '@components/irportal';
import { useIrDRMDetail } from '@hooks';
import { Tr } from '@utils';
import { MolecularFormula } from '@components/common';
import { IrAnalysisResultContext } from '@routes/IrPortal/AnalysisResult/ir/Iranalysis-result-context';
import { IrDrmDetail, RankBasedMixtureData } from '@services/ir';
import { IrDRMIndicator as DRMIndicator } from '@components/irportal';

interface ISampleDetailIndicatorProps extends Omit<StackProps, 'title'> {
	title: ReactNode;
	value: ReactNode;
	sx?: SxProps;
}
const SampleDetailIndicator: FC<ISampleDetailIndicatorProps> = ({ title, value, sx }) => {
	return (
		<Stack direction="row" marginBottom={1.5}>
			<Stack sx={{ maxWidth: '230px', width: '100%', marginRight: '32px' }}>
				<Typography variant="pg-m" sx={{ color: 'text.primary', whiteSpace: 'nowrap' }}>
					{title}
				</Typography>
			</Stack>
			<Stack sx={{ minWidth: '300px', width: '100%' }}>
				<Typography variant="pg-m" sx={{ color: 'grey.800', maxWidth: '468px', ...sx }}>
					{value}
				</Typography>
			</Stack>
		</Stack>
	);
};

interface IrAnalysisSetupSummaryProps {
	rankedRMData: RankBasedMixtureData;
}

export const IrAnalysisSetupSummary: FC<IrAnalysisSetupSummaryProps> = ({ rankedRMData }) => {
	const { analysisResultData: analysisResult } = useContext(IrAnalysisResultContext);
	if (!analysisResult) return null;

	const { technology, measurementDeviceName, type, productAndBatchNumber, details, measurementId } = analysisResult.irAnalysis;

	const [drmSpectrumData, setDrmSpectrumData] = useState<IDrmSpectrumDataType[]>([]);
	const drmSpectrumDataAry: IDrmSpectrumDataType[] = [];
	useEffect(() => {
		if (rankedRMData) {
			rankedRMData.mixtures.every((item) =>
				drmSpectrumDataAry.push({
					id: item.drmCode,
					title: item.substanceName,
					x: rankedRMData.spectrum_x,
					y: item.scaled_spectrum_y,
					firstx: rankedRMData.x_range[0],
					lastx: rankedRMData.x_range[1],
				}),
			);
			setDrmSpectrumData(drmSpectrumDataAry);
		}
	}, [rankedRMData]);

	const { drmId, visible, resetIrDetail, setIrDRMDetail } = useIrDRMDetail();
	const matchedElements: IrDrmDetail[] = [];
	const referenceMaterials = analysisResult?.referenceMaterials;
	rankedRMData?.mixtures?.forEach((mixture) => {
		const ref = referenceMaterials.find((material) => material?.drmCode === mixture?.drmCode);
		if (ref && ref !== undefined) {
			matchedElements.push(ref);
		}
	});

	const unMatchedElements =
		analysisResult?.referenceMaterials !== null && rankedRMData?.mixtures !== null
			? analysisResult.referenceMaterials.filter(({ casNumber }) =>
					rankedRMData?.mixtures.every((res) => casNumber !== res.casNumber),
				)
			: [];

	const showNotMatch = unMatchedElements && unMatchedElements?.length > 0 && !analysisResult?.isIdentification();
	const showMatch = matchedElements && matchedElements?.length > 0;
	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	const drmDetailRef = React.createRef<HTMLDivElement>();
	return (
		<GenericCard
			title={
				<Typography variant="h3" sx={{ color: 'primary.main' }}>
					<Tr.IrPortal path="analysis-setup-summary.analysis-summary" />
				</Typography>
			}
			sx={{
				boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
				border: 'none',
				paddingX: 3,
				paddingY: 4,
			}}
		>
			<Stack>
				<Stack direction="row" id="analysis-setup-summary-id" columnGap={4}>
					<Stack direction="column" width="30.25rem" rowGap={0.5}>
						<SampleDetailIndicator
							title={<Tr.IrPortal path="analysis-setup-summary.analysis-id" />}
							value={analysisResult?.analysisId}
						/>
						<SampleDetailIndicator
							title={<Tr.IrPortal path="analysis-setup-summary.analysis-technology" />}
							value={technology}
						/>
						<SampleDetailIndicator title={<Tr.IrPortal path="analysis-setup-summary.type-of-analysis" />} value={type} />
						{/* <SampleDetailIndicator title={<Tr.IrPortal path="analysis-setup-summary.sample-solvent" />} value={solvent} /> */}
					</Stack>

					<Stack direction="column" width="calc(100% - 31.75rem)" rowGap={0.5}>
						<SampleDetailIndicator
							title={<Tr.IrPortal path="analysis-setup-summary.sample-measurement-device" />}
							value={measurementDeviceName || '-'}
						/>
						<SampleDetailIndicator
							title={<Tr.IrPortal path="analysis-setup-summary.product-and-batch-number" />}
							value={productAndBatchNumber || '-'}
						/>
						<SampleDetailIndicator
							title={<Tr.IrPortal path="analysis-setup-summary.additional-details" />}
							value={details || '-'}
						/>

						<SampleDetailIndicator
							title={<Tr.IrPortal path="analysis-setup-summary.measurementId" />}
							value={
								<OverflowText variant="pg-m" width="100%" color="grey.800" enableTooltip>
									{measurementId}
								</OverflowText>
							}
						/>
					</Stack>
				</Stack>
				{showMatch && (
					<>
						<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
						<Stack direction="column">
							<Typography variant="h3" sx={{ color: 'primary.main' }}>
								<Tr.IrPortal path="analysis-setup-summary.drm-matching" />
							</Typography>
							{matchedElements?.map((referenceMaterial, index) => (
								<Stack
									data-testid={`erm-detail-id-${index}`}
									sx={{
										marginTop: 1,
									}}
									key={`key-${index}-${referenceMaterial.id}`}
									id="drm-details-id"
								>
									{drmSpectrumData && (
										<IrDRMDetail
											analysisResult={true}
											drmId={referenceMaterial.id}
											drmData={referenceMaterial}
											showModal={false}
											ref={drmDetailRef}
										/>
									)}
								</Stack>
							))}
						</Stack>
					</>
				)}

				{showNotMatch && (
					<Stack direction="column" data-testid="no-match-erm-id">
						<Typography variant="h3" sx={{ color: 'error.main', marginTop: '32px', marginBottom: '16px' }}>
							<Tr.IrPortal path="analysis-setup-summary.drm-not-matching" />
						</Typography>
						<Stack>
							{unMatchedElements?.map(
								({
									id,
									name,
									linearFormula,
									solvent: drmSolvent,
									smilesCode,
									casNumber,
									version,
									physicalProductNumber,
								}) => (
									<div key={`not-found-${id}`}>
										<Divider sx={{ marginTop: 4, marginBottom: 4 }} />

										<Stack
											direction="row"
											sx={{
												height: '201px',
												borderColor: '#A9A9BA',
												marginTop: '24px',
												marginBottom: '32px',
											}}
										>
											<Stack marginRight={4}>
												<MolecularFormula
													width={250}
													height={200}
													sx={{
														width: '268px',
														height: '201px',
														borderColor: '#A9A9BA',
													}}
													smilesFormula={smilesCode}
													ref={molecularFormulaRef}
												/>
											</Stack>
											<Stack display="flex" justifyContent="center">
												<Stack direction="row" alignItems="center" marginTop={2} marginBottom={2}>
													<Typography sx={{ fontWeight: 700, fontSize: '22px', color: 'text.primary' }}>
														{name}
													</Typography>
												</Stack>
												<DRMIndicator
													label={<Tr.IrPortal path="drm-detail.drm-product-number" />}
													value={`${physicalProductNumber}${drmSolvent}`}
												/>
												<DRMIndicator label={<Tr.IrPortal path="drm-detail.erm-version" />} value={version} />
												<DRMIndicator label={<Tr.IrPortal path="drm-detail.cas-number" />} value={casNumber} />
												<DRMIndicator
													label={<Tr.IrPortal path="drm-detail.product-number" />}
													value={physicalProductNumber}
												/>
												<DRMIndicator
													label={<Tr.IrPortal path="drm-detail.linear-formula" />}
													value={linearFormula}
												/>
											</Stack>
											<TableButton.ViewSummary
												sx={{ marginLeft: 'auto', width: '7rem' }}
												onClick={() =>
													setIrDRMDetail({
														drmId: id,
														visible: true,
														onHandleAction: () => null,
														actionLabel: '',
														drmSpectrumData: drmSpectrumData,
													})
												}
											>
												<Tr.IrPortal path="new-analysis.view-details" />{' '}
											</TableButton.ViewSummary>
										</Stack>
									</div>
								),
							)}
						</Stack>
					</Stack>
				)}
			</Stack>
			{<IrDRMDetail showModal visible={visible} drmId={drmId} ref={drmDetailRef} onCloseClick={() => resetIrDetail()} />}
		</GenericCard>
	);
};
