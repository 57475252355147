import { useContext, useState } from 'react';
import { Collapse, Stack, Tab, Tabs, Typography, tabsClasses } from '@mui/material';
import { DataTestId, Tr } from '@utils';
import { ReferenceMaterialResultView } from '@components/portal';
import { GenericCard, OverflowText } from '@components/common';
import { AnalysisResultContext } from './analysis-result-context';
import { AnalysisCommentField } from './AnalysisCommentField';

export const AnalysisMatchComparison = () => {
	const { analysisResultData } = useContext(AnalysisResultContext);
	const [selectedRMIndex, setSelectedRMIndex] = useState(0);

	const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
		setSelectedRMIndex(newValue);
	};
	if (!analysisResultData || analysisResultData.isError() || !analysisResultData.isMatched()) return null;

	return (
		<GenericCard
			sx={{
				paddingX: 3,
				paddingY: 4,
			}}
		>
			{(analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) &&
			analysisResultData.resultMatches.length > 1 ? (
				<Tabs
					value={selectedRMIndex || false}
					onChange={onTabChange}
					style={{ marginBottom: '32.5px' }}
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						[`& .${tabsClasses.scrollButtons}`]: {
							'&.Mui-disabled': { opacity: 0.3 },
						},
					}}
				>
					{analysisResultData.resultMatches.map((drm, index) => (
						<Tab
							key={`index-${index}-drmId-${drm.id}`}
							data-testid={DataTestId.getStaticTestId(`drm-test-id-${index}`)}
							label={<OverflowText enableTooltip={true}>{drm.substanceName}</OverflowText>}
							value={index}
						/>
					))}
				</Tabs>
			) : null}

			{(analysisResultData.isExecutedSuccessful() || analysisResultData.isRejected()) && (
				<>
					<Stack direction="row" justifyContent="space-between" width={1}>
						<Typography variant="h2" color="text.primary" marginBottom={4}></Typography>
					</Stack>

					<Typography variant="h3" sx={{ color: 'primary.main' }}>
						{analysisResultData.isQualitative() ? (
							<Tr.Portal path="analysis-result.predicted-comparison" />
						) : (
							<Tr.Portal path="analysis-result.input-spectrum" />
						)}
					</Typography>

					{analysisResultData.resultMatches.map((_, i) => (
						<Collapse in={selectedRMIndex === i} key={i}>
							<ReferenceMaterialResultView
								hasSubtitle={true}
								containerSx={{ marginTop: '-2rem' }}
								resultData={analysisResultData}
								selectedErmIndex={i}
								data-testid="reference-material-result-view"
							/>
						</Collapse>
					))}
				</>
			)}

			<AnalysisCommentField />
		</GenericCard>
	);
};
