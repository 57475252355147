import { ConfirmPopup, LoadingSpinnerOverlay, Notification } from '@components/common';
import { config } from '@config';
import { useAuthorizeConfig, useIdleDetection, useNavigateUrl, usePreloadImage, useService, useUserHub } from '@hooks';
import { useDataTestId } from '@hooks/useDataTestId';
import { useScrollTop } from '@hooks/useScrollTop';
import { ROUTES } from '@routes';
import {
	alertService,
	authService,
	configurationService,
	isTransmissionSpectraEnabled,
	locationService,
	notificationService,
	organizationService,
	userService,
} from '@services';
import { labService } from '@services/core/lab/lab.service';
import { setFlags, setUrlHistory, setUser, userSelector } from '@store/slices/common.slice';
import { setIrTransmissionSpectra } from '@store/ir-slices/ir.slice';
import { setLocation } from '@store/slices/location.slice';
import { isMillipore, isTestEnvironment, RxUtils, TypeUtils } from '@utils';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { UserStatus, UserStoreModel } from './models';

export const App: FC = () => {
	const router = useRoutes(ROUTES);
	const { data: notification } = useService(notificationService.getSubject);
	const { data: alert } = useService(alertService.getSubject);
	const dispatch = useDispatch();
	const user = useSelector(userSelector);
	const navigate = useNavigate();
	usePreloadImage('/assets/warning.svg', [location]);

	useEffect(() => {
		const gaScript = document.createElement('script');
		gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${config.googleAnalytics.trackingId}`;
		gaScript.async = true;
		document.head.appendChild(gaScript);
		const gaSetup = document.createElement('script');
		// TO DO: DELETE ANY INNER HTML USED
		gaSetup.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());

		gtag('config', "${config.googleAnalytics.trackingId}");
		gtag('event', 'screen_view', { app_name: "${window.location.hostname || ''}"});
		gtag('event', 'screen_view', { app_version: "${process.env.REACT_APP_VERSION || ''}"});
		gtag('event', 'screen_view', { app_Id: 1 });
		`;

		document.head.appendChild(gaSetup);

		const metaTag = document.createElement('meta');
		metaTag.name = 'robots';
		metaTag.content = 'noindex, nofollow';

		isTestEnvironment && document.head.appendChild(metaTag);

		return () => {
			isTestEnvironment && document.head.removeChild(metaTag);
		};
	}, []);

	useEffect(() => {
		if (user?.organizationId) {
			if (user?.organizationId) {
				RxUtils.promisify(organizationService.get(+user.organizationId), (value) =>
					(window as any)?.gtag('set', { dimension1: value.name }),
				);
			}
		}
	}, [user?.organizationId]);

	useEffect(() => {
		RxUtils.promisify(locationService.getLocation(), (location) => {
			dispatch(setLocation({ code: location.code }));
			document.title = isMillipore(location.code) ? 'MilliporeSigma – ChemisTwin Portal' : 'Merck – ChemisTwin Portal';
		});
	}, [user]);

	const handleInactiveUser = () => {
		if (user?.status === UserStatus.INACTIVE) {
			authService.logout();
			dispatch(setUser(null));
			navigate('/login');
			dispatch(setUrlHistory(['/']));
			return false;
		}
		return true;
	};

	const handlePortalUserData = () => {
		user?.username &&
			user?.roles &&
			!!user?.organizationId &&
			RxUtils.promisify(userService.get(user?.username), ({ name, surname, roles, laboratories, status }) => {
				if (!handleInactiveUser()) return;
				const currentLabId =
					laboratories?.find((lab) => lab.id.toString() === user?.currentLabId && lab.isActive)?.id ??
					laboratories?.find((lab) => !!lab.isActive)?.id;
				const userModel = TypeUtils.transform(UserStoreModel, {
					...user,
					name,
					surname,
					roles,
					laboratories,
					currentLabId: currentLabId?.toString() ?? '',
					isApiCalled: true,
					status,
				});

				RxUtils.promisify(userService.getCurrentUserPermissions(user?.currentLabId), (permissions: string[]) => {
					userModel.permissions = permissions;
					dispatch(setUser(userModel));
				});

				if (user?.currentLabId) {
					RxUtils.promisify(labService.get(+user?.currentLabId), (data) => {
						const transmissionEnabled = isTransmissionSpectraEnabled(data);
						dispatch(setIrTransmissionSpectra(transmissionEnabled));
					});
				}
			});
	};
	useEffect(() => {
		// reset user if changed somehow
		handlePortalUserData();

		user?.roles &&
			!user?.organizationId &&
			RxUtils.promisify(userService.getCurrentUserPermissions(), (permissions: string[]) => {
				if (!handleInactiveUser()) return;
				dispatch(
					setUser({
						...user,
						permissions,
					}),
				);
			});
	}, [user?.name, user?.surname, user?.roles?.join(','), user?.currentLabId]);

	const getFlag = (key: string) => {
		RxUtils.promisify(configurationService.getConfiguration(key), ({ status }) => dispatch(setFlags({ [key]: status })));
	};

	useEffect(() => {
		getFlag('nmr-pipeline');
		getFlag('ir-pipeline');
	}, []);
	useAuthorizeConfig();
	useIdleDetection();
	const loc = useLocation();
	useScrollTop([loc.pathname]);
	useDataTestId();
	useUserHub();
	useNavigateUrl();

	return (
		<>
			<Notification
				open={!!notification}
				onClose={() => notificationService.clear()}
				alertProps={{ variant: 'filled' }}
				{...notification}
			/>
			<ConfirmPopup visible={!!alert} {...alert}>
				{alert?.content}
			</ConfirmPopup>
			<LoadingSpinnerOverlay
				sx={{
					position: 'absolute',
				}}
			/>
			{router}
		</>
	);
};
