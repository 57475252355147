import { DataTableBackend } from '@components/common';
import { getParams, useFilterSearchParams } from '@hooks/useFilterSearchParams';
import { useService } from '@hooks/useService';
import { Stack } from '@mui/material';
import { nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { DataTestId } from '@utils/DataTestId';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NmrBulkUploadReportColumns } from './Columns';
import { SortOrder } from '@models/sorting';

export const NmrBulkUploadReport = () => {
	const { searchParams } = useFilterSearchParams('createTime', SortOrder.DESC);

	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');

	const { t, ready } = useTranslation('admin');
	const translate = (path: string) => (ready ? t(path) : '');
	const { data: reportList } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		return nmrDrmOrderService.getBatchUploads(searchParams);
	}, [searchParams]);

	const columns = NmrBulkUploadReportColumns(reportList?.data);

	return (
		<Stack data-testid={DataTestId.getStaticTestId('drm-pipeline-report-wrapper-id')}>
			<DataTableBackend
				data={reportList?.data || []}
				columns={columns}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: reportList?.totalPages ?? 1 }}
				searchText={searchTextfieldValue}
				searchPlaceholder={translate('drm-pipeline.reports-page.nmr-search-placeholder')}
				onSearchTextChange={setSearchTextfieldValue}
				searchFieldProps={{
					sx: {
						width: (250 / 1440) * window.innerWidth,
						minWidth: '250px',
						marginLeft: 1,
					},
				}}
				headerCellProps={{
					sx: {
						fontSize: '14px',
						fontWeight: 700,
						lineHeight: '105%',
						color: 'text.primary',
					},
				}}
			/>
		</Stack>
	);
};
