import { InfoIcon } from '@components/icons';
import { Stack, StackProps, SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ExtendedComponent } from 'src/types';

type TInfoIconOutlined = {
	iconSx?: SxProps;
};

export const InfoIconOutlined: FC<StackProps & TInfoIconOutlined> = ({ iconSx, sx, ...otherProps }) => (
	<Stack
		sx={{
			minWidth: 20,
			maxWidth: 20,
			height: 20,
			backgroundColor: 'secondary.main',
			borderRadius: '50%',

			...sx,
		}}
		alignItems="center"
		justifyContent="center"
		{...otherProps}
	>
		<InfoIcon sx={{ fontSize: 10, ...iconSx }} />
	</Stack>
);

interface IInfoMessageBoxProps {
	message?: string | ReactNode;
	title?: string;
}
export const InfoMessageBox: ExtendedComponent<IInfoMessageBoxProps> = ({ message, title, ...props }) => {
	return (
		<Stack direction="row" sx={{ backgroundColor: 'secondary.50', padding: 2, borderRadius: '12px' }} {...props}>
			<InfoIconOutlined sx={{ marginTop: 0.25 }} />
			<Stack direction="column" sx={{ marginLeft: 1 }} textAlign="left">
				{title && <Typography variant="h5">{title}</Typography>}
				<Typography variant="pg-s" lineHeight="180%">
					{message}
				</Typography>
			</Stack>
		</Stack>
	);
};
