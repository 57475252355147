import { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { AccordionProps, Grid, Typography, Accordion, Button } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AvatarIcon } from '@components/common/UserAvatar/AvatarIcon';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';

import { userSelector } from '@store/slices/common.slice';
import { useSelector } from 'react-redux';
import { DrmOrderCommentForm } from '../DrmOrderDetails/DrmOrderComments/DrmOrderCommentForm';
import { NmrDrmOrderCommentInfo, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { RxUtils } from '@utils/Rx';
import { alertService, notificationService } from '@services/index';
import { useTranslation } from 'react-i18next';

interface ICommentAccordionProps {
	itemList: Array<NmrDrmOrderCommentInfo>;
	onCommentUpdate: (value: boolean) => void;
	dateFormat?: string;
	avatarIconTextColor?: string;
}

export const CommentAccordion: FC<ICommentAccordionProps> = ({
	itemList,
	onCommentUpdate,
	dateFormat = 'dd/MM/yyyy HH:mm',
	avatarIconTextColor,
}) => {
	const loggedinUser = useSelector(userSelector);
	const [commentEditId, setCommentEditId] = useState<number | undefined>(0);

	const AccordionContainer = styled((props: AccordionProps) => (
		<Accordion disableGutters elevation={0} defaultExpanded={false} square {...props} />
	))(({ theme }) => ({
		'&:not(:last-child)': {
			borderBottom: `1px solid ${theme.palette.grey[200]}`,
		},
		'&:before': {
			display: 'none',
		},
	}));
	// TODO cleanup styled containers
	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'text.secondary' }} />} {...props} />
	))(() => ({
		paddingLeft: 0,
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(() => ({
		paddingLeft: '40px',
	}));

	const AccordionAvatarIcon = styled((props: any) => <AvatarIcon textColor={avatarIconTextColor} {...props} />)(({ theme }) => ({
		backgroundColor: `${theme.palette.grey[100]}`,
		'> p': {
			color: `${theme.palette.grey[400]}`,
		},
	}));
	const { t } = useTranslation('admin');

	const handleDeleteComment = (item: NmrDrmOrderCommentInfo) => {
		alertService.send({
			titleText: t('drm-order-details.order-comments.confirmation'),
			content: <Typography>{t('drm-order-details.order-comments.confirmation-explanation')}</Typography>,
			closeTextComponent: t('drm-order-details.order-comments.cancel'),
			confirmTextComponent: t('drm-order-details.order-comments.yes-delete'),
			confirmTextHeader: t('drm-order-details.order-comments.confirmation-title'),
			onConfirm: () =>
				RxUtils.promisify(
					nmrDrmOrderService.deleteComment(item.nmrDrmOrderId ?? 0, item.id),
					() => {
						notificationService.sendSuccess(t('drm-order-details.order-comments.delete-comment-success'));
					},
					(err) => notificationService.sendError(err.Message),
					() => {
						onCommentUpdate(true);
					},
				),
			onClose: () => null,
		});
	};

	return (
		<>
			{itemList.map((item, i) => (
				<AccordionContainer
					key={i}
					data-testid={`accordion-wrapper-${i}-id`}
					defaultExpanded={!commentEditId || commentEditId === 0 || commentEditId !== item.id ? false : true}
				>
					<AccordionSummary>
						<Grid container alignItems="center" spacing={1}>
							<Grid item>
								<AccordionAvatarIcon user={{ name: item.name, surname: item.surname }} />
							</Grid>
							<Grid item>
								<Typography variant="pg-m">
									<span style={{ color: '#01884C' }}>
										{item.name} {item.surname}
									</span>

									<Tr.Admin path={`erm-request-detail.accordion.title`} />

									{DateUtils.getFormattedDate(new Date(item.createTime), dateFormat)}
									{item.isEdited && (
										<Typography variant="pg-m" color="grey.800" marginLeft={1}>
											<Tr.Admin path={`erm-request-detail.accordion.edited`} />
										</Typography>
									)}
								</Typography>
							</Grid>
						</Grid>
					</AccordionSummary>
					{loggedinUser?.username === item.userId && commentEditId === item.id ? (
						<Grid item sx={{ paddingY: 2 }}>
							<DrmOrderCommentForm commentInfo={item} setCommentEditId={setCommentEditId} onCommentUpdate={onCommentUpdate} />
						</Grid>
					) : (
						<AccordionDetails>
							<Typography
								variant="pg-m"
								sx={{
									overflowWrap: 'anywhere',
								}}
							>
								{item.content}
							</Typography>
						</AccordionDetails>
					)}
					{loggedinUser?.username === item.userId && commentEditId !== item.id && (
						<Grid item sx={{ paddingY: 2 }}>
							<Grid container justifyContent="flex-end" spacing={2}>
								<Grid item>
									<Button data-testid="comment-delete-id" size="small" onClick={() => handleDeleteComment(item)}>
										<Tr.Admin path={`drm-order-details.order-comments.delete`} />
									</Button>
								</Grid>
								<Grid item>
									<Button
										data-testid="comment-edit-id"
										size="small"
										onClick={() => {
											setCommentEditId(item.id);
										}}
									>
										<Tr.Admin path={`drm-order-details.order-comments.edit`} />
									</Button>
								</Grid>
							</Grid>
						</Grid>
					)}
				</AccordionContainer>
			))}
		</>
	);
};
