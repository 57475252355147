import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RankBasedMixtureData, SampleSpectrumInfo } from '@services';
import { Tr, SpectraColorCodes, mixtureColorCodes } from '@utils';

import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/ir-slices/ir.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getTransMissionData } from '@utils';

interface IOverlaySpectrum {
	subtitleVisibility?: boolean;
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
}

export const IrOverlaySpectrum: FC<IOverlaySpectrum> = ({ subtitleVisibility, rankedRMData, userSpectrumInfo }) => {
	const { t } = useTranslation('irportal');
	const chartClassName = `c-overlay-${rankedRMData.rank}-chart`;

	const [chartDataList, setChartDataList] = useState<any>([]);
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra || false);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};

	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			//set user spectrum
			const chartDataAry: any = [];
			const userChartData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.input-spectrum'),
			};
			chartDataAry.push(userChartData);

			//set mixture chart data
			for (let i = 0; i < rankedRMData?.mixtures.length; i++) {
				const mixture = rankedRMData?.mixtures[`${i}`];
				const mixtureChartData = {
					y: displaySettingsToggle ? getTransMissionData([...mixture['scaled_spectrum_y']]) : mixture['scaled_spectrum_y'],
					x: rankedRMData.spectrum_x,
					type: 'scatter',
					mode: 'lines',
					marker: { color: mixtureColorCodes[`${i}`] },
					direction: 'counterclockwise',
					name: mixture.substanceName,
				};
				chartDataAry.push(mixtureChartData);
			}

			//set combined spectrum y
			const combinedChartData = {
				y: displaySettingsToggle
					? getTransMissionData([...rankedRMData['combined_spectrum_y']])
					: rankedRMData['combined_spectrum_y'],
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: t('analysis-result.combined-spectrum'),
			};
			if (rankedRMData?.mixtures?.length > 1) {
				chartDataAry.push(combinedChartData);
			}
			setChartDataList(chartDataAry);
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle]);

	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }}>
			{chartDataList && userSpectrumInfo && rankedRMData && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={chartClassName}
					enableZoom={false}
					data={chartDataList}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					displaySettingsToggle={handleDisplaySettings}
					layout={{
						margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
						legend: {
							traceorder: 'reversed',
							yanchor: 'bottom',
							y: 1.1,
							xanchor: 'center',
							x: 0.4,
							orientation: 'h',
							bgcolor: 'transparent',
							font: { color: '#000000', size: 12 },
						},
						autosize: true,
						xaxis: {
							range: userSpectrumInfo.sample_x_range,
							showdividers: false,
							showline: true,
							zeroline: false,
							gridcolor: SpectraColorCodes.GRAY,
							griddash: 'dot',
							automargin: true,
						},
						yaxis: { showline: true, zeroline: false, automargin: true },
						shapes: [],
					}}
				/>
			)}
		</Stack>
	);
};
