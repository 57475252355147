import { Stack, Typography, TypographyProps } from '@mui/material';
import { FC, useMemo, useState, useEffect } from 'react';
import { DataTableBackend } from '../DataTableBackend';
import { useService } from '@hooks/useService';
import { getParams, useFilterSearchParams } from '@hooks/useFilterSearchParams';
import { SortOrder } from '@models/sorting';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LabPermission, UserResponseModel, UserStatus } from '@models/user';
import { Tr } from '@utils/Translation';
import { ExtendedColumn } from '@models/table';
import { DateUtils } from '@utils/Type';
import { OverflowText } from '../OverflowText';
import { userService } from '@services/core';
import { useHasPermissions } from '@hooks/useHasPermissions';
const DEFAULT_SORT_COLUMN = 'createdDate';

interface IStatusType {
	status: UserStatus;
	isLabActive?: boolean;
}

const Body1: FC<TypographyProps & IStatusType> = ({ status, isLabActive = true, sx, ...props }) => (
	<Typography variant="pg-s" sx={{ color: status === UserStatus.ACTIVE && isLabActive ? 'text.main' : 'grey.800', ...sx }} {...props} />
);

interface ILabUsersTableProps {
	labActive?: boolean;
	triggerRequest?: boolean;
	hasDeactivatedUsers: (value: boolean) => void;
	refreshDetails: () => void;
}

export const LabUsersTable: FC<ILabUsersTableProps> = ({ labActive = true, triggerRequest, hasDeactivatedUsers }) => {
	const { searchParams } = useFilterSearchParams(DEFAULT_SORT_COLUMN, SortOrder.RESET);
	const { orgId, id } = useParams();
	const { t } = useTranslation('common');
	const { t: tPortal } = useTranslation('portal');

	const [searchTextfieldValue, setSearchTextfieldValue] = useState<string>('');

	const { data: userList } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		searchParams.set('pageSize', '4');
		searchParams.set('OrganizationId', orgId ?? '');
		searchParams.set('LaboratoryIds', id ?? '');

		return userService.getAllWithParams(searchParams);
	}, [searchParams, triggerRequest]);

	// TO DO: THAT IS WRONG! FIX IT!
	const checkUsersLabCount = () => {
		const newDeactivatedUsers = new Array<UserResponseModel>();
		hasDeactivatedUsers(false);
		if (userList) {
			userList.data.forEach((user) => {
				if (user.laboratories?.length === 1) {
					newDeactivatedUsers.push(user);
					hasDeactivatedUsers(newDeactivatedUsers.length > 0); //set users who has only current lab and may be deactivated later
				}
			});
		}
	};

	useEffect(() => {
		checkUsersLabCount();
	}, [userList]);

	const lastActiveVisibility = useHasPermissions(LabPermission.USER_LAST_ACTIVE);

	const columns = useMemo<ExtendedColumn<UserResponseModel>[]>(
		() => [
			{
				accessor: 'fullName',
				Header: (
					<Stack sx={{ marginLeft: 2 }}>
						<Tr.Common path="name" />
					</Stack>
				),
				Cell: ({ value, row }) => (
					<Body1 isLabActive={labActive} sx={{ paddingLeft: 2, fontWeight: 400 }} status={row.original.status}>
						{value}
					</Body1>
				),
			},
			{
				accessor: 'lastActivity',
				hidden: !lastActiveVisibility,
				Header: <Tr.Common path="last-active-date" />,
				Cell: ({ value, row }) => (
					<Body1 isLabActive={labActive} sx={{ fontWeight: 400 }} status={row.original.status}>
						{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy') : '-'}
					</Body1>
				),
			},
			{
				accessor: 'email',
				Header: <Tr.Common path="email" />,
				Cell: ({ value, row }) => (
					<OverflowText
						enableTooltip={true}
						sx={{
							color: row.original.status === UserStatus.ACTIVE && labActive ? 'text.main' : 'grey.400',
							fontWeight: 400,
						}}
					>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'roles',
				Header: <Tr.Portal path="user-management.role" />,
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<Body1 isLabActive={labActive} sx={{ fontWeight: 400 }} status={row.original.status}>
						{value?.length > 0 ? value.map((userRole) => tPortal(`user-management.roles.${userRole}`)).join(', ') : '-'}
					</Body1>
				),
			},
		],
		[],
	);
	return (
		<Stack direction="column" overflow="hidden">
			<DataTableBackend
				data={userList?.data || []}
				columns={columns.filter((col) => !col.hidden)}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userList?.totalPages ?? 1 }}
				searchText={searchTextfieldValue}
				searchPlaceholder={t('lab-details.search-placeholder')}
				onSearchTextChange={setSearchTextfieldValue}
				searchFieldProps={{ sx: { width: (400 / 1440) * window.screen.width, borderColor: 'grey.200', borderWidth: '1.5px' } }}
			/>
		</Stack>
	);
};
