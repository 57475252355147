export enum QuantitiveSpectrumStatus {
	EXECUTED = 'Executed',
	EXECUTING = 'Executing',
	TECHNICAL_ERROR = 'TechnicalError',
}

export class QuantitivePeakType {
	id: number;
	spectrumDataId: number;
	chemShift: string;
	multiplicity: string;
	couplingConstant: string;
	numberOfH: string;
	relativeIntegral: string;
	absoluteIntegral: string;
	isSelected: boolean;
}

export class QuantitativeSpectrum {
	id: number;
	analysisId: number;
	spectrum: string;
	status: QuantitiveSpectrumStatus;

	peaks: QuantitivePeakType[];

	public isExecuted(): boolean {
		return this.status === QuantitiveSpectrumStatus.EXECUTED;
	}

	public isExecuting(): boolean {
		return this.status === QuantitiveSpectrumStatus.EXECUTING;
	}

	public isTechnicalError(): boolean {
		return this.status === QuantitiveSpectrumStatus.TECHNICAL_ERROR;
	}
}

export class SpectrumData {
	analysisId: number;
	peaks: QuantitivePeakType[];
	spectrum: string;
	status: QuantitiveSpectrumStatus;
}
