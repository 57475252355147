import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userSelector } from '@store/slices/common.slice';
import { ExtendedComponent } from 'src/types';

export const Analytics: ExtendedComponent = ({ children }) => {
	const location = useLocation();
	const user = useSelector(userSelector);

	const capitalize = (str: string) => {
		return str
			.trim()
			.split(' ')
			.map((words) => {
				const [first, ...remain] = words.split('');
				return [first?.toUpperCase(), ...remain].join('');
			})
			.join(' ');
	};

	const getRole = () => (user?.roles ? `- (${user?.roles})` : '');

	useEffect(() => {
		const { pathname, search } = location;
		(window as any)?.gtag?.('event', 'page_view', {
			page_title: `${capitalize(pathname.replaceAll('/', ' ').toLocaleLowerCase())} ${getRole()} `,
			page_path: `${pathname} ${search}`,
		});
	}, [location]);

	return <>{children}</>;
};
