import { FC, useEffect, useState, useRef, Ref } from 'react';
import { CircularProgress, Popover, Stack, Typography } from '@mui/material';
import { AppNotification, NotificationHub, PagedResult } from '@models';
import { RxUtils, Scroll, Tr } from '@utils';
import { notificationService } from '@services';
import { useSignalRHubConnection } from '@hooks';
import { config } from '@config';
import { GenericCard } from '../GenericCard';
import { NotificationSettings } from './NotificationSettings';
import { NotificationContent } from './NotificationContent';
import { NotificationEmpty } from './NotificationEmpty';
import { HubConnection } from '@microsoft/signalr';
import { useDispatch, useSelector } from 'react-redux';
import { setBannerNotifications, userSelector } from '@store/slices/common.slice';
import { toast } from 'react-toastify';
import { ToastNotification } from '../ToastNotification';
import { ReactComponent as Check } from '@material-symbols/svg-600/outlined/check.svg';

export interface INotificationCenterProps {
	anchor: HTMLElement;
	open: boolean;
	setOpen: () => void;
	triggerRerender: () => void;
}

export const NotificationCenter: FC<INotificationCenterProps> = ({ anchor, open, setOpen, triggerRerender }) => {
	const [unReadNotifications, setUnReadNotifications] = useState<AppNotification[]>([]);
	const [readNotifications, setReadNotifications] = useState<AppNotification[]>([]);
	const [showUnread, setShowUnread] = useState(false);
	const [loading, setLoading] = useState(false);
	const user = useSelector(userSelector);
	const dispatch = useDispatch();

	const listRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		if (open) {
			loadUnReadNotifications();
		} else {
			const timeout = setTimeout(() => {
				setUnReadNotifications([]);
				setReadNotifications([]);
				setShowUnread(false);
			}, 500);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [open]);

	useSignalRHubConnection({
		hubUrl: config.notificationHubUrl,
		body: (connection: HubConnection) => {
			connection.send(NotificationHub.NOTIFICATION_CONNECTION_KEY, user?.username);
			connection.on(NotificationHub.NEW_NOTIFICATION_KEY, (notification: AppNotification) => {
				loadUnReadNotifications();
				loadBannerNotifications();
				if (!notification.isPermanent) {
					toast(<ToastNotification appNotification={notification} />);
				}
			});
		},
		shouldStartConnection: () => !!user?.name,
		deps: [user?.username],
	});

	useEffect(() => {
		if (user?.username) loadBannerNotifications();
	}, []);

	const loadNotifications = (isRead: boolean) => notificationService.getNotifications(1, 0, isRead, false);
	const loadUnReadNotifications = (disableLoader = false) => {
		!disableLoader && setLoading(true);
		RxUtils.promisify(
			loadNotifications(false),
			(data) => setUnReadNotifications(data.data),
			() => undefined,
			() => setLoading(false),
		);
	};
	const loadReadNotifications = (disableNotification = false) => {
		!disableNotification && setLoading(true);
		RxUtils.promisify(
			loadNotifications(true),
			(data) => setReadNotifications(data.data),
			() => undefined,
			() => setLoading(false),
		);
	};

	const loadBannerNotifications = () =>
		RxUtils.promisify(notificationService.getNotifications(1, 0, false, true), (appNotifications: PagedResult<AppNotification>) => {
			dispatch(setBannerNotifications(appNotifications.data));
		});
	const onChangeNotificationMarkStatus = (notificationId: number, status: boolean) =>
		RxUtils.promisify(notificationService.updateNotificationStatus(notificationId, status), () => {
			loadUnReadNotifications(true);
			showUnread && loadReadNotifications(true);
		});

	const handleMarkAllAsRead = () => {
		RxUtils.promisify(notificationService.updateBatchNotificationStatus(), () => {
			triggerRerender();
			setUnReadNotifications([]);
		});
	};

	const onViewMoreClick = () => {
		if (showUnread) {
			loadUnReadNotifications();
		} else {
			loadReadNotifications();
		}
		setShowUnread(!showUnread);
		(listRef.current as Ref<HTMLElement> as any)?.scrollIntoView();
	};

	const checkListEmpty = () => (showUnread ? readNotifications?.length < 1 : unReadNotifications?.length < 1);
	return (
		<Popover
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={open}
			anchorEl={anchor}
			onClose={() => setOpen()}
			sx={{ marginTop: '12px' }}
		>
			<NotificationSettings />
			<GenericCard
				sx={{
					padding: 0,
					width: '25rem',
					height: '28rem',
					overflowY: 'scroll',
					...Scroll.Y,
					...(checkListEmpty() && { justifyContent: 'center', display: 'flex', alignItems: 'center' }),
				}}
				dataTestId="notification-card-test-id"
			>
				<Stack
					sx={{
						justifyContent: 'center',
					}}
				>
					{loading ? (
						<Stack sx={{ minHeight: '25rem', marginTop: 'auto', justifyContent: 'center', alignItems: 'center' }}>
							<CircularProgress />
						</Stack>
					) : (
						<Stack direction={showUnread ? 'column-reverse' : 'column'} ref={listRef}>
							{/* UnRead Section */}
							{!showUnread &&
								(unReadNotifications?.length > 0 ? (
									<Stack data-testid="notification-wrapper-id">
										<Stack direction="row" justifyContent="space-between" flexWrap="wrap">
											<Typography margin="2rem 0 1rem 1.5rem" variant="pg-m">
												<Tr.Notification path="unread" />
												<> ({unReadNotifications?.length})</>
											</Typography>

											<Stack direction="row" margin="2.3rem 1.5rem 1rem 0">
												<Check data-testid="mark-all-as-read-id" width={20} height={20} fill="#01884C" />
												<Typography
													variant="pg-xs"
													color="primary"
													onClick={() => handleMarkAllAsRead()}
													sx={{
														cursor: 'pointer',
														'&:hover': {
															textDecoration: 'underline',
														},
													}}
												>
													<Tr.Notification path="mark-all-as-read" />
												</Typography>
											</Stack>
										</Stack>
										{unReadNotifications.map((notification, index) => (
											<NotificationContent
												setPopoverOpen={() => setOpen()}
												onStatusChange={(id, status) => onChangeNotificationMarkStatus(id, status)}
												notification={notification}
												key={`${notification.id}-${index}`}
											/>
										))}
									</Stack>
								) : (
									<NotificationEmpty />
								))}
							{/* Read Section */}
							{showUnread &&
								(readNotifications?.length > 0 ? (
									<Stack>
										<Typography margin="2rem 0 1rem 1.5rem" variant="pg-m">
											<Tr.Notification path="read" />
										</Typography>
										{readNotifications.map((notification, index) => (
											<NotificationContent
												setPopoverOpen={() => setOpen()}
												onStatusChange={(id, status) => onChangeNotificationMarkStatus(id, status)}
												isRead
												notification={notification}
												key={`${notification.id}-${index}`}
											/>
										))}
									</Stack>
								) : (
									<NotificationEmpty />
								))}
						</Stack>
					)}
				</Stack>
			</GenericCard>
			<Stack direction="row" justifyContent="center" padding="0.8rem 1.5rem" borderTop="1px solid #E1E1EA">
				<Typography
					onClick={() => onViewMoreClick()}
					variant="body1"
					sx={{ fontWeight: 450, fontSize: '0.8rem', color: 'text.main', cursor: 'pointer' }}
					data-testid="view-more-id"
				>
					<Tr.Notification path={showUnread ? 'view-unread-notifications' : 'view-read-notifications'} />
				</Typography>
			</Stack>
		</Popover>
	);
};
