import { TablePagination } from '@components/common';
import { DrmTabs, ErmSearchTabValues, IrDRMDetail, IrSubstanceList, ReferenceSearchField, RequestErmListItem } from '@components/irportal';
import { useIrDRMDetail, useIrFilterSearchParams } from '@hooks';
import { PagedResult } from '@models';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { irSubstanceService, notificationService } from '@services';
import { DataTestId, getIsFilterActive, RxUtils, Tr } from '@utils';
import { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleTabOnFilterChange } from '../Analysis/ir/AnalyticalProcedure/IrAnalyticalReferenceMaterial';

const MIN_SEARCH_INPUT_LEN = 1;

export const DRMDivider: FC = () => <Divider sx={{ borderBottomWidth: 1, borderColor: 'grey.200' }} />;

export const SearchReferences: FC = () => {
	const { t } = useTranslation('irportal');
	const [typedString, setTypedString] = useState('');
	const [searchResult, setSearchResult] = useState<PagedResult<any>>();
	const [substanceList, setSubstanceList] = useState<any[]>([]);
	const { filtering, searchParams, setSearchParams } = useIrFilterSearchParams();
	const { resetIrDetail, setIrDRMDetail, ...props } = useIrDRMDetail();
	const isQueryParametersActive = typedString.length > 0 || getIsFilterActive(filtering ?? []);
	const [currentTab, setCurrentTab] = useState<Maybe<ErmSearchTabValues>>(!isQueryParametersActive ? ErmSearchTabValues.Recent : null);

	useEffect(() => {
		searchResult?.data ? setSubstanceList([...searchResult.data]) : setSubstanceList([]);
	}, [searchResult]);

	useEffect(() => {
		if (typedString.length < MIN_SEARCH_INPUT_LEN) setSubstanceList([]);
	}, [typedString]);

	useEffect(() => {
		DataTestId.resetState();
		if (isQueryParametersActive) {
			getSubstanceList();
		} else if (currentTab === ErmSearchTabValues.All) {
			getSubstanceList();
		} else if (currentTab === ErmSearchTabValues.Recent) {
			getSubstanceList(true);
		} else {
			getSubstanceList();
		}
	}, [currentTab, searchParams]);

	useEffect(() => {
		if (filtering) {
			const qualityGradesFilters = filtering.filter((newFilter) =>
				Object.keys(newFilter).some((filterEntry) => filterEntry === 'qualityGrades'),
			);
			if (qualityGradesFilters.length > 0) {
				handleTabOnFilterChange(searchParams, setCurrentTab);
			}
		}
	}, [filtering, searchParams, setCurrentTab]);

	const getSubstanceList = (isLatestUsed?: boolean) => {
		if (isLatestUsed) {
			searchParams.set('isLatestUsed', 'true');
		} else {
			searchParams.set('isLatestUsed', 'false');
		}
		RxUtils.promisify(
			irSubstanceService.getSubstances(searchParams),
			(drmData) => {
				setSearchResult(drmData);
				drmData.data ? setSubstanceList([...drmData.data]) : setSubstanceList([]);
			},
			(error) => {
				notificationService.sendError(error.Message || t('search-references.search-error'));
			},
		);
	};

	const onSearchClear = () => {
		DataTestId.resetState();
		searchParams.set('query', '');
		searchParams.set('pageIndex', '1');
		setSearchParams(searchParams);
		setTypedString('');
		setCurrentTab(ErmSearchTabValues.All);
	};

	const onSearch = (query: string) => {
		searchParams.set('query', query);
		searchParams.set('pageIndex', '1');
		setSearchParams(searchParams);
	};
	const drmDetailRef = createRef<HTMLDivElement>();
	return (
		<Stack data-testid="search-references-wrapper-id" direction="column" sx={{ marginBottom: searchResult?.data.length ? 2 : 20 }}>
			<Stack
				direction="column"
				width={1}
				sx={{
					paddingTop: 5,
					position: 'sticky',
					top: 0,
					backgroundColor: 'background.default',
					zIndex: (theme) => theme.zIndex.modal,
				}}
			>
				<ReferenceSearchField
					placeholder={t('new-analysis.search-message')}
					value={typedString}
					onSearch={onSearch}
					onSearchClear={onSearchClear}
					onChange={(event) => {
						event.target.value === '' ? setCurrentTab(ErmSearchTabValues.Recent) : setCurrentTab(null);
						setTypedString(event.target.value);
					}}
					inputProps={{ 'data-testid': 'reference-search-input-id', sx: { paddingLeft: 0 } }}
					filteringSelections={filtering}
					searchParams={searchParams}
					onSearchParamsChange={setSearchParams}
					filterData={[]}
				/>

				<Stack direction="row" justifyContent="space-between" marginTop={5}>
					<DrmTabs
						value={currentTab}
						onChange={(_, newTab: ErmSearchTabValues) => {
							setCurrentTab(newTab);
							setTypedString('');
						}}
					/>

					{searchResult && searchResult.totalPages > 1 && currentTab !== ErmSearchTabValues.Recent && (
						<TablePagination
							count={searchResult?.totalPages}
							onChange={(page) => {
								searchParams.set('pageIndex', page?.toString());
								setSearchParams(searchParams);
							}}
						/>
					)}
				</Stack>

				{searchResult && (
					<Stack>
						<Typography sx={{ marginBottom: 1 }} data-testid="total-results-id">
							{typedString && !currentTab && `${searchResult.count} ${t('new-analysis.results-for')} "${typedString}"`}
							{!typedString && currentTab === ErmSearchTabValues.DrmSelectedLibrary
								? `${searchResult.count} ${t('new-analysis.total-result')}`
								: ''}
						</Typography>
					</Stack>
				)}
			</Stack>

			{substanceList.length > 0 ? (
				<Card variant="outlined" sx={{ backgroundColor: 'background.paper' }} data-testid="substance-list-card-test-id">
					<IrSubstanceList
						substanceList={substanceList}
						type="favorite"
						actionLabel={t('drm-detail.add-to-favorites')}
						onSubstanceListRefresh={() => getSubstanceList(currentTab === ErmSearchTabValues.Recent)}
						onSubstanceDetail={(visible: boolean, drmId: number) =>
							setIrDRMDetail({
								visible,
								drmId,
							})
						}
						itemSx={{ padding: '2rem 1rem' }}
					/>
				</Card>
			) : null}
			{searchResult?.data.length === 0 && currentTab !== ErmSearchTabValues.Recent ? (
				<Stack direction="column" rowGap={3} divider={<DRMDivider />}>
					<Typography variant="h5" textAlign="center" color="grey.700" data-testid="search-reference-no-drm-id">
						<Tr.IrPortal path="new-analysis.empty-search-message" />
					</Typography>
				</Stack>
			) : null}

			<RequestErmListItem />
			{useMemo(
				() => (
					<IrDRMDetail onCloseClick={() => resetIrDetail()} ref={drmDetailRef} {...props} />
				),
				[props],
			)}
		</Stack>
	);
};
