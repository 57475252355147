import React, { FC, forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import {
	FullScreenModalDialog,
	GenericCard,
	IGenericCardProps,
	MolecularFormula,
	OverflowText,
	PortalBadge,
	QualityGradeChip,
	ViewMore,
} from '@components/common';
import { Button, Divider, Stack, SxProps, Typography } from '@mui/material';
import { IrDrmDetail, irDrmService } from '@services';
import { DateUtils, mainTitleSpacer, RxUtils, Tr } from '@utils';
import { SupportedTechnology } from '@services/ir/ir-analysis/technology.enum';
import { TechnologyChip } from '@components/common/TechnologyChip';
import { differenceInWeeks } from 'date-fns';
import { IrDrmSpectrumChart } from '@components/common/IrDrmSpectrumChart/IrDrmSpectrumChart';
import { getBorderColor, getQualityGrader } from '@utils/QualityGrade';

interface IndicatorType {
	label?: ReactNode;
	value?: string | number;
	unit?: string;
	containerSx?: SxProps;
}

export interface IDrmSpectrumDataType {
	id: string;
	title: string;
	x: number[];
	y: number[];
	firstx: number;
	lastx: number;
}

export const invalidValues = ['NA', NaN, undefined, null];

export const IrDRMIndicator: FC<IndicatorType> = ({ label, value = '', unit, containerSx }) => (
	<Stack direction="row" alignItems="center" sx={{ marginBottom: 1.5, ...containerSx }}>
		<Stack>
			<Typography variant="pg-m" sx={{ width: 222, fontWeight: 400, color: 'text.primary', marginRight: 4 }}>
				{label}
			</Typography>
		</Stack>
		<Stack>
			<Typography variant="pg-m" sx={{ color: 'grey.800', lineHeight: 1.6, wordBreak: 'break-all' }}>
				{invalidValues.includes(value) ? '-' : value}
				{value && unit ? ` ${unit}` : ''}
			</Typography>
		</Stack>
	</Stack>
);

type TIrDrmDetailTitle = {
	titlePath: string;
};

const IrDrmDetailTitle: FC<TIrDrmDetailTitle> = ({ titlePath }) => {
	return (
		<Typography variant="h5" sx={{ fontSize: '1rem', fontWeight: 700, marginBottom: 1.5 }}>
			<Tr.IrPortal path={`drm-detail.${titlePath}`} />
		</Typography>
	);
};

const DrmDetailSectionDivider = () => {
	return <Divider sx={{ mt: 0.5, mb: 2, color: 'grey.200', height: '1px' }} />;
};

interface IDRMDetailProps {
	visible?: boolean;
	drmId?: Maybe<number>;
	actionLabel?: ReactNode;
	drmData?: IrDrmDetail;
	showModal?: boolean;
	onHandleAction?: (drmCode: string) => void;
	onCloseClick?: () => void;
	createReport?: boolean;
	analysisResult?: boolean;
	ref?: any;
	actionDisabled?: boolean;
}

export const IrDRMDetail = forwardRef<HTMLDivElement, IDRMDetailProps>(
	(
		{
			visible = false,
			drmId,
			actionLabel,
			onHandleAction,
			onCloseClick,
			drmData,
			showModal = true,
			createReport = false,
			analysisResult,
			actionDisabled = false,
		},
		ref,
	) => {
		const [detailData, setDetailData] = useState<IrDrmDetail>();
		const [spectrumData, setSpectrumData] = useState<IDrmSpectrumDataType>();
		useEffect(() => {
			if (drmId) {
				RxUtils.promisify(
					irDrmService.get(drmId),
					(drmDetail) => {
						setDetailData(drmDetail);
						RxUtils.promisify(irDrmService.getERMFile(drmDetail.id), (data) => {
							const xAry: number[] = [];
							for (let i = data['x'].start; i >= data['x'].stop; i--) {
								xAry.push(i);
							}
							const spectrumInfo: IDrmSpectrumDataType = {
								id: drmDetail.drmCode,
								title: drmDetail.substanceName,
								x: xAry,
								y: data?.['y'],
								firstx: data['x'].start,
								lastx: data['x'].stop,
							};
							setSpectrumData(spectrumInfo);
						});
					},
					onCloseClick,
				);
			}
		}, [drmId, drmData]);

		const borderColor = getBorderColor(detailData?.qualityGrade, !detailData?.isActive);
		const AdditionalInfo = () => (
			<>
				<IrDrmDetailTitle titlePath="additional-info" />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.reference-code" />} value={detailData?.drmCode} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.reference-version" />} value={detailData?.version} />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.release-date" />}
					value={detailData?.createTime ? DateUtils.getFormattedDate(new Date(detailData?.createTime), 'dd/MM/yyyy') : '-'}
				/>
				<Stack direction="row">
					<Stack sx={{ width: 222, marginRight: 4 }}>
						<Typography variant="pg-m" sx={{ fontWeight: 400, color: 'text.primary' }}>
							<Tr.IrPortal path="drm-detail.synonyms" />
						</Typography>
					</Stack>
					<Stack width="calc(100% - 254px)">
						<ViewMore
							content={detailData?.synonyms ?? '-'}
							typographyProps={{
								sx: {
									color: 'grey.800',
									lineHeight: 1.6,
								},
							}}
						/>
					</Stack>
				</Stack>
			</>
		);

		const AcquisitionParameters = () => (
			<>
				<IrDrmDetailTitle titlePath="acquisition-parameters" />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.erm-sampling-technique" />} value={detailData?.samplingTechnique} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.number-of-scans" />} value={detailData?.numberOfScans} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.erm-resolution" />} value={detailData?.resolution} unit="cm⁻¹" />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.erm-lower-range" />}
					value={detailData?.lastX?.split('.')[0]}
					unit="cm⁻¹"
				/>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.erm-upper-range" />}
					value={detailData?.firstX?.split('.')[0]}
					unit="cm⁻¹"
				/>
			</>
		);

		const PhysicalProductDetails = () => (
			<>
				<IrDrmDetailTitle titlePath="physical-product-details" />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.physical-product-number" />}
					value={detailData?.physicalProductNumber}
				/>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.physical-product-quality-grade" />}
					value={detailData?.physicalProductQualityGrade}
				/>
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.batch-number" />} value={detailData?.physicalProductBatchNumber} />
			</>
		);
		const isNew = useMemo(
			() => (detailData?.createTime ? Math.abs(differenceInWeeks(new Date(detailData?.createTime), new Date())) < 1 : false),
			[detailData],
		);
		const DrmCard: FC<IGenericCardProps> = ({ title, sx, ...props }) => (
			<GenericCard
				sx={{
					marginTop: 2,
					minWidth: 400,
					overflow: 'auto',
					border: 'none',
					paddingTop: 0,
					paddingX: 13,
					backgroundColor: 'transparent',
					...sx,
				}}
				title={title}
				{...props}
			>
				<Stack direction="row">
					<Stack>
						<MolecularFormula
							sx={{ maxWidth: '400px', width: '100%', height: '272px' }}
							ref={ref}
							width={400}
							smilesFormula={detailData?.smilesCode}
							borderColor={borderColor}
						/>
						<Stack
							position="relative"
							sx={{
								borderWidth: 2,
								borderStyle: 'solid',
								overflow: 'hidden',
								borderColor: `${borderColor}`,
								minWidth: '25rem',
								maxHeight: '272px',
								width: '100%',
								borderRadius: 2,
								marginTop: mainTitleSpacer,
							}}
						>
							{spectrumData && (
								<IrDrmSpectrumChart
									referenceMaterialId={detailData?.drmCode}
									axisTitle="Wavenumber [cm⁻¹]"
									hideZoomIcon={createReport}
									chartData={spectrumData}
									sx={{ padding: '0' }}
									uniqueId="drm-detail-chart"
									layout={{ margin: { pad: 0, t: 30, l: 65, r: 40, b: 260 } }}
									borderColor={borderColor}
								/>
							)}
						</Stack>
					</Stack>
					<Stack sx={{ marginLeft: 5, width: 1, maxWidth: 'calc(100% - 28rem)' }}>
						<Stack sx={{ width: 1, marginBottom: 2 }}>
							<Stack direction="row" spacing={1.5} sx={{ mb: 2 }}>
								{detailData && (
									<>
										<TechnologyChip
											technology={SupportedTechnology.IR}
											qualityGrade={detailData?.qualityGrade}
											isActive={detailData?.isActive}
											sx={{ height: '1.5rem', marginBottom: 0 }}
										/>
										<QualityGradeChip
											isReferenceActive={detailData?.isActive || false}
											qualityGrade={getQualityGrader(detailData?.qualityGrade)}
										/>
									</>
								)}
								{detailData && isNew && <PortalBadge color="warning" badgeContent={<Tr.Common path="new" />} />}
							</Stack>
							<OverflowText enableTooltip={true} variant="h3">
								{detailData?.name || ''}
							</OverflowText>
						</Stack>
						<Stack>
							<IrDrmDetailTitle titlePath="substance-details" />
							<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.cas-number" />} value={detailData?.casNumber} />
							<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.linear-formula" />} value={detailData?.linearFormula} />
							<IrDRMIndicator
								label={<Tr.IrPortal path="drm-detail.molecular-weight" />}
								value={detailData?.molecularWeight}
								unit="g/mol"
							/>
							<IrDRMIndicator
								label={<Tr.IrPortal path="drm-detail.smiles" />}
								value={detailData?.smilesCode ? detailData?.smilesCode : '-'}
							/>
						</Stack>
						<DrmDetailSectionDivider />
						<PhysicalProductDetails />
						<DrmDetailSectionDivider />
						<AcquisitionParameters />
						<DrmDetailSectionDivider />
						<AdditionalInfo />
					</Stack>
				</Stack>
			</GenericCard>
		);

		const DrmCardAnalysisResult: FC<IGenericCardProps> = () => (
			<Stack direction="column">
				<Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} margin={'15px 0px'}>
					<Typography variant="h4" sx={{ color: 'primary.text' }}>
						{drmData?.substanceName}
					</Typography>
					<Stack direction="row" spacing={1.5}>
						<QualityGradeChip
							isReferenceActive={detailData?.isActive || false}
							qualityGrade={getQualityGrader(detailData?.qualityGrade || '')}
						/>
						<TechnologyChip
							technology={SupportedTechnology.IR}
							qualityGrade={detailData?.qualityGrade}
							isActive={detailData?.isActive}
							sx={{ height: '1.5rem', marginBottom: 0 }}
						/>
					</Stack>
				</Stack>
				<Stack direction="row" marginBottom={5} columnGap={4}>
					<Stack width="30.25rem">
						<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.cas-number" />} value={detailData?.casNumber} />
						<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.linear-formula" />} value={detailData?.linearFormula} />
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.molecular-weight" />}
							value={detailData?.molecularWeight}
							unit="g/mol"
						/>
						<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.smiles" />} value={detailData?.smilesCode} />
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.physical-product-number" />}
							value={detailData?.physicalProductNumber}
						/>
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.physical-product-quality-grade" />}
							value={detailData?.physicalProductQualityGrade}
						/>
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.physical-product-batch-number" />}
							value={detailData?.physicalProductBatchNumber}
						/>
					</Stack>

					<Stack width="43.25rem">
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.sampling-technique" />}
							value={detailData?.samplingTechnique}
						/>
						<IrDRMIndicator label={<Tr.Portal path="drm-detail.number-of-scans" />} value={detailData?.numberOfScans} />
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.erm-resolution" />}
							value={detailData?.resolution}
							unit="cm⁻¹"
						/>
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.erm-lower-range" />}
							value={detailData?.lastX.split('.')[0]}
							unit="cm⁻¹"
						/>
						<IrDRMIndicator
							label={<Tr.IrPortal path="drm-detail.erm-upper-range" />}
							value={detailData?.firstX.split('.')[0]}
							unit="cm⁻¹"
						/>
						<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.reference-code" />} value={detailData?.drmCode} />
					</Stack>
				</Stack>
				<Stack direction="row">
					<Stack>
						<MolecularFormula
							borderColor={borderColor}
							ref={ref as React.Ref<HTMLDivElement>}
							width={475}
							height={400}
							smilesFormula={detailData?.smilesCode}
							sx={{ width: '100%', height: '400px' }}
						/>
					</Stack>
					<Stack
						position="relative"
						sx={{
							borderWidth: 2,
							borderStyle: 'solid',
							overflow: 'hidden',
							borderColor: borderColor,
							minWidth: '25rem',
							width: '100%',
							borderRadius: '0.5rem',
							maxHeight: '25rem',
							marginLeft: '2.5rem',
							flexGrow: 3,
						}}
					>
						{spectrumData && (
							<IrDrmSpectrumChart
								referenceMaterialId={detailData?.drmCode}
								axisTitle="Wavenumber [cm⁻¹]"
								hideZoomIcon={createReport}
								chartData={spectrumData}
								sx={{ width: '100%' }}
								uniqueId="drm-detail-chart"
								layout={{ margin: { pad: 10, t: 30, l: 70, r: 10, b: 150 } }}
								borderColor={borderColor}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>
		);

		return showModal ? (
			<FullScreenModalDialog
				data-testid="drm-modal-id"
				onClose={onCloseClick}
				open={visible}
				actionButtons={
					actionLabel && onHandleAction ? (
						<Stack direction="row" justifyContent="flex-end">
							<Button
								variant="contained"
								data-testid="detail-action-button-id"
								disableElevation
								disabled={actionDisabled}
								onClick={() => {
									detailData?.id && onHandleAction?.(detailData?.id.toString());
								}}
							>
								{actionLabel}
							</Button>
						</Stack>
					) : null
				}
			>
				{analysisResult ? <DrmCardAnalysisResult /> : <DrmCard />}
			</FullScreenModalDialog>
		) : (
			<Stack>
				{analysisResult ? (
					<DrmCardAnalysisResult />
				) : (
					<DrmCard
						title={
							<>
								<Typography variant="h3">{detailData?.title}</Typography>
								<Typography variant="body2" sx={{ marginLeft: 2, color: 'grey.500' }}>
									<Tr.IrPortal path="drm-detail.reference-code" /> {detailData?.drmCode}
								</Typography>
							</>
						}
					/>
				)}
			</Stack>
		);
	},
);
