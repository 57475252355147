import { Navigate } from 'react-router-dom';
import { AnalysisHistoryTabs, IRoute, PageType, PortalPageRoutes, PermissionEnum, DeviceManagementTabs } from '@models';
import { Tr } from '@utils';
import { AddCalibration, AuditTrailTable, CompletedAnalysisTable, OngoingAnalysisTable, RecalibrateDevice } from '@components/portal';
import {
	ActiveDeviceTable,
	ArchivedDeviceTable,
	AuditTrailTable as IrAuditTrailTable,
	CompletedAnalysisTable as IrCompletedAnalysisTable,
	InactiveDeviceTable,
} from '@components/irportal';
import IrOngoingAnalysisTable from '@components/irportal/OngoingAnalysisTable/OngoingAnalysisTable';
import { Portal } from './Portal';
import { Dashboard } from './Dashboard';
import { AnalysisHistory } from './AnalysisHistory';
import { SearchReferences } from './SearchReferences';
import { Favourites } from './Favourites';
import { PhysicalReferenceMaterial } from './PhysicalReferenceMaterial';
import { Analysis } from './Analysis';
import { AuthorizationComponent } from '@routes/Authorization';
import { OrganizationManagement, UserManagement } from './UserManagement';
import { YourProfile } from './YourProfile/YourProfile';
import { OrganizationSettings } from './OrganizationSettings';
import { Analytics } from '@components/common/Analytics';
import { Device, AddDevice } from './Device/nmr';
import { AnalysisProvider } from '@hooks/contexts/analysis/provider';
import { AnalysisResult } from './AnalysisResult/nmr/AnalysisResult';
import { LabDetails } from './LabDetails';
import { PortalElement } from './PortalElement';
import {
	Dashboard as IrDashboard,
	AnalysisHistory as IrAnalysisHistory,
	SearchReferences as IrSearchReferences,
	PhysicalReferenceMaterial as IrPhysicalReferenceMaterial,
	Favourites as IrFavourites,
	IrAnalysis,
	IrAnalysisResult,
	IrAddDevice,
	IrRecalibrate,
	GoToDevice,
	DeviceManagementTabView,
} from '@routes/IrPortal';
import { IrAnalysisProvider } from '@hooks/contexts/analysis/irprovider';
import LocalMallOutlined from '@mui/icons-material/LocalMallOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import { IrAddAccessory } from '@routes/IrPortal/Device/ir/IrAddAccessory';
import { ReactComponent as Science } from '@material-symbols/svg-600/outlined/science.svg';
import { ReactComponent as Home } from '@material-symbols/svg-600/outlined/home.svg';
import { ReactComponent as LabResearch } from '@material-symbols/svg-600/outlined/lab_research.svg';
import { ReactComponent as QuickReferenceAll } from '@material-symbols/svg-600/outlined/quick_reference_all.svg';
import { DeviceDetails } from '@components/portal/Device';
import {
	ActiveDeviceCalibrationTable,
	DeviceCalibrationManagement,
	InactiveDeviceCalibrationTable,
	WaitingDeviceCalibrationTable,
} from './DeviceCalibrationManagement';

export const PORTAL_ROUTES: IRoute = {
	path: '/portal',
	element: (
		<>
			<AuthorizationComponent path="portal">
				<Analytics>
					<Portal />
				</Analytics>
			</AuthorizationComponent>
		</>
	),
	text: 'Portal',
	children: [
		{
			path: PortalPageRoutes.DASHBOARD,
			element: (
				<PortalElement
					nmrComponent={<PortalElement nmrComponent={<Dashboard />} irComponent={<IrDashboard />} />}
					irComponent={<IrDashboard />}
				/>
			),
			icon: <Home width={24} height={24} />,
			text: <Tr.Portal path="dashboard.title" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_DASHBOARD],
		},
		{
			path: PortalPageRoutes.HISTORY,
			element: <PortalElement nmrComponent={<AnalysisHistory />} irComponent={<IrAnalysisHistory />} />,
			icon: <Science width={24} height={24} />,
			text: <Tr.Portal path="analysis-history.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
			children: [
				{
					path: AnalysisHistoryTabs.ONGOING_ANALYSIS,
					element: <PortalElement nmrComponent={<OngoingAnalysisTable />} irComponent={<IrOngoingAnalysisTable />} />,
					text: <Tr.Portal path="analysis-history.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
				},
				{
					path: AnalysisHistoryTabs.COMPLETED_ANALYSIS,
					element: <PortalElement nmrComponent={<CompletedAnalysisTable />} irComponent={<IrCompletedAnalysisTable />} />,
					text: <Tr.Portal path="analysis-history.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
				},
				{
					path: `${AnalysisHistoryTabs.AUDIT_TRAIL}`,
					element: <PortalElement nmrComponent={<AuditTrailTable />} irComponent={<IrAuditTrailTable />} />,
					text: <Tr.Portal path="analysis-history.audit-trail.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_AUDIT_TRAIL],
				},
				{
					path: `${AnalysisHistoryTabs.AUDIT_TRAIL}/:id`,
					element: <PortalElement nmrComponent={<AuditTrailTable />} irComponent={<IrAuditTrailTable />} />,
					text: <Tr.Portal path="analysis-history.audit-trail.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_AUDIT_TRAIL],
				},
			],
		},
		{
			path: PortalPageRoutes.SEARCH,
			element: <PortalElement nmrComponent={<SearchReferences />} irComponent={<IrSearchReferences />} />,
			icon: <LabResearch width={24} height={24} />,
			text: <Tr.Portal path="search-references.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DRM],
		},
		{
			path: PortalPageRoutes.FAVOURITES,
			element: <PortalElement nmrComponent={<Favourites />} irComponent={<IrFavourites />} />,
			icon: <StarBorderOutlined color="inherit" />,
			text: <Tr.Portal path="favourites.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.FAVORITE],
		},
		{
			path: PortalPageRoutes.AUDIT_TRAIL,
			element: <PortalElement nmrComponent={<AuditTrailTable />} irComponent={<IrAuditTrailTable />} />,
			icon: <QuickReferenceAll width={24} height={24} />,
			text: <Tr.Portal path="analysis-history.audit-trail.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_AUDIT_TRAIL],
		},
		{
			path: PortalPageRoutes.PHYSICAL_REFERENCE_MATERIAL,
			element: <PortalElement nmrComponent={<PhysicalReferenceMaterial />} irComponent={<IrPhysicalReferenceMaterial />} />,
			icon: <LocalMallOutlined color="inherit" />,
			text: <Tr.Portal path="physical-reference-materials.title" />,
			pageType: PageType.FULLSCREEN,
			hideTitleInPage: true,
			accessPermissions: [PermissionEnum.LabPermission.WEBSHOP],
		},
		{
			path: `${PortalPageRoutes.ANALYSIS}/:id`,
			element: (
				<PortalElement
					nmrComponent={
						<AnalysisProvider>
							<Analysis />
						</AnalysisProvider>
					}
					irComponent={
						<IrAnalysisProvider>
							<IrAnalysis />
						</IrAnalysisProvider>
					}
				/>
			),
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="new-analysis.title" />,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
		},
		{
			path: `${PortalPageRoutes.ANALYSIS_RESULT}/:id`,
			element: <PortalElement nmrComponent={<AnalysisResult />} irComponent={<IrAnalysisResult />} />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
		},
		{
			path: PortalPageRoutes.MANAGE_ORGANIZATION,
			element: <OrganizationManagement />,
			icon: <SettingsOutlined color="inherit" />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="manage-organization.title" />,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.MANAGE_USERS}/:id`,
			element: <UserManagement />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="manage-users.title" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.USER],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}`,
			element: <PortalElement nmrComponent={<DeviceCalibrationManagement />} irComponent={<DeviceManagementTabView />} />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.title" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
			children: [
				{
					path: DeviceManagementTabs.ACTIVE_DEVICES,
					element: <ActiveDeviceTable />,
					text: <Tr.Portal path="device-management.title" />,
					hideTitleInPage: true,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.ARCHIVED_DEVICES,
					element: <ArchivedDeviceTable />,
					text: <Tr.Portal path="device-management.title" />,
					hideTitleInPage: true,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.INACTIVE_DEVICES,
					element: <InactiveDeviceTable />,
					text: <Tr.Portal path="device-management.title" />,
					hideTitleInPage: true,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.ACTIVE,
					element: <ActiveDeviceCalibrationTable />,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.WAITING,
					element: <WaitingDeviceCalibrationTable />,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.INACTIVE,
					element: <InactiveDeviceCalibrationTable />,
					hasFooter: true,
				},
			],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_DEVICE}`,
			element: <PortalElement nmrComponent={<AddDevice />} irComponent={<IrAddDevice />} />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/:id`,
			element: <PortalElement nmrComponent={<Device />} irComponent={<IrRecalibrate />} />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
			children: [
				{
					path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/:id/${PortalPageRoutes.RECALIBRATE}/:calibrationId`,
					element: <PortalElement nmrComponent={<RecalibrateDevice />} irComponent={<IrRecalibrate />} />,
					text: <Tr.Portal path="device-management.device-details" />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.DEVICE],
				},
				{
					path: PortalPageRoutes.DEVICE_DETAILS,
					element: <PortalElement nmrComponent={<DeviceDetails />} irComponent={<IrRecalibrate />} />,
					text: <Tr.Portal path="device-management.device-details" />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.DEVICE],
				},
				{
					path: PortalPageRoutes.ADD_CALIBRATION,
					element: <PortalElement nmrComponent={<AddCalibration />} irComponent={<IrRecalibrate />} />,
					text: <Tr.Portal path="device-management.device-details" />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.DEVICE],
				},
			],
		},

		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/:id`,
			element: <GoToDevice />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.RESTORE_DEVICE}/:id`,
			element: <GoToDevice />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_ACCESSORY}`,
			element: <IrAddAccessory />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: PortalPageRoutes.YOUR_PROFILE,
			element: <YourProfile />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="your-profile.title" />,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.UserPermission.USER_PROFILE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ORGANIZATION_SETTINGS}/:id/:name`,
			element: <OrganizationSettings />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/:orgId/${PortalPageRoutes.LAB_DETAILS}/:id`,
			element: <LabDetails />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_DETAILS],
		},
		{
			hideFromMenu: true,
			path: '',
			element: <Navigate to={`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.DASHBOARD}`} replace />,
		},
	],
};
