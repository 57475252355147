import { parseJEOL } from 'jeolconverter';
import { TypeUtils } from '..';
import { JdfFileContentType, TParamArray } from '@models/nmr-drm-order';
import { TFileUploadChangableFields } from '@components/admin/DrmOrderDetails/drm-order-details.helper';
import { logger } from '@services/index';

type TParsedFile = {
	[key: string]: unknown;
};

const S_TO_MICROSEC = 10 ** 6;
const HZ_TO_MHZ = 10 ** 6;
export class FileUtils {
	static getExtension = (file: File): Maybe<string> => file.name.split('.').pop();

	static parseContent = (file: File, content: string): Maybe<Record<string, any>> => {
		const ext = FileUtils.getExtension(file);
		if (ext === 'jdx' || ext === 'dx') {
			return content
				.split(/##|\$\$/)
				.filter((part) => !part.startsWith('XYDATA'))
				.reduce<TParsedFile>((parsed, line) => {
					const [key, ...value] = line.split('=');
					key && (parsed[`${key}`] = value.join('=').trim());
					return parsed;
				}, {});
		}

		return null;
	};

	static readFileAsBuffer(file: File): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				const arrayBuffer = reader.result as ArrayBuffer;
				resolve(arrayBuffer);
			};

			reader.onerror = () => {
				reject(reader.error);
			};

			reader.readAsArrayBuffer(file);
		});
	}

	static getReceiverGain = (parsed: JdfFileContentType) => {
		const param_list = parsed.info.paramList.replaceAll('"', '').split(',');
		let idx = 0;
		const target = 'recvr_gain';
		let i = 0;
		for (const param of param_list) {
			if (param === target) {
				idx = i;
				break;
			}
			i = i + 1;
		}
		const gain = TypeUtils.returnValueOfKey<TParamArray[], TParamArray>(idx, parsed.parameters.paramArray)?.value;
		return gain;
	};

	static readJdf = async (file: File): Promise<TFileUploadChangableFields> => {
		const buffer = await this.readFileAsBuffer(file);
		const parsedJdf = TypeUtils.transform(JdfFileContentType, parseJEOL(buffer, logger));
		const Solvent = parsedJdf.info?.solvent;
		const NumberOfScans = Number(parsedJdf.info.numberOfScans);
		const Frequency = Number(parsedJdf.info.originFrequency?.[0]?.magnitude) / HZ_TO_MHZ;
		const MeasurementDate = new Date(
			parsedJdf.info.creationTime.year,
			parsedJdf.info.creationTime.month,
			parsedJdf.info.creationTime.day,
		);
		const Temperature = Number(parsedJdf.info.temperature?.magnitude);
		const RelaxationTime = parsedJdf.info.relaxationTime?.magnitude;
		const PulseWidth = Number(parsedJdf.info.pulseStrength90?.magnitude) * S_TO_MICROSEC;
		const ReceiverGain = Number(FileUtils.getReceiverGain(parsedJdf));
		const AcquisitionTime = parsedJdf.info.acquisitionTime?.[0]?.magnitude;

		const data = {
			Solvent,
			NumberOfScans,
			Frequency,
			MeasurementDate,
			Temperature,
			RelaxationTime,
			PulseWidth,
			ReceiverGain,
			AcquisitionTime,
		};
		return data;
	};

	static parseAcqusFileContent = (content: string): Maybe<Record<string, any>> => {
		return content
			.split(/##|\$\$/)
			.filter((part) => !part.startsWith('XYDATA'))
			.reduce<TParsedFile>((parsed, line) => {
				const [key, ...value] = line
					.replace(/[\r\n]+/g, ' ')
					.trim()
					.split('=');
				key && (parsed[`${key}`] = value.join('=').trim());
				return parsed;
			}, {});
	};
}
