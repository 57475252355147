import { Navigate } from 'react-router-dom';
import { PageRoutes, IRoute, PageType, PermissionEnum } from '@models';
import { Tr } from '@utils';
import { Dashboard } from './Dashboard';
import { AuthorizationComponent } from '@routes/Authorization';
import { Admin } from './Admin';
import { NewOrganization } from './NewOrganization';
import { EditOrganization } from './EditOrganization';
import { Analytics } from '@components/common/Analytics';
import { DrmManagement, DrmManagementTabWrapper } from './DrmManagement';
import { LabDetails } from '@routes/Portal/LabDetails';
import { ReactComponent as Database } from '@material-symbols/svg-600/outlined/database.svg';
import { ReactComponent as Home } from '@material-symbols/svg-600/outlined/home.svg';
import { AdminUsers } from './AdminUsers';
import { DrmPipeline, NmrPipeline } from './DrmPipeline';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { ReactComponent as ConversionPath } from '@material-symbols/svg-600/outlined/conversion_path.svg';
import { ReactComponent as SourceEnvironment } from '@material-symbols/svg-600/rounded/source_environment.svg';
import {
	IrDrmPipelineReport,
	IrDrmRequests,
	IrPipelineBacklog,
	NmrBulkUploadReport,
	NmrDrmRequests,
	SubstanceDetail,
} from '@components/admin';
import { IrPipeline } from './DrmPipeline/ir';
import { NmrPipelineBacklog } from '@components/admin/NmrPipelineBacklog';
import { NmrPipelineProduction } from '@components/admin/NmrPipelineProduction';
import { DrmOrderDetails } from './DrmOrderDetails';
import { NmrPipelineDeclined } from '@components/admin/NmrPipelineDeclined';
import { NmrPipelineReleased } from '@components/admin/NmrPipelineReleased';
import { EditNmrDrm } from './EditNmrDrm';
import { EditIrDrm } from './EditIrDrm';
import { ReactComponent as Labs } from '@material-symbols/svg-600/outlined/labs.svg';
import { SubstanceManagement } from './SubstanceManagement/SubstanceManagement';
import { IrDrmDatabase } from '@components/admin/IrDrmDatabase/IrDrmDatabase';
import { NmrRequestDetail } from './NmrRequestDetail/NmrRequestDetail';
import { IrRequestDetail } from './IrRequestDetail/IrRequestDetail';
import { NmrPipelineAudit } from '@components/admin/NmrPipelineAudit/NmrPipelineAudit';
import { CustomerManagement } from './CustomerManagement/CustomerManagement';
import { CustomerOrganizationManagement } from './CustomerOrganizationManagement';
import { CustomerUserManagement } from './CustomerUserManagement';
import { NmrDrmDatabase } from '@components/admin';

export const ADMIN_ROUTES: IRoute = {
	path: '/admin',
	element: (
		<AuthorizationComponent path="admin">
			<Analytics>
				<Admin />
			</Analytics>
		</AuthorizationComponent>
	),
	text: 'Admin',
	children: [
		{
			path: PageRoutes.DASHBOARD,
			element: <Dashboard />,
			icon: <Home width={24} height={24} />,
			text: <Tr.Admin path="dashboard.title" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_DASHBOARD],
		},
		{
			path: PageRoutes.CUSTOMER_MANAGEMENT,
			element: <CustomerManagement />,
			icon: <SourceEnvironment width={24} height={24} />,
			text: <Tr.Admin path="dashboard.customer-management" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			children: [
				{
					path: PageRoutes.ORGANIZATIONS,
					element: <CustomerOrganizationManagement />,
					hasFooter: true,
				},
				{
					path: PageRoutes.USERS,
					element: <CustomerUserManagement />,
					hasFooter: true,
				},
			],
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_CUSTOMER_MANAGEMENT],
		},
		{
			path: PageRoutes.SUBSTANCE_MANAGEMENT,
			element: <SubstanceManagement />,
			icon: <Labs width={24} height={24} />,
			text: <Tr.Admin path="dashboard.substance-management" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		{
			path: PageRoutes.DRM_MANAGEMENT,
			element: <DrmManagement />,
			icon: <Database width={24} height={24} />,
			text: <Tr.Admin path="dashboard.reference-management" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			children: [
				{
					path: PageRoutes.NMR,
					element: <DrmManagementTabWrapper />,
					children: [
						{
							path: PageRoutes.ERM_DATABASE,
							element: <NmrDrmDatabase />,
							hasFooter: true,
						},
						{
							path: PageRoutes.ERM_REQUESTS,
							element: <NmrDrmRequests />,
							hasFooter: true,
						},
					],
				},
				{
					path: PageRoutes.IR,
					element: <DrmManagementTabWrapper />,
					children: [
						{
							path: PageRoutes.ERM_DATABASE,
							element: <IrDrmDatabase />,
							hasFooter: true,
						},
						{
							path: PageRoutes.ERM_REQUESTS,
							element: <IrDrmRequests />,
							hasFooter: true,
						},
					],
				},
			],
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		{
			path: `${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.ERM_REQUESTS}/${PageRoutes.NMR}/:id`,
			element: <NmrRequestDetail />,
			hasFooter: true,
			hideFromMenu: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		{
			path: `${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.NMR}/${PageRoutes.EDIT_DRM}/:id`,
			element: <EditNmrDrm />,
			hideFromMenu: true,
			pageType: PageType.STANDARD,
			hideTitleInPage: false,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		{
			path: `${PageRoutes.SUBSTANCE_MANAGEMENT}/${PageRoutes.SUBSTANCE_DETAILS}/:id`,
			element: <SubstanceDetail />,
			text: <Tr.Admin path="substance-detail.substance-details" />,
			hideFromMenu: true,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		// FIX BR
		{
			path: `${PageRoutes.CUSTOMER_MANAGEMENT}/new`,
			element: <NewOrganization />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_CUSTOMER_MANAGEMENT],
		},
		{
			path: `${PageRoutes.CUSTOMER_MANAGEMENT}/:id/:name`,
			element: <EditOrganization />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_CUSTOMER_MANAGEMENT],
			children: [],
		},
		{
			path: `${PageRoutes.CUSTOMER_MANAGEMENT}/:orgId/labs/${PageRoutes.LAB_DETAILS_ADMIN}/:id`,
			element: <LabDetails />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_CUSTOMER_MANAGEMENT],
		},
		{
			path: `${PageRoutes.DRM_PIPELINE}`,
			element: <DrmPipeline />,
			icon: <ConversionPath width={24} height={24} />,
			text: <Tr.Admin path="drm-pipeline.name" />,
			pageType: PageType.FULLSCREEN,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.IrAdminPermission.VIEW_DRM_PIPELINE, PermissionEnum.NmrAdminPermission.VIEW_DRM_PIPELINE],
			children: [
				{
					path: PageRoutes.IR,
					element: <IrPipeline />,
					flag: 'ir-pipeline',

					hasFooter: true,
					children: [
						{ path: PageRoutes.DRM_PIPELINE_BACKLOG, element: <IrPipelineBacklog />, hasFooter: true },
						{ path: PageRoutes.DRM_PIPELINE_REPORTS, element: <IrDrmPipelineReport />, hasFooter: true },
					],
				},
				{
					path: PageRoutes.NMR,
					pageType: PageType.FULLSCREEN,
					element: <NmrPipeline />,
					flag: 'nmr-pipeline',
					hasFooter: true,
					children: [
						{
							path: PageRoutes.DRM_PIPELINE_BACKLOG,
							pageType: PageType.FULLSCREEN,
							element: <NmrPipelineBacklog />,
							hasFooter: true,
						},
						{
							path: PageRoutes.DRM_PIPELINE_PRODUCTION,
							pageType: PageType.FULLSCREEN,
							element: <NmrPipelineProduction />,
							hasFooter: true,
						},
						{
							path: PageRoutes.DRM_PIPELINE_DECLINED,
							pageType: PageType.FULLSCREEN,
							element: <NmrPipelineDeclined />,
							hasFooter: true,
						},
						{
							path: PageRoutes.DRM_PIPELINE_RELEASED,
							pageType: PageType.FULLSCREEN,
							element: <NmrPipelineReleased />,
							hasFooter: true,
						},
						{
							path: PageRoutes.DRM_PIPELINE_REPORTS,
							pageType: PageType.FULLSCREEN,
							element: <NmrBulkUploadReport />,
							hasFooter: true,
						},
						{
							path: PageRoutes.DRM_PIPELINE_AUDIT,
							pageType: PageType.FULLSCREEN,
							element: <NmrPipelineAudit />,
							hasFooter: true,
						},
					],
				},
			],
		},
		{
			path: PageRoutes.USERS_AND_ROLES,
			element: <AdminUsers />,
			pageType: PageType.STANDARD,
			text: <Tr.Admin path="users-and-roles.title" />,
			icon: <GroupOutlinedIcon width={24} height={24} />,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_USER_MANAGEMENT],
		},
		{
			path: `${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/:id`,
			element: <DrmOrderDetails />,
			hideFromMenu: true,
			pageType: PageType.STANDARD,
			hasFooter: true,
			backgroundEnabled: true,
		},
		{
			path: `${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.IR}/${PageRoutes.EDIT_DRM}/:id`,
			element: <EditIrDrm />,
			hideFromMenu: true,
			pageType: PageType.STANDARD,
			hideTitleInPage: false,
			hasFooter: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		{
			path: `${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.ERM_REQUESTS}/${PageRoutes.IR}/:id`,
			element: <IrRequestDetail />,
			hasFooter: true,
			hideFromMenu: true,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_SUBSTANCE_MANAGEMENT],
		},
		{
			hideFromMenu: true,
			path: '',
			element: <Navigate to={{ pathname: PageRoutes.DASHBOARD }} replace />,
			accessPermissions: [PermissionEnum.AdminPermission.VIEW_DASHBOARD],
		},
	],
};
