import { FC } from 'react';
import { IRouterLinkButtonProps, RouterLinkButton } from '@components/common';
import { Tr } from '@utils/Translation';

const RouterButton: FC<IRouterLinkButtonProps> = ({ children, sx, ...props }) => (
	<RouterLinkButton
		sx={{
			fontSize: '0.875rem',
			lineHeight: '120%',
			padding: 1,
			width: '7rem',
			borderWidth: '2px',
			borderColor: 'primary.main',
			...sx,
		}}
		variant="outlined"
		size="small"
		disableElevation
		{...props}
	>
		{children}
	</RouterLinkButton>
);

type TableButtonType = 'ViewSummary' | 'GotoAnalysis' | 'Export' | 'GotoDevice' | 'RestoreDevice';
export const TableButton: Record<TableButtonType, FC<IRouterLinkButtonProps>> = {
	ViewSummary: ({ href, sx, children, ...props }) => (
		<RouterButton href={href} sx={sx} {...props}>
			{children || <Tr.Common path="view-summary" />}
		</RouterButton>
	),
	GotoAnalysis: ({ href, sx, children, ...props }) => (
		<RouterButton href={href} sx={sx} variant="contained" {...props}>
			{children || <Tr.IrPortal path="go-to-analysis" />}
		</RouterButton>
	),
	Export: ({ sx, children, ...props }) => (
		<RouterButton sx={sx} variant="text" {...props}>
			{children || <Tr.IrPortal path="export" />}
		</RouterButton>
	),
	GotoDevice: ({ href, sx, children, ...props }) => (
		<RouterButton href={href} sx={sx} variant="contained" {...props}>
			{children || <Tr.IrPortal path="go-to-device" />}
		</RouterButton>
	),
	RestoreDevice: ({ href, sx, children, ...props }) => (
		<RouterButton href={href} sx={sx} variant="outlined" {...props}>
			{children || <Tr.IrPortal path="restore-device" />}
		</RouterButton>
	),
};
