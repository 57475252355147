import { FC, useEffect, useState } from 'react';
import { Box, Card, Divider, Stack, SxProps, Typography } from '@mui/material';
import { AddLabModalDialog } from '../LabDialogs';
import { ReactComponent as Add } from '@material-symbols/svg-600/outlined/add.svg';
import { useTranslation } from 'react-i18next';
import { Lab, Organization, organizationService } from '@services';
import { RxUtils } from '@utils/Rx';
import { LabPermission } from '@models/user';
import { useNavigate } from 'react-router';
import { useMainRoute } from '@hooks/useMainRoute';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { useRestrictedComponent } from '@hooks/useRestrictedComponent';
import { PageRoutes, PortalPageRoutes } from '@models/router';
import { ListCard } from '../ListCard';
import { DataTestId } from '@utils/DataTestId';

interface OrganizationLabsProps {
	organizationData?: Organization;
	sx?: SxProps;
}

export const OrganizationLabs: FC<OrganizationLabsProps> = ({ organizationData, sx }) => {
	const { t } = useTranslation('common');
	const [labData, setLabData] = useState<Lab[]>([]);
	const mainPath = useMainRoute();
	const canSeeLabDetails = useHasPermissions(LabPermission.VIEW_DETAILS);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
	const navigate = useNavigate();

	const getOrganizationData = () => {
		if (organizationData && organizationData.id) {
			RxUtils.promisify(organizationService.getLaboratories(+organizationData?.id), (value) => {
				setLabData(value.data);
			});
		}
	};

	useEffect(() => {
		getOrganizationData();
	}, [organizationData?.id]);

	const onSave = () => {
		setIsAddDialogOpen(false);
		getOrganizationData();
	};

	const navigateToLabDetails = (id: number) => {
		const isMerckAdmin = mainPath === '/admin';

		if (canSeeLabDetails) {
			if (isMerckAdmin) {
				navigate(
					`${mainPath}/${PageRoutes.CUSTOMER_MANAGEMENT}/${organizationData?.id}/labs/${PageRoutes.LAB_DETAILS_ADMIN}/${id}`,
				);
			} else if (mainPath === '/orgAdmin') {
				navigate(`${mainPath}/${PortalPageRoutes.DASHBOARD}/${organizationData?.id}/${PortalPageRoutes.LAB_DETAILS}/${id}`);
			} else {
				navigate(
					`${mainPath}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${organizationData?.id}/${PortalPageRoutes.LAB_DETAILS}/${id}`,
				);
			}
		}
	};
	return (
		<Stack
			flexWrap="wrap"
			flexDirection="row"
			rowGap={3}
			divider={<Divider sx={{ marginX: 1 }} />}
			sx={{ ...sx }}
			data-testid="organization-labs-wrapper"
		>
			{organizationData && isAddDialogOpen && (
				<AddLabModalDialog
					id={organizationData?.id}
					labCount={labData.length}
					isOpen={isAddDialogOpen}
					onCancel={() => setIsAddDialogOpen(false)}
					onSave={() => onSave()}
				/>
			)}
			{labData?.map((item, index) => (
				<ListCard
					key={item.name + index.toString()}
					data-testid={DataTestId.getStaticTestId(`lab-card-${index}`)}
					title={item.name}
					isActive={item.isActive}
					userCount={item.userCount}
					onClick={() => navigateToLabDetails(item?.id)}
				>
					<Stack direction="row">
						<Stack spacing={1} pr={1.5}>
							<Typography variant="subtitle3" color="grey.800">
								Technology
							</Typography>
							<Typography variant="pg-s" color="grey.800">
								{item.technology}
							</Typography>
						</Stack>
						<Divider orientation="vertical" />

						<Stack spacing={1} pl={1.5}>
							<Typography variant="subtitle3" color="grey.800">
								Admin Name
							</Typography>
							<Typography variant="pg-s" color="grey.800">
								{item.adminName}
							</Typography>
						</Stack>
					</Stack>
				</ListCard>
			))}
			{useRestrictedComponent(
				LabPermission.CREATE,
				<Card
					data-testid="organization-labs-add-button"
					sx={{
						padding: 2,
						height: 224,
						width: 400,
						boxShadow: '0px 1px 4px 0px rgba(15, 26, 46, 0.15),0px 0px 1px 0px rgba(15, 26, 46, 0.15)',
						'&:hover': {
							cursor: 'pointer',
							boxShadow: '0px 0px 4px 0px rgba(15, 26, 46, 0.15), 0px 16px 32px 0px rgba(15, 26, 46, 0.15)',
						},
					}}
					onClick={() => setIsAddDialogOpen(true)}
				>
					<Stack justifyContent="center" alignItems="center" height={1} flexGrow={1}>
						<Box
							sx={{
								width: 80,
								height: 80,
								borderRadius: '50%',
								backgroundColor: '#01884C1A',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Add width={40} height={40} fill="#01884c" />
						</Box>
						<Typography variant="label-s" color="primary.main" marginTop={2}>
							{t('organization-details.labs.add-lab')}
						</Typography>
					</Stack>
				</Card>,
			)}
		</Stack>
	);
};
