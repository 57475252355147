import { useEffect, useState } from 'react';
import { FilterKey, FilterValueType } from '@models/filter';
import { SortOrder } from '@models/index';
import { useSearchParams } from 'react-router-dom';

export const setIrParams = (searchParams: URLSearchParams) => {
	return {
		setQuery: (value?: string) => searchParams.set('query', (value || searchParams?.get('query')) ?? ''),
		setPageIndex: (value?: string) => searchParams.set('pageIndex', (value || searchParams?.get('pageIndex')) ?? '1'),
		setPageSize: (value?: string) => searchParams.set('pageSize', (value || searchParams?.get('pageSize')) ?? '15'),
		setSortBy: (sortBy?: string) => searchParams.set('sortBy', searchParams?.get('sortBy') ?? sortBy ?? ''),
		setSortOrder: (sortOrder?: string) => searchParams.set('sortOrder', searchParams?.get('sortOrder') ?? sortOrder ?? ''),
		setDeviceKey: (deviceKey?: string) => searchParams.set('deviceKey', searchParams?.get('deviceKey') ?? deviceKey ?? ''),
		setCompleted: (complete: string) => searchParams.set('completed', complete),
		setEntity: (value?: string) => value && searchParams.set('entity', value),
		setEntityId: (value?: string) => searchParams.set('entityId', value as string),
		setLibraryType: (value?: string) => value && searchParams.set('library', value),
	};
};

export const getIrParams = (searchParams: URLSearchParams) => {
	return {
		getQuery: () => searchParams.get('query') ?? '',
		getSortBy: () => searchParams.get('sortBy') ?? '',
		getSortOrder: () => searchParams.get('sortOrder') ?? '',
		getDeviceKey: () => searchParams.get('deviceKey') ?? '',
		getPageIndex: () => searchParams.get('pageIndex') ?? '1',
		getPageSize: () => searchParams.get('pageSize') ?? '',
	};
};

export const useIrFilterSearchParams = (sortBy?: string, sortOrder?: SortOrder, deviceKey?: string) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [filtering, setFiltering] = useState<FilterValueType[]>();
	const { setSortOrder, setSortBy, setCompleted, setDeviceKey, ...others } = setIrParams(searchParams);
	useEffect(() => {
		const newFilterValues: FilterValueType[] = [];

		Object.values(FilterKey).forEach((key) => {
			if (key === FilterKey.DATE_RANGE) {
				newFilterValues.push({
					[key]: searchParams
						?.get(key)
						?.split(',')
						?.map((d) => (d ? new Date(d) : null)),
				});
			}
			if (key === FilterKey.TO || key === FilterKey.FROM) {
				newFilterValues.push({ [key]: searchParams?.get(key) });
			} else {
				const values = searchParams?.getAll(key);
				values?.forEach((x) => newFilterValues.push({ [key]: x }));
			}
		});

		Object.values(others).forEach((val) => val?.());
		setSortOrder(sortOrder);
		setSortBy(sortBy);
		setCompleted('false');
		setDeviceKey(deviceKey);

		setFiltering(newFilterValues);
	}, [searchParams]);

	return {
		searchParams,
		setSearchParams,
		filtering,
		setFiltering,
	};
};
