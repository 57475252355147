import { IrMetaDataType } from '@hooks/ir/useIrParser';
import { MetaDataType } from '@hooks/useNmrParser';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';
import { IPeaksTouched } from '@models/ir-analysis';
import { IrAccessory, IrDevice, IrSubstance, NmrDevice, NmrSubstance } from '@services';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';

export enum QuantitativeStep {
	STEP_1 = 'Step1',
	STEP_2 = 'Step2',
}

export interface IProps {
	addedERMs: NmrSubstance[];
	updateAddedERMs: (type?: NmrSubstance[]) => void;
	quantitativeStep: Maybe<QuantitativeStep>;
	updateQuantitativeStep: (type?: Maybe<QuantitativeStep>) => void;
	nmrDevice: Maybe<NmrDevice>;
	updateNmrDevice: (type?: Maybe<NmrDevice>) => void;
	measurementType?: Maybe<QualitativeOrQuantitative>;
	updateMeasurementType: (type?: Maybe<QualitativeOrQuantitative>) => void;
	targetType?: Maybe<AnalysisTargetType>;
	updateTargetType: (type?: Maybe<AnalysisTargetType>) => void;
	metaData?: Maybe<MetaDataType>;
	updateMetaData: (type: Maybe<MetaDataType>) => void;
	changedPeaks: Partial<QuantitivePeakType>[];
	updateChangedPeaks: (type?: Partial<QuantitivePeakType>[]) => void;
	isAnalysisEditable?: Maybe<boolean>;
	updateAnalysisEditable: (type?: Maybe<boolean>) => void;
}

export interface IrProps {
	addedERMs: Maybe<IrSubstance[]>;
	updateAddedERMs: (type?: IrSubstance[]) => void;
	quantitativeStep: Maybe<QuantitativeStep>;
	updateQuantitativeStep: (type?: Maybe<QuantitativeStep>) => void;
	irDevice: Maybe<IrDevice>;
	updateIrDevice: (type?: Maybe<IrDevice>) => void;
	measurementTypeIr?: Maybe<QualitativeOrQuantitative>;
	analysisType?: Maybe<IrAnalysisType>;
	updateMeasurementType: (type?: Maybe<QualitativeOrQuantitative>) => void;
	updateAnalysisType: (type?: Maybe<IrAnalysisType>) => void;
	irAccessory: Maybe<IrAccessory>;
	updateIrAccessory: (type?: Maybe<IrAccessory>) => void;
	metaData?: Maybe<IrMetaDataType>;
	updateMetaData: (type: Maybe<IrMetaDataType>) => void;
	isAnalysisEditable?: Maybe<boolean>;
	updateAnalysisEditable: (type?: Maybe<boolean>) => void;
	selectedLibrary?: Maybe<string[]>;
	updateSelectedLibrary: (type?: Maybe<string[]>) => void;
	isPeaksChanged: Maybe<IPeaksTouched>;
	updatePeaksChanged: (type?: Maybe<IPeaksTouched>) => void;
}
