import { FC, ReactNode } from 'react';
import { IrAnalysisResult } from '@services';
import { Typography } from '@mui/material';
import { Tr } from '@utils';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';

type MessageMappingType =
	| 'IdentificationPositive'
	| 'IdentificationNegative'
	| 'PurityCheckPositive'
	| 'PurityCheckNegative'
	| 'QuantificationPositive'
	| 'QuantificationNegative';

const messageMapping: Record<MessageMappingType, ReactNode> = {
	IdentificationPositive: <Tr.IrPortal path="analysis-result-table.was-verified" />,
	IdentificationNegative: <Tr.IrPortal path="analysis-result-table.was-not-verified" />,
	PurityCheckPositive: <Tr.IrPortal path="analysis-result-table.was-verified" />,
	PurityCheckNegative: <Tr.IrPortal path="analysis-result-table.was-not-quantified" />,
	QuantificationPositive: <Tr.IrPortal path="analysis-result-table.was-quantified" />,
	QuantificationNegative: <Tr.IrPortal path="analysis-result-table.was-not-quantified" />,
};

export const IrAnalysisMatchedDescription: FC<{ analysisResult: IrAnalysisResult }> = ({ analysisResult }) => {
	const { resultMatches: results, irAnalysis, quantificationDetails: peakdata } = analysisResult;
	const { type, quantificationMethod } = analysisResult.irAnalysis;
	const messageType: 'Positive' | 'Negative' = results.length > 0 ? 'Positive' : 'Negative';

	return analysisResult.isQuantification() ? (
		<>
			<Typography marginY="1.5rem" fontWeight={500} variant="pg-m">
				<Tr.IrPortal path="analysis-result-table.label" />
				<strong style={{ color: '#01884C' }}>{irAnalysis?.sampleName?.split(/\.SPC|\.dx|\.spa/g)[0]}</strong>{' '}
				{messageMapping[`${type}${messageType}`]}
				{analysisResult.isQuantification() &&
					(!!quantificationMethod && quantificationMethod === 'PeakRatio' ? (
						<>
							<Tr.IrPortal path="analysis-result-table.peak-ration" />
							{`(${peakdata[0]?.peakMin} cm⁻¹ to ${peakdata[0]?.peakMax} cm⁻¹ /  ${peakdata[1]?.peakMin} cm⁻¹ to ${peakdata[1]?.peakMax} cm⁻¹)`}
							<Tr.IrPortal path="analysis-result-table.peak-label" />
						</>
					) : (
						<>
							<Tr.IrPortal path="analysis-result-table.peak-comparision" />
							{`${peakdata[0]?.peakMin} cm⁻¹ to ${peakdata[0]?.peakMax}  cm⁻¹ `}
							<Tr.IrPortal path="analysis-result-table.peak-label" />
						</>
					))}
			</Typography>
		</>
	) : (
		<Typography marginY="1.5rem" variant="pg-m">
			{results.length > 0 ? (
				<>
					<Tr.IrPortal path="analysis-result-table.label" />
					<strong style={{ color: '#01884C' }}>{irAnalysis?.sampleName?.split(/\.SPC|\.dx|\.spa/g)[0]}</strong>{' '}
				</>
			) : null}
			{results.length > 0 ? (
				type === IrAnalysisType.PURITYCHECK ? (
					<Tr.IrPortal path="analysis-result-table.checked-against-substance" />
				) : (
					<Tr.IrPortal path="analysis-result-table.identified-entire-lib" />
				)
			) : null}
		</Typography>
	);
};
