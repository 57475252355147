import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export const {
	actions: { setIrTransmissionSpectra, setChart },
	reducer,
} = createSlice({
	name: 'ir',
	initialState: {
		transmissionSpectra: false,
		chart: 'separate_chart',
	},
	reducers: {
		setIrTransmissionSpectra: (state, data: PayloadAction<Maybe<boolean>>) => {
			state.transmissionSpectra = data?.payload || false;
		},
		setChart: (state, data: PayloadAction<Maybe<any>>) => {
			state.chart = data?.payload || '';
		},
	},
});

export const useIrSettingsSelector = (state: RootState) => state.IrSettings;
