import { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Stack, Typography } from '@mui/material';
import { FormikTextfield } from '@components/common';
import { Tr } from '@utils';
import { PromiseUtils } from '@utils/PromiseUtils';
import { TableButton } from '@components/portal';
import { authService, notificationService } from '@services';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

type FormFields = Extendable<{
	email: string;
}>;

interface IForgotPassword {
	onBackClick: () => void;
	onSetNewPassword: (email: string, maskedMail: string) => void;
}

export const ForgotPassword: FC<IForgotPassword> = ({ onBackClick, onSetNewPassword }) => {
	const { t } = useTranslation('login');
	const formik = useFormik<FormFields>({
		initialValues: {
			email: '',
		},
		validationSchema: yup.object({
			email: yup.string().trim().max(250).email(t('reset.email-invalid')).required(t('reset.email-required')),
		}),
		onSubmit: () => {
			PromiseUtils.runPromise(
				() => authService.forgotPassword(formik.values.email.trim() || ''),
				(data) => onSetNewPassword(formik.values.email, data.nextStep.codeDeliveryDetails.destination || ''),
				(err) => notificationService.sendError(err.message),
			);
		},
	});
	return (
		<Stack data-testid="password-reset-wrapper-id">
			<Typography variant="h1">
				<Tr.Login path="reset.reset-password" />
			</Typography>
			<Typography variant="pg-m" sx={{ marginBottom: '2.5rem', fontSize: '0.875rem' }}>
				<Tr.Login path="reset.reset-content" />
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<FormikTextfield
					typographyProps={{ sx: { color: 'grey.800', fontSize: '13px', marginBottom: '5px' } }}
					sx={{ backgroundColor: 'grey.50' }}
					formikApi={formik}
					name="email"
					title={<Tr.Login path="reset.email-address" />}
					placeholder={t('login.enter-email')}
					inputProps={{ 'data-testid': 'reset-password-email-test-id' }}
				/>
				<Stack sx={{ flexDirection: 'row', marginTop: '2.5rem' }}>
					<Stack
						data-testid="reset-password-back-button-id"
						onClick={() => onBackClick()}
						direction="row"
						sx={{ cursor: 'pointer', alignItems: 'center' }}
					>
						<KeyboardBackspaceIcon color="primary" />
						<Typography color="primary" marginLeft="0.625rem">
							<Tr.Login path="register.back" />
						</Typography>
					</Stack>
					<TableButton.GotoAnalysis
						type="submit"
						size="small"
						disabled={!formik.isValid || !formik.dirty}
						sx={{ marginLeft: 'auto', display: 'flex', width: 'fit-content', height: '2.5rem' }}
						data-testid="reset-password-reset-button-id"
					>
						<Tr.Login path="reset.reset-password" />
					</TableButton.GotoAnalysis>
				</Stack>
			</form>
		</Stack>
	);
};
