import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { NmrDeviceCalibration, NmrCalibrationStatus } from '@services';
import { DateUtils } from '@utils/Type';
import { Tr } from '@utils/Translation';
import { getCalibrationStatusColors } from './device-calibration-colors';

const getValueLabel = (deviceCalibration: NmrDeviceCalibration) => {
	if (deviceCalibration?.isCalibrate?.() || deviceCalibration?.isCalibrating?.()) {
		return <Tr.Portal path="device-management.calibration-pending" />;
	} else if (deviceCalibration.isCalibrationError?.()) {
		return '-';
	}
	return deviceCalibration.linearity;
};

type IDeviceInfoProps = {
	recalibrationData: NmrDeviceCalibration;
	isDeviceActive: boolean;
};

export const DeviceInfo: FC<IDeviceInfoProps> = ({ recalibrationData, isDeviceActive }) => {
	const { nextCalibrationTime, lastCalibrationTime } = recalibrationData;

	const colors = getCalibrationStatusColors(isDeviceActive, recalibrationData?.status !== NmrCalibrationStatus.ACTIVE);
	const healthCheckColors =
		recalibrationData?.status === NmrCalibrationStatus.CALIBRATION_ERROR
			? {
					subtitle: 'error.main',
					content: 'error.main',
				}
			: {
					subtitle: colors.subtitle,
					content: colors.content,
				};
	const isValuesVisible =
		isDeviceActive &&
		recalibrationData.status !== NmrCalibrationStatus.INACTIVE &&
		recalibrationData.status !== NmrCalibrationStatus.CALIBRATION_NEEDED;
	const isCalibrationError =
		recalibrationData.status === NmrCalibrationStatus.CALIBRATION_ERROR ||
		recalibrationData.status === NmrCalibrationStatus.CALIBRATION_NEEDED;

	return (
		<Grid container columns={3} rowGap={2} margin="1rem 0">
			<Grid item md={1} sm={1} lg={1} borderRight="1px solid #E1E1EA">
				<Stack>
					<Typography variant="label-s" marginBottom={1} color={colors.subtitle}>
						<Tr.Portal path="device-management.device-info.last-calibration-date" />
					</Typography>
					<Typography variant="pg-m" color={colors.content}>
						{lastCalibrationTime ? DateUtils.getFormattedDate(new Date(lastCalibrationTime)) : '-'}
					</Typography>
				</Stack>
			</Grid>
			<Grid item md={1} sm={1} lg={1} paddingLeft={2} borderRight="1px solid #E1E1EA">
				<Stack>
					<Typography variant="label-s" marginBottom={1} color={healthCheckColors.subtitle}>
						<Tr.Portal path="device-management.health-check" />
					</Typography>
					<Typography variant="pg-m" color={healthCheckColors.content}>
						{nextCalibrationTime && isValuesVisible ? DateUtils.getFormattedDate(new Date(nextCalibrationTime)) : '-'}
					</Typography>
				</Stack>
			</Grid>
			<Grid item md={1} sm={1} lg={1} paddingLeft={2}>
				<Stack>
					<Typography marginBottom={1} variant="label-s" color={colors.subtitle}>
						<Tr.Portal path="device-management.device-info.calibration-value" />
					</Typography>
					<Typography variant="pg-m" color={colors.content} sx={{ wordBreak: 'break-all' }}>
						{recalibrationData && isValuesVisible && !isCalibrationError ? getValueLabel(recalibrationData) : '-'}
					</Typography>
				</Stack>
			</Grid>
		</Grid>
	);
};
