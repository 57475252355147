import { Grid } from '@mui/material';
import { FC, useContext } from 'react';
import { InputSpectrum } from './InputSpectrum';
import { AnalysisResult } from './AnalysisResult';
import { UpdateButtons } from '../../nmr/UpdateButtons';
import { AnalysisResultLabel } from '@components/common';
import { AnalysisResultFields } from '../../nmr/AnalysisResultFields';
import { AnalysisErrorSummary } from '../../nmr/AnalysisErrorSummary';
import { AnalysisResultContext } from '../../nmr/analysis-result-context';
import { AlgorithmSummary, AnalysisSetupSummary } from '@components/portal';
import { DataTestId } from '@utils/DataTestId';

export const QuantitativeNonTargetedResultPage: FC = () => {
	const { analysisResultData } = useContext(AnalysisResultContext);

	if (!analysisResultData) return null;

	return (
		<Grid container spacing={4} data-testid={DataTestId.getStaticTestId('quantitative-non-targeted-id')}>
			<Grid item xs={6}>
				<AnalysisResultLabel />
			</Grid>
			<Grid item xs={6}>
				<UpdateButtons />
			</Grid>
			<Grid item xs={12}>
				<AnalysisResultFields id={analysisResultData.analysisId} />
			</Grid>
			<Grid item xs={12}>
				{analysisResultData.isError() ? <AnalysisErrorSummary /> : <AnalysisResult />}
			</Grid>
			<Grid item xs={12}>
				<InputSpectrum />
			</Grid>
			<Grid item xs={12}>
				<AnalysisSetupSummary />
			</Grid>
			<Grid item xs={12}>
				<AlgorithmSummary
					targetType={analysisResultData.nmrAnalysis.targetType}
					measurementType={analysisResultData.nmrAnalysis.measurementType}
				/>
			</Grid>
		</Grid>
	);
};
