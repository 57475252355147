import { FormikAutoComplete, FormikTextfield, InfoMessageBox } from '@components/common';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Button, Checkbox, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { getPipelineDrmOrderValidationSchema } from '@schemas';
import { nmrDrmOrderService, NmrDrmOrderStatusParentStatusMap, NrmDrmOrderPriority } from '@services/nmr-drm-pipeline';
import { setTriggerBacklogRefresh } from '@store/slices/pipeline.slice';
import { DataTestId } from '@utils/DataTestId';
import { RxUtils } from '@utils/Rx';
import { formFieldSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export enum AddDrmOrderExpectedErrors {
	DOES_NOT_EXIST_IN_PUBCHEM = '26003',
	EXIST_IN_DRM_DB = '31006',
	EXIST_IN_DRM_ORDERS = '31001',
}

type FormFields = Extendable<{ casNumber: String; priority: NrmDrmOrderPriority }>;

type TAddSingleOrder = {
	onClose: () => void;
	onFieldGrow: () => void;
};

export const AddSingleOrder: FC<TAddSingleOrder> = ({ onClose, onFieldGrow }) => {
	const { t: tCommon } = useTranslation('common');
	const { t } = useTranslation('admin');
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		onFieldGrow();
	}, []);

	const [drmAlreadyExists, setDrmAlreadyExists] = useState(false);
	const [haveRequestSent, setHaveRequestSent] = useState(false);
	const [doesntExistOnPubchem, setDoesntExistOnPubchem] = useState(false);
	const [alreadyExistingOrderType, setAlreadyExistingOrderType] = useState<String[]>([]);
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	const [focusField, setFocusField] = useState<boolean>(true);
	const [inInitialState, setInInitialState] = useState<boolean>(true);

	const handleSearchCasNumber = (values: FormFields) => {
		const urlSearchParams: URLSearchParams = new URLSearchParams({
			pageIndex: '1',
			casNumber: values.casNumber.trim(),
		});
		RxUtils.promisify(nmrDrmOrderService.getAllWithSearchParams(urlSearchParams), (nmrDrmOrders) => {
			const alreadyExistingOrders = new Set<String>();
			nmrDrmOrders.data.forEach((occurence) => {
				const parentStatus = NmrDrmOrderStatusParentStatusMap[occurence.status];
				alreadyExistingOrders.add(parentStatus);
			});
			if (alreadyExistingOrders.size > 0 && !isCheckboxChecked) {
				onFieldGrow();
				setAlreadyExistingOrderType([...alreadyExistingOrders]);
			}
		});
	};

	const handleSubmitPost = (values: FormFields) => {
		RxUtils.promisify(
			nmrDrmOrderService.post(
				{
					priority: values.priority,
					casNumber: values.casNumber.trim(),
					skipExistingDrmOrderValidation: haveRequestSent,
				},
				'unhandle',
			),
			() => {
				onClose();
				dispatch(setTriggerBacklogRefresh());
			},
			(err) => {
				if (`${err.ErrorCode}` === AddDrmOrderExpectedErrors.DOES_NOT_EXIST_IN_PUBCHEM) {
					setFocusField(true);
					onFieldGrow();
					setDoesntExistOnPubchem(true);
				}
				if (`${err.ErrorCode}` === AddDrmOrderExpectedErrors.EXIST_IN_DRM_DB) {
					setDrmAlreadyExists(true);
					onFieldGrow();
				}

				if (`${err.ErrorCode}` === AddDrmOrderExpectedErrors.EXIST_IN_DRM_ORDERS) {
					handleSearchCasNumber(values);
				}
				setHaveRequestSent(true);
			},
		);
	};

	useEffect(() => {
		setFocusField(true);
		setInInitialState(true);
	}, []);

	const resetState = () => {
		setHaveRequestSent(false);
		setDoesntExistOnPubchem(false);
		setAlreadyExistingOrderType([]);
		setIsCheckboxChecked(false);
		setDrmAlreadyExists(false);
	};

	const priorityOptions: Array<string> = Object.values(NrmDrmOrderPriority);

	const formik = useFormik<FormFields>({
		initialValues: {
			priority: NrmDrmOrderPriority.LOW,
			casNumber: '',
		},
		validationSchema: getPipelineDrmOrderValidationSchema(tCommon, inInitialState),
		onSubmit: (values) => {
			handleSubmitPost(values);
		},
		validateOnBlur: !inInitialState,
	});

	const exitInitialState = (_: unknown) => setInInitialState(false);
	useEffect(() => {
		if (formik.dirty) setInInitialState(false);
	}, [formik.dirty]);

	return (
		<form onSubmit={formik.handleSubmit} style={{ width: '100%', height: 'auto' }} onMouseDown={exitInitialState}>
			<Stack spacing={formFieldSpacer}>
				<Stack spacing={1}>
					<FormikTextfield
						formikApi={formik}
						inputRef={(input) => {
							if (input && focusField) {
								input.focus();
								setFocusField(false);
							}
						}}
						onMouseDown={exitInitialState}
						autoComplete="off"
						name="casNumber"
						inputProps={{
							'data-testid': DataTestId.getStaticTestId('cas-number-input-test-id'),
						}}
						title={t('drm-pipeline.nmr.backlog.cas-number')}
						onChange={(event) => (formik.setFieldValue('casNumber', event.target.value || ''), resetState())}
						placeholder={t('drm-pipeline.nmr.backlog.cas-number-placeholder')}
						required
					/>
					{doesntExistOnPubchem && (
						<InfoMessageBox
							message={t('drm-pipeline.nmr.backlog.there-is-no-substance')}
							data-testid={DataTestId.getStaticTestId('pubchem-warning-box-id')}
						/>
					)}
				</Stack>

				<FormikAutoComplete
					required
					select={false}
					formik={formik}
					name="priority"
					variant="outlined"
					options={priorityOptions}
					title={t('drm-pipeline.nmr.backlog.priority')}
					value={formik.values.priority}
					placeholder={t('drm-pipeline.nmr.backlog.priority-placeholder')}
					onMouseDown={exitInitialState}
					autocompleteProps={{
						disablePortal: true,
					}}
					onSelectionChange={(value) => {
						formik.setFieldValue('priority', value || null);
					}}
				/>
				{(alreadyExistingOrderType.length > 0 || drmAlreadyExists) && (
					<Stack
						direction="row"
						sx={{
							width: '100%',
							paddingX: 2,
							paddingY: 0.875,
							border: '1px solid #E1E1EA',
							borderRadius: 1,
							justifyContent: 'space-between',
						}}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Checkbox
								value={isCheckboxChecked}
								onChange={(event) => setIsCheckboxChecked(Boolean(event.target.checked))}
								data-testid={DataTestId.getStaticTestId('add-drm-order-checkbox-id')}
								sx={{ width: 24, height: 24 }}
							/>
							<Typography variant="pg-s" sx={{ transform: 'translateY(1px)' }}>
								<Tr.Common path="confirm" />
							</Typography>
						</Stack>
						<Stack justifyContent="center">
							<Tooltip
								title={
									<>
										{!drmAlreadyExists && alreadyExistingOrderType.length > 1 ? (
											<Tr.Admin
												path={'drm-pipeline.nmr.backlog.a-drm-order-exists-with-cas-number'}
												options={{ existsIn: alreadyExistingOrderType.join(', ') }}
											/>
										) : (
											<>
												{!drmAlreadyExists && (
													<Tr.Admin
														path={'drm-pipeline.nmr.backlog.a-drm-order-exists-with-cas-number-single'}
														options={{ existsIn: alreadyExistingOrderType.join(', ') }}
													/>
												)}
											</>
										)}
										{drmAlreadyExists && (
											<Tr.Admin
												path={'drm-pipeline.nmr.backlog.a-drm-order-exists-in-reference-materials'}
												options={{ existsIn: alreadyExistingOrderType.join(', ') }}
											/>
										)}
									</>
								}
							>
								<InfoOutlined sx={{ height: 16, width: 16, color: 'grey.400' }} />
							</Tooltip>
						</Stack>
					</Stack>
				)}
				<Grid container>
					<Grid
						item
						xs={6}
						sx={{
							paddingRight: 1,
						}}
					>
						<Button
							variant="outlined"
							fullWidth={true}
							data-testid={DataTestId.getStaticTestId('cancel-drm-button-id')}
							disableElevation
							onClick={onClose}
						>
							<Tr.Portal path="cancel" />
						</Button>
					</Grid>

					<Grid
						item
						xs={6}
						sx={{
							paddingLeft: 1,
						}}
					>
						<Button
							fullWidth={true}
							data-testid={DataTestId.getStaticTestId('save-drm-button-id')}
							variant="contained"
							type="submit"
							disableElevation
							disabled={
								!formik.isValid ||
								!formik.dirty ||
								(!isCheckboxChecked && alreadyExistingOrderType.length > 0) ||
								doesntExistOnPubchem
							}
							sx={{ textTransform: 'capitalize' }}
						>
							<Tr.Common path="save" />
						</Button>
					</Grid>
				</Grid>
			</Stack>
		</form>
	);
};
