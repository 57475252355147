import { FC, useState } from 'react';
import { Button, Stack, Typography, TypographyProps } from '@mui/material';
import { Tr } from '@utils';
import { RequestErm } from '@components/portal';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';

const labelStyle: TypographyProps['sx'] = {
	fontWeight: 500,
	marginRight: 1,
	marginTop: 1,
	fontSize: 'medium',
	whiteSpace: 'nowrap',
	color: 'text.primary',
};

export const RequestErmListItem: FC = () => {
	const [isRequestErmModalVisible, setIsRequestErmModalVisible] = useState(false);

	return (
		<Stack data-testid="request-erm-wrapper-id" id="portal-layout" direction="column" marginTop={5} marginBottom={8}>
			<Stack direction="column" sx={{ marginRight: 'auto', maxWidth: '40%' }}>
				<>
					<Typography variant="h4" sx={{ wordBreak: 'break-word', color: 'text.primary' }}>
						<Tr.IrPortal path="erm-request.list-item-title" />
					</Typography>
					<Typography variant="body1" sx={{ ...labelStyle }}>
						<Tr.IrPortal path="erm-request.list-item-content" />
					</Typography>
				</>
			</Stack>
			<Button
				data-testid="modal-button-id"
				variant="contained"
				disableElevation
				color="secondary"
				size="small"
				sx={{ alignSelf: 'start', marginBottom: 1, marginTop: 3 }}
				onClick={() => {
					setIsRequestErmModalVisible(true);
					analyticsErmEvent.ErmRequested();
				}}
			>
				<Tr.IrPortal path="erm-request.list-item-request-button" />
			</Button>
			<RequestErm open={isRequestErmModalVisible} onCancel={() => setIsRequestErmModalVisible(false)} />
		</Stack>
	);
};
