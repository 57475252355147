import { IDrmSpectrumDataType } from '@components/irportal';
import { SxProps } from '@mui/material';
import { NmrDrmDetail } from '@services/nmr';
import { useState } from 'react';

export interface DRMDetailState {
	visible: boolean;
	drmId?: number | null;
	drmData?: NmrDrmDetail;
	actionLabel?: string;
	onHandleAction?: (drmId: number) => void;
	actionDisabled?: boolean;
	actionBtnSx?: SxProps;
	isTheoretical?: boolean;
	onRequestDrm?: (drm: NmrDrmDetail) => void;
}

export interface IrDRMDetailState {
	visible: boolean;
	drmId: number | null;
	actionLabel?: string;
	onHandleAction?: (drmId: string) => void;
	actionDisabled?: boolean;
	drmSpectrumData?: IDrmSpectrumDataType[];
}

const initialDRMDetail = {
	visible: false,
	drmId: null,
	actionLabel: '',
	actionBtnSx: {},
	onHandleAction: () => null,
	actionDisabled: false,
};

const initialIrDRMDetail = {
	visible: false,
	drmId: null,
	actionLabel: '',
	actionDisabled: false,
	onHandleAction: () => null,
	drmSpectrumData: [],
};

export const useDRMDetail = () => {
	const [drmDetail, setDRMDetail] = useState<DRMDetailState>(initialDRMDetail);

	function resetDetail() {
		setDRMDetail(initialDRMDetail);
	}

	return { ...drmDetail, setDRMDetail, resetDetail };
};

// TO DO: CHANGE ITS FILE
export const useIrDRMDetail = () => {
	const [drmDetail, setIrDRMDetail] = useState<IrDRMDetailState>(initialIrDRMDetail);

	function resetIrDetail() {
		setIrDRMDetail(initialIrDRMDetail);
	}

	return { ...drmDetail, setIrDRMDetail, resetIrDetail };
};
