import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { Link, Stack, Typography } from '@mui/material';
import { NmrSubstanceListResponse } from '@services/nmr';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TAnalyticalSubstanceNoResult = {
	onRequestRmClick: () => void;
	searchResult: NmrSubstanceListResponse;
	typedString?: string;
};

export const AnalyticalSubstanceNoResult: FC<TAnalyticalSubstanceNoResult> = ({ searchResult, typedString = '', onRequestRmClick }) => {
	const { t } = useTranslation('portal');
	if (searchResult && searchResult?.substances?.pageIndex > 1) {
		return null;
	}

	const hasTheoretical = searchResult && searchResult?.totalTheoreticalSpectrumCount > 0;

	return (
		<Stack paddingY={7} sx={{ backgroundColor: 'grey.50', borderRadius: '8px' }}>
			<Stack direction="column" justifyContent="center" alignItems="center">
				<Stack paddingBottom={3}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						padding={1.5}
						sx={{ backgroundColor: 'grey.100', borderRadius: '50%', width: '80px', height: '80px' }}
					>
						<SearchOutlined sx={{ fontSize: '40px', color: 'grey.400' }} />
					</Stack>
				</Stack>
				<Typography variant="h3" color="grey.800">
					{typedString
						? `${
								!hasTheoretical ? t('new-analysis.no-drm-and-theoretical-results') : t('new-analysis.no-drm-results-for')
							} "${typedString}"`
						: t('new-analysis.no-last-used-drm-results')}
				</Typography>

				{!typedString && (
					<Typography variant="pg-m" color="grey.800" paddingTop={1 / 2}>
						{t('new-analysis.this-is-where-you-will-view-last-drms')}
					</Typography>
				)}

				{hasTheoretical && (
					<Typography variant="pg-m" color="grey.800" sx={{ lineHeight: '160%' }}>
						{t('new-analysis.use-theoretical-explanation')}{' '}
						<Link sx={{ cursor: 'pointer' }} onClick={onRequestRmClick}>
							{t('new-analysis.theoretical-explanation-request-link')}
						</Link>
					</Typography>
				)}
			</Stack>
		</Stack>
	);
};
