import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getParams, setParams, useFilterSearchParams, useHasPermissions, useService } from '@hooks';
import { LabPermission, SortOrder } from '@models';
import { nmrAnalysisService, nmrFilterService } from '@services';
import { Box, Stack } from '@mui/material';
import { completeColumns } from '@components/portal';
import { DataTableBackend } from '@components/common/DataTableBackend';
import { CompletedHistoryLegend } from '@components/common/Legend';

export const CompletedAnalysisTable: FC = () => {
	const { searchParams, filtering, setFiltering } = useFilterSearchParams('', SortOrder.RESET);
	const [typedString, setTypedString] = useState('');

	const { t } = useTranslation('common');
	const { t: tPortal } = useTranslation('portal');

	const { data: filterData } = useService(() => nmrFilterService.getAnalysisHistoryFilter(true));

	const { data: historyData, loading } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		setParams(searchParams).setCompleted('true');
		return nmrAnalysisService.getAllWithSearchParams(searchParams);
	}, [searchParams]);

	const hasLabAdminPermission = useHasPermissions(LabPermission.ANALYSIS_OWNER);

	return (
		<Stack>
			<DataTableBackend
				data={historyData ? historyData.data : ([] as any)}
				columns={completeColumns(t)}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: historyData?.totalPages ?? 1 }}
				searchText={typedString}
				onSearchTextChange={setTypedString}
				searchPlaceholder={
					hasLabAdminPermission
						? tPortal('analysis-history.completed-analysis-search-placeholder-lab-admin')
						: tPortal('analysis-history.completed-analysis-search-placeholder-lab-member')
				}
				sx={{ visibility: loading ? 'hidden' : 'visible', marginX: 2 }}
				headerCellProps={{ sx: { minWidth: 60 } }}
				searchFieldProps={{ sx: { maxWidth: 425, width: 425, maxHeight: 40, height: 40 }, 'aria-label': 'ongoing-search-id' }}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
			{!loading && historyData && historyData.data.length > 0 && (
				<Box sx={{ mt: '1.5rem' }}>
					<CompletedHistoryLegend />
				</Box>
			)}
		</Stack>
	);
};
