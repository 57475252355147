import { FC } from 'react';
import { PageRoutes } from '@models/router';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DashboardCard } from '@components/admin';
import { Grid, Stack, Typography } from '@mui/material';
import { DataTestId, Tr, mainTitleSpacer } from '@utils';
import { useHasPermissions } from '@hooks/useHasPermissions';
import AccessManagementPNG from '@assets/images/access_management.png';
import { AdminPermission, IrAdminPermission, NmrAdminPermission } from '@models/user';
import { useSelector } from 'react-redux';
import { flagsSelector } from '@store/slices/common.slice';

type TDashboardCard = {
	key: string;
	flag?: string;
	permission: string | string[];
	component: JSX.Element;
};

export const Dashboard: FC = () => {
	const navigate = useNavigate();
	const { t, ready } = useTranslation('admin');
	const translate = (path: string) => (ready ? t(path) : '');
	const flags = useSelector(flagsSelector);

	//Made hooks into custom hooks/functional components

	const useRefManagementDesc = () => {
		const createNMRRef = useHasPermissions(NmrAdminPermission.CREATE_DRM_REQUEST);
		const createIRRef = useHasPermissions(IrAdminPermission.CREATE_DRM_REQUEST);
		const editRef = useHasPermissions(NmrAdminPermission.EDIT_DRM);
		const viewDRMRequest = useHasPermissions(AdminPermission.VIEW_DRM_REQUEST);

		if (createNMRRef || createIRRef) {
			return translate('dashboard.reference-management-desc');
		} else if (editRef) {
			return !viewDRMRequest
				? translate('dashboard.reference-management-edit-desc')
				: translate('dashboard.reference-management-view-desc');
		}

		return translate('dashboard.reference-management-view-desc');
	};

	const useSubsManagementDesc = () => {
		const viewSubstance = useHasPermissions(AdminPermission.VIEW_SUBSTANCE);
		const createSubstance = useHasPermissions(AdminPermission.CREATE_SUBSTANCE);

		if (createSubstance) {
			return translate('dashboard.substance-management-create-desc');
		} else if (viewSubstance && !createSubstance) {
			return translate('dashboard.substance-management-view-desc');
		}

		return translate('dashboard.substance-management-view-desc');
	};

	const cards: TDashboardCard[] = [
		{
			key: 'customer-management',
			permission: AdminPermission.VIEW_CUSTOMER_MANAGEMENT,
			component: (
				<DashboardCard
					img={AccessManagementPNG}
					title={translate('dashboard.customer-management')}
					onClick={() => navigate(`../customer-management`)}
					description={translate('dashboard.customer-management-desc')}
					dataTestId={DataTestId.getStaticTestId('admin-customer-card-id')}
				/>
			),
		},
		{
			key: 'substance-management',
			permission: [AdminPermission.VIEW_SUBSTANCE, AdminPermission.CREATE_SUBSTANCE],
			component: (
				<DashboardCard
					img={AccessManagementPNG}
					title={translate('dashboard.substance-management')}
					dataTestId={DataTestId.getStaticTestId('admin-erm-card-id')}
					onClick={() => navigate(`../${PageRoutes.SUBSTANCE_MANAGEMENT}`)}
					description={useSubsManagementDesc()}
				/>
			),
		},
		{
			key: 'reference-management',
			permission: [
				NmrAdminPermission.CREATE_DRM_REQUEST,
				IrAdminPermission.CREATE_DRM_REQUEST,
				AdminPermission.VIEW_DRM_REQUEST,
				NmrAdminPermission.EDIT_DRM,
			],
			component: (
				<DashboardCard
					img={AccessManagementPNG}
					title={translate('dashboard.reference-management')}
					dataTestId={DataTestId.getStaticTestId('admin-erm-card-id')}
					onClick={() => navigate(`../${PageRoutes.DRM_MANAGEMENT}`)}
					description={useRefManagementDesc()}
				/>
			),
		},
		{
			key: 'drm-pipeline',
			flag: 'ir-pipeline',
			permission: [
				IrAdminPermission.UPLOAD_FILE,
				IrAdminPermission.RELEASE_WAVE,
				NmrAdminPermission.ASSIGN_DRM_ORDER,
				IrAdminPermission.VIEW_DRM_PIPELINE,
				NmrAdminPermission.VIEW_DRM_PIPELINE,
			],
			component: (
				<DashboardCard
					img={AccessManagementPNG}
					title={translate('dashboard.drm-pipeline')}
					onClick={() => navigate(`../${PageRoutes.DRM_PIPELINE}`)}
					dataTestId={DataTestId.getStaticTestId('admin-erm-card-id')}
					description={
						useHasPermissions([
							IrAdminPermission.UPLOAD_FILE,
							IrAdminPermission.RELEASE_WAVE,
							NmrAdminPermission.ASSIGN_DRM_ORDER,
						])
							? translate('dashboard.drm-pipeline-management-desc')
							: translate('dashboard.drm-pipeline-desc')
					}
				/>
			),
		},
		{
			key: 'user-roles',
			permission: AdminPermission.VIEW_USER_MANAGEMENT,
			component: (
				<DashboardCard
					img={AccessManagementPNG}
					title={translate('dashboard.users-and-roles-title')}
					dataTestId={DataTestId.getStaticTestId('admin-erm-card-id')}
					onClick={() => navigate(`../${PageRoutes.USERS_AND_ROLES}`)}
					description={
						useHasPermissions(AdminPermission.EDIT_ADMIN_USER)
							? translate('dashboard.users-and-roles-editable-desc')
							: translate('dashboard.users-and-roles-desc')
					}
				/>
			),
		},
	];

	const filteredCards = cards.filter(({ permission, flag }) => useHasPermissions(permission) && (flag ? flags[`${flag}`] : true));

	return (
		<Stack data-testid="admin-dashboard-id" direction="column">
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={mainTitleSpacer}>
				<Typography variant="h1" sx={{ color: 'text.secondary' }}>
					<Tr.Admin path="dashboard.title" />
				</Typography>
			</Stack>
			<Grid container spacing={4}>
				{filteredCards.map(({ component, key }) => (
					<Grid key={key} item xs={6} md={4} lg={3}>
						{component}
					</Grid>
				))}
			</Grid>
		</Stack>
	);
};
