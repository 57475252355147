const OVERFLOW_COMMON = {
	'&::-webkit-scrollbar-track': {},
	overflow: 'auto',
	'&::-webkit-scrollbar-thumb': {
		background: '#00000061',
		borderRadius: '30px',
	},
};

const OVERFLOW_X_SCROLL = {
	'&::-webkit-scrollbar': {
		transform: 'translateY(4px)',
		width: '100px',
		height: '6px',
	},
	...OVERFLOW_COMMON,
};

const OVERFLOW_Y_SCROLL = {
	'&::-webkit-scrollbar': {
		width: '6px',
		height: '100px',
	},
	...OVERFLOW_COMMON,
};

export const Scroll: Record<'X' | 'Y', typeof OVERFLOW_X_SCROLL | typeof OVERFLOW_Y_SCROLL> = {
	X: OVERFLOW_X_SCROLL,
	Y: OVERFLOW_Y_SCROLL,
};
