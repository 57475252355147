import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { NmrCalibrationStatus } from '@services';
import { Tr } from '@utils';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';
import SearchOutlined from '@mui/icons-material/SearchOffOutlined';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import { useAnalysis } from '@hooks/useAnalysis';
import { FormikApiType } from '@components/common';
interface IAnalysisStepButtonProps {
	formik: FormikApiType;
}

export const AnalysisStepButton: FC<IAnalysisStepButtonProps> = ({ formik }) => {
	const { addedERMs, nmrDevice, metaData, measurementType, targetType, changedPeaks, isAnalysisEditable } = useAnalysis();

	const checkButtonValidity = () => {
		const { QUANTITATIVE } = QualitativeOrQuantitative;
		const calibrations = nmrDevice?.nmrDeviceCalibrations;
		const receiverGain = metaData?.RG;

		if (measurementType === QUANTITATIVE && targetType === AnalysisTargetType.TARGETED) {
			const selectedCalibration = calibrations?.find((c) => c.id === formik?.values.deviceCalibrationId);
			return (
				!addedERMs ||
				addedERMs?.length === 0 ||
				selectedCalibration?.status !== NmrCalibrationStatus.ACTIVE ||
				!nmrDevice?.isActive ||
				Number(receiverGain) < selectedCalibration?.receiverGainThresholds.min ||
				Number(receiverGain) > selectedCalibration?.receiverGainThresholds.max
			);
		} else if (measurementType === QUANTITATIVE && targetType === AnalysisTargetType.NON_TARGETED) {
			const selectedCalibration = calibrations?.find((c) => c.id === formik?.values.deviceCalibrationId);
			return (
				selectedCalibration?.status !== NmrCalibrationStatus.ACTIVE ||
				!nmrDevice?.isActive ||
				Number(receiverGain) < selectedCalibration?.receiverGainThresholds.min ||
				Number(receiverGain) > selectedCalibration?.receiverGainThresholds.max ||
				!changedPeaks ||
				changedPeaks.length === 0
			);
		}
	};

	// TODO: investigate this why not working in <Button >/
	const shouldDisable = !formik?.isValid || checkButtonValidity() || !isAnalysisEditable;

	const getButtonLabel = () => {
		const { QUALITATIVE } = QualitativeOrQuantitative;
		if (measurementType === QUALITATIVE) {
			return !addedERMs || addedERMs?.length === 0 ? (
				<Stack direction="row" spacing={1} alignItems="center">
					<Stack>
						<SearchOutlined />
					</Stack>
					<Stack>
						<Tr.Portal path="new-analysis.search-entire-library" />
					</Stack>
				</Stack>
			) : (
				<>
					<Stack direction="row" spacing={1} alignItems="center">
						<Stack>
							<PlayCircleOutline />
						</Stack>
						<Stack>
							<Tr.Portal path="new-analysis.run-analysis" />
						</Stack>
					</Stack>
				</>
			);
		} else {
			return (
				<Stack direction="row" spacing={1} alignItems="center">
					<Stack>
						<PlayCircleOutline />
					</Stack>
					<Stack>
						<Tr.Portal path="new-analysis.run-analysis" />
					</Stack>
				</Stack>
			);
		}
	};

	const buttonAction = () => {
		const { QUALITATIVE } = QualitativeOrQuantitative;
		const hasNotErm = !addedERMs || addedERMs?.length === 0;
		if (measurementType === QUALITATIVE) {
			hasNotErm && formik?.setFieldValue('targetType', AnalysisTargetType.NON_TARGETED);
		}
	};

	return (
		<Button
			disabled={shouldDisable}
			variant="contained"
			type="submit"
			data-testid="run-analysis-button"
			size="medium"
			color="secondary"
			disableElevation
			onClick={buttonAction}
		>
			{getButtonLabel()}
		</Button>
	);
};
