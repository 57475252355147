import { CircularProgress, Paper, Stack, SxProps, Typography } from '@mui/material';
import { NotificationType, notificationService } from '@services/index';
import { nameLengthValidator } from '@utils/File';
import { Tr } from '@utils/Translation';
import { FC, useCallback, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/rounded/cloud_upload.svg';
import { FileGeneralTypeAcceptedExtensionMapping, TFileTypeMatching } from '../nmr-drm-order-file-uploader.helper';
import { TypeUtils } from '@utils/Type';
import { NmrDrmOrderFileValidationStatus, NmrDrmOrderFileValidationStatusType } from './droppable-area-file-validation';
const paperDefaultSx: SxProps = {
	backgroundColor: 'grey.50',
	borderRadius: 1,
	border: '1px solid',
	minHeight: 136,
	display: 'flex',
	flexDirection: 'column',
};

type TDroppableAreaProps = {
	onFileUpload: (file: File, switchLoading: () => void) => void;
	generalFileType: 'core' | 'additional';
};

const MAX_FILE_SIZE = 209715200;
export const DroppableArea: FC<TDroppableAreaProps> = ({ onFileUpload, generalFileType }) => {
	const { t } = useTranslation('admin');
	const [loading, setLoading] = useState(false);
	const errorMapping: Record<NmrDrmOrderFileValidationStatusType, string> = {
		[NmrDrmOrderFileValidationStatus.INVALID]: t(`drm-order-details.file-uploader.file-type-error-${generalFileType}`),
		[NmrDrmOrderFileValidationStatus.MAX_SIZE]: t('drm-order-details.file-uploader.file-size-error'),
		[NmrDrmOrderFileValidationStatus.NAME_LARGE]: t('drm-order-details.file-uploader.file-length-error'),
		[NmrDrmOrderFileValidationStatus.INVALID_DATA_TYPE]: t('drm-order-details.file-uploader.file-data-type-error'),
	};

	const switchLoading = () => setLoading((prev) => !prev);
	const onDrop = useCallback<Required<DropzoneOptions>['onDrop']>(
		([file]) => {
			if (file) {
				onFileUpload(file, switchLoading);
			}
		},
		[onFileUpload],
	);

	const onDropRejected = ([{ errors }]: FileRejection[]) => {
		notificationService.send({
			type: NotificationType.ERROR,
			message: errorMapping[errors[0].code as NmrDrmOrderFileValidationStatusType],
		});
	};

	const acceptFiles = TypeUtils.returnValueOfKey<typeof FileGeneralTypeAcceptedExtensionMapping, TFileTypeMatching>(
		generalFileType,
		FileGeneralTypeAcceptedExtensionMapping,
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: false,
		accept: acceptFiles ?? {},
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
		onDropRejected,
	});
	const getBorderColor = () => {
		if (isDragActive) {
			return 'grey.700';
		} else {
			return 'grey.200';
		}
	};

	if (loading) {
		return (
			<Paper
				elevation={0}
				square
				sx={[
					paperDefaultSx,
					{
						borderColor: getBorderColor(),
						backgroundColor: 'unset',
						alignItems: 'center',
						justifyContent: 'center',
					},
				]}
			>
				<CircularProgress color="success" thickness={4} sx={{ width: 40, height: 40 }} />
			</Paper>
		);
	}

	return (
		<Paper
			{...getRootProps()}
			elevation={0}
			square
			sx={[
				paperDefaultSx,
				{
					borderColor: getBorderColor(),
				},
			]}
		>
			<Stack direction="column" alignItems="center" justifyContent="center" flexGrow={1}>
				<input data-testid="file-upload-id" {...getInputProps()} />

				<Stack spacing={1} alignItems="center">
					<CloudUpload width={24} height={24} style={{ fill: '#5E5E72' }} />
					<Typography variant="label-s" textAlign="center" color="grey.800">
						<Tr.Admin path="drm-order-details.file-uploader.upload-a-file" />
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
};
