import { ReferenceMaterialFavorite, TReferenceMaterialFavorite } from '@components/common';
import { alertService, notificationService } from '@services/core';
import { irFavoriteReferencesService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IrAddFavoriteDialog } from '../AddFavoriteDialog';

type TIrReferenceMaterialFavorite = {
	drmCode: string;
	favoriteId?: number;
	name?: string;
	ermId?: number;
	onSuccess: () => void;
};

export const IrReferenceMaterialFavorite: FC<Omit<TReferenceMaterialFavorite, 'onClick' | 'isFavorite'> & TIrReferenceMaterialFavorite> = ({
	drmCode,
	favoriteId,
	name,
	onSuccess,
	...props
}) => {
	const [isAddToFavoritesOpened, setIsAddToFavoritesOpened] = useState<boolean>(false);
	const { t } = useTranslation('irportal');
	const onRemoveFavoriteConfirmed = () => {
		if (favoriteId)
			RxUtils.promisify(
				irFavoriteReferencesService.delete(favoriteId),
				() => {
					notificationService.sendSuccess(t('favourites.successfully-deleted'));
					onSuccess();
				},
				(err) => notificationService.sendError(err.Message),
			);
	};

	const onButtonClick = () => {
		if (favoriteId) {
			alertService.send({
				titleText: <Tr.Portal path="favourites.delete" />,
				confirmTextHeader: name ? (
					<Tr.IrPortal path="favourites.delete-the-diaolog-header" />
				) : (
					<Tr.IrPortal path="favourites.delete-diaolog-header" />
				),
				onConfirm: () => onRemoveFavoriteConfirmed(),
				content: name
					? t('favourites.delete-confirmation-message', { name: name })
					: t('favourites.delete-this-confirmation-message'),
				confirmTextComponent: <Tr.IrPortal path="favourites.confirm-delete" />,
			});
		} else {
			setIsAddToFavoritesOpened(true);
		}
	};
	return (
		<>
			{isAddToFavoritesOpened && (
				<IrAddFavoriteDialog
					drmCode={drmCode}
					onCloseDialog={() => setIsAddToFavoritesOpened(false)}
					onSuccess={() => (onSuccess(), setIsAddToFavoritesOpened(false))}
				/>
			)}

			<ReferenceMaterialFavorite onClick={onButtonClick} isFavorite={!!favoriteId} {...props} />
		</>
	);
};
