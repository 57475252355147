import {
	AddUserModal,
	DataTableBackend,
	DeleteUser,
	EditUserModal,
	OverflowText,
	PrimaryUserStatusButton,
	ResendInvitation,
} from '@components/common';
import { getParams, useFilterSearchParams, useHasPermissions, useService } from '@hooks';
import { ExtendedColumn, OrganizationPermission, SortOrder, UserResponseModel, UserStatus } from '@models';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Add from '@mui/icons-material/Add';
import { Button, Stack, Typography, TypographyProps } from '@mui/material';
import { AddUserToOrganization, notificationService, userService } from '@services';
import { DateUtils, RxUtils, Tr } from '@utils';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
const DEFAULT_SORT_COLUMN = 'createdDate';
interface IStatusType {
	status: UserStatus;
}

const Body1: FC<TypographyProps & IStatusType> = ({ status, sx, ...props }) => (
	<Typography variant="pg-s" sx={{ color: status === UserStatus.ACTIVE ? 'inherit' : 'grey.400', ...sx }} {...props} />
);

export const UserManagement: FC = () => {
	const { searchParams } = useFilterSearchParams(DEFAULT_SORT_COLUMN, SortOrder.RESET);

	const { t, ready } = useTranslation('portal');
	const translate = (path: string) => (ready ? t(path) : '');

	const { id } = useParams();
	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');
	const [addUserVisible, setAddUserVisible] = useState(false);
	const [currentUser, setCurrentUser] = useState<Maybe<UserResponseModel>>();
	const hasOrganizationUserPermission = useHasPermissions(OrganizationPermission.USER);

	const { data: userList, trigger: refreshUserList } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		searchParams.set('OrganizationId', id ?? '');
		return userService.getAllWithParams(searchParams);
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<UserResponseModel>[]>(
		() => [
			{
				accessor: 'status',
				Header: (
					<Typography variant="label-s" marginLeft={1.5}>
						<Tr.Common path="status" />
					</Typography>
				),
				Cell: ({ value }) => <PrimaryUserStatusButton status={value} />,
			},
			{
				accessor: 'fullName',
				Header: <Tr.Common path="name" />,
				Cell: ({ value, row }) => <Body1 status={row.original.status}>{value}</Body1>,
			},
			{
				accessor: 'lastActivity',
				Header: <Tr.Common path="last-active-date" />,
				Cell: ({ value, row }) => (
					<Body1 status={row.original.status}>{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy') : '-'}</Body1>
				),
			},
			{
				accessor: 'email',
				Header: <Tr.Common path="email" />,
				Cell: ({ value, row }) => (
					<OverflowText
						enableTooltip={true}
						sx={{
							maxWidth: 300,
							minWidth: 200,
							color: row.original.status === UserStatus.ACTIVE ? 'text.primary' : 'grey.400',
						}}
					>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'laboratories',
				Header: <Tr.Common path="lab-name" />,
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<OverflowText
						enableTooltip={true}
						sx={{
							maxWidth: 300,
							minWidth: 200,
							color: row.original.status === UserStatus.ACTIVE ? 'text.primary' : 'grey.400',
						}}
					>
						{value?.map((lab) => lab.name || '').join(', ') ?? ''}
					</OverflowText>
				),
			},
			{
				accessor: 'roles',
				Header: <Tr.Portal path="user-management.role" />,
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<Body1 status={row.original.status}>{value ? value.map((r) => t(`user-management.roles.${r}`)).join(', ') : '-'}</Body1>
				),
			},
			{
				accessor: 'action',
				disableSortBy: true,
				hidden: !hasOrganizationUserPermission,
				Header: <Tr.Common path="" />,
				Cell: ({ row }) => (
					<Stack sx={{ float: 'right', mr: 1 }}>
						<Body1 status={UserStatus.ACTIVE}>
							{row.original.status === UserStatus.INVITED ? (
								<Stack direction="row" alignItems="center">
									<ResendInvitation data-testid={`resent-button-id-${row.index}`} id={row.original.id} />
									<DeleteUser data-testid={`delete-button-id-${row.index}`} id={row.original.id} cb={refreshUserList} />
								</Stack>
							) : (
								<EditOutlined
									data-testid={`edit-button-id-${row.index}`}
									width="18"
									height="18"
									color="primary"
									sx={{ cursor: 'pointer', marginLeft: 2 }}
									onClick={() => setCurrentUser(row.original)}
								/>
							)}
						</Body1>
					</Stack>
				),
			},
		],
		[],
	);
	return (
		<>
			{addUserVisible && (
				<AddUserModal
					onClose={() => setAddUserVisible(false)}
					visible={addUserVisible}
					organizationId={id && id}
					skipOrgCheck={true}
					onSubmit={(data: AddUserToOrganization) => {
						RxUtils.promisify(
							userService.addUserToOrganization({
								...data,
							}),
							() => {
								notificationService.sendSuccess(
									t('user-management.add-user-success-message', { email: (data as AddUserToOrganization).Email }),
									{
										duration: 5000,
									},
								);
								refreshUserList();
								setAddUserVisible(false);
							},
							() => setAddUserVisible(false),
						);
					}}
				/>
			)}
			{currentUser && (
				<EditUserModal
					currentUser={currentUser}
					saveCallback={() => (refreshUserList(), setCurrentUser(undefined))}
					onClose={() => setCurrentUser(undefined)}
				/>
			)}
			<Stack data-testid="user-list-wrapper-id">
				<Stack direction="row" sx={{ marginBottom: 2, marginTop: 2 }} justifyContent="space-between" alignItems="center">
					<Typography variant="h2" sx={{ color: 'text.secondary' }}>
						<Tr.Portal path={'user-management.title'} />
					</Typography>

					{hasOrganizationUserPermission && (
						<Button
							data-testid="add-user-id"
							variant="text"
							sx={{ alignItems: 'center' }}
							onClick={() => setAddUserVisible(true)}
						>
							<Add sx={{ fontSize: '20px' }} />
							<Typography variant="subtitle2" sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }} color="primary.main">
								<Tr.Common path="lab-details.add-user" />
							</Typography>
						</Button>
					)}
				</Stack>
				<DataTableBackend
					data={userList?.data || []}
					columns={columns}
					sorting={{
						sortBy: getParams(searchParams).getSortBy(),
						sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
					}}
					pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userList?.totalPages ?? 1 }}
					searchText={searchTextfieldValue}
					searchPlaceholder={translate('user-management.search-placeholder')}
					onSearchTextChange={setSearchTextfieldValue}
					searchFieldProps={{ sx: { width: '25rem' } }}
				/>
			</Stack>
		</>
	);
};
