import { FC, useRef, useState } from 'react';
import { IconButton, ListItem, Menu, Stack, Typography } from '@mui/material';
import { Tr } from '@utils';
import { AppNotification, NotificationType } from '@models';
import { NotificationContentLinkMapping, NotificationMessage } from '../NotificationMessageLink';

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const getNotificationDate = (date: Date, t: (str: string) => unknown) => {
	if (!date) return '';
	if (isToday(date)) {
		return `${t('today')} ${format(date, 'hh:mm a')}`;
	}
	if (isYesterday(date)) {
		return `${t('yesterday')} ${format(date, 'hh:mm a')}`;
	}

	return `${t(`days.${days[getDay(date)]}`)} ${format(date, 'hh:mm a')}`;
};

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format, getDay, isToday, isYesterday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMemoizedTestId } from '@hooks/useMemoizedTestId';
interface INotificationContentProps {
	isRead?: boolean;
	notification: AppNotification;
	onStatusChange: (notificationId: number, status: boolean) => Promise<unknown>;
	setPopoverOpen?: () => void;
}

export const NotificationContent: FC<INotificationContentProps> = ({ isRead, notification, onStatusChange, setPopoverOpen }) => {
	const [open, setOpen] = useState(false);
	const moreRef = useRef<HTMLButtonElement>(null);
	const { t } = useTranslation('notification');
	const isClickable = !(
		notification.notificationType === NotificationType.NewErmAvailable ||
		notification.notificationType === NotificationType.RequestedErmAvailable
	);
	return (
		<Stack
			data-testid={useMemoizedTestId('notification-content')}
			direction="row"
			sx={{
				backgroundColor: isRead ? 'transparent' : 'primary.100',
				margin: '0.2rem 0.8rem',
				padding: '0.8rem 0 0.8rem 1rem',
				borderRadius: '0.5rem',
				justifyContent: 'space-between',
				textDecoration: 'none',
				cursor: isClickable ? 'pointer' : 'default',
			}}
			onClick={() => {
				if (!isClickable) {
					return;
				}
				if (!notification.isRead) {
					onStatusChange(notification.id, true).then(
						() => (window.location.href = NotificationContentLinkMapping[notification.notificationType](notification)), // TODO use navigate from react route
					);
				} else {
					window.location.href = NotificationContentLinkMapping[notification.notificationType](notification);
				}
				setPopoverOpen?.();
			}}
		>
			<Stack>
				<Typography variant="pg-s" sx={{ fontSize: '14px', fontWeight: '500', wordBreak: 'break-word' }}>
					<NotificationMessage notification={notification} />
				</Typography>
				<Typography variant="pg-xs" marginTop="0.5rem">
					{getNotificationDate(notification.createTime, t)}
				</Typography>
			</Stack>
			<IconButton
				onClick={(e) => (setOpen(!open), e.stopPropagation(), e.preventDefault())}
				sx={{ marginBottom: 'auto', marginTop: '-0.62rem', color: 'text.primary' }}
				color="inherit"
				ref={moreRef}
				data-testid={useMemoizedTestId('more-icon')}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				open={open}
				onClose={() => setOpen(false)}
				anchorEl={moreRef.current}
			>
				<ListItem
					sx={{ cursor: 'pointer' }}
					onClick={(e) => {
						onStatusChange(notification.id, !notification.isRead);
						setOpen(false);
						e.stopPropagation();
					}}
				>
					<Typography variant="subtitle3" color="primary.main" data-testid={useMemoizedTestId('mark-as-read')}>
						{isRead ? <Tr.Notification path="mark-as-unread" /> : <Tr.Notification path="mark-as-read" />}
					</Typography>
				</ListItem>
			</Menu>
		</Stack>
	);
};
