import { FC } from 'react';
import { Autocomplete, Paper, Stack, Typography } from '@mui/material';
import phoneCodes from '@assets/data/phone-codes.json';
import { FormikTextfield, FormikApiType } from '../FormikTextfield';

const phoneCodeList = phoneCodes.map(({ name, dial_code }) => ({ label: `${name} (${dial_code})`, value: dial_code }));

interface PhoneCodeProps {
	title: string;
	codeName: string;
	name: string;
	formik: FormikApiType;
}
// TO DO: DELETE
export const PhoneCodeDropdown: FC<PhoneCodeProps> = ({ title, name, codeName, formik }) => {
	return (
		<Stack data-testid="phone-dropdown-id" direction="column" sx={{ marginBottom: 1.25 }}>
			<Typography variant="label-s" sx={{ marginBottom: 1.25 }}>
				{title}
			</Typography>
			<Stack direction="row" columnGap={2}>
				<Autocomplete
					disablePortal
					options={phoneCodeList}
					sx={{
						width: '50%',
						'& 	.MuiOutlinedInput-root': {
							padding: '2px',
						},
					}}
					PaperComponent={({ children }) => <Paper style={{ fontWeight: 450, fontSize: '0.8rem' }}>{children}</Paper>}
					renderInput={(params) => (
						<FormikTextfield
							select={false}
							formikApi={formik}
							name={codeName}
							placeholder="Code"
							variant="outlined"
							sx={{ backgroundColor: 'grey.50' }}
							{...params}
						/>
					)}
					value={formik.values.phoneCode ? { label: formik.values.phoneCode, value: formik.values.phoneCode } : null}
					isOptionEqualToValue={(option, optionValue) => option.value === optionValue.value}
					onChange={(event, newValue) => {
						formik.setFieldValue(codeName, newValue?.value || '');
					}}
				/>

				<FormikTextfield
					formikApi={formik}
					name={name}
					placeholder={title}
					variant="outlined"
					sx={{ backgroundColor: 'grey.50' }}
					containerProps={{ width: 1 }}
					inputProps={{
						'data-testid': 'phoneNumber-id',
					}}
				/>
			</Stack>
		</Stack>
	);
};
