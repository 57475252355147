// ISO 4217 Currency Codes
export enum Currency {
	CAD = 'CAD',
	CHF = 'CHF',
	DKK = 'DKK',
	EUR = 'EUR',
	GBP = 'GBP',
	INR = 'INR',
	KRW = 'KRW',
	NOK = 'NOK',
	SEK = 'SEK',
	SGD = 'SGD',
	USD = 'USD',
	YEN = 'YEN',
}

export const CURRENCY_LIMIT_MAP: Record<Currency, number | null> = {
	[Currency.CAD]: 10000,
	[Currency.CHF]: 10000,
	[Currency.DKK]: 100000,
	[Currency.EUR]: 10000,
	[Currency.GBP]: 10000,
	[Currency.INR]: Infinity, // get input
	[Currency.KRW]: Infinity, // get input
	[Currency.NOK]: 100000,
	[Currency.SEK]: 100000,
	[Currency.SGD]: Infinity, // get input
	[Currency.USD]: 10000,
	[Currency.YEN]: Infinity, // get input
};
