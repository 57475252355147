import { FC, PropsWithChildren, Ref, useRef, useMemo, useState, ReactNode } from 'react';
import { HeaderGroup, useSortBy, useTable } from 'react-table';
import {
	DataType,
	ExtendedColumn,
	FilterValueType,
	getNextSortOrder,
	IFilter,
	IPaginationDefinition,
	ISortingDefinition,
	SortOrder,
} from '@models';
import {
	Box,
	Card,
	Stack,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableProps,
	TableRow,
	TextFieldProps,
	Theme,
	Typography,
	TypographyProps,
} from '@mui/material';
import { SortIcon } from '../SortIcon';
import { SearchField } from '../SearchField';
import { TablePagination } from '../Pagination';
import { TableFilter } from '../Filter';
import { FilterChips } from '../Filter/FilterChips';
import { TableButton } from '@components/portal';
import { useSearchParams } from 'react-router-dom';
import { DataTestId } from '@utils/DataTestId';

const Header: FC<TypographyProps> = ({ sx, ...props }) => <Typography variant="label-s" sx={{ wordBreak: 'keep-all', ...sx }} {...props} />;

interface IExtraTableProps {
	sticky: boolean;
}

const getStickyProps = (column: IExtraTableProps) =>
	column.sticky ? { position: 'sticky', right: 0, backgroundColor: 'background.paper' } : {};

const concatenatedHeaderSx: (column: HeaderGroup<DataType>, tableHeaderCellSx?: SxProps<Theme>) => SxProps<Theme> = (
	column,
	tableHeaderCellSx,
) => ({
	cursor: column.canSort ? 'pointer' : 'default',
	pointerEvents: column.canSort ? 'auto' : 'none',
	padding: '0.8rem 0.4rem',
	...tableHeaderCellSx,
});

export interface IDataTableBackendProps<DataType extends object = object> extends TableProps {
	columns: ExtendedColumn<DataType>[];
	data: DataType[];
	pagination?: IPaginationDefinition;
	searchText?: string;
	searchPlaceholder?: string;
	searchFieldProps?: TextFieldProps;
	headerCellProps?: TypographyProps;
	sorting?: ISortingDefinition;
	showExportButton?: boolean;
	exportButtonText?: string;
	onExportButtonClick?: () => void;
	tableRef?: Ref<HTMLElement>;
	onSortingChange?: (sorting: ISortingDefinition) => void;
	onPageChange?: (page: number) => void;
	onSearchTextChange?: (text: string) => void;
	filterData?: IFilter[];
	onFilter?: (filter: FilterValueType[]) => void;
	filteringSelections?: FilterValueType[];
	tableSucceessorComponent?: ReactNode;
	filterChipSuccessor?: ReactNode;
	cardSx?: SxProps;
	tableHeaderCellSx?: SxProps<Theme>;
	setCurrentFilterValues?: (i: FilterValueType | undefined) => void;
}

export const DataTableBackend = <DataType extends object>({
	columns,
	data,
	pagination,
	searchText,
	searchPlaceholder,
	searchFieldProps,
	headerCellProps,
	sorting = { sortBy: '', sortOrder: SortOrder.RESET },
	showExportButton,
	exportButtonText,
	onExportButtonClick,
	tableRef,
	onSearchTextChange,
	filterData,
	filteringSelections,
	tableSucceessorComponent,
	filterChipSuccessor,
	sx,
	cardSx,
	tableHeaderCellSx,
	setCurrentFilterValues,
	...props
}: PropsWithChildren<IDataTableBackendProps<DataType>>) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data, disableMultiSort: true },
		useSortBy,
	);
	const [openFilter, setOpenFilter] = useState(false);
	const [deletedItem, setDeletedItem] = useState<{ key: string; value: string }>();
	const searchFieldRef = useRef<HTMLDivElement>(null);
	const searchAvailable = !!onSearchTextChange;
	const hasTableHeader = searchAvailable || pagination;

	const onSearchClear = () => {
		searchParams.set('query', '');
		searchParams.set('pageIndex', '1');
		setSearchParams(searchParams);
		onSearchTextChange?.('');
	};

	return (
		<Card variant="outlined" sx={{ backgroundColor: 'background.paper', ...cardSx }} data-testid="backend-table-test-id">
			<Stack direction="column">
				{hasTableHeader ? (
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{
							paddingX: 2,
							paddingY: 2.25,
							borderBottomWidth: 1,
							borderBottomStyle: 'solid',
							borderBottomColor: 'grey.300',
							backgroundColor: 'background.paper',
						}}
					>
						{searchAvailable ? (
							<>
								<SearchField
									{...searchFieldProps}
									onSearch={(query: string) => {
										searchParams.set('query', query);
										searchParams.set('pageIndex', '1');
										setSearchParams(searchParams);
									}}
									placeholder={searchPlaceholder}
									value={searchText}
									onSearchClear={onSearchClear}
									onChange={onSearchTextChange ? (event) => onSearchTextChange(event.target.value) : undefined}
									ref={searchFieldRef}
									filter={!!filterData}
									inputProps={{ sx: { paddingLeft: 0 } }}
									onFilterClick={() => setOpenFilter(!openFilter)}
									sx={{ backgroundColor: 'grey.50', ...searchFieldProps?.sx }}
									data-testid={DataTestId.getStaticTestId('data-table-backend-search-field')}
								/>
								{useMemo(
									() =>
										filterData && (
											<TableFilter
												setOpen={() => setOpenFilter(!openFilter)}
												open={openFilter}
												anchor={searchFieldRef.current as HTMLElement}
												filterData={filterData}
												removeFromChip={deletedItem}
												data-testid="filter-id"
												setCurrentFilterValues={setCurrentFilterValues}
											/>
										),
									[openFilter, deletedItem, filterData],
								)}
							</>
						) : (
							<Box />
						)}
						{showExportButton ? (
							<TableButton.Export
								data-testid="export-button-id"
								sx={{
									fontSize: '1rem',
									marginLeft: 'auto',
									width: '7rem',
									marginRight: 2,
									':hover': {
										backgroundColor: 'rgba(1, 136, 76, 0.1)',
									},
								}}
								onClick={() => onExportButtonClick?.()}
							>
								{exportButtonText}
							</TableButton.Export>
						) : (
							<></>
						)}
						{pagination && pagination.totalPages > 1 ? (
							<TablePagination
								onChange={(page) => {
									searchParams.set('pageIndex', page?.toString());
									setSearchParams(searchParams);
								}}
								page={pagination?.pageIndex}
								count={pagination?.totalPages}
								data-testid={DataTestId.getStaticTestId('data-table-backend-pagination-field')}
							/>
						) : null}
					</Stack>
				) : null}
				{/* TODO: Table can be seperated as an another component in the future if needed */}

				<Stack direction="column" sx={{ overflow: 'auto' }} ref={tableRef as Ref<HTMLDivElement>}>
					{filterData && filteringSelections && (
						<FilterChips
							onDeleteItem={(deletedItemFromChip) => setDeletedItem(deletedItemFromChip)}
							filterData={filterData || []}
							selectedFilterData={filteringSelections || []}
						/>
					)}
					{filterChipSuccessor && filterChipSuccessor}
					<Table {...getTableProps()} sx={{ ...sx }} {...props}>
						<TableHead>
							{headerGroups.map((headerGroup, i) => (
								<TableRow {...headerGroup.getHeaderGroupProps()} key={`header-${headerGroup.id}-${i}`}>
									{headerGroup.headers.map((column, j) => {
										return (
											<TableCell
												{...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
												key={`header-cell-${headerGroup.id}-${i}-${j}`}
												sx={
													{
														...getStickyProps(column as any),
														...concatenatedHeaderSx(column as any, tableHeaderCellSx),
													} as SxProps
												}
												onClick={() => {
													let newSortOrder = '';
													let newSortBy = '';

													if (column.id === sorting.sortBy) {
														const sortOrder = getNextSortOrder(sorting.sortOrder);
														newSortOrder = sortOrder;
														newSortBy = sortOrder === SortOrder.RESET ? '' : column.id;
													} else {
														newSortOrder = SortOrder.ASC;
														newSortBy = column.id;
													}
													searchParams.set('sortBy', newSortBy);
													searchParams.set('sortOrder', newSortOrder);
													setSearchParams(searchParams);
												}}
											>
												<Stack direction="row" alignItems="center" justifyContent="space-between">
													<Header {...headerCellProps}>{column.render('Header')}</Header>
													<SortIcon
														sortable={column.canSort}
														sortOrder={sorting.sortBy === column.id ? sorting.sortOrder : undefined}
														data-testid={DataTestId.getStaticTestId(`sort-icon-${i}-id`)}
													/>
												</Stack>
											</TableCell>
										);
									})}
								</TableRow>
							))}
						</TableHead>
						<TableBody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								prepareRow(row);
								return (
									<TableRow
										{...row.getRowProps()}
										key={`row-${row.id}-${i}`}
										data-testid={DataTestId.getStaticTestId(`row-${row.index}-id`)}
										id={`row-id-${row.values.id}`}
									>
										{row.cells.map((cell, j) => {
											return (
												<TableCell
													{...cell.getCellProps()}
													key={`row-${row.id}-${i}-cell-${j}`}
													sx={{
														padding: '0.9rem  0.4rem',
														width: 'fit-content',
														maxWidth: '15rem',
														borderBottom: rows.length - 1 === i ? 'none' : '',
														...getStickyProps(cell.column as any),
													}}
												>
													{cell.render('Cell')}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Stack>

				{tableSucceessorComponent ? tableSucceessorComponent : null}
			</Stack>
		</Card>
	);
};
