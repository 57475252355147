import { FC, ReactNode } from 'react';
import { FormControl, FormControlLabel, Link, LinkProps, Radio, RadioGroup, Stack, Typography, TypographyProps } from '@mui/material';
import { Tr } from '@utils';

import { useIrAnalysis } from '@hooks/ir/useIrAnalysis';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';

export const HereLink: FC<LinkProps> = ({ sx, ...props }) => (
	<Link sx={{ display: 'inline-block', fontWeight: 'bolder', color: 'inherit', textDecorationColor: 'inherit', ...sx }} {...props}>
		<Tr.IrPortal path="new-analysis.here" />
	</Link>
);

export const TypographyType: Record<'normal' | 'error', FC<TypographyProps & { render: ReactNode }>> = {
	error: ({ sx, render, ...props }) => (
		<Typography sx={{ color: 'error.main', fontSize: 12, ...sx }} {...props}>
			{render}
		</Typography>
	),
	normal: ({ sx, render, ...props }) => (
		<Typography sx={{ color: 'grey.500', fontSize: 12, ...sx }} {...props}>
			{render}
		</Typography>
	),
};

export type AnalysisTypeSelectionProps = {
	title?: ReactNode;
	typographyProps?: TypographyProps;
	onTypeChange?: (value: IrAnalysisType) => void;
};

export const AnalysisTypeSelection: FC<AnalysisTypeSelectionProps> = ({
	title,
	typographyProps: { sx: typographySx, ...typographyProps } = {},
	onTypeChange: onAnalysisTypeChange,
}) => {
	const { analysisType, isAnalysisEditable } = useIrAnalysis();
	return (
		<FormControl>
			{title ? (
				<Stack direction="row" alignItems="center" columnGap={2}>
					<Typography
						className={isAnalysisEditable ? 'required' : ''}
						sx={{ marginBottom: 1.25, ...typographySx }}
						{...typographyProps}
					>
						{title}
					</Typography>
					{!analysisType && isAnalysisEditable && (
						<Typography variant="body2" sx={{ color: 'error.main', marginBottom: 1.25, fontSize: 14 }}>
							<Tr.IrPortal path="new-analysis.analysis-type-required" />
						</Typography>
					)}
				</Stack>
			) : null}
			<RadioGroup
				value={analysisType}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => onAnalysisTypeChange?.(event.target.value as IrAnalysisType)}
			>
				<FormControlLabel
					value={IrAnalysisType.IDENTIFICATION}
					disabled={!isAnalysisEditable}
					control={
						<Radio
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							inputProps={{ 'data-testid': 'quantitative-test-id' }}
						/>
					}
					label={<Tr.IrPortal path="Identification" />}
				/>
				{/* TO DO: FIX PATH FORMAT: purity-check */}
				<FormControlLabel
					value={IrAnalysisType.PURITYCHECK}
					control={<Radio />}
					disabled={!isAnalysisEditable}
					label={<Tr.IrPortal path="Purity Check" />}
				/>

				<FormControlLabel
					value={IrAnalysisType.QUANTIFICATION}
					disabled={!isAnalysisEditable}
					control={<Radio />}
					label={<Tr.IrPortal path="Quantification" />}
				/>
			</RadioGroup>
		</FormControl>
	);
};
