import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { Body1 } from '../TableComponents';
import { ExtendedColumn } from '@models/table';
import { useEffect, useMemo, useState } from 'react';
import { NmrDrmOrder, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { DataTableBackend } from '@components/common';
import { useTranslation } from 'react-i18next';
import { EmptyBacklogTable, MultiselectDrmOrder, PriorityDropdown, RejectionSeverity, SelectedNmrDrmOrder } from './TableComponents';
import { DateUtils, TypeUtils } from '@utils/Type';
import { EmptyBacklog } from './EmptyBacklog';
import { useService } from '@hooks/useService';
import { getParams, useFilterSearchParams } from '@hooks/useFilterSearchParams';
import { SortOrder, FilterKey, NmrAdminPermission, PageRoutes, AdminRoleEnum, PagedResult } from '@models';
import { nmrDrmPipelineFilterService } from '@services/nmr-drm-pipeline/nmr-drm-pipeline-filter';
import { getAssigneeFilter, getOrderedBacklogFilter, getWaveFilter, isOnlyPageIndexChanged } from './nmr-pipeline-helper';
import { useSelector } from 'react-redux';
import { triggerBacklogRefreshSelector, waveDataSelector } from '@store/slices/pipeline.slice';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { useNavigate } from 'react-router-dom';
import { history } from '@utils/Router/history.const';
import { DrmPipelineFlag } from '../DrmPipelineFlag';
import { userService } from '@services/index';
import { PipelineAddDrmOrderModal } from '../AddDrmOrder/PipelineAddDrmOrderModal';
import { WaveDropdown } from './TableComponents';
import { Observable } from 'rxjs';
const checkboxSx = {
	zIndex: 1,
	marginTop: '-3px',
	'&::before': {
		content: '""',
		width: '16px',
		height: '16px',
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		background: 'white',
		zIndex: -1,
	},
};

export const NmrPipelineBacklog = () => {
	const { t: translate } = useTranslation('admin');
	const navigate = useNavigate();

	const [selectedItems, setSelectedItems] = useState<SelectedNmrDrmOrder[]>([]);
	const { searchParams, setSearchParams, filtering, setFiltering } = useFilterSearchParams('', SortOrder.RESET);
	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');
	const [lastSearchParams, setLastSearchParams] = useState(searchParams);
	const [isSearchParamsInitialized, setIsSearchParamsInitialized] = useState<boolean>(false);

	useEffect(() => {
		const currentWaveIds = searchParams.getAll(FilterKey.WAVE_IDS);

		if ((!currentWaveIds || currentWaveIds.length === 0) && history.action !== 'POP') {
			searchParams.set(FilterKey.WAVE_IDS, String(0));
			searchParams.set(FilterKey.STATUS, String(0));
			setSearchParams(searchParams, { replace: true });
		}
		setIsSearchParamsInitialized(true);
	}, []);

	const { data: filterData } = useService(() => nmrDrmPipelineFilterService.nmrPipelineNmrDrmOrderFilterOptions());
	const { data: userData } = useService(() => {
		const userParams = new URLSearchParams({ pageIndex: '1' });
		userParams.append('roles', AdminRoleEnum.NMR_ANALYST_QC_INSPECTOR.toString());
		userParams.append('roles', AdminRoleEnum.NMR_QA_REVIEWER.toString());
		return userService.getAllWithParams(userParams, '/merck');
	}, []);
	const triggerBacklogRefreshSelect = useSelector(triggerBacklogRefreshSelector);

	const { data: nmrBacklogData, trigger: refreshBacklogData } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		if (!isOnlyPageIndexChanged(searchParams, lastSearchParams)) {
			setSelectedItems([]);
		}
		setLastSearchParams(searchParams);
		if (isSearchParamsInitialized) {
			return nmrDrmOrderService.getAllWithSearchParams(searchParams);
		}
		return new Observable<PagedResult<NmrDrmOrder>>();
	}, [searchParams, triggerBacklogRefreshSelect, isSearchParamsInitialized]);

	const hasEditBacklogPermission = useHasPermissions(NmrAdminPermission.MANAGE_BACKLOG);
	const hasWaveManagementPermission = useHasPermissions(NmrAdminPermission.DRM_ORDER_WAVE_PLANNING);
	const hasChangeFlagStatusPermission = useHasPermissions(NmrAdminPermission.DRM_ORDER_CHANGE_FLAG);

	const refreshMultipleSelector = () => {
		setSelectedItems([]);
		refreshBacklogData();
	};

	const isEveryCheckboxSelected =
		!!nmrBacklogData?.data.length &&
		selectedItems.length >= (nmrBacklogData.data?.length ?? 0) &&
		nmrBacklogData?.data.every((item) => selectedItems.some((dataItem) => dataItem.id === item.id));

	const handleBulkMultipleSelection = () => {
		if (!isEveryCheckboxSelected) {
			const notIncludedRows =
				nmrBacklogData?.data
					.filter((row) => !selectedItems.some((item) => item.id === row.id))
					.map((item, index) => ({
						...item,
						index,
					})) ?? [];
			setSelectedItems([...selectedItems, ...notIncludedRows]);
		} else {
			setSelectedItems(selectedItems.filter((item) => !nmrBacklogData?.data.some((backlogData) => backlogData.id === item.id)));
		}
	};
	const isNoResult = !nmrBacklogData?.data.length;
	const columns = useMemo<ExtendedColumn<NmrDrmOrder>[]>(
		() => [
			{
				accessor: 'id',
				disableSortBy: true,
				hidden: !hasEditBacklogPermission || isNoResult,
				Header: (
					<Stack sx={{ pointerEvents: 'none' }} onClick={(event) => event.stopPropagation()}>
						<Checkbox
							data-testid="check-consent-id"
							checked={isEveryCheckboxSelected && !!nmrBacklogData?.data.length}
							onChange={handleBulkMultipleSelection}
							sx={{ pointerEvents: 'all' }}
						/>
					</Stack>
				),
				Cell: ({ row }) => (
					<Checkbox
						checked={selectedItems.some((item) => item.id === row.values.id)}
						onChange={() => {
							if (selectedItems.some((item) => item.id === row.values.id)) {
								setSelectedItems(selectedItems.filter((item) => item.id !== row.values.id));
							} else {
								setSelectedItems([...selectedItems, { ...TypeUtils.transform(NmrDrmOrder, row.values), index: row.index }]);
							}
						}}
						sx={checkboxSx}
					/>
				),
			},
			{
				accessor: 'priority',
				hidden: isNoResult,
				Header: (
					<Stack paddingLeft={!hasWaveManagementPermission ? 1.25 : 0}>
						<Tr.Admin path="drm-pipeline.nmr.backlog.priority" />
					</Stack>
				),
				Cell: ({ row }) => (
					<Box paddingLeft={!hasWaveManagementPermission ? 1.25 : 0} id={`box-with-id-${row.values.id}`}>
						<PriorityDropdown
							readOnly={!hasWaveManagementPermission}
							refresh={refreshBacklogData}
							nmrDrmOrderData={TypeUtils.transform(NmrDrmOrder, row.values)}
						/>
					</Box>
				),
			},
			{
				accessor: 'physicalProductNumber',
				hidden: isNoResult,
				Header: <Tr.Admin path="drm-pipeline.nmr.backlog.physical-product-no" />,
				Cell: ({ value, row }) => (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Body1>{value || '-'}</Body1>
						<RejectionSeverity
							severity={nmrBacklogData?.data.filter((order) => order.id === row.original.id)?.[0]?.rejectionSeverity}
						/>
					</Stack>
				),
			},
			{
				accessor: 'casNumber',
				hidden: isNoResult,
				Header: <Tr.Admin path="drm-pipeline.nmr.backlog.cas-number" />,
				Cell: ({ value }) => <Body1>{value ?? '-'}</Body1>,
			},
			{
				accessor: 'createTime',
				hidden: isNoResult,
				Header: <Tr.Admin path="drm-pipeline.nmr.backlog.creation-date" />,
				Cell: ({ value }) => <Body1>{DateUtils.getFormattedDate(value, 'dd/MM/yyyy')}</Body1>,
			},
			{
				accessor: 'wave',
				hidden: isNoResult,
				Header: <Tr.Admin path="drm-pipeline.nmr.backlog.wave" />,
				Cell: ({ row }) => (
					<Body1>
						<WaveDropdown readOnly={!hasWaveManagementPermission} order={TypeUtils.transform(NmrDrmOrder, row.values)} />
					</Body1>
				),
			},
			{
				accessor: 'status',
				disableSortBy: true,
				hidden: isNoResult,
				Header: <></>,
				Cell: ({ row }) => (
					<Stack direction="row" alignItems="center" justifyContent="end" spacing={3.5} marginRight={1.25}>
						<DrmPipelineFlag order={row.original} tooltipEnabled refreshData={refreshBacklogData} />

						<Button
							onClick={() =>
								navigate(
									`/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/${
										nmrBacklogData?.data.filter((i, idx) => idx === row.index)?.[0]?.id
									}`,
								)
							}
							variant="outlined"
							size="small"
							sx={{ paddingX: 1, paddingY: 3 / 4, width: 48, height: 32, backgroundColor: 'white' }}
						>
							<Tr.Admin path="drm-pipeline.nmr.backlog.view" />
						</Button>
					</Stack>
				),
			},
		],
		[
			selectedItems,
			JSON.stringify(nmrBacklogData?.data),
			hasEditBacklogPermission,
			hasWaveManagementPermission,
			hasChangeFlagStatusPermission,
		],
	);

	const waveData = useSelector(waveDataSelector);
	const showTable = nmrBacklogData?.data.length !== 0 || filtering?.length || searchTextfieldValue;

	return (
		<Stack>
			{showTable && (
				<Stack direction="row" width="100%" justifyContent="space-between" marginBottom={1.5}>
					<Typography variant="h2" color="primary.main">
						<Tr.Admin path="drm-pipeline.nmr.backlog.all-orders" />
					</Typography>
					<PipelineAddDrmOrderModal />
				</Stack>
			)}

			{showTable ? (
				<DataTableBackend
					data={nmrBacklogData?.data ?? []}
					columns={columns.filter((col) => !col.hidden)}
					sorting={{
						sortBy: getParams(searchParams).getSortBy(),
						sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
					}}
					pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: nmrBacklogData?.totalPages ?? 1 }}
					searchText={searchTextfieldValue}
					searchPlaceholder={translate('drm-pipeline.nmr.backlog.search-placeholder')}
					onSearchTextChange={setSearchTextfieldValue}
					searchFieldProps={{
						sx: {
							width: (600 / 1440) * window.innerWidth,
						},
					}}
					filterData={getOrderedBacklogFilter([
						getAssigneeFilter(userData?.data ?? []),
						getWaveFilter(waveData ?? []),
						...(filterData?.options ?? []),
					])}
					onFilter={setFiltering}
					filteringSelections={filtering}
					tableSucceessorComponent={
						nmrBacklogData?.data.length === 0 ? (
							searchTextfieldValue.length ? (
								<EmptyBacklogTable searchTextfieldValue={searchTextfieldValue} />
							) : (
								<EmptyBacklogTable />
							)
						) : null
					}
					filterChipSuccessor={
						selectedItems ? (
							<MultiselectDrmOrder
								onClose={() => setSelectedItems([])}
								selectedItems={selectedItems}
								refreshBacklog={refreshMultipleSelector}
								backlogData={nmrBacklogData}
							/>
						) : null
					}
					sx={{
						'& .MuiTableRow-root': {
							transition: 'all 100ms ease-in',
						},
					}}
				/>
			) : (
				<EmptyBacklog />
			)}
		</Stack>
	);
};
