import { FC } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { IconProps, Stack, Typography } from '@mui/material';

import { Tr } from '@utils';
import { IrAnalysisResult, IrMatchCategory } from '@services';

type ColorType = 'success' | 'warning' | 'error';
interface ISizeAndThreshold {
	length: number;
}

// TO DO: AVOID MAGIC NUMBERS
const colorMapping: Record<Partial<ColorType>, ISizeAndThreshold> = {
	success: { length: 3 },
	warning: { length: 2 },
	error: { length: 1 },
};
// TO DO: THIS IS NOT A MAP, INSTEAD USE Record
export const valueColorMapping = (data: IrMatchCategory) => {
	if (data === IrMatchCategory.SATISFACTORY) {
		return 'success';
	} else if (data === IrMatchCategory.QUESTIONABLE) {
		return 'warning';
	} else {
		return 'error';
	}
};

// TO DO: THIS IS NOT A MAP, INSTEAD USE Record
export const irValueColorMapping = (data: IrMatchCategory) => {
	if (data === IrMatchCategory.SATISFACTORY) {
		return 'success';
	} else if (data === IrMatchCategory.QUESTIONABLE) {
		return 'warning';
	} else {
		return 'error';
	}
};

export const StarIconIndicator: FC<IconProps> = ({ color, sx }) => (
	<Stack direction="row" marginRight="0.5rem" sx={sx}>
		{Array(colorMapping[color as ColorType].length)
			.fill(0)
			.map((icon, index) => (
				<StarIcon key={index} color={color} />
			))}
	</Stack>
);

export const TableLegend: FC<{ analysisResult: IrAnalysisResult }> = ({ analysisResult }) => (
	<Stack padding="1rem 1rem 1rem 0" direction="row">
		<Stack direction="row" alignItems="center">
			<StarIconIndicator color="success" />
			<Typography variant="body2" sx={{ lineHeight: '160%' }}>
				{analysisResult.isQuantification() ? (
					<Tr.IrPortal path="analysis-result-table.satisfactory-result-quantification" />
				) : (
					<Tr.IrPortal path="analysis-result-table.satisfactory-result" />
				)}
			</Typography>
		</Stack>
		<Stack direction="row" alignItems="center" margin="0.1rem 1.5rem">
			<StarIconIndicator color="warning" />
			<Typography variant="body2" sx={{ lineHeight: '160%' }}>
				{analysisResult.isQuantification() ? (
					<Tr.IrPortal path="analysis-result-table.questionable-result-quantification" />
				) : (
					<Tr.IrPortal path="analysis-result-table.questionable-result" />
				)}
			</Typography>
		</Stack>
		<Stack direction="row" alignItems="center">
			<StarIconIndicator color="error" />
			<Typography variant="body2" sx={{ lineHeight: '160%' }}>
				{analysisResult.isQuantification() && <Tr.IrPortal path="analysis-result-table.unsatisfactory-result-quantification" />}
				{analysisResult.isIdentification() && <Tr.IrPortal path="analysis-result-table.unsatisfactory-result" />}
				{!analysisResult.isIdentification() && !analysisResult.isQuantification() && (
					<Tr.IrPortal path="analysis-result-table.unsatisfactory-result-verification" />
				)}
			</Typography>
		</Stack>
	</Stack>
);
