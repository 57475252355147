import { FC, ReactElement } from 'react';
import { userSelector } from '@store/slices/common.slice';
import { useSelector } from 'react-redux';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

export type PortalElementProps = {
	nmrComponent: ReactElement;
	irComponent: ReactElement;
};

export const PortalElement: FC<PortalElementProps> = ({ nmrComponent, irComponent }) => {
	const user = useSelector(userSelector);

	if (!user || !user.currentLabId || !user.laboratories) {
		return null;
	}

	const lab = user.laboratories.find((l) => l.id === +(user.currentLabId ?? 0));
	if (!lab) {
		return null;
	}

	switch (lab.technology) {
		case SupportedTechnology.NMR:
			return nmrComponent;
		case SupportedTechnology.IR:
			return irComponent;
		default:
			return nmrComponent;
	}
};
