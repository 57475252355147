import { NmrAnalysisStage as AnalysisStageType } from '@services/nmr/nmr-analysis/nmr-analysis-stage.enum';

export interface BreadcrumbConfig {
	key?: string;
	path?: string;
	pathIndex?: number; //portal or admin
	subPathIndex?: number;
	subPaths?: ISubPath[];
}

enum BreadcrumbKey {
	PROFILE = 'profile',
	AUDIT_TRAIL = 'audit-trail',
	ANALYSIS_COMPLETED = 'completed',
	ANALYSIS_ONGOING = 'ongoing',
	ANALYSIS_RESULT = 'analysis-result',
	DEVICE_MGMT = 'device-management',
	MANAGE_USERS = 'manage-users',
	ORGANIZATION_SETTINGS = 'organization-settings',
	DEVICE_CALIBRATION = 'device-calibration',
	GOTO_DEVICE = 'go-to-device',
	DEVICE = 'device',
	RESTORE_DEVICE = 'restore-device',
	DRM_MANAGEMENT = 'drm-management',
	SUBSTANCE_MGMT_SUBSTANCE_DB = 'substance-management-substance-database',
	SUBSTANCE_MGMT_DRM_DB = 'substance-management-drm-database',
	SUBSTANCE_MGMT_DETAILS = 'substance-management-details',
	EDIT_ERM = 'substance-management-drm-details',
	SUBSTANCE_MGMT_THEORETICAL_DETAILS = 'substance-management-theoretical-details',
	CUSTOMER_MANAGEMENT = 'customer-management',
	DRM_REQUEST = 'drm-request',
	DRM_REQUEST_DETAIL = 'drm-request-detail',
	LAB_DETAILS = 'lab-details',
	LAB_DETAILS_ADMIN = 'admin-lab-details',
	DRM_ORDER_DETAILS = 'drm-order-details',
	ADD_ACCESSORY = 'add-accessory',
}

export interface ISubPath {
	navigateTo?: string;
	text?: string;
	textLabel?: string;
	conditions?: ICondition[];
	isDynamic?: string;
	dynamicParameterList?: string[];
	entityKey?: string;
}

interface ICondition {
	entityKey: string;
	items: unknown[];
}

// TO DO: CHANGE INDEX BASED CONFIG TO KEY BASED!! THIS IS A BAD PRACTICE
export const BreadcrumbConfig = [
	{
		key: BreadcrumbKey.PROFILE,
		pathIndex: 3,
		subPathIndex: 16,
		subPaths: [
			{
				navigateTo: '/portal/dashboard',
				textLabel: 'dashboard',
			},
			{
				navigateTo: '/portal/your-profile',
				textLabel: 'profile',
			},
		],
	},
	{
		key: BreadcrumbKey.AUDIT_TRAIL,
		path: 'history/audit-trail/:id',
		pathIndex: 3,
		subPathIndex: 1,
		subPaths: [
			{ navigateTo: '/portal/history', textLabel: 'analysis-database' },
			{
				navigateTo: '/portal/history/audit-trail',
				textLabel: 'audit-trail',
			},
			{
				entityKey: 'title',
			},
		],
	},
	{
		key: BreadcrumbKey.ANALYSIS_ONGOING,
		path: 'history/ongoing/',
		pathIndex: 3,
		subPathIndex: 1,
		subPaths: [
			{ navigateTo: '/portal/history', textLabel: 'analysis-database' },
			{
				textLabel: 'analysis-ongoing',
			},
		],
	},
	{
		key: BreadcrumbKey.ANALYSIS_COMPLETED,
		path: 'history/completed/',
		pathIndex: 3,
		subPathIndex: 1,
		subPaths: [
			{ navigateTo: '/portal/history', textLabel: 'analysis-database' },
			{
				textLabel: 'analysis-completed',
			},
		],
	},
	{
		key: BreadcrumbKey.ANALYSIS_RESULT,
		pathIndex: 3,
		subPathIndex: 7,
		subPaths: [
			{
				navigateTo: '/portal/history',
				textLabel: 'analysis-database',
				conditions: [
					{
						entityKey: 'stage',
						items: [
							AnalysisStageType.EXECUTED,
							AnalysisStageType.CONFIRMED_BY_LAB_MEMBER,
							AnalysisStageType.APPROVED_BY_LAB_ADMIN,
							AnalysisStageType.REJECTED_BY_LAB_MEMBER,
							AnalysisStageType.REJECTED_BY_LAB_ADMIN,
						],
					},
				],
			},
			{
				navigateTo: '/portal/history',
				textLabel: 'ongoing-analyses',
				conditions: [
					{
						entityKey: 'stage',
						items: [AnalysisStageType.EXECUTED, AnalysisStageType.CONFIRMED_BY_LAB_MEMBER],
					},
					{
						entityKey: 'isCompleted',
						items: [false],
					},
				],
			},
			{
				navigateTo: '/portal/history/completed',
				textLabel: 'completed-analyses',
				conditions: [
					{
						entityKey: 'isCompleted',
						items: [true],
					},
				],
			},
			{
				entityKey: 'title',
				conditions: [
					{
						entityKey: 'stage',
						items: [
							AnalysisStageType.EXECUTED,
							AnalysisStageType.CONFIRMED_BY_LAB_MEMBER,
							AnalysisStageType.APPROVED_BY_LAB_ADMIN,
							AnalysisStageType.REJECTED_BY_LAB_MEMBER,
							AnalysisStageType.REJECTED_BY_LAB_ADMIN,
						],
					},
				],
			},
		],
	},
	{
		key: BreadcrumbKey.DEVICE_MGMT,
		pathIndex: 3,
		subPathIndex: 10,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/device-management',
				textLabel: 'device-management',
			},
		],
	},
	{
		key: BreadcrumbKey.ADD_ACCESSORY,
		pathIndex: 3,
		subPathIndex: 15,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/device-management',
				textLabel: 'irdevice-management',
			},
			{
				entityKey: 'name',
				navigateTo: '/portal/manage-organization/go-to-device/:id',
				dynamicParameterList: [':id'],
			},
			{
				navigateTo: '/portal/manage-organization/add-accessory',
				textLabel: 'add-accessory',
			},
		],
	},
	{
		key: BreadcrumbKey.DEVICE_CALIBRATION,
		pathIndex: 3,
		subPathIndex: 11,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/device-management',
				textLabel: 'device-management',
			},
			{
				entityKey: 'name',
			},
		],
	},
	{
		key: BreadcrumbKey.GOTO_DEVICE,
		pathIndex: 3,
		subPathIndex: 13,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/device-management',
				textLabel: 'irdevice-management',
			},
			{
				entityKey: 'name',
				navigateTo: '/portal/manage-organization/go-to-device/:id',
				dynamicParameterList: [':id'],
			},
		],
	},
	{
		key: BreadcrumbKey.RESTORE_DEVICE,
		pathIndex: 3,
		subPathIndex: 14,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/device-management',
				textLabel: 'irdevice-management',
			},
			{
				entityKey: 'name',
				navigateTo: '/portal/manage-organization/restore-device/:id',
				dynamicParameterList: [':id'],
			},
		],
	},
	{
		key: BreadcrumbKey.ORGANIZATION_SETTINGS,
		pathIndex: 3,
		subPathIndex: 17,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/organization-settings/:id/overview',
				textLabel: 'organization-settings',
				dynamicParameterList: [':id'],
			},
			{
				entityKey: 'tab',
			},
		],
	},
	{
		key: BreadcrumbKey.LAB_DETAILS,
		pathIndex: 3,
		subPathIndex: 18,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/organization-settings/:id/overview',
				textLabel: 'organization-settings',
				dynamicParameterList: [':id'],
			},
			{
				navigateTo: '/portal/manage-organization/organization-settings/:id/labs',
				textLabel: 'labs',
				dynamicParameterList: [':id'],
			},
			{
				entityKey: 'name',
			},
		],
	},
	{
		key: BreadcrumbKey.CUSTOMER_MANAGEMENT,
		pathIndex: 4,
		subPathIndex: 1,
		subPaths: [
			{
				navigateTo: '/admin/customer-management/organizations',
				textLabel: 'customer-management',
			},
			{
				navigateTo: '/admin/customer-management/:tab',
				dynamicParameterList: [':tab'],
				entityKey: 'tab',
			},
		],
	},

	{
		key: BreadcrumbKey.SUBSTANCE_MGMT_DETAILS,
		pathIndex: 4,
		subPathIndex: 6,
		subPaths: [
			{
				navigateTo: '/admin/substance-management',
				textLabel: 'substance-management',
			},
			{
				navigateTo: '/admin/substance-management/substance-details/:id',
				dynamicParameterList: [':id'],
				entityKey: 'substanceName',
			},
		],
	},

	{
		key: BreadcrumbKey.DRM_REQUEST,
		pathIndex: 4,
		subPathIndex: 4,
		subPaths: [
			{
				navigateTo: '/admin/drm-management',
				textLabel: 'drm-management',
			},
			{
				navigateTo: '/admin/drm-database/nmr/drm-requests',
				textLabel: 'drm-requests',
			},
			{
				entityKey: 'requestNumber',
			},
		],
	},
	{
		key: BreadcrumbKey.CUSTOMER_MANAGEMENT,
		pathIndex: 4,
		subPathIndex: 7,
		subPaths: [
			{
				navigateTo: '/admin/customer-management',
				textLabel: 'customer-management',
			},
			{
				textLabel: 'add-new-organization',
			},
		],
	},
	{
		key: BreadcrumbKey.CUSTOMER_MANAGEMENT,
		pathIndex: 4,
		subPathIndex: 8,
		subPaths: [
			{
				navigateTo: '/admin/customer-management',
				textLabel: 'customer-management',
			},
			{
				entityKey: 'name',
				navigateTo: '/admin/customer-management/:id/overview',
				dynamicParameterList: [':id'],
			},
			{
				entityKey: 'tab',
			},
		],
	},
	{
		key: BreadcrumbKey.LAB_DETAILS_ADMIN,
		pathIndex: 4,
		subPathIndex: 9,
		subPaths: [
			{
				navigateTo: '/admin/customer-management',
				textLabel: 'customer-management',
			},
			{
				entityKey: 'orgName',
				navigateTo: '/admin/customer-management/:id/overview',
				dynamicParameterList: [':id'],
			},
			{
				navigateTo: '/admin/customer-management/:id/labs',
				textLabel: 'labs',
				dynamicParameterList: [':id'],
			},
			{
				entityKey: 'name',
			},
		],
	},
	{
		key: BreadcrumbKey.DRM_ORDER_DETAILS,
		pathIndex: 4,
		subPathIndex: 12,
		subPaths: [
			{
				navigateTo: ':path',
				dynamicParameterList: [':path'],
				entityKey: 'status-label',
			},
			{
				entityKey: 'drm-order-name',
			},
		],
	},
	{
		key: BreadcrumbKey.SUBSTANCE_MGMT_DRM_DB,
		pathIndex: 4,
		subPathIndex: 5,
		subPaths: [
			{
				navigateTo: '/admin/drm-management/nmr',
				textLabel: 'rm-management',
			},
			{
				navigateTo: '/admin/drm-management/nmr/drm-database',
				textLabel: 'rm-database',
			},
			{
				entityKey: 'drmProductNumber',
			},
		],
	},
	{
		key: BreadcrumbKey.EDIT_ERM,
		pathIndex: 4,
		subPathIndex: 13,
		subPaths: [
			{
				navigateTo: '/admin/drm-management/ir',
				textLabel: 'rm-management',
			},
			{
				navigateTo: '/admin/drm-management/ir/drm-database',
				textLabel: 'rm-database',
			},
			{
				entityKey: 'productNumber',
			},
		],
	},
	{
		key: BreadcrumbKey.LAB_DETAILS,
		pathIndex: 5,
		subPathIndex: 4,
		subPaths: [
			{
				navigateTo: '/orgAdmin/dashboard/:id/labs',
				textLabel: 'labs',
				dynamicParameterList: [':id'],
			},
			{
				entityKey: 'name',
			},
		],
	},
	{
		key: BreadcrumbKey.DEVICE,
		pathIndex: 3,
		subPathIndex: 12,
		subPaths: [
			{
				navigateTo: '/portal/manage-organization',
				textLabel: 'manage-organization',
			},
			{
				navigateTo: '/portal/manage-organization/device-management',
				textLabel: 'irdevice-management',
			},
			{
				entityKey: 'name',
				navigateTo: '/portal/manage-organization/go-to-device/:id',
				dynamicParameterList: [':id'],
			},
		],
	},
];
