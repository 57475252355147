import { FormikTextfield, ModalDialog } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { useParams } from 'react-router-dom';
import { onPeakSelectionsChange } from './IntegratedTableHelper';

type TAddNumberOfProtonsModalProps = {
	peak: QuantitivePeakType;
	onSubmit: () => void;
	onClose: () => void;
};

export type FormFields = Extendable<{ numberOfH: number }>;

export const AddNumberOfProtonsModal: FC<TAddNumberOfProtonsModalProps> = ({ peak, onSubmit, onClose }) => {
	const { id = '' } = useParams<string>();
	const { t } = useTranslation('portal');
	const formik = useFormik<FormFields>({
		enableReinitialize: true,
		initialValues: {
			numberOfH: peak.numberOfH,
		},
		validationSchema: yup.object({
			numberOfH: yup
				.number()
				.min(1, t('new-analysis.you-can-only-enter-positive-integer'))
				.max(100000, t('new-analysis.max-proton-number-is'))
				.required(t('new-analysis.number-of-protons-required')),
		}),
		onSubmit: (changes) => {
			onPeakSelectionsChange(+id, changes.numberOfH, peak, onSubmit);
		},
		validateOnChange: true,
	});

	const closeModal = () => {
		onClose();
		formik.resetForm();
	};

	return (
		<ModalDialog
			variant="primary"
			open={true}
			titleProps={{
				sx: {
					justifyContent: 'space-between',
					marginTop: 0,
					marginBottom: 3,
					minHeight: 30,
					backgroundColor: 'grey.50',
					paddingY: 1.2,
					paddingX: 2,
					height: 40,
				},
			}}
			title={<Typography variant="pg-s">{t('new-analysis.add')}</Typography>}
			maxWidth="sm"
			iconButtonProps={{
				right: 8,
			}}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					minHeight: '351px',
					justifyContent: 'space-between',
				},
			}}
			onClose={closeModal}
			contentProps={{ sx: { width: 472, paddingBottom: 3 } }}
		>
			<form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
				<Stack spacing={3} sx={{ alignItems: 'center', textAlign: 'center', width: '100%', paddingX: 8, paddingBottom: 7 }}>
					<Typography variant="subtitle1">
						<Tr.Portal path="new-analysis.add-number-of-protons" />
					</Typography>
					<Typography variant="pg-m">
						<Tr.Portal path="new-analysis.add-number-of-protons-explanation" />
					</Typography>

					<Stack style={{ width: '100%', textAlign: 'start' }}>
						<FormikTextfield
							formikApi={formik}
							name="numberOfH"
							title={t('new-analysis.number-of-protons')}
							placeholder={t('new-analysis.enter-number-of-protons')}
							onKeyDown={(event) => {
								if (event?.key === '-' || event?.key === '.' || event?.key === 'e') {
									event.preventDefault();
								}
							}}
							type="number"
							required
						/>
					</Stack>
					<Stack direction="row" width="100%" paddingTop={1} spacing={1.5}>
						<Button data-testid="cancel-btn-id" variant="outlined" sx={{ width: '100%' }} onClick={closeModal}>
							<Tr.Portal path="new-analysis.cancel" />
						</Button>
						<Button
							data-testid="submit-button-id"
							type="submit"
							disabled={!formik.dirty || !formik.isValid}
							sx={{ width: '100%' }}
							variant="contained"
							disableElevation
						>
							<Tr.Portal path="Save" />
						</Button>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
