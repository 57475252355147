import React, { FC, useState } from 'react';
import { Button, Stack, StackProps, SxProps, Typography, TypographyProps } from '@mui/material';
import { ModalDialog, MolecularFormula, OverflowText } from '@components/common';
import { Tr } from '@utils/Translation';
import { NmrDrmDetail, NmrSubstanceListItem } from '@services';
import { useTranslation } from 'react-i18next';
import { DRMDivider } from '@routes/Portal/SearchReferences';
import { useMemoizedTestId } from '@hooks/useMemoizedTestId';
import { SubstanceTable } from './SubstanceTable';

interface ISubstanceListItemProps {
	substance: NmrSubstanceListItem;
	referenceMaterialAccessor?: string;
	type?: 'add' | 'remove';
	actionLabel: string;
	onSubstanceAdd?: (substanceId: number) => void;
	onSubstanceRemove?: (substanceId: number) => void;
	onDrmDetail?: (visible: boolean, detail: NmrDrmDetail) => void;
	onRefreshSubstanceList?: () => void;
	sx?: SxProps;
	width?: string;
	disabled?: boolean;
}

const fieldStyle: StackProps['sx'] = {
	marginBottom: 1,
};

const substanceLabelStyle: TypographyProps['sx'] = {
	fontWeight: 400,
	fontSize: '16px',
	whiteSpace: 'nowrap',
	lineHeight: '160%',
};

const SubstanceField: FC<{ namePath: string; value: string | number; enableTooltip?: boolean; unitPath?: string }> = ({
	namePath,
	value,
	enableTooltip = false,
	unitPath = '',
	...props
}) => (
	<Stack direction="row" sx={fieldStyle} {...props}>
		<Typography variant="pg-m" sx={{ ...substanceLabelStyle, color: 'text.primary', flex: 1 }}>
			<Tr.Portal path={namePath} />
		</Typography>
		<OverflowText
			variant="pg-m"
			enableTooltip={enableTooltip}
			sx={{ ...substanceLabelStyle, color: 'grey.800' }}
			containerProps={{ flex: 2 }}
		>
			{`${value} `}
			<Tr.Portal path={unitPath} />
		</OverflowText>
	</Stack>
);

export const SubstanceListItem: FC<ISubstanceListItemProps> = ({
	substance,
	type,
	actionLabel,
	onSubstanceAdd,
	onSubstanceRemove,
	onRefreshSubstanceList,
	onDrmDetail,
	referenceMaterialAccessor = 'referenceMaterials',
	sx,
	disabled,
}) => {
	const { t } = useTranslation('portal');

	const { name, linearMolecularFormula, molecularWeight, synonyms, casNumber } = substance;

	const referenceMaterials = substance[`${referenceMaterialAccessor}`];

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const testid = useMemoizedTestId(`substance-list-item-${type}`, true);

	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	return (
		<Stack>
			{substance.totalTheoreticalSpectrumCount && substance.searchString && (
				<Stack sx={{ marginTop: substance.theoreticalStartIndex !== 0 ? '3rem' : '1rem' }}>
					<Typography variant="pg-xs" sx={[{ color: 'text.primary' }, { marginX: 2 }]}>{`${
						substance.totalTheoreticalSpectrumCount
					} ${t('theoretical-spectra')} ${t('new-analysis.results-for')} "${substance.searchString}"`}</Typography>

					<DRMDivider sx={{ marginTop: 1, marginX: 0 }} />
				</Stack>
			)}

			<Stack id={`substance-list-item-${substance.id}`} direction="row" justifyContent="space-between" width={1} sx={sx}>
				<MolecularFormula
					width={250}
					height={200}
					sx={{ width: '250px', height: '200px' }}
					deactivateColors={true}
					smilesFormula={substance.smilesCode}
					ref={molecularFormulaRef}
				/>
				<Stack direction="column" sx={{ maxWidth: 'calc(100% - 17rem)' }} width={1}>
					<Stack sx={{ position: 'relative' }}>
						<Stack maxWidth="50rem" width="100%">
							<OverflowText
								variant="h3"
								enableTooltip={true}
								sx={{
									color: 'text.primary',
									width: 'calc(100% - 170px)',
									pointerEvents: 'none',
									height: 'fit-content',
									marginBottom: '1rem',
									marginRight: '1rem',
									maxHeight: '90px',
									whiteSpace: 'nowrap',
								}}
							>
								{name}
							</OverflowText>
							<SubstanceField namePath="new-analysis.cas-number" value={casNumber} data-testid={`cas-number-${testid}`} />
							<SubstanceField namePath="drm-detail.linear-formula" value={linearMolecularFormula} />
							<SubstanceField
								namePath="drm-detail.molecular-weight"
								value={molecularWeight}
								unitPath="drm-detail.molecular-weight-unit"
							/>
							<SubstanceField enableTooltip={true} namePath="new-analysis.synonyms" value={synonyms} />
						</Stack>

						<Stack
							direction="column"
							rowGap={2}
							justifyContent={'space-around'}
							data-testid={`add-favorites-stack-${substance.id}`}
							sx={{ position: 'absolute', top: 0, right: 10 }}
						>
							<ModalDialog
								maxWidth="xs"
								onClose={() => setIsDialogOpen(false)}
								title={
									<Typography variant="inherit">
										<Tr.Portal path="new-analysis.remove" />
									</Typography>
								}
								titleProps={{ sx: { paddingBottom: 0 } }}
								onBackdropClick={() => setIsDialogOpen(false)}
								open={isDialogOpen}
								variant="secondary"
								sx={{
									'& .MuiDialog-container': {
										'& .MuiPaper-root': {
											width: '100%',
											maxWidth: '472px',
										},
									},
								}}
							>
								<Stack sx={{ paddingX: 8, paddingTop: 3, paddingBottom: 7 }} textAlign="center" spacing={3}>
									<Stack>
										<Typography variant="subtitle1">
											<Tr.Portal
												path="new-analysis.remove-dialog.title"
												options={{ tech: substance.isTheoretical ? t('theoretical-spectrum') : t('drm') }}
											/>
										</Typography>
									</Stack>
									<Stack>
										<Typography variant="pg-m">
											<Tr.Portal
												path="new-analysis.remove-dialog.description"
												options={{ tech: substance.isTheoretical ? t('theoretical-spectrum') : t('drm') }}
											/>
										</Typography>
									</Stack>
									<Stack direction="row" justifyContent="space-between" spacing={2}>
										<Button fullWidth variant="outlined" onClick={() => setIsDialogOpen(false)}>
											<Tr.Portal path="new-analysis.remove-dialog.cancel" />
										</Button>
										<Button
											fullWidth
											variant="contained"
											data-testid="substance-detail-button-id"
											onClick={() => {
												onSubstanceRemove && onSubstanceRemove(substance.id);
											}}
										>
											<Tr.Portal path="new-analysis.remove" />
										</Button>
									</Stack>
								</Stack>
							</ModalDialog>
						</Stack>

						{referenceMaterials?.length > 0 && (
							<SubstanceTable
								referenceMaterials={referenceMaterials}
								onDrmDetail={onDrmDetail}
								type={type}
								onDrmAdd={onSubstanceAdd}
								onDrmRemove={onSubstanceRemove}
								onRefreshSubstanceList={onRefreshSubstanceList}
								actionLabel={actionLabel}
								actionProps={{ disabled: disabled }}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
