import { useService } from '@hooks/useService';
import { PageRoutes } from '@models/router';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import { irDrmRequestService } from '@services/ir';
import { nmrDrmRequestService } from '@services/nmr';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { DataTestId } from '@utils/DataTestId';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';

export interface ISubstanceRequest {
	count: number;
	casNumber: string;
}

export const SubstanceRequestsMap: Record<SupportedTechnology, FC<ISubstanceRequest>> = {
	[SupportedTechnology.NMR]: (props) => <SubstanceRequest supportedTechnology={SupportedTechnology.NMR} {...props} />,
	[SupportedTechnology.IR]: (props) => (
		<SubstanceRequest supportedTechnology={SupportedTechnology.IR} sx={{ marginLeft: 1 }} {...props} />
	),
};

const SupportedTechnologyTextMap: Record<SupportedTechnology, string> = {
	[SupportedTechnology.NMR]: 'NMR',
	[SupportedTechnology.IR]: 'IR',
};

interface ISubstanceRequestCount {
	supportedTechnology: SupportedTechnology;
	count: number;
	sx?: SxProps;
	casNumber: string;
}

interface SubstanceRequestsProps {
	substanceId: number;
	casNumber: string;
}

export const SubstanceRequests: FC<SubstanceRequestsProps> = ({ substanceId, casNumber }) => {
	const { data: nmrRequests } = useService(() => {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.append('SubstanceId', `${substanceId}`);

		return nmrDrmRequestService.requestsErmListWithParams(urlSearchParams);
	}, []);

	const { data: irRequests } = useService(() => {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.append('SubstanceId', `${substanceId}`);

		return irDrmRequestService.requestsErmListWithParams(urlSearchParams);
	}, []);
	const render: ReactNode[] = [];
	if (nmrRequests && nmrRequests.data.length) {
		render.push(<SubstanceRequestsMap.NMR key="nmr-requests" count={nmrRequests.data.length} casNumber={casNumber} />);
	}
	if (irRequests && irRequests.data.length) {
		render.push(<SubstanceRequestsMap.IR key="ir-requests" count={irRequests.data.length} casNumber={casNumber} />);
	}

	return (
		<Stack data-testid={DataTestId.getStaticTestId('requests-wrapper-test-id')} direction="row">
			{render.length > 0 ? render : '-'}
		</Stack>
	);
};

const SubstanceRequest: FC<ISubstanceRequestCount> = ({ count, supportedTechnology, casNumber }) => {
	const navigate = useNavigate();

	return (
		<Button
			onClick={() => {
				const requestsSearchParams = new URLSearchParams();
				requestsSearchParams.set('query', casNumber);
				requestsSearchParams.set('technologies', supportedTechnology === SupportedTechnology.NMR ? '0' : '1');
				requestsSearchParams.set('pageIndex', '1');

				navigate(`/admin/${PageRoutes.SUBSTANCE_MANAGEMENT}/${PageRoutes.ERM_REQUESTS}?${requestsSearchParams.toString()}`);
			}}
			variant="outlined"
			sx={{ height: 24, paddingX: 0.5, paddingY: 0, borderColor: 'grey.400', borderWidth: 1 }}
		>
			<Typography variant="label-s" sx={{ color: 'grey.800' }} data-testid={DataTestId.getStaticTestId('substance-requests-test-id')}>
				{SupportedTechnologyTextMap[`${supportedTechnology}`]}: {count}
			</Typography>
		</Button>
	);
};
