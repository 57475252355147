import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUploadedFile } from '@models';
import { cardSpacer, Tr, TypeUtils } from '@utils';
import { Grid, Stack, Typography } from '@mui/material';
import { FormikApiType, FormikAutoComplete, GenericCard } from '@components/common';
import { AnalysisErrorIndicator, AnalysisTypeSelection, SampleFileUploader, SecondApprovalSelection } from '@components/portal';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { AnalysisSelectionType, AnalysisSelectionTypeMap, TAnalysisSelectionType } from '@models/analysis/analysis.enum';
import { useAnalysis } from '@hooks/useAnalysis';

interface ISampleMeasurementsProps {
	spectrumFile?: Partial<IUploadedFile>;
	onUploadedFileChange?: (f?: IUploadedFile) => void;
	formik: FormikApiType;
	onAnalysisTypeChange: (value: AnalysisSelectionType) => void;
}

export const SampleMeasurements: FC<ISampleMeasurementsProps> = ({ spectrumFile, onUploadedFileChange, formik, onAnalysisTypeChange }) => {
	const { t } = useTranslation('common');

	const { isAnalysisEditable } = useAnalysis();
	const technologyOptions: Array<string> = Object.values(SupportedTechnology);

	const getSampleExperimentProps: () => { variant: 'h4' | 'h3'; color: string } = () => {
		if (spectrumFile?.content) {
			return {
				variant: 'h4',
				color: 'grey.800',
			};
		}
		return {
			variant: 'h3',
			color: 'primary.main',
		};
	};

	const onMeasurementTypeChange = (val: AnalysisSelectionType) => {
		const analysisSelectionType = TypeUtils.returnValueOfKey<typeof AnalysisSelectionTypeMap, TAnalysisSelectionType>(
			val,
			AnalysisSelectionTypeMap,
		);
		formik?.setFieldValue('measurementType', analysisSelectionType.measurementType).then(() => formik?.validateForm());
		if (val !== AnalysisSelectionType.QUALITATIVE) {
			formik?.setFieldValue('targetType', analysisSelectionType.targetType).then(() => formik?.validateForm());
		}
		onAnalysisTypeChange(val);
	};
	return (
		<GenericCard
			sx={{
				marginTop: cardSpacer,
				borderBottomWidth: spectrumFile?.content ? '0px' : '1.33px',
				borderBottomLeftRadius: spectrumFile?.content ? 0 : 8,
				borderBottomRightRadius: spectrumFile?.content ? 0 : 8,
				width: spectrumFile?.content ? '100%' : '50%',
			}}
		>
			<Stack>
				<AnalysisErrorIndicator />
			</Stack>
			<Stack>
				{spectrumFile?.content && (
					<Typography variant="h3" color="primary.main" sx={{ mb: 3 }}>
						<Tr.Portal path="new-analysis.step1-title" />
					</Typography>
				)}
				<Typography sx={{ mb: 3 }} {...getSampleExperimentProps()}>
					<Tr.Portal path="new-analysis.sample-experimental-spectrum" />
				</Typography>
			</Stack>
			<Grid container columnSpacing={4}>
				<Grid item xs={spectrumFile?.content ? 6 : 12}>
					<Stack data-testid="sample-file-uploader">
						{useMemo(
							() => (
								<SampleFileUploader
									uploadedFile={spectrumFile}
									analysisHasFile={!!spectrumFile?.content}
									onUploadedFileChange={onUploadedFileChange}
									title={formik?.values.title}
									isDisabled={!isAnalysisEditable}
								/>
							),
							[spectrumFile],
						)}
					</Stack>
				</Grid>
				{spectrumFile?.content ? (
					<Grid item xs={6} data-testid="analysis-type-id">
						<Stack direction="column" spacing={4} marginBottom={3}>
							<FormikAutoComplete
								required
								disabled
								value="NMR"
								formik={formik}
								name="technology"
								variant="outlined"
								title={t('technology')}
								options={technologyOptions}
								typographyProps={{ color: 'inherit' }}
								data-testid="new-analysis-technology-id"
								placeholder={t('technology-placeholder')}
								autocompleteProps={{ disablePortal: true }}
								onSelectionChange={(value) => {
									formik.setFieldValue('technology', value || null);
								}}
							/>
							<AnalysisTypeSelection
								title={<Tr.Portal path="new-analysis.type-of-analysis" />}
								onMeasurementTypeChange={onMeasurementTypeChange}
							/>
						</Stack>
						<SecondApprovalSelection
							dataTestId={formik?.values.secondApproval ? 'two-step-approval' : 'one-step-approval'}
							secondApprovalStatus={formik?.values.secondApproval}
							onSecondApprovalStatusChange={(secondApprovalStatus: boolean) =>
								formik?.setFieldValue('secondApproval', secondApprovalStatus)
							}
							sx={{ alignItems: 'flex-start' }}
							disabled={!isAnalysisEditable}
						/>
					</Grid>
				) : null}
			</Grid>
		</GenericCard>
	);
};
