import { FC, ReactNode, useState, useEffect } from 'react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useService } from '@hooks/useService';
import { of } from 'rxjs';
import { organizationService } from '@services';
import { useTranslation } from 'react-i18next';
import { Tr } from '@utils/Translation';
import { EditOrganizationLayout } from '@components/admin/EditOrganizationLayout';
import { alertService } from '@services';
import { EditSubscriptionLayout } from '@components/admin/EditSubscriptionLayout';
import { innerTitleSpacer } from '@utils/Theme';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common.slice';
import { OrganizationLabs } from '@components/common/OrganizationLabs';
import { TabDivider } from '@components/common/TabDivider';
import { LabPermission, OrganizationPermission } from '@models/user';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { FormikApiType } from '@components/common';
import { OrganizationSaveFields } from '@components/admin/Organization';
import { PageRoutes } from '@models/router';
import { AdminEditOrganizationTabs } from '@models/organization/edit-organization-tabs.enum';

export const EditOrganization: FC = () => {
	const { t: tPortal } = useTranslation('portal');

	// location hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loc = useLocation();
	const { id = '', name = AdminEditOrganizationTabs.OVERVIEW } = useParams<{ id: string; name: AdminEditOrganizationTabs }>();

	const { data: editingOrganization, trigger: refreshOrganizationData } = useService(() => {
		if (+id) return organizationService.get(+id);
		return of();
	});
	const canSeeOrganizationDetails = useHasPermissions(OrganizationPermission.VIEW_DETAILS);
	const canSeeSubscriptionDetails = useHasPermissions(OrganizationPermission.VIEW_SUBSCRIPTION_DETAILS);
	const canSeeLabs = useHasPermissions(LabPermission.LIST);

	const [currentTab, setCurrentTab] = useState<AdminEditOrganizationTabs>(AdminEditOrganizationTabs.OVERVIEW);
	const [dirty, setDirty] = useState(false);
	const [formik, setFormik] = useState<FormikApiType>();

	useEffect(() => {
		setCurrentTab(name); // TO DO: TEST IF IT RERENDERS
		if (editingOrganization?.name) {
			dispatch(setBreadcrumb({ name: editingOrganization?.name, tab: tPortal(`dashboard.${name}`), ':id': editingOrganization.id }));
		}
		return () => {
			dispatch(setBreadcrumb(null));
		};
	}, [loc, editingOrganization?.name, tPortal]);

	if (!editingOrganization) {
		return null;
	}

	const contentMap: Record<AdminEditOrganizationTabs, ReactNode> = {
		overview: (
			<EditOrganizationLayout
				organizationData={editingOrganization}
				setDirty={(value) => setDirty(value)}
				parentFormikState={formik}
				setFormik={(value) => setFormik(value)}
				refreshOrgData={() => refreshOrganizationData()}
			/>
		),
		labs: <OrganizationLabs organizationData={editingOrganization} />,
		subscription: <EditSubscriptionLayout organizationData={editingOrganization} />,
	};

	return (
		<Stack data-testid="edit-organization-id">
			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2 }}>
				<Typography variant="h1" sx={{ color: 'text.secondary' }}>
					{editingOrganization?.name}
				</Typography>

				{formik && currentTab === AdminEditOrganizationTabs.OVERVIEW && (
					<form onSubmit={formik?.handleSubmit}>
						<OrganizationSaveFields formik={formik} editing={true} />
					</form>
				)}
			</Stack>
			<Tabs
				sx={{ marginBottom: innerTitleSpacer }}
				textColor="primary"
				value={currentTab}
				onChange={(_, newValue) => {
					if (!dirty || currentTab !== AdminEditOrganizationTabs.OVERVIEW) {
						setDirty(false);
						navigate(`../${PageRoutes.CUSTOMER_MANAGEMENT}/${id}/${newValue}`);
					} else {
						alertService.send({
							titleText: <Tr.Admin path="organization.warning" />,
							onConfirm: () => {
								setDirty(false);
								navigate(`../${PageRoutes.CUSTOMER_MANAGEMENT}/${id}/${newValue}`);
							},
							content: <Tr.Admin path="organization.cancel-message" />,
							confirmTextComponent: <Tr.Admin data-testid="alert-confirm-id" path="organization.confirm" />,
							closeTextComponent: <Tr.Admin path="organization.cancel" />,
						});
					}
				}}
			>
				{canSeeOrganizationDetails && (
					<Tab
						value={AdminEditOrganizationTabs.OVERVIEW}
						label={tPortal(`dashboard.${AdminEditOrganizationTabs.OVERVIEW}`)}
						sx={{
							color: currentTab === AdminEditOrganizationTabs.OVERVIEW ? 'primary.main' : 'grey.800',
						}}
					/>
				)}

				{canSeeSubscriptionDetails && (
					<Tab
						value={AdminEditOrganizationTabs.SUBSCRIPTION}
						label={tPortal(`dashboard.${AdminEditOrganizationTabs.SUBSCRIPTION}`)}
						data-testid="subscription-tab-id"
						sx={{
							color: currentTab === AdminEditOrganizationTabs.SUBSCRIPTION ? 'primary.main' : 'grey.800',
						}}
					/>
				)}

				{canSeeLabs && (
					<Tab
						value={AdminEditOrganizationTabs.LABS}
						label={tPortal(`dashboard.${AdminEditOrganizationTabs.LABS}`)}
						data-testid="lab-tab-id"
						sx={{
							color: currentTab === AdminEditOrganizationTabs.LABS ? 'primary.main' : 'grey.800',
						}}
					/>
				)}

				<TabDivider />
			</Tabs>
			{contentMap[`${currentTab}`]}
		</Stack>
	);
};
