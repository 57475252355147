import { FC, ReactNode, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Stack } from '@mui/material';

import { userSelector } from '@store/slices/common.slice';
import { TypeUtils, Tr, sendImage } from '@utils';
import { UserRole, UserStoreModel } from '@models';
import { AnalysisReport } from '@components/portal';
import { AnalysisResultContext } from './analysis-result-context';
import { AnalysisConfirmation } from './AnalysisConfirmation';

interface IConfirmResultProps {
	confirmMessage: ReactNode;
	disabled?: boolean;
}

const ConfirmResult: FC<IConfirmResultProps> = ({ disabled, confirmMessage }) => {
	const [confirmVisible, setConfirmationVisibility] = useState(false);
	const [rejectVisible, setRejectionVisibility] = useState(false);

	return (
		<Stack data-testid="confirm-result-id" direction="row" columnGap={1.5}>
			<AnalysisConfirmation
				confirmVisible={confirmVisible}
				rejectVisible={rejectVisible}
				setConfirmationVisibility={setConfirmationVisibility}
				setRejectionVisibility={setRejectionVisibility}
			/>
			<Button
				data-testid="reject-id"
				variant="outlined"
				disableElevation
				disabled={disabled}
				sx={{ width: 147, marginLeft: 'auto', padding: '10px 12px' }}
				onClick={() => setRejectionVisibility(true)}
			>
				<Tr.Portal path="analysis-result.reject-analysis" />
			</Button>

			<Button
				data-testid="confirm-id"
				variant="contained"
				disabled={disabled}
				disableElevation
				sx={{ width: 147, padding: '10px 12px' }}
				onClick={() => setConfirmationVisibility(true)}
			>
				{confirmMessage}
			</Button>
		</Stack>
	);
};

export const UpdateButtons: FC = () => {
	const { analysisResultData } = useContext(AnalysisResultContext);
	const loc = useLocation();
	const isSecondApprovalEnabled = analysisResultData?.nmrAnalysis?.secondApproval;
	const user = TypeUtils.transform(UserStoreModel, useSelector(userSelector));
	const [pdfUrl, setPdfUrl] = useState<string | null>(null);
	const [openCreateReport, setOpenCreateReport] = useState(loc.search.includes('openReport=true'));

	useEffect(() => {
		if (openCreateReport && analysisResultData?.analysisId) {
			sendImage(analysisResultData, (urlPdf) => {
				setPdfUrl(urlPdf);
			});
		}
	}, [openCreateReport, analysisResultData]);

	if (!analysisResultData) return null;

	if (isSecondApprovalEnabled ? analysisResultData.isApprovedByLabAdmin() : analysisResultData.isConfirmedByLabMember()) {
		return (
			<>
				<Button
					data-testid="create-report-button-id"
					variant="contained"
					onClick={() => setOpenCreateReport(true)}
					disableElevation
					sx={{ width: 124, marginLeft: 'auto', display: 'flex' }}
				>
					<Tr.Portal path="analysis-result.create-report" />
				</Button>
				{pdfUrl && (
					<AnalysisReport
						data-testid="analysis-report-id"
						open={openCreateReport}
						onClose={() => setOpenCreateReport(false)}
						resultData={analysisResultData}
						pdfUrl={pdfUrl}
					/>
				)}
			</>
		);
	}

	if (user?.roles.length === 1 && user?.roles.includes(UserRole.LAB_MEMBER) && analysisResultData.isExecuted()) {
		return <ConfirmResult confirmMessage={<Tr.Portal path="analysis-result.approve-analysis" />} />;
	}

	// TODO:Refactor here
	if (
		!analysisResultData.isRejected() &&
		!analysisResultData.isError() &&
		user?.roles.includes(UserRole.LAB_ADMIN) &&
		(analysisResultData.isExecutedSuccessful() || analysisResultData.isConfirmedByLabMember())
	)
		return (
			<ConfirmResult
				disabled={!analysisResultData.isConfirmedByLabMember() && analysisResultData?.nmrAnalysis?.operatorId !== user.username}
				confirmMessage={<Tr.Portal path="analysis-result.approve-analysis" />}
			/>
		);

	return null;
};
