import { NmrDrmOrderRejectionReason, NmrDrmOrderRejectionSeverity } from '@services/nmr-drm-pipeline';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getRejectConfirmationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		rejectionReasons: yup
			.array()
			.of(yup.string().oneOf(Object.values(NmrDrmOrderRejectionReason), t('validation.select-value', { key: t('reason') })))
			.min(1)
			.required()
			.ensure(),
		severity: yup
			.string()
			.max(250)
			.nullable()
			.oneOf(Object.values(NmrDrmOrderRejectionSeverity), t('validation.select-value', { key: t('severity') }))
			.required(t('validation.required', { key: t('severity') })),
		details: yup
			.string()
			.max(250)
			.nullable()
			.required(t('validation.required', { key: t('details') })),
	});
