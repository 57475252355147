import { FC, ReactElement, useEffect, useState } from 'react';
import { FilterKey, FilterType, FilterValueType, IFilter } from '@models';
import { Box, Button, IconButton, Popover, PopoverProps, SxProps, Typography } from '@mui/material';
import { GenericCard } from '../GenericCard';
import { SelectDropDown } from './SelectDropdown';
import { innerTitleSpacer } from '@utils/Theme';
import { DataTestId, Tr } from '@utils';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { FormikDateRangePicker } from '../FormikDateRangePicker';
import dayjs from 'dayjs';
import { SearchableDropdown } from './SearchableDropdown';
import { RangePickerProps } from 'antd/es/date-picker';
import React from 'react';

interface ITableFilter {
	filterData: IFilter[];
	anchor: HTMLElement;
	open: boolean;
	setOpen: () => void;
	removeFromChip?: { key: string; value: string };
	setCurrentFilterValues?: (i: FilterValueType | undefined) => void;
	cardSx?: SxProps;
	popoverProps?: Omit<PopoverProps, 'open'>;
}

export const TableFilter: FC<ITableFilter> = ({ ...props }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	return <Filter searchParams={searchParams} setSearchParams={setSearchParams} {...props} />;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
	// Can not select days after today
	return current && current > dayjs();
};

interface IFilterOptions {
	searchParams: URLSearchParams;
	setSearchParams: (searchParams: URLSearchParams) => void;
}

export const Filter: FC<ITableFilter & IFilterOptions> = ({
	anchor,
	open,
	setOpen,
	filterData,
	removeFromChip,
	setCurrentFilterValues,
	cardSx,
	popoverProps,
	searchParams,
	setSearchParams,
}) => {
	const [values, setValues] = useState<FilterValueType>();
	const [previousValues, setPreviousValues] = useState<FilterValueType>();
	const { t } = useTranslation('filter');

	useEffect(() => {
		setCurrentFilterValues?.(values);
	}, [values]);
	useEffect(() => {
		let filterValue: FilterValueType = {};
		Object.values(FilterKey).forEach((key) => {
			if (key === 'dateRange') {
				filterValue = {
					...filterValue,
					dateRange: searchParams
						.get('dateRange')
						?.split(',')
						.map((d) => (d ? new Date(d) : null)),
					from: searchParams.get('from'),
					to: searchParams.get('to'),
				};
			} else {
				filterValue = { ...filterValue, [key]: searchParams.getAll(key) };
			}
		});

		setValues(filterValue);
	}, [searchParams]);

	const newStateMap = {
		clearAll: () => undefined,
		dateRange: () => {
			return { ...values, dateRange: undefined };
		},
		other: (key: string, value: string) => {
			return {
				...values,
				[key]: (values?.[`${key}`] as unknown[])?.filter((item) => item !== value),
			};
		},
	};

	useEffect(() => {
		if (removeFromChip) {
			const { key, value } = removeFromChip;
			setValues(() => {
				const newValues = newStateMap[`${key}`] ? newStateMap[`${key}`]() : newStateMap.other(key, value);

				searchClick(newValues);
				return newValues;
			});
		}
	}, [removeFromChip]);

	useEffect(() => {
		if (open) {
			setPreviousValues(values);
		}
	}, [open]);

	const searchClick = (newValues?: FilterValueType) => {
		const dateRange = newValues?.['dateRange'] as Date[];
		setPreviousValues(newValues);
		// 'yyyy-MM-dd'
		const toSendData = {
			...newValues,
			from: dateRange?.[0] ? format(dateRange?.[0], 'yyyy-MM-dd') + 'T00:00:00Z' : null,
			to: dateRange?.[1] ? format(dateRange?.[1], 'yyyy-MM-dd') + 'T23:59:59Z' : null,
		};

		const flatData = Object.entries(toSendData).reduce((current: unknown[], [key, value]) => {
			const filteringData: { [key: string]: unknown }[] = [];
			if (key === 'dateRange') return [...current, { [key]: value }];
			if (Array.isArray(value)) {
				value.forEach((item) => filteringData.push({ [key]: item }));
			} else if (value) {
				filteringData.push({ [key]: value });
			}
			return [...current, ...filteringData];
		}, []);

		Object.values(FilterKey).forEach((f) => searchParams.delete(f.toString()));
		flatData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] !== undefined && searchParams.append(entry[0], `${entry[1]}`);
		});

		searchParams.set('pageIndex', '1');
		setSearchParams(searchParams);
	};

	const resetClick = () => setValues(undefined);

	const onClose = (): void => {
		setValues(previousValues);
		setOpen();
	};

	const typeComponentMapping: Record<FilterType, (item: IFilter) => ReactElement> = {
		dropdown: (item) => (
			<SelectDropDown
				key={item.key}
				elementTitle={item.title ?? item.key}
				options={item.values.map((i) => ({
					id: i.id,
					value: i.value,
				}))}
				selectedValues={((values?.[item.key] ?? []) as string[]) || []}
				inputProps={{ 'aria-label': 'Without label' }}
				displayEmpty
				renderValue={(renderedValues) => {
					if ((!renderedValues && renderedValues !== 0) || (renderedValues as string[]).length === 0) {
						return (
							<Typography variant="label-m" color="grey.800">
								{t('any')}
							</Typography>
						);
					}
					if (typeof renderedValues === 'string') {
						return item.values
							.filter((renderedItem) => renderedItem.id === renderedValues)
							.map((filteredElement) =>
								item.escapesTranslation ? filteredElement.value : t(`filter.${filteredElement.value.toLowerCase()}`),
							);
					}
					return item.values
						.filter(
							(renderedItem) =>
								((renderedValues ?? []) as string[]).find((selectedIndex) => selectedIndex === renderedItem.id) !==
								undefined,
						)
						.map((filteredElement) =>
							item.escapesTranslation ? filteredElement.value : t(`filter.${filteredElement.value.toLowerCase()}`),
						)
						.join(', ');
				}}
				onChange={(e) => {
					const {
						target: { value },
					} = e;
					setValues({
						...values,
						[item.key]: (value ?? []) as string[],
					});
				}}
				onClear={() => {
					if (item.single) {
						setValues({
							...values,
							[item.key]: [] as string[],
						});
					}
				}}
				escapesTranslation={item.escapesTranslation}
				multiple={!item.single}
			/>
		),
		searchableDropdown: (item) => (
			<SearchableDropdown
				key={item.key}
				options={item.values.map((i) => ({
					id: i.id,
					value: i.value,
				}))}
				onChange={(_, value) => {
					setValues({
						...values,
						[item.key]: (value ?? []) as string[],
					});
				}}
				selectedValues={((values?.[item.key] ?? []) as string[]) || []}
				loading={item.loading}
				disabled={item.disabled}
				elementTitle={item.title ?? item.key}
				filterOption
			/>
		),
		dateRange: (item) => (
			<FormikDateRangePicker
				key={item.key}
				name="dateRange"
				value={
					(values?.[item.key] as Date[])?.length !== 2
						? undefined
						: [dayjs((values?.[item.key] as Date[])[0]), dayjs((values?.[item.key] as Date[])[1])]
				}
				firstTitle={t(`filter.${item.key}`)}
				onChange={(e) => {
					const from = e?.[0]?.toDate();
					const to = e?.[1]?.toDate();

					from?.setHours(0, 0, 1);
					to?.setHours(23, 59, 59);

					setValues({ ...values, [item.key]: [from?.toISOString(), to?.toISOString()] });
				}}
				separator={<Typography paddingX={12 / 16}>{t('to')}</Typography>}
				pickerClassName="antd-custom-picker-transparent"
				required={false}
				disabledDate={disabledDate}
			/>
		),
	};

	return (
		<>
			<Popover
				open={open}
				anchorEl={anchor}
				onClose={onClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				{...popoverProps}
			>
				<GenericCard sx={{ width: anchor?.clientWidth, padding: innerTitleSpacer, ...cardSx }}>
					<IconButton onClick={onClose} sx={{ marginLeft: 'auto', color: 'text.primary' }}>
						<CloseIcon sx={{ width: '1.2rem', height: '1.2rem', color: 'inherit' }} />
					</IconButton>
					{filterData.map((item) => (
						<React.Fragment key={item.key}>{typeComponentMapping[item.type](item)}</React.Fragment>
					))}
					<Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<Button
							sx={{ width: '4.5rem', height: '2.5rem' }}
							size="small"
							onClick={resetClick}
							data-testid={DataTestId.getStaticTestId('reset-filter-button-test-id')}
						>
							<Tr.Filter path="common.reset" />
						</Button>
						<Button
							disableElevation
							sx={{ width: '4.5rem', height: '2.5rem', marginLeft: '0.6rem' }}
							size="small"
							variant="contained"
							onClick={() => {
								searchClick(values);
								setOpen();
							}}
							data-testid={DataTestId.getStaticTestId('search-button-test-id')}
						>
							<Tr.Filter path="common.search" />
						</Button>
					</Box>
				</GenericCard>
			</Popover>
		</>
	);
};
