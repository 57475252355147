import React, { FC, forwardRef, useEffect, useRef } from 'react';
import { IconButton, IconButtonProps, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import Search from '@mui/icons-material/Search';
import Tune from '@mui/icons-material/Tune';
import Cancel from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@hooks';

const MIN_SEARCH_LEN = 1;
type ISearchFieldProps = TextFieldProps & {
	onSearch?: (typed: string) => void;
	onSearchClear: () => void;
	filter?: boolean;
	onFilterClick?: () => void;
};

export const SearchField = forwardRef<HTMLDivElement, ISearchFieldProps>(
	({ placeholder, onSearch, onSearchClear, value, onChange, filter, onFilterClick, ...props }, ref) => {
		const { t } = useTranslation('common');
		const inputRef = useRef<HTMLInputElement>(null);
		const searchEnabled = () =>
			(inputRef.current?.value?.trim().length || 0) >= MIN_SEARCH_LEN || !inputRef.current?.value?.trim().length;

		const { debounce } = useDebounce(500);
		const triggerSearchEvent = () =>
			debounce(() => {
				const currentValue = inputRef.current?.value.trim() as string;
				searchEnabled() && onSearch?.(currentValue);
			});

		const onClear = () => {
			inputRef.current && (inputRef.current.value = '');
			onSearchClear();
		};

		const isEnter = (code: string) => ['enter', 'numpadEnter'].some((item) => item.toUpperCase() === code?.toUpperCase());

		useEffect(() => {
			!value && inputRef.current && (inputRef.current.value = '');
		}, [value]);

		const SearchButton: FC<IconButtonProps> = ({ sx, ...buttonProps }) => (
			<IconButton
				disabled={!searchEnabled() || !value}
				onClick={triggerSearchEvent}
				sx={{ color: 'text.primary', ...sx }}
				data-testid="search-field-search-icon"
				{...buttonProps}
			>
				<Search style={{ width: '20px', height: '20px', color: '#5E5E72' }} />
			</IconButton>
		);

		return (
			<TextField
				ref={ref}
				inputRef={inputRef}
				name="searchField"
				onChange={(e) => (triggerSearchEvent(), onChange?.(e))}
				onKeyDown={(e) => isEnter(e.code) && (e.preventDefault(), e.stopPropagation())}
				placeholder={placeholder || t('search')}
				sx={{
					backgroundColor: 'grey.50',
					maxWidth: 300,
					'.MuiInputBase-input': { padding: 0 },
					...props.sx,
				}}
				value={value}
				inputProps={{
					'data-testid': 'search-field-id',
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{value ? (
								<IconButton onClick={onClear}>
									<Cancel />
								</IconButton>
							) : null}
							{filter && (
								<IconButton sx={{ color: 'text.primary' }} onClick={onFilterClick}>
									<Tune sx={{ color: 'grey.800' }} />
								</IconButton>
							)}
						</InputAdornment>
					),
					startAdornment: (
						<InputAdornment position="start">
							<SearchButton sx={{ padding: 0 }} />
						</InputAdornment>
					),
					sx: {
						input: {
							paddingLeft: 0.5,
							paddingBottom: 1,
						},
					},
				}}
				{...props}
			/>
		);
	},
);
