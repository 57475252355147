import { useEffect, useState } from 'react';
import { FilterValueType } from '@models/filter';
import { SortOrder } from '@models/index';
import { useSearchParams } from 'react-router-dom';
import { getFiltersFromSearchParams } from '@utils/Filter/FilterUtils';

export const setParams = (searchParams: URLSearchParams) => {
	return {
		setQuery: (value?: string) => searchParams.set('query', (value || searchParams?.get('query')) ?? ''),
		setPageIndex: (value?: string) => searchParams.set('pageIndex', (value || searchParams?.get('pageIndex')) ?? '1'),
		setPageSize: (value?: string) => searchParams.set('pageSize', (value || searchParams?.get('pageSize')) ?? '15'),
		setSortBy: (sortBy?: string) => searchParams.set('sortBy', searchParams?.get('sortBy') ?? sortBy ?? ''),
		setSortOrder: (sortOrder?: string) => searchParams.set('sortOrder', searchParams?.get('sortOrder') ?? sortOrder ?? ''),
		setCompleted: (complete: string) => searchParams.set('completed', complete),
		setEntities: (value?: string) => value && searchParams.set('entities', value),
		setEntityId: (value?: string) => searchParams.set('entityId', value as string),
		setMeasurementType: (value?: string) => value && searchParams.set('measurementType', value),
	};
};

export const getParams = (searchParams: URLSearchParams) => {
	return {
		getQuery: () => searchParams.get('query') ?? '',
		getSortBy: () => searchParams.get('sortBy') ?? '',
		getSortOrder: () => searchParams.get('sortOrder') ?? '',
		getPageIndex: () => searchParams.get('pageIndex') ?? '1',
		getPageSize: () => searchParams.get('pageSize') ?? '',
	};
};

export const useFilterSearchParams = (sortBy?: string, sortOrder?: SortOrder) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [filtering, setFiltering] = useState<FilterValueType[]>();
	const { setSortOrder, setSortBy, setCompleted, ...others } = setParams(searchParams);
	useEffect(() => {
		const newFilterValues = getFiltersFromSearchParams(searchParams);

		Object.values(others).forEach((val) => val?.());
		setSortOrder(sortOrder);
		setSortBy(sortBy);
		setCompleted('false');
		setFiltering(newFilterValues);
	}, [searchParams]);

	return {
		searchParams,
		setSearchParams,
		filtering,
		setFiltering,
	};
};
