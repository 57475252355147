import { OverflowText } from '@components/common';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { PageRoutes } from '@models/router';
import { NmrAdminPermission } from '@models/user';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { Stack, Tooltip } from '@mui/material';
import { NmrDrmOrder } from '@services/nmr-drm-pipeline';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type TDrmOrderLink = {
	drmOrder?: NmrDrmOrder;
};

export const DrmOrderLink: FC<TDrmOrderLink> = ({ drmOrder }) => {
	const navigate = useNavigate();
	const hasDrmOrderPermission = useHasPermissions(NmrAdminPermission.VIEW_DRM_PIPELINE);
	const { t } = useTranslation('admin');
	const onDrmOrderLinkClick = () => {
		if (drmOrder && hasDrmOrderPermission) navigate(`/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/${drmOrder.id}`);
	};
	return (
		<Stack direction="row" width="100%" alignItems="center">
			{!hasDrmOrderPermission && (
				<Tooltip title={t('substance-detail.drm-order-no-access')}>
					<LockOutlined sx={{ height: 20, width: 20, color: 'grey.800', mr: 1 }} />
				</Tooltip>
			)}

			<OverflowText
				variant="pg-m"
				sx={{
					lineHeight: '26px',
					wordBreak: 'break-all',
					paddingRight: '0.5rem',
					color: hasDrmOrderPermission ? 'primary.main' : 'text.primary',
					cursor: hasDrmOrderPermission ? 'pointer' : undefined,
				}}
				onClick={onDrmOrderLinkClick}
				enableTooltip
			>
				{drmOrder?.name || '-'}
			</OverflowText>
		</Stack>
	);
};
