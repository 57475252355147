import { FC, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { authService, loadingSpinnerOverlayService, logger, notificationService } from '@services';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableButton } from '@components/portal';
import { FormikTextfield } from '@components/common';
import { amplifyConfig, InputErrorRelativePosition, PoolType, Tr } from '@utils';
import { useTranslation } from 'react-i18next';
import { checkChallenges, UserRole } from '@models/user';
import { PortalPageRoutes } from '@models';
import { Amplify } from 'aws-amplify';
import { PromiseUtils } from '@utils/PromiseUtils';
import { setBreadcrumb, setBannerNotifications, setUser, urlHistorySelector } from '@store/slices/common.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginValidationSchema } from '@schemas/login/login.validation';

type FormFields = Extendable<{
	email: string;
	password: string;
}>;

interface IActionProps {
	user: any;
	password: string;
	email: string;
}

interface ILoginProps {
	onNewPassRequired: (actionProps: IActionProps) => void;
	onMFARequired: (actionProps: IActionProps) => void;
	onMFATypeSelection: (actionProps: IActionProps) => void;
	onPasswordReset: () => void;
}

export const Login: FC<ILoginProps> = ({ onNewPassRequired, onMFARequired, onPasswordReset, onMFATypeSelection }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navigateToUserPage = () => navigate((state as any)?.redirect || '/portal');
	const navigateToAdminPage = () => navigate('/admin');
	const { state } = useLocation();
	const { t, ready } = useTranslation('login');
	const translate = (path: string) => (ready ? t(path) : '');
	const [requestIsOngoing, setRequestIsOngoing] = useState(false);
	const urlHistory = useSelector(urlHistorySelector);

	const formik = useFormik<FormFields>({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: getLoginValidationSchema(t),
		onSubmit: () => {
			requestIsOngoing || sendLoginRequest();
		},
	});

	const sendLoginRequest = () => {
		setRequestIsOngoing(true);
		const email = formik.values.email.toLowerCase().trim();
		const cleanedPassword = formik.values.password.trim();
		amplifyConfig.currentConfig = PoolType.USER_POOL;
		Amplify.configure(amplifyConfig.getCurrentAmplifyConfig());
		PromiseUtils.runPromise(
			() => authService.newLogin(email, cleanedPassword),
			(userInfo) => {
				const challengeName = userInfo.nextStep.signInStep;
				const challenge = checkChallenges(challengeName);
				if (!challengeName || challenge.isDone()) {
					navigate('/portal');
				} else if (challenge.isNewPassRequired()) {
					onNewPassRequired({ user: userInfo, password: cleanedPassword, email: email });
				} else if (challenge.isSmsMfa()) {
					onMFARequired({ user: userInfo, password: cleanedPassword, email: email });
				} else if (challenge.isMFAType()) {
					onMFATypeSelection({ user: userInfo, password: cleanedPassword, email: email });
				}
			},
			(err) => {
				formik.setFieldValue('password', '', false);
				notificationService.sendError(err.message);
			},
			() => setRequestIsOngoing(false),
		);
	};

	useEffect(() => {
		loadingSpinnerOverlayService.reset();
		authService
			.getUser(false)
			.then((user) => (user?.roles.includes(UserRole.MERCK_ADMIN) ? navigateToAdminPage() : navigateToUserPage()))
			.catch(() => {
				// Clear store of user specific data if it remained
				authService.signOut().catch((r) => logger.error(r));
				dispatch(setBreadcrumb(null));
				dispatch(setBannerNotifications([]));
				dispatch(setUser(null));
			});
	}, []);

	// This is for SSO login
	const subscribeAmplify = (poolType: PoolType) => {
		window.localStorage.clear();
		if ((state as any)?.redirect.includes(PortalPageRoutes.ANALYSIS_RESULT)) {
			window.localStorage.setItem('redirectUrl', (state as any).redirect);
		}
		amplifyConfig.currentConfig = poolType;
		Amplify.configure(amplifyConfig.getCurrentAmplifyConfig());
		authService.login();

		navigate(urlHistory[0]);
	};

	return (
		<Stack data-testid="login-screen-id" sx={{ ...InputErrorRelativePosition }}>
			<Typography variant="h1">
				<Tr.Login path="login.welcome" />
			</Typography>
			<Typography variant="pg-m" sx={{ marginBottom: '2.5rem', fontSize: '0.875rem' }}>
				<Tr.Login path="login.login-account" />
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<FormikTextfield
					typographyProps={{ sx: { color: 'grey.800', fontSize: '13px', marginBottom: '5px' } }}
					formikApi={formik}
					name="email"
					title={<Tr.Login path="login.email-address" />}
					placeholder={translate('login.enter-email')}
					inputProps={{ 'data-testid': 'login-email-id' }}
					sx={{ ...InputErrorRelativePosition }}
				/>
				<FormikTextfield
					typographyProps={{ sx: { color: 'grey.800', fontSize: '13px', marginBottom: '5px', marginTop: '1rem' } }}
					formikApi={formik}
					type="password"
					name="password"
					title={<Tr.Login path="login.password" />}
					placeholder={translate('login.enter-password')}
					inputProps={{ 'data-testid': 'login-password-id' }}
					sx={{ ...InputErrorRelativePosition }}
				/>
				<Stack direction="row" justifyContent="space-between" marginTop="0.75rem">
					<Typography
						data-testid="login-sso-id"
						variant="label-xs"
						sx={{ color: 'grey.800', cursor: 'pointer' }}
						onClick={() => subscribeAmplify(PoolType.ADMIN_POOL)}
					>
						<Tr.Login path="login.sso-login" />
					</Typography>
					<Typography
						data-testid="password-reset-button-id"
						variant="label-xs"
						sx={{ fontSize: '12px', color: 'grey.800', cursor: 'pointer' }}
						onClick={() => onPasswordReset()}
					>
						<Tr.Login path="login.forgot-password" />
					</Typography>
				</Stack>
				<TableButton.GotoAnalysis
					data-testid="login-login-button-id"
					type="submit"
					size="small"
					disabled={requestIsOngoing || !formik.isValid || !formik.dirty}
					sx={{ marginLeft: 'auto', marginTop: '2.5rem', display: 'flex', width: '3.75rem', height: '2.5rem' }}
				>
					<Tr.Login path="login.login" />
				</TableButton.GotoAnalysis>
			</form>
		</Stack>
	);
};
