import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IPipeline, ProductionFilterData } from '@models/pipeline';
import { NmrDrmOrderStatusChangeErrors, NmrDrmOrderWave } from '@services/nmr-drm-pipeline';

export const initialPipeline: IPipeline = {
	triggerWaveRefresh: 0,
	waveData: null,
	triggerBacklogRefresh: 0,
	productionFilterData: null,
	transitionErrors: new Array<NmrDrmOrderStatusChangeErrors>(),
};

export const {
	actions: { setWave, setTriggerBacklogRefresh, setTriggerWaveRefresh, setProductionFilterData, setTransitionErrors },
	reducer,
} = createSlice({
	name: 'pipeline',
	initialState: initialPipeline,
	reducers: {
		setWave: (state, data: PayloadAction<Maybe<NmrDrmOrderWave[]>>) => {
			state.waveData = data.payload ? data.payload : null;
			return state;
		},
		setTriggerWaveRefresh: (state) => {
			state.triggerWaveRefresh = state.triggerWaveRefresh + 1;
			return;
		},
		setTriggerBacklogRefresh: (state) => {
			state.triggerBacklogRefresh = state.triggerBacklogRefresh + 1;
			return;
		},
		setProductionFilterData: (state, data: PayloadAction<Maybe<ProductionFilterData>>) => {
			state.productionFilterData = data.payload ? data.payload : null;
			return state;
		},
		setTransitionErrors: (state, data: PayloadAction<Array<NmrDrmOrderStatusChangeErrors>>) => {
			state.transitionErrors = data.payload ? data.payload : [];
			return state;
		},
	},
});

export const triggerWaveRefreshSelector = (state: RootState) => state.Pipeline.triggerWaveRefresh;
export const waveDataSelector = (state: RootState) => state.Pipeline.waveData;
export const triggerBacklogRefreshSelector = (state: RootState) => state.Pipeline.triggerBacklogRefresh;
export const pipelineSelector = (state: RootState) => state.Pipeline;
export const productionFilterDataSelector = (state: RootState) => state.Pipeline.productionFilterData;
export const transitionErrorsSelector = (state: RootState) => state.Pipeline.transitionErrors;
