import { FC, useContext } from 'react';
import { NmrAnalysisResult } from '@services';
import { Box, Stack, Typography } from '@mui/material';
import { Tr } from '@utils';
import { AnalysisResultRunTheoretical } from '../AnalysisResultRunTheoretical';
import { AnalysisResultContext } from '@routes/Portal/AnalysisResult/nmr/analysis-result-context';
import { AnalysisResultTheoreticalSearchStatus } from '@models/analysis';

export const NoMatchTable: FC<{ analysisResult: NmrAnalysisResult }> = ({ analysisResult }) => {
	const { theoreticalEnabled } = useContext(AnalysisResultContext);
	const hasResultMatches = analysisResult.resultMatches.length > 0;

	const noMatchMaterialTheoretical =
		!analysisResult.resultMatches || analysisResult.resultMatches.length === 0
			? analysisResult.referenceMaterials?.filter((rm) => rm.qualityGrade === 'Theoretical')
			: analysisResult.referenceMaterials?.filter((rm) => {
					return (
						rm.qualityGrade === 'Theoretical' &&
						!analysisResult.resultMatches.some(
							(matchItem) => rm.casNumber === matchItem.casNumber && matchItem.qualityGrade === 'Theoretical',
						)
					);
				});

	const noMatchMaterial =
		!analysisResult.resultMatches || analysisResult.resultMatches.length === 0
			? analysisResult.referenceMaterials?.filter((rm) => rm.qualityGrade !== 'Theoretical')
			: analysisResult.referenceMaterials?.filter((rm) => {
					return (
						rm.qualityGrade !== 'Theoretical' &&
						!analysisResult.resultMatches.some((matchItem) => rm.casNumber === matchItem.casNumber)
					);
				});

	const shouldShowOneLine =
		hasResultMatches &&
		((noMatchMaterial && noMatchMaterial.length > 0) || (noMatchMaterialTheoretical && noMatchMaterialTheoretical.length > 0));

	const getNoMatchWithBorder = () => {
		return (
			<Box
				data-testid="no-match-test-id"
				sx={{
					border: '1px solid',
					borderColor: 'grey.200',
					padding: '2rem',
					borderRadius: '0.5rem',
					width: '100%',
					marginTop: 3,
					direction: 'row',
				}}
			>
				{!(analysisResult.isQualitative() && !analysisResult.isTargeted()) && (
					<Typography variant="subtitle2" color="error.100" marginBottom="1rem">
						<Tr.Portal path="analysis-result-table.no-match-found" />
					</Typography>
				)}

				{!analysisResult.isQualitative() && noMatchMaterial?.length
					? noMatchMaterial.map((material, index) => (
							<Typography variant="pg-m" key={`index-${index}-material-not-matched-${material.id}`} marginTop="1rem">
								<strong>{material.name} </strong>
								{`(CAS No: ${material.casNumber})  `}
								<Tr.Portal path="analysis-result-table.has-below" />
							</Typography>
						))
					: null}

				{!analysisResult.isQualitative() && noMatchMaterialTheoretical?.length
					? noMatchMaterialTheoretical.map((material, index) => (
							<Typography key={`index-${index}-material-not-matched-${material.id}`} variant="pg-m" marginTop="1rem">
								<strong>{material.substanceName} (Theoretical) </strong>
								{`(CAS No: ${material.casNumber})  `}
								<Tr.Portal path="analysis-result-table.has-below" />
							</Typography>
						))
					: null}

				{analysisResult.isQualitative() &&
				analysisResult.isTargeted() &&
				(noMatchMaterial?.length > 0 || noMatchMaterialTheoretical?.length > 0) ? (
					<Stack marginTop="1rem" direction="row" flexWrap="wrap" data-testid="no-match-table">
						{noMatchMaterial?.map((material, index) => (
							<Typography
								key={`noMatchMaterial-i${index}-${material.id}`}
								variant="pg-m"
								lineHeight="160%"
								sx={{ marginLeft: 0.5, color: 'grey.800' }}
							>
								<strong>{material.name} </strong>
								{`(CAS No: ${material.casNumber})${
									noMatchMaterial.length - 1 !== index ||
									(noMatchMaterialTheoretical && noMatchMaterialTheoretical.length > 0)
										? '; '
										: ''
								}  `}
							</Typography>
						))}

						{noMatchMaterialTheoretical?.map((material, index) => (
							<Typography
								key={`noMatchMaterial-i${index}-${material.substanceId}`}
								variant="pg-m"
								sx={{ marginLeft: 0.5, color: 'grey.800' }}
							>
								<strong>{material.substanceName} (Theoretical) </strong>
								{`(CAS No: ${material.casNumber})${noMatchMaterialTheoretical.length - 1 !== index ? '; ' : ''}  `}
							</Typography>
						))}

						<Typography variant="pg-m" sx={{ marginLeft: 0.5, color: 'grey.800' }}>
							{(noMatchMaterial?.length ?? 0) + (noMatchMaterialTheoretical?.length ?? 0) > 1 ? (
								<Tr.Portal path="analysis-result-table.have-below" />
							) : (
								<Tr.Portal path="analysis-result-table.has-below" />
							)}
						</Typography>
					</Stack>
				) : null}

				{analysisResult.isQualitative() && !analysisResult.isTargeted() ? (
					<Stack direction="column">
						<Stack direction="column">
							<Typography variant="subtitle2" color="error.100" marginBottom={1} fontSize={16}>
								<Tr.Portal path="analysis-result-table.no-match-found" />
							</Typography>

							<Typography variant="pg-m">
								{theoreticalEnabled &&
								analysisResult.theoreticalSearchStatus === AnalysisResultTheoreticalSearchStatus.EXECUTED ? (
									<Tr.Portal path="theoretical-spectrum-run.below-50-theoretical" />
								) : (
									<Tr.Portal path="analysis-result-table.non-targeted-below-50-part-1" />
								)}
							</Typography>
						</Stack>

						{analysisResult.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.EXECUTED &&
						analysisResult.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.NON_EXECUTABLE ? (
							<AnalysisResultRunTheoretical hideText />
						) : null}
					</Stack>
				) : null}
			</Box>
		);
	};

	const renderNoMatches = () => {
		return (
			<>
				{noMatchMaterial?.map((item, index) => (
					<Typography variant="pg-m" sx={{ marginLeft: 0.5, color: 'grey.800' }} key={`noMatchMaterial-key-${index}`}>
						<strong>{item.name}</strong>{' '}
						{`(CAS No: ${item.casNumber}) ${
							noMatchMaterial.length - 1 !== index || (noMatchMaterialTheoretical && noMatchMaterialTheoretical.length > 0)
								? ';'
								: '.'
						}`}
					</Typography>
				))}

				{noMatchMaterialTheoretical?.map((item, index) => (
					<Typography
						variant="pg-m"
						fontWeight={500}
						sx={{ marginLeft: 0.5, color: 'grey.800' }}
						key={`noMatchMaterialTheoretical-key-${index}`}
					>
						<strong>{item.substanceName} (Theoretical)</strong>{' '}
						{`(CAS No: ${item.casNumber}) ${noMatchMaterialTheoretical.length - 1 !== index ? ';' : '.'}`}
					</Typography>
				))}
			</>
		);
	};

	const getNoMatchOneLine = () => {
		return (
			<Stack direction="row">
				<Typography variant="pg-m" sx={{ color: 'error.main' }}>
					<Tr.Portal path="analysis-result-table.no-match" />:
				</Typography>
				{renderNoMatches()}
			</Stack>
		);
	};

	if (shouldShowOneLine) {
		return getNoMatchOneLine();
	} else if (!hasResultMatches) {
		return getNoMatchWithBorder();
	} else {
		return null;
	}
};
