import { FC, ReactNode } from 'react';
import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';
import { Tr } from '@utils/Translation';
import { OverflowText } from '@components/common';

type MappingType = 'QualitativeTargeted' | 'QualitativeNonTargeted' | 'QuantitativeTargeted' | 'QuantitativeNonTargeted';

const typeTitleMapping: Record<MappingType, ReactNode> = {
	QualitativeNonTargeted: <Tr.Portal path="analysis-result.identification" />,
	QualitativeTargeted: <Tr.Portal path="analysis-result.verification" />,
	QuantitativeTargeted: <Tr.Portal path="analysis-result.quantitative-targeted" />,
	QuantitativeNonTargeted: <Tr.Portal path="analysis-result.quantitative-non-targeted" />,
};

export const AnalysisTypeIndicator: FC<
	{ measurementType: QualitativeOrQuantitative; targetType: AnalysisTargetType | string } & TypographyProps
> = ({ measurementType, targetType, sx, ...props }) =>
	measurementType ? (
		<Tooltip
			PopperProps={{ sx: { zIndex: 1000000 } }}
			title={
				<>
					<Tr.Portal path={measurementType?.toLowerCase()} />, <Tr.Portal path={targetType?.toLowerCase?.()} />
				</>
			}
		>
			<Stack>
				<OverflowText
					containerProps={{ display: 'inline-flex', flexShrink: 0, width: 'auto', marginTop: 'auto' }}
					enableTooltip={false}
					sx={{ width: 'fit-content', ...sx }}
					{...props}
				>
					{targetType ? typeTitleMapping[`${measurementType}${targetType}`] : ''}
				</OverflowText>
			</Stack>
		</Tooltip>
	) : (
		<Typography sx={sx} {...props}>
			-
		</Typography>
	);
