import { AddWaveModal } from '@components/admin/AddWaveModal';
import { FormikApiType } from '@components/common';
import { SingleSelectMenu, SingleSelectMenuAddButton, SingleSelectMenuOnSelectType } from '@components/common/SingleSelectMenu';
import { waveToSelectItem } from '@models/nmr-drm-order';
import { NmrDrmOrderWave } from '@services/nmr-drm-pipeline';
import { Tr } from '@utils/Translation';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SELECT_ADD_WAVE_ID = 'ADD';

const ADD_SELECT_VALUE = {
	id: SELECT_ADD_WAVE_ID,
	label: <SingleSelectMenuAddButton content={<Tr.Admin path="drm-pipeline.nmr.backlog.add-wave" />} />,
};

type TBulkUploadSelectWave = {
	waveData?: Maybe<NmrDrmOrderWave[]>;
	disabled: boolean;
	formik: FormikApiType;
};

export const BulkUploadSelectWave: FC<TBulkUploadSelectWave> = ({ waveData, disabled, formik }) => {
	const { t } = useTranslation('admin');
	const items = waveData?.filter((w) => !w.isClosed).map((w) => waveToSelectItem(w)) ?? [];
	const itemsWithAdd = [...items, ADD_SELECT_VALUE];
	const [isAddWaveOpened, setIsAddWaveOpened] = useState<boolean>(false);
	const [savedWaveName, setSavedWaveName] = useState<string>();

	useEffect(() => {
		const selectedWave = waveData?.find((w) => w.name === savedWaveName);
		if (savedWaveName && selectedWave) {
			formik.setFieldValue('wave', waveToSelectItem(selectedWave).id);
			setSavedWaveName(undefined);
		}
	}, [waveData]);
	const onSelectedItemChange: SingleSelectMenuOnSelectType = (item) => {
		const selectedItem = item as string;
		if (selectedItem === ADD_SELECT_VALUE.id) {
			setIsAddWaveOpened(true);
		} else {
			formik.setFieldValue('wave', item);
		}
	};

	const onAddWaveClosed = (name?: string) => {
		if (name) {
			setSavedWaveName(name);
		}
		setIsAddWaveOpened(false);
	};
	return (
		<>
			<SingleSelectMenu
				items={itemsWithAdd}
				selectedItem={{
					label: formik.values.wave,
					id: formik.values.wave,
				}}
				defaultLabel={t('drm-pipeline.nmr.backlog.select-wave')}
				sx={{ minHeight: 40, maxHeight: 40 }}
				onSelectedItemChange={onSelectedItemChange}
				disabled={disabled}
			/>
			{isAddWaveOpened && <AddWaveModal onClose={onAddWaveClosed} />}
		</>
	);
};
