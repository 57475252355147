import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogProps, Divider, Stack, StackProps, Typography } from '@mui/material';
import { ConfirmDialogWithInfo, FormikAutoComplete, FormikTextfield, FullScreenModalDialog, GenericCard } from '@components/common';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { SolventList } from '../SolventList';
import {
	alertService,
	notificationService,
	NmrDrmDetail,
	ErrorCode,
	userService,
	organizationService,
	OrganizationManagementModel,
	nmrDrmRequestService,
	irDrmRequestService,
	RequestNmrDrmModel,
	RequestIrDrmModel,
} from '@services';
import { RxUtils } from '@utils/Rx';
import { OrganizationList, UserList } from '@components/admin';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { flagsSelector, userSelector } from '@store/slices/common.slice';
import { substanceService } from '@services/core/substance';
import { getDrmRequestSchema } from '@schemas';
import {
	IrAdminPermission,
	NmrAdminPermission,
	TechnologyPermissionKeys,
	UserResponseModel,
	UserStatus,
	UserStoreModel,
} from '@models/user';
import { getFlaggedTechnology, getTechnologiesByPermission } from '@utils';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { getSelectedTechnology } from '@components/common/DynamicTechnologyHandler';

type IRequestErmFormFields = Extendable<RequestNmrDrmModel | RequestIrDrmModel>;

interface IRequestErmProps extends DialogProps {
	onCancel: () => void;
	refreshOnSuccess?: boolean;
	merckAdmin?: boolean;
	details?: NmrDrmDetail;
}

const fullWidth: StackProps = { width: 1 };

const CREATE_DRM_REQUEST_PERMISSIONS: string[] = [NmrAdminPermission.CREATE_DRM_REQUEST, IrAdminPermission.CREATE_DRM_REQUEST];

const getPreselectedTechnology = (user: Maybe<UserStoreModel>, isMerckAdmin?: boolean, pathTechnology?: string) => {
	if (!user) {
		return '';
	}
	if (!isMerckAdmin) {
		return user.laboratories?.filter((lab) => lab.id?.toString() === user.currentLabId)[0].technology;
	}

	if (pathTechnology) {
		return pathTechnology;
	}

	const existingPermissions = user.permissions?.filter((userPermission) => CREATE_DRM_REQUEST_PERMISSIONS.includes(userPermission));

	if (!existingPermissions || existingPermissions.length > 1) {
		return '';
	}

	if (existingPermissions[0] === NmrAdminPermission.CREATE_DRM_REQUEST) {
		return SupportedTechnology.NMR;
	}
	return SupportedTechnology.IR;
};

export const RequestErm: FC<IRequestErmProps> = ({ onCancel, refreshOnSuccess = true, merckAdmin, details, ...props }) => {
	const { t } = useTranslation('portal');
	const user = useSelector(userSelector);
	const flags = useSelector(flagsSelector);
	const loc = useLocation();
	const navigate = useNavigate();
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
	const [selectedOrgId, setSelectedOrgId] = useState<number>();

	const handleErmRequest = (fields: IRequestErmFormFields, isSkipPubchem?: boolean) => {
		const requestErmModel: RequestNmrDrmModel | RequestIrDrmModel = {
			chemicalName: fields.chemicalName,
			casNumber: fields.casNumber?.replace(/[^a-zA-Z0-9-]/g, ''),
			emailAddress: fields.emailAddress,
			physicalProductNumber: fields.physicalProductNumber,
			preferredSolvent: fields.preferredSolvent,
			preferredApplication: fields.preferredApplication,
			referenceMethod: fields.referenceMethod,
			usualConcentration: fields.usualConcentration,
			comment: fields.comment,
			technology: fields.technology,
			organization: fields.organization,
			skipPubChemValidation: isSkipPubchem ?? false,
		};

		if (fields.technology === SupportedTechnology.NMR) {
			RxUtils.promisify(nmrDrmRequestService.requestErm(requestErmModel), () => {
				analyticsErmEvent.ErmRequested(formik.values.casNumber);
				notificationService.sendSuccess(t('erm-request.successfully-added'));
				closeForm();
				refreshOnSuccess && navigate(0);
			});
		} else {
			RxUtils.promisify(irDrmRequestService.requestErm(requestErmModel), () => {
				analyticsErmEvent.ErmRequested(formik.values.casNumber);
				notificationService.sendSuccess(t('erm-request.successfully-added'));
				closeForm();
				refreshOnSuccess && navigate(0);
			});
		}
	};

	const [organizationList, setOrganizationList] = useState<OrganizationManagementModel[]>();
	const [userList, setUserList] = useState<UserResponseModel[]>();
	useEffect(() => {
		if (merckAdmin) {
			RxUtils.promisify(organizationService.getAll({ PageIndex: 1, PageSize: 0, Status: 0 }), (orgData) =>
				setOrganizationList(orgData.data),
			);
		}
	}, []);
	useEffect(() => {
		if (selectedOrgId && merckAdmin) {
			RxUtils.promisify(
				userService.getAll({
					PageIndex: 1,
					PageSize: 0,
					Status: UserStatus.ACTIVE,
					OrganizationId: selectedOrgId,
				}),
				(userData) => setUserList(userData.data),
			);
		}
	}, [selectedOrgId]);
	const formik = useFormik<IRequestErmFormFields>({
		initialValues: {
			chemicalName: '',
			casNumber: '',
			email: '',
			merckPhysicalProductNumber: '',
			generalComment: '',
			preferredSolvent: '',
			preferredApplication: '',
			referenceMethodUsed: '',
			usualConcentration: '',
			technology: getPreselectedTechnology(user, merckAdmin, getSelectedTechnology(loc)),
			organization: '',
		},
		validationSchema: getDrmRequestSchema(t, merckAdmin ?? false, userList),
		onSubmit: (fields: IRequestErmFormFields) => {
			if (formik.isValid) {
				//search pubchem -> show confirm dialog only if you get not found
				RxUtils.promisify(
					substanceService.searchSubstance(formik.values.casNumber as string),
					(res) => {
						if (res) {
							setIsConfirmDialogOpen(false);
							handleErmRequest(fields);
						}
					},
					(error) => {
						const code = error.ErrorCode?.toString();
						if (code === ErrorCode.SubstanceMissingFromPubchemExceptionCode?.toString()) {
							setIsConfirmDialogOpen(true);
						} else if (code === ErrorCode.SubstanceAlreadyExistsExceptionCode?.toString()) {
							setIsConfirmDialogOpen(false);
							handleErmRequest(fields);
						} else {
							setIsConfirmDialogOpen(false);
							notificationService.sendError(t('erm-request.error-message'));
						}
					},
				);
			}
		},
	});

	useEffect(() => {
		if (details) {
			formik.setFieldValue('chemicalName', details.name);
			formik.setFieldValue('casNumber', details.casNumber);
			formik.setFieldValue('email', user?.email);

			const timeout = setTimeout(() => {
				//TODO check why it is not working without set timeout
				formik.validateForm();
			}, 10);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [details]);

	const closeForm = () => {
		formik.resetForm();
		onCancel();
	};

	const technologies = merckAdmin
		? getTechnologiesByPermission(TechnologyPermissionKeys.CREATE_DRM_REQUEST, flags, user?.permissions)
		: Object.values(SupportedTechnology).filter((tech) => !getFlaggedTechnology(tech, flags));
	return (
		<FullScreenModalDialog onClose={closeForm} {...props}>
			<Stack sx={{ paddingLeft: 4, paddingRight: 4 }}>
				<form onSubmit={formik.handleSubmit}>
					<Stack direction="row" justifyContent="space-between" sx={{ paddingLeft: 4, paddingRight: 4, paddingBottom: 6 }}>
						<Typography variant="h2" sx={{ justifyContent: 'start', alignItems: 'start' }}>
							<Tr.Portal path="erm-request.title" />
						</Typography>
						<Stack direction="row" justifyContent="flex-end" columnGap={3}>
							<Button
								variant="outlined"
								data-testid="request-erm-cancel-button-id"
								disableElevation
								onClick={() => {
									formik.dirty
										? alertService.send({
												titleText: <Tr.Admin path="organization.warning" />,
												onConfirm: () => closeForm(),
												content: <Tr.Admin path="organization.cancel-add-message" />,
												confirmTextComponent: <Tr.Admin path="organization.confirm" />,
												closeTextComponent: <Tr.Admin path="organization.cancel" />,
											})
										: onCancel();
								}}
							>
								<Tr.Portal path="erm-request.cancel" />
							</Button>
							<Button
								data-testid="request-erm-approve-button-id"
								variant="contained"
								type="submit"
								disableElevation
								disabled={!formik.dirty || !formik.isValid}
							>
								<Tr.Portal path="erm-request.send-request" />
								{}
							</Button>
						</Stack>
					</Stack>

					<GenericCard data-testid="request-erm-wrapper-id" sx={{ marginY: 0, overflow: 'auto', border: 'none', paddingTop: 0 }}>
						<Typography data-testid="confirmation-title-id" variant="pg-m" sx={{ color: 'black' }}>
							<Tr.Portal
								path={merckAdmin ? 'erm-request.erm-request-admin-explanation' : 'erm-request.erm-request-explanation'}
							/>
						</Typography>
						<Divider sx={{ marginBottom: 4, marginTop: 4, height: '1px' }} />

						<Stack flex={1} flexDirection="row" gap={4}>
							<Stack width="33%" spacing={'32px'}>
								<Typography
									data-testid="confirmation-title-id"
									variant="h5"
									sx={{
										fontWeight: 700,
										letterSpacing: 0.2,
										lineHeight: '22.4px',
										color: 'black',
									}}
								>
									<Tr.Portal path="erm-request.request-detail" />
								</Typography>
								{merckAdmin && (
									<OrganizationList
										formik={formik}
										organizationList={organizationList}
										onOrganizationChange={({ id }) => {
											formik.setFieldValue('organization', id).then(() => formik.validateForm());
											formik.setFieldValue('email', '');
											setSelectedOrgId(id);
										}}
										name="organization"
										title={t('erm-request.organization')}
										required
										placeholder={t('erm-request.organization-placeholder')}
										variant="outlined"
										typographyProps={{ sx: { marginBottom: 1.25 } }}
									/>
								)}
								{merckAdmin ? (
									<UserList
										formik={formik}
										value={formik.values.emailAddress}
										userList={userList}
										onUserListChange={({ email }) => formik.setFieldValue('emailAddress', email)}
										onBlur={() => formik.validateForm()}
										name="emailAddress"
										title={t('erm-request.email')}
										required
										placeholder={t('erm-request.email-select-placeholder')}
										variant="outlined"
										listDisabled={
											!formik.values.organization || !organizationList?.some((org) => org.id === selectedOrgId)
										}
										sx={{
											pointerEvents: !formik.values.organization ? 'none' : 'initial',
										}}
										typographyProps={{ sx: { marginBottom: 1.25 } }}
									/>
								) : (
									<FormikTextfield
										formikApi={formik}
										name="emailAddress"
										title={t('erm-request.email')}
										required
										placeholder={t('erm-request.email-placeholder')}
										variant="outlined"
										containerProps={fullWidth}
									/>
								)}
								<FormikTextfield
									formikApi={formik}
									name="chemicalName"
									title={t('erm-request.chemical-name')}
									placeholder={t('erm-request.chemical-name-placeholder')}
									variant="outlined"
									required
									containerProps={fullWidth}
								/>
								<FormikTextfield
									formikApi={formik}
									name="casNumber"
									required
									title={t('erm-request.cas-number')}
									placeholder={t('erm-request.cas-number-placeholder')}
									variant="outlined"
									containerProps={fullWidth}
								/>
								<FormikAutoComplete
									formik={formik}
									onSelectionChange={(value) => formik.setFieldValue('technology', value || '')}
									value={formik.values.technology}
									name="technology"
									title={t('erm-request.technology')}
									placeholder={t('erm-request.technology-placeholder')}
									variant="outlined"
									options={technologies}
									select={false}
									autocompleteProps={{
										disablePortal: true,
									}}
									required
								/>
								<FormikTextfield
									formikApi={formik}
									name="physicalProductNumber"
									title={t('erm-request.merck-physical-product-number')}
									placeholder={t('erm-request.merck-physical-product-number-placeholder')}
									variant="outlined"
									containerProps={fullWidth}
								/>
							</Stack>
							<Stack width="33%" spacing={'32px'}>
								<Typography
									data-testid="confirmation-title-id"
									variant="h5"
									sx={{
										fontWeight: 700,
										letterSpacing: 0.2,
										lineHeight: '22.4px',
										color: 'black',
									}}
								>
									<Tr.Portal path="erm-request.additional-detail" />
								</Typography>

								<SolventList
									name="preferredSolvent"
									title={t('erm-request.preferred-solvent')}
									required={false}
									formik={formik}
								/>

								<FormikTextfield
									formikApi={formik}
									name="preferredApplication"
									title={t('erm-request.preferred-application')}
									placeholder={t('erm-request.preferred-application-placeholder')}
									variant="outlined"
									containerProps={fullWidth}
								/>

								<FormikTextfield
									formikApi={formik}
									name="referenceMethod"
									inputProps={{
										'data-testid': 'reference-method-id',
									}}
									title={t('erm-request.reference-method-used')}
									placeholder={t('erm-request.reference-method-used-placeholder')}
									variant="outlined"
									containerProps={fullWidth}
								/>

								<FormikTextfield
									formikApi={formik}
									name="usualConcentration"
									title={t('erm-request.ususal-concentration')}
									placeholder={t('erm-request.ususal-concentration-placeholder')}
									variant="outlined"
									containerProps={fullWidth}
								/>
							</Stack>
							<Stack width="33%">
								<FormikTextfield
									formikApi={formik}
									name="comment"
									title={t('erm-request.comment')}
									placeholder={t('erm-request.comment-placeholder')}
									variant="outlined"
									typographyProps={{ paddingTop: '54px' }}
									sx={{
										'.MuiInputBase-root': {
											padding: '10px 8px 8px 12px!important',
											backgroundColor: 'grey.50',
										},
									}}
									multiline
									rows={5}
									containerProps={fullWidth}
									inputProps={{
										'data-testid': 'subscription-comment-id',
										maxLength: 4000,
										style: {
											padding: 0,
										},
									}}
								/>
							</Stack>
						</Stack>
					</GenericCard>
				</form>
				<ConfirmDialogWithInfo
					visible={isConfirmDialogOpen}
					titleText={'Are you sure to send the dRM Request?'}
					infoText={t('erm-request.erm-request-confirmation-text')}
					onClose={() => {
						setIsConfirmDialogOpen(false);
					}}
					onConfirm={() => {
						handleErmRequest(formik.values, true);
						setIsConfirmDialogOpen(false);
						closeForm();
					}}
					closeButtonText={<Tr.Portal path="cancel" />}
					confirmButtonText={<Tr.Portal path="erm-request.send-request" />}
				/>
			</Stack>
		</FullScreenModalDialog>
	);
};
