import { GenericCard, InfoMessageBox } from '@components/common';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useState } from 'react';
import { OverviewCard } from './OverviewCard/OverviewCard';
import {
	CardValue,
	OverviewCardType,
	TargetStatusActionMapping,
	convertStringArrayToCardValues,
	getSortedStatusArray,
	getUserListForAssignment,
	getWaveItemList,
	userListToCardValue,
} from './OverviewCard/overview-card-helper';
import {
	NmrDrmOrder,
	NmrDrmOrderChangeStatusToWaitingForMeasurementReason,
	NmrDrmOrderDeclineReason,
	NmrDrmOrderStatus,
	NmrDrmOrderStatusChangeErrors,
	NrmDrmOrderPriority,
	nmrDrmOrderService,
	nmrDrmWaveService,
} from '@services/nmr-drm-pipeline';
import { useService } from '@hooks/useService';
import { ErrorCode, NmrDrmDetail, NotificationType, alertService, nmrDrmService, notificationService, userService } from '@services/index';
import { IErrorResponse, PagedResult } from '@models/request-response';
import { useTranslation } from 'react-i18next';
import { TypeUtils } from '@utils/Type';
import { RxUtils } from '@utils/Rx';
import { TFunction } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common.slice';
import { getChangeStatusToWaitingForMeasurementConfirmationSchema, getDeclineConfirmationSchema } from '@schemas/drm';
import { ConfirmationWithReasonAndComment } from '@components/common/ConfirmationWithReasonAndComment';
import { setTransitionErrors } from '@store/slices/pipeline.slice';
import { ExtendedComponent } from 'src/types';
import { Observable } from 'rxjs';

type TOverviewWrapperProps = {
	titlePath: string;
	isDeclined?: boolean;
};

const OverviewWrapper: ExtendedComponent<TOverviewWrapperProps> = ({ titlePath, children, isDeclined }) => {
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));
	return (
		<Stack spacing={1}>
			<Typography variant="h4" color={isDeclined ? 'grey.500' : 'text.primary'}>
				<Tr.Admin path={titlePath} />
			</Typography>
			<Stack direction={media ? 'row' : 'column'} spacing={2}>
				{children}
			</Stack>
		</Stack>
	);
};

type TDrmOrderDetailsOverviewProps = {
	nmrDrmOrder?: NmrDrmOrder;
	successCallback?: () => void;
};

const priorityItems = convertStringArrayToCardValues(Object.values(NrmDrmOrderPriority));

const toValueId = (value: unknown) => {
	const transformedValue: CardValue = { label: '', id: '' };
	const valueId = TypeUtils.transformFromExist(transformedValue, value).id;
	return valueId;
};

const changeStatus = (
	value: unknown,
	nmrDrmOrder?: NmrDrmOrder,
	successCallback?: () => void,
	statusTransitionErrorCallback?: (err: IErrorResponse) => void,
	additionalData?: any,
) => {
	const transformedValue: CardValue = { label: '', id: '' };
	const valueId = TypeUtils.transformFromExist(transformedValue, value).id;
	if (nmrDrmOrder?.id && valueId && valueId !== nmrDrmOrder?.status) {
		RxUtils.promisify(
			nmrDrmOrderService.postTransition(nmrDrmOrder?.id, {
				status: valueId as NmrDrmOrderStatus,
				declineReason: additionalData?.reason ?? null,
				comment: additionalData?.details ?? null,
			}),
			successCallback,
			statusTransitionErrorCallback,
		);
	}
};

const changeStatusWithConfirmation = (
	value: unknown,
	translate: TFunction,
	nmrDrmOrder?: NmrDrmOrder,
	successCallback?: () => void,
	statusTransitionErrorCallback?: (err: IErrorResponse) => void,
	nmrPipelineData?: PagedResult<NmrDrmDetail> | undefined,
) => {
	const transformedValue: CardValue = { label: '', id: '' };
	const valueId = TypeUtils.transformFromExist(transformedValue, value).id;

	const releaseWarningTranslation =
		nmrPipelineData && valueId?.toString() === NmrDrmOrderStatus.RELEASED.toString() && nmrPipelineData?.data.length > 0
			? 'drm-order-details.change-status-same-data-warning'
			: '';

	if (valueId !== nmrDrmOrder?.status) {
		alertService.send({
			titleText: translate('drm-order-details.confirmation'),
			content: (
				<>
					{' '}
					<Typography display="inline-block">{translate('drm-order-details.change-status-explanation')}</Typography>{' '}
					<Typography display="inline-block" fontWeight="700">
						{translate(nmrDrmOrder?.status ?? '')}
					</Typography>{' '}
					<Typography display="inline-block">{translate('drm-order-details.to')}</Typography>{' '}
					<Typography display="inline-block" fontWeight="700">
						{translate(valueId?.toString() ?? '') + '.'}
					</Typography>
					{releaseWarningTranslation && (
						<Stack pt={2}>
							<InfoMessageBox message={translate(releaseWarningTranslation)}></InfoMessageBox>
						</Stack>
					)}
				</>
			),
			closeTextComponent: translate('drm-order-details.cancel'),
			confirmTextComponent: translate('drm-order-details.confirm'),
			confirmTextHeader: translate('drm-order-details.change-status'),
			onConfirm: () => changeStatus(value, nmrDrmOrder, successCallback, statusTransitionErrorCallback),
			onClose: () => null,
		});
	}
};

export enum ConfirmationWithReasonModalStatus {
	DECLINE = 'decline',
	CHANGE_STATUS_FOR_WAITING_FOR_MEASUREMENT = 'changeStatusToWaitingForMeasurement',
	NONE = 'none',
}

const getDeclineReasonOptions = (translate: TFunction) =>
	Object.values(NmrDrmOrderDeclineReason).map((declineReason) => {
		return {
			value: declineReason,
			label: translate(`drm-order-details.decline-drm-order.reason.${declineReason}`),
		};
	});
const getChangeStatusToWaitingForMeasurementReasonOptions = (translate: TFunction) =>
	Object.values(NmrDrmOrderChangeStatusToWaitingForMeasurementReason).map((reason) => {
		return {
			value: reason,
			label: translate(`drm-order-details.change-status-to-waiting-for-measurement-drm-order.reason.${reason}`),
		};
	});

export const DrmOrderDetailsOverview: FC<TDrmOrderDetailsOverviewProps> = ({ nmrDrmOrder, successCallback }) => {
	const { t: translate } = useTranslation('admin');
	const { t: tCommon } = useTranslation('common');
	const isDeclined = nmrDrmOrder?.status === NmrDrmOrderStatus.DECLINED;

	const [confirmationWithReasonState, setConfirmationWithReasonState] = useState<ConfirmationWithReasonModalStatus>(
		ConfirmationWithReasonModalStatus.NONE,
	);

	const dispatch = useDispatch();
	const { data: waveData } = useService(() => {
		return nmrDrmWaveService.fetchAllWaves();
	}, []);

	const { data: merckAdminData } = useService(() => {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.set('pageIndex', '1');
		urlSearchParams.set('isActive', 'true');

		return userService.getAllWithParams(urlSearchParams, '/merck');
	}, []);
	const user = useSelector(userSelector);
	const analystCardValues = userListToCardValue(
		getUserListForAssignment(OverviewCardType.Analyst, merckAdminData?.data, user, nmrDrmOrder),
	);
	const qcInspectorCardValues = userListToCardValue(
		getUserListForAssignment(OverviewCardType.QcInspector, merckAdminData?.data, user, nmrDrmOrder),
	);
	const qaReviewerCardValues = userListToCardValue(
		getUserListForAssignment(OverviewCardType.QaReviewer, merckAdminData?.data, user, nmrDrmOrder),
	);

	const statusTransitionErrorCallback = (errorResponse: IErrorResponse) => {
		const errors = `${errorResponse.ErrorCode}`;
		const toGlobalStore = new Array<NmrDrmOrderStatusChangeErrors>();
		errors.split(', ').forEach((err: string) => {
			if (err === NmrDrmOrderStatusChangeErrors.WAVE_DOES_NOT_EXIST) {
				notificationService.send({
					message: translate('drm-order-details.please-select-a-wave'),
					type: NotificationType.WARNING,
				});
				return;
			}
			if (err === NmrDrmOrderStatusChangeErrors.DATA_DETAILS_MISSING || err === NmrDrmOrderStatusChangeErrors.ORDER_FILES_MISSING) {
				toGlobalStore.push(err);
				return;
			}
			if (err === ErrorCode.NmrReferenceMaterialCreationExceptionCode.toString()) {
				notificationService.sendError(translate('drm-order-details.release-error'));
				return;
			}
			notificationService.sendError(translate('drm-order-details.something-happened-while-transition'));
		});
		dispatch(setTransitionErrors(toGlobalStore));
	};

	const callPostTransition = (value: unknown) => {
		TargetStatusActionMapping(
			() =>
				changeStatusWithConfirmation(
					value,
					translate,
					nmrDrmOrder,
					successCallback,
					statusTransitionErrorCallback,
					identicalNmrDrmData,
				),
			() => changeStatus(value, nmrDrmOrder, successCallback, statusTransitionErrorCallback),
			(type: ConfirmationWithReasonModalStatus) => {
				setConfirmationWithReasonState(type);
			},
		)[nmrDrmOrder?.status ?? NmrDrmOrderStatus.DRAFT][toValueId(value)]();
	};

	const isDeclineSelected = confirmationWithReasonState === ConfirmationWithReasonModalStatus.DECLINE;
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));

	const { data: identicalNmrDrmData } = useService(() => {
		if (nmrDrmOrder?.physicalProductNumber) {
			const urlSearchParams = new URLSearchParams();
			urlSearchParams.set('ProductNumber', nmrDrmOrder.physicalProductNumber);
			urlSearchParams.set('pageIndex', '1');
			urlSearchParams.set('Frequency', Number(nmrDrmOrder.frequency).toFixed(0));
			urlSearchParams.set('Solvent', nmrDrmOrder.solvent);
			urlSearchParams.set('pageSize', '1');
			return nmrDrmService.searchAllWithUrlParams(urlSearchParams);
		}

		return new Observable<PagedResult<NmrDrmDetail>>();
	}, [nmrDrmOrder]);

	const isConfirmationWithReasonOpened = confirmationWithReasonState !== ConfirmationWithReasonModalStatus.NONE;
	return (
		<GenericCard sx={{ height: media ? 'auto' : '100%', width: media ? '100%' : 'auto' }}>
			<Stack spacing={4}>
				<OverviewWrapper titlePath="drm-order-details.overview" isDeclined={isDeclined}>
					<OverviewCard
						cardType={OverviewCardType.Priority}
						nmrDrmOrder={nmrDrmOrder}
						items={priorityItems}
						successCallback={successCallback}
					/>
					<OverviewCard
						cardType={OverviewCardType.Status}
						nmrDrmOrder={nmrDrmOrder}
						successCallback={successCallback}
						errorCallback={statusTransitionErrorCallback}
						items={
							nmrDrmOrder
								? convertStringArrayToCardValues(
										getSortedStatusArray(nmrDrmOrder ? [nmrDrmOrder.status, ...nmrDrmOrder.availableStatuses] : []),
									)
								: []
						}
						customOnSelect={callPostTransition}
					/>
					<OverviewCard
						cardType={OverviewCardType.Wave}
						items={getWaveItemList(waveData, nmrDrmOrder)}
						nmrDrmOrder={nmrDrmOrder}
						successCallback={successCallback}
					/>
				</OverviewWrapper>
				<OverviewWrapper titlePath="drm-order-details.assignments" isDeclined={isDeclined}>
					<OverviewCard
						nmrDrmOrder={nmrDrmOrder}
						cardType={OverviewCardType.Analyst}
						successCallback={successCallback}
						items={analystCardValues}
					/>
					<OverviewCard
						nmrDrmOrder={nmrDrmOrder}
						cardType={OverviewCardType.QcInspector}
						successCallback={successCallback}
						items={qcInspectorCardValues}
					/>
					<OverviewCard
						nmrDrmOrder={nmrDrmOrder}
						cardType={OverviewCardType.QaReviewer}
						successCallback={successCallback}
						items={qaReviewerCardValues}
					/>
				</OverviewWrapper>
			</Stack>
			{isConfirmationWithReasonOpened && (
				<ConfirmationWithReasonAndComment
					requestDetails={isDeclineSelected}
					subTitle={
						<>
							<Stack>
								<Typography variant="h3" lineHeight="120%">
									{translate(
										`drm-order-details.${
											isDeclineSelected ? 'decline-drm-order' : 'change-status-to-waiting-for-measurement-drm-order'
										}.title`,
									)}
								</Typography>
							</Stack>
							<Stack>
								{isDeclineSelected ? (
									<Typography variant="pg-m">{translate(`drm-order-details.decline-drm-order.explain`)}</Typography>
								) : (
									<Typography>
										<Typography variant="pg-m">
											{translate(`drm-order-details.change-status-to-waiting-for-measurement-drm-order.explain`)}
										</Typography>
										<Typography display="inline-block" fontWeight="700">
											{translate(nmrDrmOrder?.status ?? '')}
										</Typography>
										{translate('drm-order-details.to')}

										<Typography display="inline-block" fontWeight="700">
											{translate(NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT) + '.'}
										</Typography>
									</Typography>
								)}
							</Stack>
						</>
					}
					reasonTitle={translate(
						`drm-order-details.${
							isDeclineSelected ? 'decline-drm-order' : 'change-status-to-waiting-for-measurement-drm-order'
						}.reason-title`,
					)}
					detailsTitle={translate('drm-order-details.decline-drm-order.details-title')}
					buttonText={isDeclineSelected ? '' : translate('drm-order-details.confirm')}
					reasonPlaceHolder={translate(
						`drm-order-details.${
							isDeclineSelected ? 'decline-drm-order' : 'change-status-to-waiting-for-measurement-drm-order'
						}.reason-placeholder`,
					)}
					detailsPlaceHolder={translate('drm-order-details.decline-drm-order.details-placeholder')}
					visible={isConfirmationWithReasonOpened}
					reasonOptions={
						isDeclineSelected
							? getDeclineReasonOptions(translate)
							: getChangeStatusToWaitingForMeasurementReasonOptions(translate)
					}
					validationSchema={
						isDeclineSelected
							? getDeclineConfirmationSchema(tCommon)
							: getChangeStatusToWaitingForMeasurementConfirmationSchema(tCommon)
					}
					onConfirm={(reason: string, details: string) =>
						changeStatus(
							{
								id: isDeclineSelected ? NmrDrmOrderStatus.DECLINED : NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT,
							},
							nmrDrmOrder,
							() => (setConfirmationWithReasonState(ConfirmationWithReasonModalStatus.NONE), successCallback?.()),
							statusTransitionErrorCallback,
							{ reason, details },
						)
					}
					onCancel={() => setConfirmationWithReasonState(ConfirmationWithReasonModalStatus.NONE)}
				/>
			)}
		</GenericCard>
	);
};
