import { FC, ReactNode, useState } from 'react';
import { Button, Stack, Typography, TypographyProps } from '@mui/material';
import { Tr } from '@utils';
import { RequestErm } from '@components/portal';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';

const labelStyle: TypographyProps['sx'] = {
	marginRight: 1,
	marginTop: 1,
	whiteSpace: 'nowrap',
};

type TRequestErmListItemProps = {
	title?: string | ReactNode;
};

export const RequestErmListItem: FC<TRequestErmListItemProps> = ({ title }) => {
	const [isRequestErmModalVisible, setIsRequestErmModalVisible] = useState(false);

	return (
		<Stack data-testid="request-erm-wrapper-id" id="portal-layout" direction="column" marginTop={5} marginBottom={8}>
			<Stack direction="column" sx={{ marginRight: 'auto', maxWidth: '40%' }}>
				<>
					<Typography variant="h3" sx={{ wordBreak: 'break-word', color: 'text.primary', lineHeight: '120%' }}>
						{title ?? <Tr.Portal path="erm-request.list-item-title" />}
					</Typography>
					<Typography variant="pg-m" sx={{ ...labelStyle }}>
						<Tr.Portal path="erm-request.list-item-content" />
					</Typography>
				</>
			</Stack>
			<Button
				data-testid="modal-button-id"
				variant="contained"
				disableElevation
				color="secondary"
				size="medium"
				sx={{ alignSelf: 'start', marginBottom: 1, marginTop: 3 }}
				onClick={() => {
					setIsRequestErmModalVisible(true);
					analyticsErmEvent.ErmRequested();
				}}
			>
				<Tr.Portal path="erm-request.list-item-request-button" />
			</Button>
			<RequestErm open={isRequestErmModalVisible} onCancel={() => setIsRequestErmModalVisible(false)} />
		</Stack>
	);
};
