import { FC } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { isMillipore } from '@utils';
import { useLocationSelector } from '@store/slices/location.slice';
import { ReactComponent as Logo } from '@assets/logo/logo-m-letter.svg';
import { ReactComponent as MilliporeLogo } from '@assets/logo/logo-millipore-navbar.svg';

export const LogoMLetter: FC<SvgIconProps> = ({ ...rest }) => {
	const country = useSelector(useLocationSelector);
	const millipore = isMillipore(country.code);
	return (
		<SvgIcon
			component={millipore ? MilliporeLogo : Logo}
			viewBox={'0 0 120 20'}
			style={{ width: '140px', marginTop: '10px', fill: '#FFC832' }}
			{...rest}
		/>
	);
};
