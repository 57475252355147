import { waveToSelectItem } from '@models';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { PatchType } from '@models/request-response';
import { NmrAdminPermission, UserResponseModel, UserRole, UserStoreModel } from '@models/user';
import {
	NmrDrmOrder,
	NmrDrmOrderAssignee,
	NmrDrmOrderStatus,
	NmrDrmOrderWave,
	NrmDrmOrderPriority,
	UnassignedWave,
	nmrDrmOrderService,
} from '@services/nmr-drm-pipeline';
import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { ConfirmationWithReasonModalStatus } from '../DrmOrderDetailsOverview';

export enum OverviewCardType {
	Priority = 'Priority',
	Status = 'Status',
	Wave = 'Wave',
	Analyst = 'Analyst',
	QcInspector = 'QcInspector',
	QaReviewer = 'QaReviewer',
}

export type CardValue = {
	label: string | undefined;
	id: string | undefined | number | null;
};

export const CardTypeValueMapping: Record<OverviewCardType, (nmrDrmOrder?: NmrDrmOrder) => CardValue> = {
	[OverviewCardType.Priority]: (nmrDrmOrder) => ({ label: nmrDrmOrder?.priority, id: nmrDrmOrder?.priority }),
	[OverviewCardType.Status]: (nmrDrmOrder) => ({ label: nmrDrmOrder?.status.valueOf().toString(), id: nmrDrmOrder?.status }),
	[OverviewCardType.Wave]: (nmrDrmOrder) => ({ label: nmrDrmOrder?.wave?.name, id: nmrDrmOrder?.wave?.id ?? null }),
	[OverviewCardType.Analyst]: (nmrDrmOrder) => ({ label: nmrDrmOrder?.analyst?.name, id: nmrDrmOrder?.analyst?.id }),
	[OverviewCardType.QcInspector]: (nmrDrmOrder) => ({ label: nmrDrmOrder?.qcInspector?.name, id: nmrDrmOrder?.qcInspector?.id }),
	[OverviewCardType.QaReviewer]: (nmrDrmOrder) => ({ label: nmrDrmOrder?.qaReviewer?.name, id: nmrDrmOrder?.qaReviewer?.id }),
};

export const CardTypePathMapping: Record<OverviewCardType, string> = {
	[OverviewCardType.Priority]: '/Priority',
	[OverviewCardType.Status]: '/',
	[OverviewCardType.Wave]: '/WaveId',
	[OverviewCardType.Analyst]: '/Analyst',
	[OverviewCardType.QcInspector]: '/QcInspector',
	[OverviewCardType.QaReviewer]: '/QaReviewer',
};

export const onPatch = (id: number | undefined, value: unknown, path: string, successCallback?: () => void) => {
	const transformedValue: CardValue = { label: '', id: '' };
	if (value && id) {
		const valueId = TypeUtils.transformFromExist(transformedValue, value).id;
		RxUtils.promisify(
			nmrDrmOrderService.changeOrder(id, [
				{
					op: PatchType.REPLACE,
					path: path,
					value: valueId === 'undefined' ? null : valueId,
				},
			]),
			successCallback,
		);
	}
};

export const userListToCardValue = (usersData?: UserResponseModel[]) => {
	return (
		usersData?.map((user) => ({
			id: user.id,
			label: user.fullName,
		})) ?? []
	);
};

export const TargetStatusActionMapping = (
	transitionWithConfirmationDialog: () => void,
	transitionWithoutConfirmation: () => void,
	transitionWithReasonConfirmationDialog: (type: ConfirmationWithReasonModalStatus) => void,
) => ({
	[NmrDrmOrderStatus.DRAFT]: {
		[NmrDrmOrderStatus.READY]: transitionWithoutConfirmation,
		[NmrDrmOrderStatus.DECLINED]: () => transitionWithReasonConfirmationDialog(ConfirmationWithReasonModalStatus.DECLINE),
	},
	[NmrDrmOrderStatus.READY]: {
		[NmrDrmOrderStatus.DRAFT]: transitionWithoutConfirmation,
		[NmrDrmOrderStatus.IN_PRODUCTION]: transitionWithConfirmationDialog,
		[NmrDrmOrderStatus.DECLINED]: () => transitionWithReasonConfirmationDialog(ConfirmationWithReasonModalStatus.DECLINE),
	},
	[NmrDrmOrderStatus.IN_PRODUCTION]: {
		[NmrDrmOrderStatus.QC_INSPECTION]: transitionWithConfirmationDialog,
		[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: () =>
			transitionWithReasonConfirmationDialog(ConfirmationWithReasonModalStatus.CHANGE_STATUS_FOR_WAITING_FOR_MEASUREMENT),
		[NmrDrmOrderStatus.DECLINED]: () => transitionWithReasonConfirmationDialog(ConfirmationWithReasonModalStatus.DECLINE),
	},
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: {
		[NmrDrmOrderStatus.QC_INSPECTION]: transitionWithConfirmationDialog,
		[NmrDrmOrderStatus.IN_PRODUCTION]: transitionWithConfirmationDialog,
	},
	[NmrDrmOrderStatus.QC_INSPECTION]: {
		[NmrDrmOrderStatus.QA_REVIEW]: transitionWithConfirmationDialog,
		[NmrDrmOrderStatus.RELEASED]: transitionWithConfirmationDialog,
	},
	[NmrDrmOrderStatus.QA_REVIEW]: {
		[NmrDrmOrderStatus.RELEASED]: transitionWithConfirmationDialog,
	},
	[NmrDrmOrderStatus.DECLINED]: {
		[NmrDrmOrderStatus.DRAFT]: transitionWithoutConfirmation,
	},
});

const ValidStatusOrder: Record<NmrDrmOrderStatus, number> = {
	[NmrDrmOrderStatus.DRAFT]: 1,
	[NmrDrmOrderStatus.READY]: 2,
	[NmrDrmOrderStatus.IN_PRODUCTION]: 3,
	[NmrDrmOrderStatus.QC_INSPECTION]: 4,
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: 5,
	[NmrDrmOrderStatus.QA_REVIEW]: 6,
	[NmrDrmOrderStatus.RELEASED]: 7,
	[NmrDrmOrderStatus.DECLINED]: 8,
};

export const convertStringArrayToCardValues = (value: string[]) => {
	return value.map((i) => ({ label: i, id: i }));
};

export const getSortedStatusArray = (status: NmrDrmOrderStatus[]) => {
	return status.sort((a: NmrDrmOrderStatus, b: NmrDrmOrderStatus) => ValidStatusOrder[`${a}`] - ValidStatusOrder[`${b}`]);
};

const PriorityColorMapping: Record<NrmDrmOrderPriority, string> = {
	[NrmDrmOrderPriority.LOW]: 'grey.500',
	[NrmDrmOrderPriority.MEDIUM]: 'warning.main',
	[NrmDrmOrderPriority.HIGH]: 'error.main',
};

const PriorityBackgroundColorMapping: Record<NrmDrmOrderPriority, string> = {
	[NrmDrmOrderPriority.LOW]: 'grey.50',
	[NrmDrmOrderPriority.MEDIUM]: 'secondary.50',
	[NrmDrmOrderPriority.HIGH]: 'error.200',
};

const StatusColorMapping: Record<NmrDrmOrderStatus, string> = {
	[NmrDrmOrderStatus.DRAFT]: 'grey.500',
	[NmrDrmOrderStatus.READY]: 'grey.800',
	[NmrDrmOrderStatus.IN_PRODUCTION]: 'grey.800',
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: 'grey.800',
	[NmrDrmOrderStatus.QA_REVIEW]: 'grey.800',
	[NmrDrmOrderStatus.QC_INSPECTION]: 'grey.800',
	[NmrDrmOrderStatus.RELEASED]: 'primary.main',
	[NmrDrmOrderStatus.DECLINED]: 'grey.800',
};

const StatusBackgroundColorMapping: Record<NmrDrmOrderStatus, string> = {
	[NmrDrmOrderStatus.DRAFT]: 'grey.50',
	[NmrDrmOrderStatus.READY]: 'primary.100',
	[NmrDrmOrderStatus.IN_PRODUCTION]: 'primary.100',
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: 'primary.100',
	[NmrDrmOrderStatus.QA_REVIEW]: 'primary.100',
	[NmrDrmOrderStatus.QC_INSPECTION]: 'primary.100',
	[NmrDrmOrderStatus.RELEASED]: 'primary.100',
	[NmrDrmOrderStatus.DECLINED]: 'grey.50',
};

export const CardValueDividerColorMapping: Record<OverviewCardType, (nmrDrmOrder: NmrDrmOrder) => string> = {
	[OverviewCardType.Priority]: (nmrDrmOrder) => PriorityColorMapping[nmrDrmOrder.priority],
	[OverviewCardType.Status]: (nmrDrmOrder) => StatusColorMapping[nmrDrmOrder.status],
	[OverviewCardType.Wave]: () => 'primary.main',
	[OverviewCardType.Analyst]: () => 'info.700',
	[OverviewCardType.QcInspector]: () => 'warning.main',
	[OverviewCardType.QaReviewer]: () => '#2B8DD8',
};

export const CardBackgroundColorMapping: Record<OverviewCardType, (nmrDrmOrder: NmrDrmOrder) => string> = {
	[OverviewCardType.Priority]: (nmrDrmOrder) => PriorityBackgroundColorMapping[nmrDrmOrder.priority],
	[OverviewCardType.Status]: (nmrDrmOrder) => StatusBackgroundColorMapping[nmrDrmOrder.status],
	[OverviewCardType.Wave]: (nmrDrmOrder) => (!nmrDrmOrder.wave ? 'grey.50' : 'primary.100'),
	[OverviewCardType.Analyst]: (nmrDrmOrder) => (!nmrDrmOrder.analyst ? 'grey.50' : '#FAF7FF'),
	[OverviewCardType.QcInspector]: (nmrDrmOrder) => (!nmrDrmOrder.qcInspector ? 'grey.50' : 'secondary.50'),
	[OverviewCardType.QaReviewer]: (nmrDrmOrder) => (!nmrDrmOrder.qaReviewer ? 'grey.50' : '#F4FAFF'),
};

type CardTypePermissions = {
	permissionName?: string;
	needsUserMatch?: string;
	requiresOneOfRoles?: UserRole[];
	notEditable?: boolean;
};

const BacklogStatusPermissions: Record<NmrDrmOrderStatus, CardTypePermissions> = {
	[NmrDrmOrderStatus.DRAFT]: {
		permissionName: NmrAdminPermission.MANAGE_BACKLOG,
	},
	[NmrDrmOrderStatus.READY]: {
		permissionName: NmrAdminPermission.MANAGE_BACKLOG,
	},
	[NmrDrmOrderStatus.IN_PRODUCTION]: {
		permissionName: NmrAdminPermission.MANAGE_BACKLOG,
	},
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: {
		permissionName: NmrAdminPermission.MANAGE_BACKLOG,
	},
	[NmrDrmOrderStatus.QC_INSPECTION]: {
		notEditable: true,
	},
	[NmrDrmOrderStatus.QA_REVIEW]: {
		notEditable: true,
	},
	[NmrDrmOrderStatus.RELEASED]: {
		notEditable: true,
	},
	[NmrDrmOrderStatus.DECLINED]: {
		notEditable: true,
	},
};

const AssignmentPermissions: Record<NmrDrmOrderStatus, (cardType: OverviewCardType) => CardTypePermissions> = {
	[NmrDrmOrderStatus.DRAFT]: () => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
	}),
	[NmrDrmOrderStatus.READY]: () => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
	}),
	[NmrDrmOrderStatus.IN_PRODUCTION]: () => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
	}),
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: () => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
	}),
	[NmrDrmOrderStatus.QC_INSPECTION]: (cardType) => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
		notEditable: cardType === OverviewCardType.Analyst,
	}),
	[NmrDrmOrderStatus.QA_REVIEW]: (cardType) => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
		notEditable: cardType !== OverviewCardType.QaReviewer,
	}),
	[NmrDrmOrderStatus.RELEASED]: () => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
		notEditable: true,
	}),
	[NmrDrmOrderStatus.DECLINED]: () => ({
		permissionName: NmrAdminPermission.ASSIGN_DRM_ORDER,
		notEditable: true,
	}),
};

const StatusTransitionPermissions: Record<NmrDrmOrderStatus, CardTypePermissions> = {
	[NmrDrmOrderStatus.DRAFT]: {
		requiresOneOfRoles: [UserRole.NMR_PIPELINE_PLANNER],
	},
	[NmrDrmOrderStatus.READY]: {
		requiresOneOfRoles: [UserRole.NMR_PIPELINE_PLANNER],
		needsUserMatch: 'analyst',
	},
	[NmrDrmOrderStatus.IN_PRODUCTION]: {
		needsUserMatch: 'analyst',
	},
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: {
		needsUserMatch: 'analyst',
	},
	[NmrDrmOrderStatus.QC_INSPECTION]: {
		needsUserMatch: 'qcInspector',
	},
	[NmrDrmOrderStatus.QA_REVIEW]: {
		needsUserMatch: 'qaReviewer',
	},
	[NmrDrmOrderStatus.RELEASED]: {
		notEditable: true,
	},
	[NmrDrmOrderStatus.DECLINED]: {
		permissionName: NmrAdminPermission.EDIT_DRM_ORDER,
	},
};

type TCardTypePermissionFunction = (nmrDrmOrder?: NmrDrmOrder) => CardTypePermissions | undefined;
const CardTypePermissionMapping: Record<OverviewCardType, TCardTypePermissionFunction> = {
	[OverviewCardType.Priority]: (nmrDrmOrder) => (nmrDrmOrder ? BacklogStatusPermissions[nmrDrmOrder?.status] : undefined),
	[OverviewCardType.Status]: (nmrDrmOrder) => (nmrDrmOrder ? StatusTransitionPermissions[nmrDrmOrder?.status] : undefined),
	[OverviewCardType.Wave]: (nmrDrmOrder) => (nmrDrmOrder ? BacklogStatusPermissions[nmrDrmOrder?.status] : undefined),
	[OverviewCardType.Analyst]: (nmrDrmOrder) =>
		nmrDrmOrder ? AssignmentPermissions[nmrDrmOrder?.status](OverviewCardType.Analyst) : undefined,
	[OverviewCardType.QaReviewer]: (nmrDrmOrder) =>
		nmrDrmOrder ? AssignmentPermissions[nmrDrmOrder?.status](OverviewCardType.QaReviewer) : undefined,
	[OverviewCardType.QcInspector]: (nmrDrmOrder) =>
		nmrDrmOrder ? AssignmentPermissions[nmrDrmOrder?.status](OverviewCardType.QcInspector) : undefined,
};

export const getOverviewCardEditable = (
	hasPermissionsHook: typeof useHasPermissions,
	cardType: OverviewCardType,
	nmrDrmOrder?: NmrDrmOrder,
	user?: UserStoreModel | null,
) => {
	const mappedPermissions = TypeUtils.returnValueOfKey<typeof CardTypePermissionMapping, TCardTypePermissionFunction>(
		cardType,
		CardTypePermissionMapping,
	)?.(nmrDrmOrder);
	const hasPermissions = hasPermissionsHook(mappedPermissions?.permissionName ?? NmrAdminPermission.VIEW_BACKLOG);
	const hasRole = mappedPermissions?.requiresOneOfRoles?.some((role) => user?.roles?.some((userRole) => userRole.trim() === role));
	// FALSE POSITIVE
	// eslint-disable-next-line
	const matchesAssignee =
		user &&
		mappedPermissions?.needsUserMatch &&
		nmrDrmOrder &&
		user?.username === (nmrDrmOrder[mappedPermissions?.needsUserMatch as keyof NmrDrmOrder] as NmrDrmOrderAssignee)?.id;
	if (!mappedPermissions) return false;

	if (mappedPermissions.notEditable) return false;
	return (mappedPermissions?.permissionName && hasPermissions) || hasRole || matchesAssignee;
};

type AssignmentExcludeType = {
	ifAppears: string[];
	requiresRole: UserRole;
};

const AssignmentExcludeMapping: Record<string, AssignmentExcludeType> = {
	Analyst: { ifAppears: ['qcInspector', 'qaReviewer'], requiresRole: UserRole.NMR_ANALYST_QC_INSPECTOR },
	QaReviewer: { ifAppears: ['qcInspector', 'analyst'], requiresRole: UserRole.NMR_QA_REVIEWER },
	QcInspector: { ifAppears: ['qaReviewer', 'analyst'], requiresRole: UserRole.NMR_ANALYST_QC_INSPECTOR },
};

const UnassignedUser: UserResponseModel = TypeUtils.transform(UserResponseModel, {
	fullName: 'Unassigned',
	id: undefined,
	roles: [UserRole.NMR_ANALYST_QC_INSPECTOR, UserRole.NMR_QA_REVIEWER],
});

export const getUserListForAssignment = (
	cardType: OverviewCardType,
	userList: UserResponseModel[] | undefined,
	currentUser: Maybe<UserStoreModel>,
	nmrDrmOrder?: NmrDrmOrder,
) => {
	if (currentUser && nmrDrmOrder && userList) {
		const assignmentExclude = TypeUtils.returnValueOfKey<typeof AssignmentExcludeMapping, AssignmentExcludeType>(
			cardType,
			AssignmentExcludeMapping,
		);
		const otherTypeValues = assignmentExclude?.ifAppears.map(
			(otherType: string) => (nmrDrmOrder[otherType as keyof NmrDrmOrder] as NmrDrmOrderAssignee)?.id,
		);
		const currentUserResponse: UserResponseModel = TypeUtils.transform(UserResponseModel, {
			fullName: 'Assign to me',
			id: currentUser.username,
			roles: currentUser.roles.map((role) => role.trim()),
		});
		const requiredRole: UserRole = assignmentExclude?.requiresRole ?? UserRole.CUSTOMER_SUPPORT;
		return [
			UnassignedUser,
			...[currentUserResponse, ...userList.filter((user) => user.id !== currentUser.username)].filter(
				(user) =>
					!otherTypeValues?.includes(user.id) &&
					user.roles.some((role) => role.valueOf().toLowerCase() === requiredRole.valueOf().toLowerCase()),
			),
		];
	}
	return [];
};

export const getWaveItemList = (waveData: NmrDrmOrderWave[] | undefined, nmrDrmOrder: NmrDrmOrder | undefined) => {
	if (!waveData && nmrDrmOrder?.status !== NmrDrmOrderStatus.DRAFT) {
		return [];
	}
	if (waveData && nmrDrmOrder?.status !== NmrDrmOrderStatus.DRAFT) {
		return waveData.map((wave) => waveToSelectItem(wave));
	}
	return waveData
		? [
				{
					id: UnassignedWave.id,
					label: UnassignedWave.name,
				},
				...waveData.map((wave) => waveToSelectItem(wave)),
			]
		: [
				{
					id: UnassignedWave.id,
					label: UnassignedWave.name,
				},
			];
};
