import { FC, useState } from 'react';
import { GenericCard } from '../GenericCard';
import { Button, Divider, Grid, Stack, SxProps, Typography } from '@mui/material';
import { Subscription, subscribeService } from '@services/core';
import { Tr } from '@utils/Translation';
import { SubscriptionType } from '@models/subscribe';
import { SubscriptionLinearProgress } from '@components/portal';
import { useTranslation } from 'react-i18next';
import { DeactivationConfirmPopUp } from '../DeactivationConfirmation';
import { RxUtils } from '@utils/Rx';
import { useParams } from 'react-router-dom';
import { EditSubscriptionModal } from './EditSubscriptionModal';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { DateUtils } from '@utils/Type';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataTestId } from '@utils/DataTestId';

interface ISubscriptionDetailsLayoutProps {
	editing?: boolean;
	cardSx?: SxProps;
	containerSx?: SxProps;
	subscriptionData?: Subscription;
	triggerSubscriptionRequest?: () => void;
	details?: boolean;
}
interface ITitleTextContentProps {
	title: string;
	content?: number | Date | string;
}

const TechnologyColorMapping: Record<string, string> = {
	[SupportedTechnology.NMR]: 'primary.main',
	[SupportedTechnology.IR]: 'warning.main',
};

export const SubscriptionDetailsLayout: FC<ISubscriptionDetailsLayoutProps> = ({
	editing = false,
	cardSx,
	containerSx,
	subscriptionData,
	triggerSubscriptionRequest,
	details = false,
}) => {
	const { t } = useTranslation('common');

	const { id } = useParams();
	const isFreeSubscription = subscriptionData?.subscriptionType === SubscriptionType.FREE;
	const subscriptionType = isFreeSubscription ? subscriptionData?.subscriptionType : subscriptionData?.paymentType;
	const TitleTextContent: FC<ITitleTextContentProps> = ({ title, content }) => {
		return (
			<Stack>
				<Typography variant="label-s" color="grey.800" paddingBottom={1}>
					{title}
				</Typography>
				<Typography variant="pg-m" color="text.primary" sx={{ wordWrap: 'break-word' }}>
					{content?.toString()}
				</Typography>
			</Stack>
		);
	};

	const [isEndSubscriptionOpen, setIsEndSubscriptionOpen] = useState<boolean>(false);
	const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);

	return (
		<GenericCard
			sx={{ width: 1, height: 1, ...cardSx }}
			containerSx={containerSx}
			data-testid={DataTestId.getStaticTestId('subscription-layout-id')}
		>
			<Stack direction="row" justifyContent="space-between" alignItems={'center'} position="relative" marginBottom={4}>
				<Typography variant="h4">
					<Tr.Common path={`subscription.${subscriptionType?.toLowerCase()}`} />
				</Typography>
				{editing && (
					<Stack
						data-testid="edit-button-test-id"
						alignItems="start"
						justifyContent="flex-start"
						position="relative"
						onClick={() => setIsEditModalOpened(true)}
					>
						<EditOutlinedIcon
							color="inherit"
							width={'18px'}
							cursor={'pointer'}
							sx={{
								'&.MuiSvgIcon-root path': {
									fill: '#5E5E72',
								},
							}}
						/>
					</Stack>
				)}
				{details && (
					<Typography variant="h5" color={'grey.800'}>
						<Tr.Common path={t(`subscription.id`) + ': ' + subscriptionData?.id} />
					</Typography>
				)}
			</Stack>
			<SubscriptionLinearProgress
				total={subscriptionData?.budget ?? 0}
				title={t(`subscription.${subscriptionType?.toLocaleLowerCase()}-budget`)}
				budgetConsumptionDetails={
					(isFreeSubscription
						? [
								{
									consumed: subscriptionData?.totalConsumptionCount,
									name: t('subscription.used'),
									color: 'primary.main',
								},
							]
						: subscriptionData?.technologyDetails.map((technologyDetails) => ({
								consumed: technologyDetails.consumptionCount,
								name: technologyDetails.technology,
								color: TechnologyColorMapping[technologyDetails.technology],
							}))) ?? []
				}
				currency={isFreeSubscription ? undefined : subscriptionData?.currency}
			/>
			<Divider sx={{ marginY: 5 }} />
			<Grid container>
				<Grid xs={3} item>
					<TitleTextContent
						title={t('subscription.start-date')}
						content={
							subscriptionData?.startDate && DateUtils.getFormattedDate(new Date(subscriptionData?.startDate), 'dd/MM/yyyy')
						}
					/>
				</Grid>
				<Grid xs={3} item>
					<TitleTextContent
						title={t('subscription.end-date')}
						content={subscriptionData?.endDate && DateUtils.getFormattedDate(new Date(subscriptionData?.endDate), 'dd/MM/yyyy')}
					/>
				</Grid>
				{isFreeSubscription && (
					<Grid xs={6} item sx={{ position: 'relative' }}>
						<Divider sx={{ position: 'absolute', left: -20 }} orientation="vertical" />
						<TitleTextContent title={t('subscription.num-of-analyses')} content={subscriptionData?.budget} />
					</Grid>
				)}
				{!isFreeSubscription &&
					subscriptionData?.technologyDetails.map((technologyField, index) => (
						<Grid item xs={3} key={technologyField.technology} sx={{ position: 'relative' }}>
							{index === 0 && <Divider sx={{ position: 'absolute', left: -20 }} orientation="vertical" />}
							<TitleTextContent
								title={t(`subscription.${technologyField?.technology.toLowerCase()}-price`)}
								content={`${technologyField.price} ${subscriptionData?.currency ?? ''}`}
							/>
						</Grid>
					))}

				<Grid item xs={12}>
					<Divider sx={{ marginY: 5 }} />
					<TitleTextContent title={t('subscription.comment')} content={subscriptionData?.comment} />
				</Grid>
			</Grid>
			{!isFreeSubscription && editing && (
				<Stack direction="row" justifyContent="flex-end">
					<Button
						data-testid="deactivate-button-id"
						variant="text"
						sx={{
							textTransform: 'capitalize',
						}}
						disableElevation
						color="error"
						onClick={() => setIsEndSubscriptionOpen(true)}
					>
						{t('subscription.end-subscription')}
					</Button>
				</Stack>
			)}
			{isEndSubscriptionOpen && (
				<DeactivationConfirmPopUp
					title={<Tr.Common path={'subscription.confirmation'} />}
					subTitle={
						<>
							<Stack>
								<Typography variant="subtitle1">
									<Tr.Common path={`subscription.end-subscription-${subscriptionType?.toLocaleLowerCase()}-title`} />
								</Typography>
							</Stack>
							<Stack>
								<Typography variant="pg-m">{t('subscription.end-subscription-message-1')}</Typography>
								<Typography variant="pg-m">{t('subscription.end-subscription-message-2')}</Typography>
							</Stack>
						</>
					}
					message={t('subscription.end-subscription-placeholder')}
					textFieldTitle={t('Reason')}
					buttonText={t('subscription.end-subscription')}
					visible={isEndSubscriptionOpen}
					onConfirm={() => {
						if (id && subscriptionData?.id)
							RxUtils.promisify(subscribeService.delete(+id, subscriptionData?.id), () => {
								triggerSubscriptionRequest?.();
							});
						setIsEndSubscriptionOpen(false);
					}}
					onCancel={() => setIsEndSubscriptionOpen(false)}
				/>
			)}
			{isEditModalOpened && subscriptionData && (
				<EditSubscriptionModal
					subscriptionData={subscriptionData}
					isOpen={isEditModalOpened}
					onCancel={() => setIsEditModalOpened(false)}
					onSave={() => {
						triggerSubscriptionRequest?.();
						setIsEditModalOpened(false);
					}}
				/>
			)}
		</GenericCard>
	);
};
