import { DataTableBackend } from '@components/common';
import { getParams, setParams, useFilterSearchParams, useService } from '@hooks';
import { ExtendedColumn, FilterKey, PagedResult, SortOrder } from '@models';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Stack, Typography, TypographyProps } from '@mui/material';
import { AuditTrail, auditTrailService, filterService, loadingSpinnerOverlayService } from '@services';
import { DateUtils, RxUtils, Tr, downloadFileFromContent, mainTitleSpacer } from '@utils';
import { history } from '@utils/Router/history.const';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Observable } from 'rxjs';

const DEFAULT_SORT_COLUMN = 'createTime';
const TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
const Cell: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography sx={{ color: 'text.primary', wordBreak: 'break-word', ...sx }} {...props} />
);

const DEFAULT_MONTH_RANGE = 3;
export const AuditTrailTable: FC = () => {
	const { id = '' } = useParams<{ id: string }>();
	const { searchParams, filtering, setFiltering, setSearchParams } = useFilterSearchParams(DEFAULT_SORT_COLUMN, SortOrder.DESC);

	const [typedString, setTypedString] = useState('');
	const [isSearchParamsInitialized, setIsSearchParamsInitialized] = useState<boolean>(false);

	const { t } = useTranslation('irportal');
	const { data: filterData } = useService(() => filterService.auditLogsFilterOptions());
	const tableRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const currentFrom = searchParams.getAll(FilterKey.FROM);
		const currentTo = searchParams.getAll(FilterKey.TO);

		setParams(searchParams).setEntities('IrAnalysis');
		setParams(searchParams).setEntityId(id);
		if ((!currentFrom || currentFrom.length === 0) && (!currentTo || currentTo.length === 0) && id === '' && history.action !== 'POP') {
			const to = new Date(Date.now());
			const from = new Date(Date.now());

			from.setMonth(to.getMonth() - DEFAULT_MONTH_RANGE);
			from.setHours(0, 0, 1);
			to.setUTCHours(23, 59, 59);

			searchParams.set(FilterKey.DATE_RANGE, `${from.toISOString()},${to.toISOString()}`);
			searchParams.set(FilterKey.FROM, from.toISOString());
			searchParams.set(FilterKey.TO, to.toISOString());
			setSearchParams(searchParams);
		}
		setIsSearchParamsInitialized(true);
	}, []);

	const { data: auditTrailData, loading } = useService(() => {
		if (isSearchParamsInitialized) {
			return auditTrailService.getAllWithParams(searchParams);
		}
		return new Observable<PagedResult<AuditTrail>>();
	}, [searchParams, isSearchParamsInitialized]);

	const columns = useMemo<ExtendedColumn<AuditTrail>[]>(
		() => [
			{
				accessor: 'createTime',
				Header: <Tr.IrPortal path="analysis-history.audit-trail.when" />,
				Cell: ({ value }) => <Cell>{DateUtils.getFormattedDate(value, 'dd/MM/yyyy HH:mm:ss')}</Cell>,
			},
			{
				accessor: 'user',
				Header: <Tr.IrPortal path="analysis-history.audit-trail.who" />,
				disableSortBy: true,
				Cell: ({ value }) => <Cell>{value || '-'}</Cell>,
			},
			{
				accessor: 'title',
				Header: <Tr.IrPortal path="analysis-history.audit-trail.where" />,
				Cell: ({ value, row }) => (
					<Cell sx={{ maxWidth: 200 }}>
						{value || '-'}
						<br />
						{`(Analysis ID: ${row.original.entityId})`}
					</Cell>
				),
			},
			{
				accessor: 'action',
				Header: <Tr.IrPortal path="analysis-history.audit-trail.action" />,
				Cell: ({ value }) => <Cell sx={{ maxWidth: 150 }}>{value ? value : '-'}</Cell>,
			},
			{
				accessor: 'change',
				Header: <Tr.IrPortal path="analysis-history.audit-trail.what" />,
				disableSortBy: true,
				Cell: ({ value }) => {
					const lineCount = value?.trim().split('\n').length ?? 0;
					return (
						<Stack sx={{ fontSize: 14 }}>
							{value ? (
								<Accordion
									sx={{
										border: 'none',
										boxShadow: 'none',
										'&.Mui-disabled': {
											backgroundColor: 'transparent',
											color: 'text.primary',
											opacity: 1,
										},
									}}
									disabled={lineCount <= 1}
								>
									{value
										?.trim()
										.split('\n')
										.map((i, index) => (
											<AccordionSummary
												expandIcon={
													index === 0 && lineCount > 1 ? <ExpandMore sx={{ color: 'primary.main' }} /> : null
												}
												sx={{
													paddingLeft: 0,
													border: 'none',
													textOverflow: 'ellipsis',
													wordBreak: 'break-word',
													'&.Mui-disabled': {
														opacity: 1,
													},
													'&.MuiAccordionSummary-content': {
														margin: '8px 0px',
													},
												}}
												key={`${index}_${i}`}
											>
												{i}
											</AccordionSummary>
										))}
								</Accordion>
							) : (
								'-'
							)}
						</Stack>
					);
				},
			},
		],
		[searchParams],
	);

	const onSaveAsPdf = async (title: string) => {
		loadingSpinnerOverlayService.increment();

		RxUtils.promisify(auditTrailService.getReport(searchParams), (res) => downloadFileFromContent(res, title));
		loadingSpinnerOverlayService.decrement();
	};

	return (
		<Stack data-testid="audit-trail-wrapper-id" marginTop={mainTitleSpacer}>
			<DataTableBackend
				columns={columns}
				data={auditTrailData?.data || []}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: auditTrailData?.totalPages ?? 1 }}
				searchText={typedString}
				searchPlaceholder={t('analysis-history.audit-trail.search-message')}
				searchFieldProps={{ sx: { maxWidth: 450, width: 450 } }}
				onSearchTextChange={setTypedString}
				headerCellProps={{ sx: { minWidth: 60 } }}
				sx={{
					visibility: loading ? 'hidden' : 'visible',
					marginX: 2,
				}}
				showExportButton={true}
				exportButtonText={t('analysis-history.audit-trail.export-pdf')}
				onExportButtonClick={() =>
					tableRef?.current &&
					onSaveAsPdf(
						`${t('analysis-history.audit-trail.report-title')}_${DateUtils.getFormattedDate(new Date(), TIME_FORMAT)}.pdf`,
					)
				}
				tableRef={tableRef}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</Stack>
	);
};
