export enum PortalPageRoutes {
	PORTAL = 'portal',
	DASHBOARD = 'dashboard',
	HISTORY = 'history',
	SEARCH = 'search',
	FAVOURITES = 'favorites',
	PHYSICAL_REFERENCE_MATERIAL = 'physical-reference-materials',
	ANALYSIS = 'analysis',
	MANAGE_USERS = 'manage-users',
	MANAGE_ORGANIZATION = 'manage-organization',
	ANALYSIS_RESULT = 'analysis-result',
	YOUR_PROFILE = 'your-profile',
	ORGANIZATION_SETTINGS = 'organization-settings',
	DEVICE_MANAGEMENT = 'device-management',
	ADD_DEVICE = 'add-device',
	DEVICE = 'device',
	LAB_DETAILS = 'lab-details',
	GO_TO_DEVICE = 'go-to-device',
	ADD_ACCESSORY = 'add-accessory',
	RESTORE_DEVICE = 'restore-device',
	DEVICE_DETAILS = 'details',
	ADD_CALIBRATION = 'add-calibration',
	RECALIBRATE = 'recalibrate',
	AUDIT_TRAIL = 'audit-trail',
}
