import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { DateUtils, RxUtils, Tr } from '@utils';
import { TableButton } from '@components/portal';
import { TechnologyPermissionKeys } from '@models/user';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { GenericCard, OverflowText } from '@components/common';
import { ActionRequired, DrmOrderLink, DrmRequestButton, UpdateDrmRequestCasNumber } from '@components/admin';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import { SingleSelectMenu } from '@components/common/SingleSelectMenu';
import { useService } from '@hooks/useService';
import { NmrDrmOrder, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { Observable } from 'rxjs';
import { DrmOrderComments } from '@components/admin/DrmOrderDetails/DrmOrderComments';
import { NmrRequestComments } from '@components/admin/NmrRequestComments';
import { NmrRequestCommentDropdownType } from './nmr-request-comment.enum';
import { nmrDrmRequestService, NmrRequestDrm } from '@services/nmr';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common.slice';

const REQUEST_INDICATOR_TITLE_HEIGHT = 26;
const ErmRequestIndicator: FC<{ title: ReactNode; value: ReactNode; titleSuccessor?: ReactNode }> = ({ title, value, titleSuccessor }) => (
	<Stack sx={{ marginBottom: '1.5rem' }}>
		<Stack sx={{ height: REQUEST_INDICATOR_TITLE_HEIGHT }} justifyContent="space-between" alignItems="center" direction="row">
			<Typography variant="label-s" sx={{ color: 'grey.800' }}>
				<Tr.Admin path={`erm-request-detail.${title}`} />
			</Typography>
			{titleSuccessor}
		</Stack>

		<OverflowText variant="pg-m" sx={{ lineHeight: '26px', wordBreak: 'break-all', paddingRight: '0.5rem' }} enableTooltip>
			{value || '-'}
		</OverflowText>
	</Stack>
);

export const NmrRequestDetail = () => {
	const { id } = useParams();

	const [requestErm, setRequestDrm] = useState<NmrRequestDrm>();

	const [isAddCommentButtonClicked, setIsAddCommentButtonClicked] = useState<boolean>(false);
	const [dropDownSelection, setdropDownSelection] = useState(NmrRequestCommentDropdownType.REQUEST);

	const hasCommentPermission = useHasPermissions(TechnologyPermissionKeys.COMMENT_DRM_REQUEST, SupportedTechnology.NMR);

	const dispatch = useDispatch();

	useEffect(() => {
		getDetailData();

		return () => {
			setRequestDrm(undefined);
		};
	}, []);

	const getDetailData = () =>
		id &&
		RxUtils.promisify(nmrDrmRequestService.requestsDetail(+id), (value) => {
			setRequestDrm(value);
			dispatch(setBreadcrumb({ requestNumber: value.id }));
		});

	const { data: drmOrderData } = useService(() => {
		if (requestErm?.nmrDrmOrderId) {
			return nmrDrmOrderService.get(+requestErm.nmrDrmOrderId);
		}
		return new Observable<NmrDrmOrder>();
	}, [requestErm?.nmrDrmOrderId]);

	if (!id || !requestErm) return null;

	const onSelectedItemChange = (item) => {
		setdropDownSelection(item);
	};

	return (
		<Stack direction="column" spacing={3}>
			<Stack>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h1" sx={{ color: 'primary.main', textTransform: 'none', marginBottom: '1.5rem' }}>
						<Tr.Admin path="erm-request-detail.request" /> #{requestErm?.id || '-'}
					</Typography>
					<Link href={`mailto:${requestErm?.email}`} sx={{ textDecoration: 'none' }}>
						<TableButton.ViewSummary
							data-testid="contact-customer-id"
							sx={{ width: '10rem', height: '2.5rem', fontSize: '16px' }}
						>
							<Tr.Admin path={`erm-request-detail.contact-customer`} />
						</TableButton.ViewSummary>
					</Link>
				</Stack>
				<GenericCard>
					{requestErm.actionRequired && <ActionRequired />}

					<Stack data-testid="request-detail-id" direction="row">
						<Stack
							sx={{
								maxWidth: '25rem',
								width: '33%',
								borderRight: '1px solid',
								borderColor: 'grey.200',
							}}
						>
							<Stack sx={{ marginBottom: '1.5rem' }}>
								<Typography variant="label-s" sx={{ color: 'grey.800', marginBottom: '0.6rem' }}>
									<Tr.Admin path={`erm-request-detail.status`} />
								</Typography>
								<DrmRequestButton status={String(requestErm?.status)} isEditable={false} />
							</Stack>
							<ErmRequestIndicator title="request-id" value={requestErm?.id} />
							<ErmRequestIndicator
								title="date"
								value={requestErm?.date ? DateUtils.getFormattedDate(requestErm.date) : null}
							/>
							<ErmRequestIndicator title="organization" value={requestErm?.organization} />
							<ErmRequestIndicator title="email" value={requestErm?.email} />
						</Stack>
						<Stack sx={{ width: '33%', borderRight: '1px solid', borderColor: 'grey.200', paddingLeft: '2rem' }}>
							<ErmRequestIndicator title="chemical-name" value={requestErm?.chemicalName} />
							<ErmRequestIndicator
								title="cas-number"
								value={requestErm?.casNumber}
								titleSuccessor={<UpdateDrmRequestCasNumber refreshDetailData={getDetailData} drmRequest={requestErm} />}
							/>
							<ErmRequestIndicator title="merck-physical-product-number" value={requestErm?.merckPhysicalProductNumber} />
							<ErmRequestIndicator title="preferred-solvent" value={requestErm?.preferredSolvent} />
							<ErmRequestIndicator title="preferred-application" value={requestErm?.preferredApplication} />
						</Stack>
						<Stack sx={{ width: '33%', paddingLeft: '2rem' }}>
							<ErmRequestIndicator title="reference-method-used" value={requestErm?.referenceMethod} />
							<ErmRequestIndicator title="technology" value={SupportedTechnology.NMR} />
							<ErmRequestIndicator title="usual-concentration-in" value={requestErm?.usualConcentration} />
							<ErmRequestIndicator title="comment" value={requestErm?.comment} />
							<Stack width="100%">
								<ErmRequestIndicator title="drm-order" value={<DrmOrderLink drmOrder={drmOrderData} />} />
							</Stack>
						</Stack>
					</Stack>
				</GenericCard>
			</Stack>

			<Grid item>
				<Grid container direction="column" spacing={1}>
					<Grid item>
						<Grid container justifyContent="space-between">
							<Grid item>
								{dropDownSelection === NmrRequestCommentDropdownType.REQUEST ? (
									<>
										<Typography variant="h2" color="primary.main" marginBottom={1.5}>
											<Tr.Admin path={'erm-request-detail.comment-title'} />
										</Typography>
									</>
								) : (
									<>
										<Typography variant="h2" color="primary.main" marginBottom={1.5}>
											<Tr.Admin path={`erm-request-detail.comments.title`} />
										</Typography>
									</>
								)}
							</Grid>
							<Grid item alignItems="center" justifyContent="flex-end">
								<Grid item>
									<Stack direction="row">
										<SingleSelectMenu
											selectedItem={dropDownSelection}
											sx={{ minWidth: 198, maxHeight: 40, marginRight: 2 }}
											items={Object.values(NmrRequestCommentDropdownType)}
											onSelectedItemChange={onSelectedItemChange}
										/>

										{hasCommentPermission && dropDownSelection === NmrRequestCommentDropdownType.REQUEST && (
											<Button
												data-testid="add-comment-button-id"
												onClick={() => setIsAddCommentButtonClicked(true)}
												startIcon={<AddIcon />}
											>
												<Tr.Admin path={`erm-request-detail.add-comment`} />
											</Button>
										)}
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction="column" spacing={2}>
							{dropDownSelection === NmrRequestCommentDropdownType.REQUEST ? (
								<Grid item>
									<NmrRequestComments
										setIsAddCommentButtonClicked={setIsAddCommentButtonClicked}
										isAddCommentButtonClicked={isAddCommentButtonClicked}
										requestId={requestErm.id}
									/>
								</Grid>
							) : (
								<Grid item>
									<DrmOrderComments
										setIsAddCommentButtonClicked={setIsAddCommentButtonClicked}
										isAddCommentButtonClicked={isAddCommentButtonClicked}
										nmrDrmOrderId={requestErm.nmrDrmOrderId ?? 0}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Stack>
	);
};
