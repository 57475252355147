import { FC } from 'react';
import { Tr, DateUtils } from '@utils';
import { NmrAnalysis, getNmrAnalysisRoute } from '@services';
import { Card, Stack, Typography, TypographyProps } from '@mui/material';
import { OverflowText } from '@components/common';
import { AnalysisCircularProgress, AnalysisTypeIndicator } from '@components/portal';
import { Link } from 'react-router-dom';

interface IMyAnalysesCardProps {
	analysis: NmrAnalysis;
	dataTestId?: String;
}

const titleStyle: TypographyProps['sx'] = {
	marginTop: '1.5rem',
	marginBottom: '1.5rem',
};

const labelStyle: TypographyProps['sx'] = {
	color: 'text.main',
	whiteSpace: 'nowrap',
	height: '1.25rem',
};

const textStyle: TypographyProps['sx'] = {
	color: 'grey.800',
	whiteSpace: 'nowrap',
	height: '1.25rem',
};

const viewDetailsStyle: TypographyProps['sx'] = {
	color: 'primary.main',
	marginTop: '1.5rem',
	'&:hover': {
		textDecoration: 'underline',
	},
};

export const ANALYSIS_CARD_WIDTH = 18.25;
const cardStyle = {
	padding: '1rem',
	marginY: 2,
	width: `${ANALYSIS_CARD_WIDTH}rem`,
	height: '30rem',
	cursor: 'pointer',
	boxShadow: '0 0 0.15rem 0.05rem rgba(0,0,0,0.1)',
	[':hover']: {
		boxShadow: '0.25rem 0.25rem 0.75rem 0.25rem rgba(0,0,0,0.2)',
		transform: 'translate(-1%, -1.6%)',
	},
	transitionProperty: 'transform, box-shadow',
	transitionDuration: '0.3s',
};

export const MyAnalysesCard: FC<IMyAnalysesCardProps> = ({ analysis, dataTestId }) => {
	const { id, stage, measurementType, targetType, technology, operatorName, measurementDate, title, substances } = analysis;

	return (
		<Card variant="outlined" sx={cardStyle}>
			<Link to={`../${getNmrAnalysisRoute(stage)}/${id}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
				<AnalysisCircularProgress analysisStage={stage} sx={{ padding: 1 }} />
				<OverflowText variant="h4" sx={titleStyle}>
					{title || '-'}
				</OverflowText>
				<Stack direction="row" spacing="0.5rem">
					<Stack spacing={1}>
						<Typography variant="pg-s" sx={labelStyle}>
							<Tr.Portal path="dashboard.my-analyses.analysis-type" />:
						</Typography>
						<Typography variant="pg-s" sx={labelStyle}>
							<Tr.Portal path="dashboard.my-analyses.technology" />:
						</Typography>
						<Typography variant="pg-s" sx={labelStyle}>
							<Tr.Portal path="dashboard.my-analyses.substances" />:
						</Typography>
						<Typography variant="pg-s" sx={labelStyle}>
							<Tr.Portal path="dashboard.my-analyses.measurement-date" />:
						</Typography>
						<Typography variant="pg-s" sx={labelStyle}>
							<Tr.Portal path="dashboard.my-analyses.operator-id" />:
						</Typography>
					</Stack>
					<Stack spacing={1} sx={{ width: '35%' }}>
						<AnalysisTypeIndicator variant="pg-s" sx={textStyle} measurementType={measurementType} targetType={targetType} />
						<OverflowText variant="pg-s" sx={textStyle}>
							{technology || '-'}
						</OverflowText>
						<OverflowText variant="pg-s" enableTooltip={true} sx={textStyle}>
							{substances || '-'}
						</OverflowText>
						<OverflowText variant="pg-s" sx={textStyle}>
							{DateUtils.getFormattedDate(measurementDate) || '-'}
						</OverflowText>
						<OverflowText variant="pg-s" sx={textStyle}>
							{operatorName || ''}
						</OverflowText>
					</Stack>
				</Stack>
				<Typography variant="h6" lineHeight="115%" sx={viewDetailsStyle} data-testid={dataTestId}>
					<Tr.Portal path="dashboard.my-analyses.view-details" />
				</Typography>
			</Link>
		</Card>
	);
};
