import { nmrAnalysisService } from '@services/nmr';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { RxUtils } from '@utils/Rx';

const changeNumberOfProtons = (analysisId: number, peakId: number, numberOfH: Maybe<number>, callback: () => void) => {
	RxUtils.promisify(nmrAnalysisService.changeNumberOfProtons(+analysisId, peakId, numberOfH?.toString() ?? ''), callback);
};

export const onPeakSelectionsChange = (analysisId: number, numberOfH: Maybe<number>, peak: QuantitivePeakType, callback: () => void) => {
	changeNumberOfProtons(analysisId, peak.id, numberOfH, callback);
};
