import { SearchField } from '@components/common';
import { Filter } from '@components/common/Filter';
import { FilterChips } from '@components/common/Filter/FilterChips';
import { useService } from '@hooks/useService';
import { FilterKey, FilterValueType, IFilter } from '@models/filter';
import { PopoverProps, Stack, TextFieldProps } from '@mui/material';
import { irFilterService } from '@services/ir';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';
import { FC, useMemo, useRef, useState } from 'react';

type IReferenceSearchProps = TextFieldProps & {
	onSearch?: (typed: string) => void;
	onSearchClear: () => void;
	filterData?: IFilter[];
	filteringSelections?: FilterValueType[];
	searchParams: URLSearchParams;
	onSearchParamsChange: (searchParams: URLSearchParams) => void;
	hideLibraryFilterOption?: boolean;
};

const popOverProps: Omit<PopoverProps, 'open'> = {
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin: {
		horizontal: 'right',
		vertical: 'top',
	},
};
export const ReferenceSearchField: FC<IReferenceSearchProps> = ({
	onSearchClear,
	onSearch,
	searchParams,
	onSearchParamsChange,
	filteringSelections,
	filterData,
	sx,
	hideLibraryFilterOption,
	...props
}) => {
	const [openFilter, setOpenFilter] = useState(false);
	const [deletedItem, setDeletedItem] = useState<{ key: string; value: string }>();
	const searchFieldRef = useRef<HTMLDivElement>(null);

	const filterWidth = searchFieldRef.current?.clientWidth ? searchFieldRef.current?.clientWidth / 3 : 300;
	const { data: substanceFilterData } = useService(() => irFilterService.getSubstanceFilterOptions());
	if (substanceFilterData?.options) {
		substanceFilterData.options.map((option) => {
			if (option.key === FilterKey.LIBRARIES) {
				option.escapesTranslation = true;
			}
		});
		if (hideLibraryFilterOption) {
			substanceFilterData.options = substanceFilterData.options.filter((item) => item.key !== FilterKey.LIBRARIES);
		}
	}
	return (
		<Stack>
			<Stack direction="row">
				<SearchField
					sx={{ flexGrow: 1, ...sx }}
					onSearch={(typed: string) => {
						onSearch?.(typed);
						typed && analyticsErmEvent.ErmSearched(typed);
					}}
					onSearchClear={onSearchClear}
					ref={searchFieldRef}
					filter={true}
					onFilterClick={() => setOpenFilter(!openFilter)}
					{...props}
				/>
				{useMemo(
					() => (
						<Filter
							setOpen={() => setOpenFilter(!openFilter)}
							searchParams={searchParams}
							setSearchParams={onSearchParamsChange}
							open={openFilter}
							anchor={searchFieldRef.current as HTMLElement}
							filterData={substanceFilterData?.options || []}
							removeFromChip={deletedItem}
							cardSx={{ width: filterWidth }}
							popoverProps={popOverProps}
							data-testid="filter-id"
						/>
					),
					[openFilter, deletedItem, filterData],
				)}
			</Stack>
			{filteringSelections && (
				<FilterChips
					onDeleteItem={(deletedItemFromChip) => setDeletedItem(deletedItemFromChip)}
					filterData={substanceFilterData?.options}
					selectedFilterData={filteringSelections || []}
					sx={{ borderBottom: 0, paddingLeft: 0 }}
					chipProps={{ sx: { marginRight: 1.5 } }}
					buttonSx={{ marginTop: 1 }}
				/>
			)}
		</Stack>
	);
};
