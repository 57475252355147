import { QualityGrade } from '@models/reference-material';

const qualityGradeMapping: Record<string, QualityGrade> = {
	drm: QualityGrade.DRM,
	das: QualityGrade.DAS,
	theoretical: QualityGrade.THEORETICAL,
};

export const getQualityGrader = (grade: string): QualityGrade => {
	if (typeof grade !== 'string') {
		return QualityGrade.DRM;
	}
	const normalizedGrade = grade.toLowerCase();
	return qualityGradeMapping[normalizedGrade as keyof typeof qualityGradeMapping] ?? QualityGrade.DRM;
};

export const getBorderColor = (qualityGradeValue: string | undefined, deactivateBorderColors: boolean): string => {
	if (deactivateBorderColors) {
		return 'grey.800';
	}
	return QualityGradeBorderColorMapping[qualityGradeValue ?? 'drm'] ?? 'primary.main';
};

const QualityGradeBorderColorMapping: Record<QualityGrade, string> = {
	[QualityGrade.DRM]: 'primary.main',
	[QualityGrade.DAS]: 'info.dark',
	[QualityGrade.THEORETICAL]: 'info.700',
};
