import { FC, ReactNode, useContext } from 'react';
import { Divider, Grid, Stack, SxProps, Typography } from '@mui/material';
import { GenericCard } from '@components/common';
import { NMRAnalysisReviewLevel, nmrAnalysisService } from '@services';
import { Tr } from '@utils';
import { useService } from '@hooks';
import { AnalysisResultContext } from './analysis-result-context';

interface IResultFieldProps {
	fieldName: ReactNode;
	fieldValue?: ReactNode;
	visible?: boolean;
	hasError?: boolean;
	sxProps?: SxProps;
}

const ResultField: FC<IResultFieldProps> = ({ fieldName, fieldValue, visible = true, hasError, sxProps, ...props }) =>
	visible ? (
		<Stack direction="column">
			<Typography sx={{ color: 'grey.800', fontSize: 14, fontWeight: 700 }}>{fieldName}</Typography>
			<Typography
				variant="pg-m"
				sx={{
					color: hasError ? 'error.main' : 'primary.main',
					marginTop: '0.5rem',
					lineBreak: 'anywhere',
					...sxProps,
				}}
				{...props}
			>
				{fieldValue || '-'}
			</Typography>
		</Stack>
	) : null;

interface IAnalysisResultFieldsProps {
	id: number;
}

export const AnalysisResultFields: FC<IAnalysisResultFieldsProps> = ({ id }) => {
	const { analysisResultData } = useContext(AnalysisResultContext);

	const { data: reviewData } = useService(() => {
		const reviewLevel = analysisResultData?.isRejectedByLabAdmin()
			? NMRAnalysisReviewLevel.LAB_ADMIN
			: NMRAnalysisReviewLevel.LAB_MEMBER;
		return nmrAnalysisService.getReview(+id, reviewLevel);
	}, [analysisResultData?.comment, analysisResultData?.status, id]);

	const getApprovalStatus = () => {
		const isSecondApprovalEnabled = analysisResultData?.nmrAnalysis?.secondApproval;

		if (isSecondApprovalEnabled ? analysisResultData?.isApprovedByLabAdmin() : analysisResultData?.isConfirmedByLabMember()) {
			return <Tr.Portal path="analysis-result.approved" />;
		}

		if (analysisResultData?.isConfirmedByLabMember()) {
			return <Tr.Portal path="analysis-result.awaiting-second-approval" />;
		}

		if (analysisResultData?.isRejected()) {
			return (
				<>
					<Tr.Portal path="analysis-result.analysis-rejected" />{' '}
					{analysisResultData?.isRejectedByLabMember() ? (
						<Tr.Portal path="analysis-result.owner" />
					) : (
						<Tr.Portal path="analysis-result.lab-admin" />
					)}
				</>
			);
		}

		if (!analysisResultData?.isError()) return <Tr.Portal path="analysis-result.await-first-approve" />;

		return null;
	};

	return (
		<GenericCard
			sx={{
				border: 'none',
				paddingX: 3,
				paddingY: 4,
				boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
			}}
		>
			<Grid container rowGap={3} columnSpacing={4}>
				<Grid item md={4} xs={4} sm={4}>
					<ResultField
						fieldName={<Tr.Portal path="analysis-result.analysis-title" />}
						fieldValue={analysisResultData?.nmrAnalysis?.title}
						data-testid="analysis-title-value"
					/>
				</Grid>

				<Grid item md={4} xs={4} sm={4}>
					<ResultField
						fieldName={<Tr.Portal path="analysis-result.operator-id" />}
						fieldValue={analysisResultData?.nmrAnalysis?.operatorName}
						data-testid="analysis-operator-value"
					/>
				</Grid>

				<Grid item md={4} xs={4} sm={4}>
					<ResultField
						hasError={analysisResultData?.isRejected()}
						fieldName={<Tr.Portal path="analysis-result.approval-status" />}
						fieldValue={getApprovalStatus()}
						visible={!!getApprovalStatus()}
						data-testid="analysis-approval-status-value"
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider sx={{ marginY: 1.5 }} />
				</Grid>

				<Grid item md={4} xs={4} sm={4}>
					<ResultField
						fieldName={<Tr.Portal path="analysis-result.project-id" />}
						fieldValue={analysisResultData?.nmrAnalysis?.projectId}
						data-testid="analysis-project-id-value"
					/>
				</Grid>

				<Grid item md={4} xs={4} sm={4}>
					<ResultField
						fieldName={<Tr.Portal path="analysis-result.measurement-executor-name" />}
						fieldValue={analysisResultData?.nmrAnalysis?.measurementExecutorId}
						data-testid="analysis-executor-name-value"
					/>
				</Grid>
				{analysisResultData?.nmrAnalysis.secondApproval && (
					<Grid item md={4} xs={4} sm={4}>
						<ResultField
							fieldName={<Tr.Portal path="analysis-result.reviewer-name" />}
							fieldValue={analysisResultData?.reviewerName}
							data-testid="analysis-reviewer-name-value"
						/>
					</Grid>
				)}

				{analysisResultData?.isRejected() && (
					<>
						<Grid item xs={12}>
							<Divider sx={{ marginY: 1.5 }} />
						</Grid>
						<Grid item md={4} xs={4} sm={4}>
							<ResultField
								fieldName={<Tr.Portal path="analysis-result.rejection-reason-for-result" />}
								fieldValue={reviewData?.comment}
								sxProps={{ color: 'grey.800', fontWeight: 500 }}
								data-testid="analysis-rejected-reason-value"
							/>
						</Grid>
					</>
				)}
			</Grid>
		</GenericCard>
	);
};
