import { FormikTextfield, ModalDialog } from '@components/common';
import { Button, Stack, Typography } from '@mui/material';
import { nmrDrmWaveService } from '@services/nmr-drm-pipeline';
import { setTriggerBacklogRefresh, setTriggerWaveRefresh } from '@store/slices/pipeline.slice';
import { RxUtils } from '@utils/Rx';
import { formFieldSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

type TAddWaveModal = {
	onClose: (name?: string) => void;
};

export const AddWaveModal: FC<TAddWaveModal> = ({ onClose }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('admin');
	const refreshData = () => {
		dispatch(setTriggerWaveRefresh());
		dispatch(setTriggerBacklogRefresh());
	};

	const formik = useFormik<Extendable<{ name: string }>>({
		initialValues: {
			name: '',
		},
		validationSchema: yup.object({
			name: yup.string().max(250).required(t('drm-pipeline.nmr.backlog.wave-name-is-required')),
		}),
		onSubmit: (values) => {
			RxUtils.promisify(nmrDrmWaveService.post(values.name), () => (onClose(values.name), refreshData()));
		},
	});

	return (
		<ModalDialog variant="secondary" open={true} onClose={() => onClose()} maxWidth="xs" title={<Tr.Common path="add" />}>
			<form onSubmit={formik.handleSubmit}>
				<Stack sx={{ pb: 7, pt: 3, px: 8 }}>
					<Typography variant="h3" textAlign="center" mb={3}>
						<Tr.Admin path="drm-pipeline.nmr.backlog.add-wave" />
					</Typography>
					<FormikTextfield
						name="name"
						title={t('drm-pipeline.nmr.backlog.wave')}
						formikApi={formik}
						placeholder={t('drm-pipeline.nmr.backlog.add-wave-name')}
						sx={{
							mb: formFieldSpacer,
						}}
					/>
					<Stack direction="row" alignSelf="flex-end" spacing={1} width={1}>
						<Button data-testid="add-wave-cancel-id" variant="outlined" onClick={() => onClose()} fullWidth>
							<Tr.Common path="cancel" />
						</Button>
						<Button
							disabled={!formik.dirty || !formik.isValid}
							data-testid="add-wave-submit-id"
							type="submit"
							variant="contained"
							disableElevation
							fullWidth
						>
							<Tr.Common path="add" />
						</Button>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
