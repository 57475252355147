export enum QualityGrade {
	DRM = 'Drm',
	DAS = 'Das',
	THEORETICAL = 'Theoretical',
}

export const QualityGradeTextMapping: Record<QualityGrade, string> = {
	[QualityGrade.DAS]: 'dAS',
	[QualityGrade.DRM]: 'dRM',
	[QualityGrade.THEORETICAL]: 'Theoretical',
};
export const QualityGradeBorderColorMapping: Record<QualityGrade, string> = {
	[QualityGrade.DRM]: 'primary.main',
	[QualityGrade.DAS]: 'info.dark',
	[QualityGrade.THEORETICAL]: 'info.700',
};

export const QualityGradeChipColorMapping: Record<QualityGrade, { text: string; background: string }> = {
	[QualityGrade.DRM]: { text: 'primary.main', background: 'primary.400' },
	[QualityGrade.DAS]: { text: 'info.dark', background: '#ECF5FC' },
	[QualityGrade.THEORETICAL]: { text: 'info.700', background: 'info.600' },
};
