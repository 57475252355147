import { Column } from 'react-table';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@components/common';
import { FC, useContext, useMemo } from 'react';
import { transformKebabCase } from '@utils/General';
import { Typography, Card, Box, TypographyProps } from '@mui/material';
import { AnalysisResultContext } from '../../nmr/analysis-result-context';
import { DataTestId } from '@utils/DataTestId';

const Cell: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography variant="pg-m" style={{ textAlign: 'left' }} sx={{ textAlign: 'center', wordBreak: 'break-word', ...sx }} {...props} />
);

export const AnalysisResult: FC = () => {
	const { t } = useTranslation('portal');
	const { analysisResultData } = useContext(AnalysisResultContext);

	const data = [
		{
			molecularWeight: analysisResultData?.molecularWeight,
			sampleWeight: analysisResultData?.sampleWeight,
			solventWeight: analysisResultData?.solventWeight,
			massFraction: analysisResultData?.massFraction,
			concentration: analysisResultData?.concentration,
		},
	];

	const columnKeys = ['molecularWeight', 'sampleWeight', 'solventWeight', 'massFraction', 'concentration'];
	const columns = useMemo<Column[]>(
		() =>
			columnKeys.map((key) => {
				if (key === 'concentration') {
					return {
						accessor: key,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell data-testid={DataTestId.getStaticTestId(`${key}-${row.index}`)}>{value || '-'}</Cell>
						),
						Header: `${t('analysis-result.concentration')} [${analysisResultData?.unitOfConcentration}]`,
					};
				}
				return {
					accessor: key,
					disableSortBy: true,
					Cell: ({ value, row }) => <Cell data-testid={DataTestId.getStaticTestId(`${key}-${row.index}`)}>{value || '-'}</Cell>,
					Header: <Tr.Portal path={`analysis-result.table.${transformKebabCase(key)}`} />,
				};
			}),
		[],
	);

	return (
		<Card variant="elevation" sx={{ padding: '2rem 1.5rem 2.5rem' }}>
			<Typography variant="h3" mb={1.5} sx={{ color: 'success.main' }}>
				{t('analysis-result.analysis-results')}
			</Typography>
			<Typography paragraph mb={3} variant="label-m" sx={{ color: 'grey.800' }}>
				{t('analysis-result.quantitative-non-targeted')}
			</Typography>
			<Typography paragraph mb={3} variant="pg-m" sx={{ color: 'grey.800' }}>
				{t('analysis-result.quantitative-non-targeted-text', { key: analysisResultData?.nmrAnalysis.title })}
			</Typography>
			<Box
				sx={{
					border: '1px solid',
					height: 'fit-content',
					borderRadius: '0.5rem',
					borderColor: 'grey.200',
				}}
			>
				<DataTable
					data={data}
					columns={columns}
					sx={{
						'& .MuiTableBody-root 	.MuiTableRow-root': {
							'&:last-child': {
								'& .MuiTableCell-root': {
									borderBottom: 'none',
								},
							},
							'& .MuiTableCell-root': {
								padding: '0 1rem',
							},

							height: '2.5rem',
						},
						'& .MuiTableRow-head': {
							'& .MuiTableCell-head': {
								padding: '0.7rem 1rem',
							},
						},
					}}
				/>
			</Box>
		</Card>
	);
};
