import { FC, ReactNode, useEffect, useState } from 'react';
import {
	FormControl,
	FormControlLabel,
	Link,
	LinkProps,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
	TypographyProps,
} from '@mui/material';
import { RxUtils, Tr, TypeUtils } from '@utils';
import { configurationService } from '@services';
import { useAnalysis } from '@hooks/useAnalysis';
import { AnalysisSelectionType, MapToAnalysisSelectionType } from '@models/analysis/analysis.enum';
import { TMaybeQualitativeOrQuantitative } from '@models/analysis';

export const HereLink: FC<LinkProps> = ({ sx, ...props }) => (
	<Link sx={{ display: 'inline-block', fontWeight: 'bolder', color: 'inherit', textDecorationColor: 'inherit', ...sx }} {...props}>
		<Tr.Portal path="new-analysis.here" />
	</Link>
);

export type AnalysisTypeSelectionProps = {
	title?: ReactNode;
	typographyProps?: TypographyProps;
	onMeasurementTypeChange?: (value: AnalysisSelectionType) => void;
};

const labelStyle = {
	color: 'grey.500',
	transform: 'translateY(-4px)',
};

export const AnalysisTypeSelection: FC<AnalysisTypeSelectionProps> = ({
	title,
	typographyProps: { sx: typographySx, ...typographyProps } = {},
	onMeasurementTypeChange: onQualitativeOrQuantitativeChange,
}) => {
	const { nmrDevice, measurementType, targetType, isAnalysisEditable } = useAnalysis();
	const [isQuantitativeDisabled, setIsQuantitativeDisabled] = useState<boolean>(true);

	useEffect(() => {
		RxUtils.promisify(configurationService.getConfiguration('nmr-quantitative'), (res) => {
			setIsQuantitativeDisabled(!res);
		});
	}, [nmrDevice]);

	return (
		<FormControl>
			{title ? (
				<Stack direction="row" alignItems="center">
					<Typography variant="label-s" className="required" sx={{ ...typographySx }} {...typographyProps}>
						{title}
					</Typography>
				</Stack>
			) : null}
			<RadioGroup
				value={
					measurementType &&
					TypeUtils.returnValueOfKeys(
						[measurementType as TMaybeQualitativeOrQuantitative, targetType as TMaybeQualitativeOrQuantitative],
						MapToAnalysisSelectionType,
					)
				}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					onQualitativeOrQuantitativeChange?.(event.target.value as AnalysisSelectionType);
				}}
			>
				<FormControlLabel
					value={AnalysisSelectionType.QUALITATIVE}
					disabled={!isAnalysisEditable}
					control={
						<Radio
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							inputProps={{ 'data-testid': 'qualitative-test-id' }}
						/>
					}
					label={
						<Tooltip title={<Tr.Portal path="new-analysis.qualitative-tooltip" />}>
							<Typography variant="h5">
								<Tr.Portal path="qualitative" />
							</Typography>
						</Tooltip>
					}
				/>
				<Stack direction="row" alignItems="center" sx={{ marginLeft: 4 }}>
					<Typography variant="label-xs" sx={labelStyle}>
						<Tr.Portal path="new-analysis.qualitative-legend" />
					</Typography>
				</Stack>
				<FormControlLabel
					value={AnalysisSelectionType.QUANTITATIVE_TARGETED}
					control={
						<Radio
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							inputProps={{ 'data-testid': 'quantitative-test-id' }}
						/>
					}
					disabled={isQuantitativeDisabled || !isAnalysisEditable}
					label={
						<Tooltip title={<Tr.Portal path="new-analysis.quantitative-targeted-tooltip" />}>
							<Typography variant="h5">
								<Tr.Portal path="quantitative-targeted" />
							</Typography>
						</Tooltip>
					}
				/>
				<Stack direction="row" alignItems="center" sx={{ marginLeft: 4 }}>
					<Typography variant="label-xs" sx={labelStyle}>
						<Tr.Portal path="new-analysis.quantitative-legend" />
					</Typography>
				</Stack>
				<FormControlLabel
					value={AnalysisSelectionType.QUANTITATIVE_NON_TARGETED}
					control={
						<Radio
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							inputProps={{ 'data-testid': 'quantitative-non-targeted-test-id' }}
						/>
					}
					disabled={isQuantitativeDisabled || !isAnalysisEditable}
					label={
						<Tooltip title={<Tr.Portal path="new-analysis.quantitative-non-targeted-tooltip" />}>
							<Typography variant="h5">
								<Tr.Portal path="quantitative-non-targeted" />
							</Typography>
						</Tooltip>
					}
				/>
				<Stack direction="row" alignItems="center" columnGap={2} sx={{ marginLeft: 4 }}>
					<Typography variant="label-xs" sx={labelStyle}>
						<Tr.Portal path="new-analysis.quantitative-non-targeted-legend" />
					</Typography>
				</Stack>
			</RadioGroup>
		</FormControl>
	);
};
