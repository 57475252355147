import { FormikTextfield, ModalDialog } from '@components/common';
import { PatchType } from '@models/request-response';
import { PropertyOf } from '@models/request-response/http-patch';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { substanceService } from '@services/core/substance';
import { ErrorCode, notificationService } from '@services/index';
import { nmrDrmRequestService } from '@services/nmr/nmr-drm/nmr-drm-request.service';
import { NmrRequestDrm } from '@services/nmr/nmr-drm/nmr-request-drm.class';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type TUpdateDrmRequestCasNumber = {
	drmRequest?: NmrRequestDrm;
	refreshDetailData: () => void;
};

type TUpdateCasNumberFields = Extendable<{
	casNumber: string;
}>;
export const UpdateDrmRequestCasNumber: FC<TUpdateDrmRequestCasNumber> = ({ drmRequest, refreshDetailData }) => {
	const [open, setOpen] = useState<boolean>(false);
	const showEditButton = drmRequest?.actionRequired;
	const { t } = useTranslation('admin');

	const handleCasNumberPatch = (value: string) => {
		if (drmRequest) {
			RxUtils.promisify(
				nmrDrmRequestService.updateRequest(drmRequest?.id, [
					{
						op: PatchType.REPLACE,
						path: PropertyOf<NmrRequestDrm>('casNumber'),
						value: value,
					},
				]),
				() => {
					refreshDetailData();
					onClose();
				},
			);
		}
	};

	const formik = useFormik<TUpdateCasNumberFields>({
		initialValues: {
			casNumber: drmRequest?.casNumber,
		},
		validationSchema: yup.object({
			casNumber: yup
				.string()
				.trim()
				.max(32, t('organization-details.labs.name-should-have-max-32-characters'))
				.required(t('organization-details.labs.name-required')),
		}),
		onSubmit: (fields: TUpdateCasNumberFields) => {
			if (formik.isValid && drmRequest) {
				RxUtils.promisify(
					substanceService.searchSubstance(fields.casNumber),
					() => handleCasNumberPatch(fields.casNumber),
					(error) => {
						const code = error.ErrorCode.toString();
						if (code === ErrorCode.SubstanceMissingFromPubchemExceptionCode.toString()) {
							notificationService.sendError(t('erm-request-detail.cas-number-not-found'));
						} else if (code === ErrorCode.SubstanceAlreadyExistsExceptionCode.toString()) {
							handleCasNumberPatch(fields.casNumber);
						} else {
							notificationService.sendError('erm-request-detail.technical-error');
						}
					},
				);
			}
		},
		validateOnChange: true,
	});
	const onClose = () => {
		setOpen(false);
		formik.resetForm();
	};
	useEffect(() => {
		formik.resetForm();
	}, [drmRequest]);
	return (
		<Stack sx={{ paddingRight: 4 }}>
			{showEditButton && (
				<EditOutlined sx={{ width: 20, height: 20, color: 'grey.800', cursor: 'pointer' }} onClick={() => setOpen(true)} />
			)}
			{open && (
				<ModalDialog
					variant="secondary"
					open={open}
					onClose={onClose}
					title={
						<Typography variant="pg-s">
							<Tr.Common path="Edit" />
						</Typography>
					}
					subtitle={<Tr.Admin path="erm-request-detail.edit-cas-number" />}
					width={472}
					height={326}
				>
					<form onSubmit={formik.handleSubmit}>
						<Stack sx={{ paddingX: 8 }} spacing={4}>
							<FormikTextfield
								formikApi={formik}
								name="casNumber"
								title={t('erm-request-detail.cas-number')}
								placeholder={t('erm-request-detail.edit-cas-number')}
							/>
							<Grid container paddingTop={1}>
								<Grid
									item
									xs={6}
									sx={{
										paddingRight: 1,
									}}
								>
									<Button
										variant="outlined"
										fullWidth={true}
										data-testid="rename-cancel-button-id"
										disableElevation
										onClick={onClose}
									>
										<Tr.Common path="cancel" />
									</Button>
								</Grid>

								<Grid
									item
									xs={6}
									sx={{
										paddingLeft: 1,
									}}
								>
									<Button
										fullWidth={true}
										data-testid="rename-approve-button-id"
										variant="contained"
										type="submit"
										disableElevation
										disabled={!formik.dirty || !formik.isValid}
										sx={{ textTransform: 'capitalize' }}
									>
										<Tr.Common path="save" />
									</Button>
								</Grid>
							</Grid>
						</Stack>
					</form>
				</ModalDialog>
			)}
		</Stack>
	);
};
