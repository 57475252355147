import { Column } from 'react-table';
import { TypeUtils } from '@utils/Type';
import { Tr } from '@utils/Translation';
import { FC, useContext, useMemo } from 'react';
import { WarningIcon } from '@components/icons';
import { NmrReferenceMaterialResult } from '@services';
import { DataTable, OverflowText, QualityGradeChip } from '@components/common';
import { AnalysisType } from '@models/analysis/analysis-type.enum';
import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { AnalysisMatchedDescription } from './AnalysisMatchedDescription';
import { StarIconIndicator, TableLegend, valueColorMapping } from './TableLegend';
import { AnalysisResultContext } from '@routes/Portal/AnalysisResult/nmr/analysis-result-context';
import { DataTestId } from '@utils/DataTestId';
import { getQualityGrader } from '@utils/QualityGrade';

const Cell: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography
		variant="pg-m"
		style={{ textAlign: 'left' }}
		sx={{ textAlign: 'center', wordBreak: 'break-word', fontWeight: 600, ...sx }}
		{...props}
	/>
);
const getWarningMessage = (matchedResults: NmrReferenceMaterialResult[]) => {
	const hCount = matchedResults.some((item) => item.isQuestionableHCount);
	const maxDa = matchedResults.some((item) => item.isQuestionableMaxDa);
	const nmrPurity = matchedResults.some((item) => item.isQuestionableNmrPurity);

	// TO DO: FIX NESTED IFS
	if (hCount) {
		if (maxDa) {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-h-count-max-d-a-nmr-purity';
			} else {
				return 'analysis-result-table.warning-message-h-count-max-d-a';
			}
		} else {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-h-count-nmr-purity';
			} else {
				return 'analysis-result-table.warning-message-h-count';
			}
		}
	} else {
		if (maxDa) {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-max-d-a-nmr-purity';
			} else {
				return 'analysis-result-table.warning-message-max-d-a';
			}
		} else {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-nmr-purity';
			}
		}
		return '';
	}
};

const ExceptionIndicator: FC = () => (
	<Box
		sx={{
			maxWidth: '1rem',
			maxHeight: '1rem',
			minWidth: '1rem',
			minHeight: '1rem',
			borderRadius: '50%',
			backgroundColor: 'warning.main',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			marginRight: '0.4rem',
			transform: 'rotate(180deg)',
		}}
	>
		<WarningIcon sx={{ ' path': { fill: '#0F1A2E' }, fontSize: '10px' }} />
	</Box>
);

export interface AnalysisResultTableProps {
	resultMatches: NmrReferenceMaterialResult[];
	isTheoretical: boolean;
}

export const AnalysisResultTable: FC<AnalysisResultTableProps> = ({ resultMatches, isTheoretical }) => {
	const { analysisResultData } = useContext(AnalysisResultContext);
	if (!analysisResultData) return null;

	const { sampleWeight, solventWeight, massFraction, concentration } = analysisResultData;

	const data = resultMatches.map((match) => {
		const item = { ...match, sampleWeight, solventWeight, massFraction, concentration };
		return TypeUtils.transform(NmrReferenceMaterialResult, item);
	});

	const columns = useMemo<Column<NmrReferenceMaterialResult>[]>(() => {
		return analysisResultData.isQuantitative()
			? [
					{
						accessor: 'substanceName',
						Header: <Tr.Portal path="analysis-result.substance-name" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell
								data-testid={`substance-name-${row.index}`}
								sx={{ color: 'text.primary', fontWeight: '400', border: 'none' }}
							>
								<Cell
									sx={{ color: 'text.primary', fontWeight: '400', border: 'none', display: 'flex', alignItems: 'center' }}
								>
									{row.original.isFalsePositive && <ExceptionIndicator />}
									<OverflowText
										enableTooltip={true}
										variant="pg-m"
										containerProps={{ width: 'auto !important', maxWidth: 400, minWidth: 200 }}
									>
										{value || '-'}
									</OverflowText>
								</Cell>
							</Cell>
						),
					},
					{
						accessor: 'casNumber',
						Header: <Tr.Portal path="analysis-result.cas-number" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell data-testid={`casNumber-${row.index}`} sx={{ color: 'text.primary', fontWeight: '400' }}>
								{value || ''}{' '}
							</Cell>
						),
					},
					{
						accessor: 'qualityGrade',
						Header: <Tr.Portal path="drm-detail.quality-grade" />,
						disableSortBy: true,
						Cell: ({ value, row }) => {
							return (
								<Cell data-testid={`quality-grade-${row.index}`} sx={{ color: 'text.primary', fontWeight: '400' }}>
									<QualityGradeChip
										isReferenceActive={row.original?.isActive || true}
										qualityGrade={getQualityGrader(value || 'dRM')}
									/>
								</Cell>
							);
						},
					},
					{
						accessor: 'sampleWeight',
						Header: <Tr.Portal path="analysis-result.sample-weight-with-unit" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell
								data-testid={`sampleWeight-${row.index}`}
								style={{ textAlign: 'left', paddingLeft: '1rem' }}
								sx={{ color: 'text.primary', fontWeight: '400' }}
							>
								{value ? value : '-'}
							</Cell>
						),
					},
					{
						accessor: 'solventWeight',
						Header: <Tr.Portal path="analysis-result.solvent-weight-with-unit" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell
								data-testid={`solventWeight-${row.index}`}
								style={{ textAlign: 'left', paddingLeft: '1rem' }}
								sx={{ color: 'text.primary', fontWeight: '400' }}
							>
								{value ? value : '-'}
							</Cell>
						),
					},
					{
						accessor: 'matchFactor',
						Header: <Tr.Portal path="analysis-result.identified-match-factor" />,
						disableSortBy: true,
						Cell: ({ value, row }) => {
							const hqiColor = valueColorMapping(row.original.category);
							return (
								<Cell data-testid={`identified-match-factor-${row.index}`}>
									{value ? (
										<Stack direction="row" alignItems="end">
											<StarIconIndicator color={hqiColor} sx={{ minWidth: 'fit-content' }} />
											<Typography
												variant="pg-m"
												color="text.primary"
												sx={{
													maxWidth: '2.8rem',
													minWidth: '2.8rem',
													fontWeight: '400',
													whiteSpace: 'nowrap',
												}}
											>
												{!isTheoretical && `${Math.round(value <= 1 ? value * 100 : value)}%`}
											</Typography>
										</Stack>
									) : (
										''
									)}
								</Cell>
							);
						},
					},
					{
						accessor: 'massFraction',
						Header: <Tr.Portal path="analysis-result.mass-fraction-with-unit" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell data-testid={DataTestId.getStaticTestId(`massFraction-${row.index}`)} sx={{ fontWeight: 400 }}>
								{value ? value : '-'}
							</Cell>
						),
					},
					{
						accessor: 'concentration',
						Header: <Tr.Portal path="analysis-result.concentration-with-unit" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell data-testid={DataTestId.getStaticTestId(`concentration-${row.index}`)} sx={{ fontWeight: 400 }}>
								{value ? value : '-'}{' '}
							</Cell>
						),
					},
				]
			: [
					{
						accessor: 'substanceName',
						Header: <Tr.Portal path="analysis-result.substance-name" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell sx={{ color: 'text.primary', fontWeight: '400', border: 'none', display: 'flex', alignItems: 'center' }}>
								{row.original.isFalsePositive && row.original.matchFactor > 0.92 && <ExceptionIndicator />}
								<OverflowText
									data-testid={`substance-name-${row.index}`}
									enableTooltip={true}
									variant="pg-m"
									containerProps={{ width: 'auto !important', maxWidth: 400, minWidth: 200 }}
								>
									{value || '-'}
								</OverflowText>
							</Cell>
						),
					},
					{
						accessor: 'casNumber',
						Header: <Tr.Portal path="analysis-result.cas-number" />,
						disableSortBy: true,
						Cell: ({ value, row }) => (
							<Cell data-testid={`cas-number-${row.index}`} sx={{ color: 'text.primary', fontWeight: '400' }}>
								{value || ''}{' '}
							</Cell>
						),
					},
					{
						accessor: 'qualityGrade',
						Header: <Tr.Portal path="drm-detail.quality-grade" />,
						disableSortBy: true,
						Cell: ({ value, row }) => {
							return (
								<Cell data-testid={`quality-grade-${row.index}`} sx={{ color: 'text.primary', fontWeight: '400' }}>
									<QualityGradeChip
										isReferenceActive={row.original.isActive || true}
										qualityGrade={getQualityGrader(value || 'dRM')}
									/>
								</Cell>
							);
						},
					},
					{
						accessor: 'matchFactor',
						Header: <Tr.Portal path="analysis-result.match-factor" />,
						disableSortBy: true,
						Cell: ({ value, row }) => {
							const hqiColor = valueColorMapping(row.original.category);
							return (
								<Cell data-testid={`match-factor-${row.index}`}>
									{value ? (
										<Stack direction="row" alignItems="end">
											<StarIconIndicator color={hqiColor} sx={{ minWidth: 'fit-content' }} />
											{!isTheoretical && (
												<Typography
													variant="pg-m"
													color="text.primary"
													sx={{
														maxWidth: '3rem',
														minWidth: '3rem',
														fontWeight: '400',
														whiteSpace: 'nowrap',
													}}
												>
													{`${Math.round(value <= 1 ? value * 100 : value)}%`}
												</Typography>
											)}
										</Stack>
									) : (
										''
									)}
								</Cell>
							);
						},
					},
				];
	}, []);

	return (
		<Stack direction="column">
			<AnalysisMatchedDescription analysisResult={analysisResultData} isTheoretical={isTheoretical} />

			<Stack direction="column">
				<>
					<Box
						sx={{
							borderRadius: '12px',
							border: '1px solid',
							borderColor: 'grey.200',
							height: 'fit-content',
						}}
					>
						<DataTable
							sx={{
								'& .MuiTableBody-root 	.MuiTableRow-root': {
									'&:last-child': {
										'& .MuiTableCell-root': {
											borderBottom: 'none',
										},
									},
									'& .MuiTableCell-root': {
										padding: '0 1rem',
									},

									height: '2.5rem',
								},
								'& .MuiTableRow-head': {
									'& .MuiTableCell-head': {
										padding: '0.7rem 1rem',
									},
								},
							}}
							columns={columns}
							data={data}
						/>
					</Box>
					<TableLegend isTheoretical={isTheoretical} />
				</>

				{!analysisResultData.isQualitative() && !analysisResultData.nmrAnalysis.solvent.toLowerCase().includes('dmso') ? (
					<Stack sx={{ display: 'flex', flexDirection: 'row' }}>
						<ExceptionIndicator />
						<Typography variant="pg-s" fontWeight={500}>
							<Tr.Portal path="analysis-result-table.solvent-dmso-warning" />
						</Typography>
					</Stack>
				) : null}

				{resultMatches.some((item) => item.isFalsePositive && item.matchFactor > 0.92) ? (
					<Stack sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
						<ExceptionIndicator />
						<Typography variant="pg-s" fontWeight={500}>
							<Tr.Portal path={getWarningMessage(resultMatches)} />
						</Typography>
					</Stack>
				) : null}
				{analysisResultData.nmrAnalysis.type === AnalysisType.QUANTIFICATION ? (
					<Stack sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
						<Typography variant="pg-s" fontWeight={500}>
							<Tr.Portal path="analysis-result-table.mass-fraction-warning" />
						</Typography>
					</Stack>
				) : null}
			</Stack>
		</Stack>
	);
};
