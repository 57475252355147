import { FormikApiType } from '@components/common';
import { IrDRMDetail, IrSubstanceList } from '@components/irportal';
import { AddFromFavoritesButton } from '@components/irportal/favorites';
import { useIrAnalysis, useIrDRMDetail } from '@hooks';
import { AnalysisTargetType } from '@models/analysis';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';
import { Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { IrSubstance, LibraryCount, alertService, irDrmService } from '@services';
import { RxUtils, Tr } from '@utils';
import { FC, createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
interface IAnalysisFavorite {
	addedSubstances: IrSubstance[] | undefined;
	onRemoveSubstance: (drmCode: number) => void;
	id: number;
	formik: FormikApiType;
	onAddErmOrSubstance: (ermId: number) => void;
	onSubstanceListRefresh: () => void;
}
export const AnalysisFavorite: FC<IAnalysisFavorite> = ({
	addedSubstances,
	onRemoveSubstance,
	onAddErmOrSubstance,
	onSubstanceListRefresh,
	formik,
}) => {
	const [displayedAddedSubstanceList, setDisplayedAddedSubstanceList] = useState<IrSubstance[]>([]);
	const [librarycount, setlibrarycount] = useState<LibraryCount[]>([]);
	const { resetIrDetail: resetDrmDetail, setIrDRMDetail, ...drmProps } = useIrDRMDetail();
	const { isAnalysisEditable, updateSelectedLibrary } = useIrAnalysis();

	const { t } = useTranslation('irportal');
	//TODO change this
	const onRemoveAddedSubstance = (referenceMaterialId: number) => {
		resetDrmDetail();
		onRemoveSubstance(referenceMaterialId);
	};

	const onFavoriteSelected = (favoriteId) => {
		onAddErmOrSubstance(favoriteId);
	};

	useEffect(() => {
		if (addedSubstances?.length) {
			formik?.setFieldValue('targetType', AnalysisTargetType.TARGETED);
		}
		setDisplayedAddedSubstanceList(addedSubstances || []);
	}, [addedSubstances]);

	useEffect(() => {
		/// reset
		if (displayedAddedSubstanceList?.length) {
			displayedAddedSubstanceList.splice(0, displayedAddedSubstanceList.length);
			formik?.setFieldValue('referenceId', null);
			formik?.values.selectedImpurity && formik?.setFieldValue('selectedImpurity', null);
		}
	}, [formik.values.type]);

	useEffect(() => {
		RxUtils.promisify(irDrmService.getLibraryCount(), (data) => setlibrarycount(data));
	}, [formik?.values?.type]);

	const isAnalaysisTypeIdentification = formik.values.type === IrAnalysisType.IDENTIFICATION;
	let formikSelectedLibrary = formik?.values?.selectedLibrary || [];
	// TO DO: Completely mess component. Every radio group & form group should be seperated
	const renderMultiSelectionForIdentification = () => {
		return (
			<FormControl sx={{ marginBottom: 2 }} component="fieldset" variant="standard">
				<Stack direction="row" alignItems="center" columnGap={2}>
					<FormLabel component="legend">Available Libraries:</FormLabel>
					{formikSelectedLibrary?.length < 1 && (
						<Typography variant="body2" sx={{ color: 'error.main', marginBottom: 1.25, marginTop: 1.25, fontSize: 14 }}>
							<Tr.IrPortal path="new-analysis.analysis-library-required-multiple" />
						</Typography>
					)}
				</Stack>

				{librarycount &&
					librarycount.map((item) => {
						return (
							<FormControlLabel
								value={item.libraryId}
								disabled={!isAnalysisEditable}
								control={
									<Checkbox
										name={item.libraryName}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											if (!displayedAddedSubstanceList?.length) {
												const currentSelectedLibrary = (event.target as HTMLInputElement).value;

												if (event.target.checked) {
													updateSelectedLibrary([...formikSelectedLibrary, currentSelectedLibrary]);
												} else {
													formikSelectedLibrary = formikSelectedLibrary?.filter(
														(libraryId: number) => libraryId !== +currentSelectedLibrary,
													);
													updateSelectedLibrary([...formikSelectedLibrary]);
												}
											}
										}}
										sx={{ marginTop: '-5px', '& .MuiSvgIcon-root': { fontSize: 32 } }}
										id={item.libraryCode}
									/>
								}
								key={item.count}
								label={`${item.libraryName}  (${item?.count})`}
							/>
						);
					})}
			</FormControl>
		);
	};
	const drmDetailRef = createRef<HTMLDivElement>();
	return (
		<Stack data-testid="analysis-favorite">
			<Stack direction="row" justifyContent="space-between">
				<Stack>
					<Typography variant="h4" color="primary.main">
						<Tr.IrPortal path="new-analysis.add-reference" />
					</Typography>
				</Stack>
				<Stack>
					{(formik?.values.type === IrAnalysisType.PURITYCHECK || formik?.values.type === IrAnalysisType.QUANTIFICATION) &&
						formik?.values?.selectedLibrary?.length > 0 && (
							<Stack direction="row" sx={{ marginLeft: 'auto' }}>
								<AddFromFavoritesButton
									type={formik?.values.type}
									library={formik?.values.selectedLibrary}
									onFavoriteSelect={onFavoriteSelected}
									isDisabled={!isAnalysisEditable}
								/>
							</Stack>
						)}
				</Stack>
			</Stack>
			<Stack>
				{isAnalaysisTypeIdentification ? (
					renderMultiSelectionForIdentification()
				) : (
					<FormControl sx={{ marginBottom: 2 }} component="fieldset" variant="standard" disabled={!isAnalysisEditable}>
						<Stack direction="row" alignItems="center" columnGap={2}>
							<FormLabel component="legend">Available Libraries:</FormLabel>
							{formikSelectedLibrary?.length < 1 && (
								<Typography variant="body2" sx={{ color: 'error.main', marginBottom: 1.25, marginTop: 1.25, fontSize: 14 }}>
									<Tr.IrPortal path="new-analysis.analysis-library-required" />
								</Typography>
							)}
						</Stack>

						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							value={formik.values.selectedLibrary || []}
							name="selectedLibrary"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								displayedAddedSubstanceList?.length &&
									alertService.send({
										content: <Tr.IrPortal path="search-references.remove-drm-error" />,
										titleText: <Tr.IrPortal path="library-change" />,
										closeTextComponent: <Tr.IrPortal path="cancel" />,
										confirmTextComponent: <Tr.IrPortal path="search-references.continue" />,
										onConfirm: () => {
											displayedAddedSubstanceList?.length &&
												displayedAddedSubstanceList.splice(0, displayedAddedSubstanceList.length);
											updateSelectedLibrary([(event.target as HTMLInputElement).value]);
										},
										onClose: () => undefined,
									});
								!displayedAddedSubstanceList.length && updateSelectedLibrary([(event.target as HTMLInputElement).value]);
							}}
						>
							{librarycount &&
								librarycount.map((item) => {
									return (
										<FormControlLabel
											value={item.libraryId}
											control={<Radio />}
											key={item.count}
											label={`${item.libraryName}  (${item?.count})`}
										/>
									);
								})}
						</RadioGroup>
					</FormControl>
				)}
			</Stack>

			{displayedAddedSubstanceList?.length &&
			!(formik?.values.type === IrAnalysisType.IDENTIFICATION) &&
			formik?.values.selectedLibrary ? (
				<>
					<Stack data-testid="drm-list-wrapper-id" direction="column" sx={{ mt: 1 }}>
						<Stack direction="row" alignItems="center">
							<Typography variant="h5">
								<Tr.IrPortal path="new-analysis.your-references" />
							</Typography>
						</Stack>
						<Divider sx={{ mt: 1 }} />

						<IrSubstanceList
							substanceList={displayedAddedSubstanceList}
							type="remove"
							actionLabel={t('drm-detail.remove-from-analysis')}
							onSubstanceListRefresh={onSubstanceListRefresh}
							disabled={!isAnalysisEditable}
							onSubstanceRemove={(drmCode: number) => onRemoveAddedSubstance(drmCode)}
							onSubstanceDetail={(drmVisibility: boolean, detailId: number) =>
								setIrDRMDetail({
									actionLabel: t('drm-detail.remove-from-analysis'),
									// TODO checking for substanceID here. Should be checking for referenceMaterial ID. But i think Adding and removal will be done by BE
									drmId: displayedAddedSubstanceList.find((e) => e.id === detailId)?.id ?? detailId,
									onHandleAction: (dmrId) => onRemoveAddedSubstance(+dmrId),
									visible: drmVisibility,
								})
							}
							itemSx={{ padding: '1rem 0' }}
							dataTestId="substance-added-drms-wrapper"
						/>
						<Divider sx={{ mb: 2 }} />
					</Stack>
				</>
			) : null}
			{useMemo(
				() => (
					<IrDRMDetail onCloseClick={() => resetDrmDetail()} ref={drmDetailRef} {...drmProps} />
				),
				[drmProps],
			)}
		</Stack>
	);
};
