import { useHasPermissions } from '@hooks/useHasPermissions';
import { LabPermission } from '@models/user';
import { Button, SxProps, Tabs, TabsProps } from '@mui/material';
import { Tr } from '@utils/Translation/Tr';
import { FC } from 'react';

export enum ErmSearchTabValues {
	All = 'all',
	Recent = 'recent',
}

export const DrmTabs: FC<TabsProps> = ({ value, onChange }) => {
	const getStyle = (tab: ErmSearchTabValues) => {
		let style: SxProps = {
			borderRadius: tab === ErmSearchTabValues.Recent ? '4px 0px 0px 4px' : '0px 4px 4px 0px',
			border: '2px solid #01884C',
			boxShadow: 'none',
			'&:hover': { backgroundColor: 'transparent' },
		};

		if (tab !== value) {
			style = {
				backgroundColor: 'white',
				color: '#01884C',
				...style,
			};
		} else {
			style = {
				pointerEvents: 'none',
				...style,
			};
		}

		return style;
	};

	const hasDrmPermission = useHasPermissions(LabPermission.DRM);

	return (
		<Tabs
			TabIndicatorProps={{ style: { display: 'none' } }}
			textColor="primary"
			value={value}
			data-testid="drm-tabs"
			sx={{ '&:before': { content: 'unset' }, marginBottom: 2 }}
		>
			{hasDrmPermission && (
				<Button
					data-testid="erm-tab-id"
					variant="contained"
					disableElevation
					onClick={(_) => value !== ErmSearchTabValues.Recent && onChange?.(_, ErmSearchTabValues.Recent)}
					sx={getStyle(ErmSearchTabValues.Recent)}
				>
					<Tr.Portal path="search-references.latest-substance-tab-title" />
				</Button>
			)}
			{hasDrmPermission && (
				<Button
					data-testid="rm-tab-id"
					variant="contained"
					disableElevation
					onClick={(_) => value !== ErmSearchTabValues.All && onChange?.(_, ErmSearchTabValues.All)}
					sx={getStyle(ErmSearchTabValues.All)}
				>
					<Tr.Portal path="search-references.all-substance-tab-title" />
				</Button>
			)}
		</Tabs>
	);
};
