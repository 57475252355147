import { TypeUtils } from '@utils/Type';
import { AnalysisTargetType } from './analysis-target-type.enum';
import { QualitativeOrQuantitative } from './qualitative-or-quantitative.enum';

export enum AnalysisSelectionType {
	QUALITATIVE = 'Qualitative',
	QUANTITATIVE_NON_TARGETED = 'QuantitativeNonTargeted',
	QUANTITATIVE_TARGETED = 'QuantitativeTargeted',
}

export type TAnalysisSelectionType = {
	measurementType: QualitativeOrQuantitative;
	targetType: AnalysisTargetType | null;
};

export const AnalysisSelectionTypeMap = {
	[AnalysisSelectionType.QUALITATIVE]: {
		measurementType: QualitativeOrQuantitative.QUALITATIVE,
		targetType: null,
	},
	[AnalysisSelectionType.QUANTITATIVE_NON_TARGETED]: {
		measurementType: QualitativeOrQuantitative.QUANTITATIVE,
		targetType: AnalysisTargetType.NON_TARGETED,
	},
	[AnalysisSelectionType.QUANTITATIVE_TARGETED]: {
		measurementType: QualitativeOrQuantitative.QUANTITATIVE,
		targetType: AnalysisTargetType.TARGETED,
	},
};

export const MapToAnalysisSelectionType = {
	[QualitativeOrQuantitative.QUALITATIVE]: {
		[AnalysisTargetType.TARGETED]: AnalysisSelectionType.QUALITATIVE,
		[AnalysisTargetType.NON_TARGETED]: AnalysisSelectionType.QUALITATIVE,
	},
	[QualitativeOrQuantitative.QUANTITATIVE]: {
		[AnalysisTargetType.TARGETED]: AnalysisSelectionType.QUANTITATIVE_TARGETED,
		[AnalysisTargetType.NON_TARGETED]: AnalysisSelectionType.QUANTITATIVE_NON_TARGETED,
	},
};
type TMapFirstKey = QualitativeOrQuantitative.QUALITATIVE | QualitativeOrQuantitative.QUANTITATIVE;
export const getIsQuantitiveNonTargeted = (measurementType?: Maybe<QualitativeOrQuantitative>, targetType?: Maybe<AnalysisTargetType>) => {
	return (
		!!measurementType &&
		!!targetType &&
		TypeUtils.returnValueOfKeys(
			[measurementType as TMapFirstKey, targetType as unknown as TMapFirstKey],
			MapToAnalysisSelectionType,
		) === AnalysisSelectionType.QUANTITATIVE_NON_TARGETED
	);
};
