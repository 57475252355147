/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { GenericCard } from '@components/common';
import { mainTitleSpacer, Tr } from '@utils';
import AddIcon from '@mui/icons-material/Add';
import { PortalPageRoutes } from '@models';
import { DeviceSpecificationInfo } from '../../../portal/Device/DeviceSpecification/DeviceSpecification';
import { IrEditDeviceNameDialog } from '../IrDeviceInfo';
import { AccessoryList } from './AccessoryList';
import { IrAccessoryCalibrationStatus, IrDevice, irDeviceService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { irAccessoryService, notificationService } from '@services';
import { EditAccessoryDialog } from './EditAccessoryDialog';
import { alertService } from '@services';
import { useParams } from 'react-router-dom';
import { HealthCheckUploadDialog } from './HealtCheckUploadDialog';
import { ReactComponent as Edit } from '@material-symbols/svg-600/outlined/edit.svg';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common.slice';

export interface DeviceData {
	deviceName: string;
	manufacturerName: string;
	deviceId: number;
}

const deviceData: DeviceData = {
	deviceName: '',
	manufacturerName: '',
	deviceId: 0,
};

export interface AccessoryDialogData {
	deviceId: number;
	currentName: string;
}

export const GoToDevice = () => {
	const [editNameOpen, setEditNameOpen] = useState(false);
	const [editAccessoryOpen, setEditAccessoryOpen] = useState(false);
	const [healthCheckOpen, setHealthCheckOpen] = useState(false);
	const [accessoryData, setAccessoryData] = useState<AccessoryDialogData>();
	const [irDeviceData, setIrDeviceData] = useState<IrDevice>();
	const [deleteRefresh, setdeleteRefresh] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation('irportal');
	const { state } = useLocation();
	Object.assign(deviceData, state);
	const deviceIdParam = useParams<{ id: string }>();
	const deviceID = parseInt(deviceIdParam.id || '');
	const dispatch = useDispatch();
	const addNewDevice = () => {
		navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_ACCESSORY}`, {
			state: {
				deviceId: irDeviceData?.id,
			},
		});
	};
	const addCalibrate = (id: number, deviceId: number, accessoryName: string) => {
		RxUtils.promisify(
			irAccessoryService.startCalibrate(id),
			(data) => {
				navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${deviceId}`, {
					state: {
						deviceId: data,
						deviceData: irDeviceData,
						accessoryName: accessoryName,
					},
				});
			},
			(err) => {
				notificationService.sendError(err.Message);
			},
		);
	};
	const restoreAccessory = (id: number) => {
		RxUtils.promisify(
			irAccessoryService.getRestoreAccessory(id),
			() => {
				RxUtils.promisify(irDeviceService.getDevice(deviceID), (data: IrDevice) => {
					setIrDeviceData(data);
				});
			},
			(err) => {
				notificationService.sendError(err.Message);
			},
		);
	};

	const deleteCalibrate = (id: number) => {
		RxUtils.promisify(
			irAccessoryService.deleteAccessory(id),
			() => {
				setdeleteRefresh(!deleteRefresh);
			},
			(err) => {
				notificationService.sendError(err.Message);
			},
		);
	};

	const openDeleteDialog = (id: number, name: string): void => {
		const isLastCalibration = irDeviceData?.accessories?.length === 1;
		alertService.send({
			titleText: <Tr.IrPortal path="device-management.archive-accessory" />,
			onConfirm: () => deleteCalibrate(id),
			content: (
				<Typography variant="inherit">
					{isLastCalibration && (
						<>
							<Tr.IrPortal path="device-management.delete-last-calibration-part-1" options={{ calibrationName: name }} />
							<Typography variant="inherit" sx={{ fontWeight: 700, display: 'inline-block' }}>
								{name}
							</Typography>
							<Tr.IrPortal path="device-management.delete-last-calibration-part-2" options={{ calibrationName: name }} />
						</>
					)}
					{!isLastCalibration && (
						<>
							<Tr.IrPortal path="device-management.delete-calibration-confirmation" />
							<Typography variant="inherit" sx={{ fontWeight: 700, display: 'inline-block' }}>
								{name}
							</Typography>
							{'? '}
						</>
					)}
				</Typography>
			),
			confirmTextComponent: <Tr.IrPortal path="device-management.archive-confirmation" />,
		});
	};

	const editAccessory = (id: number, name: string) => {
		setAccessoryData({ deviceId: id, currentName: name });
		setEditAccessoryOpen(true);
	};

	const deleteDevice = () => {
		RxUtils.promisify(
			irDeviceService.deleteDevice(deviceID),
			() => {
				navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}`);
			},
			(err) => {
				notificationService.sendError(err.Message);
			},
		);
	};

	const healthCheck = (id: number, status: IrAccessoryCalibrationStatus) => {
		if (status === IrAccessoryCalibrationStatus.CALIBRATION_CHECK_NEEDED || status === IrAccessoryCalibrationStatus.CALIBRATED) {
			RxUtils.promisify(
				irAccessoryService.startHealthCheck(id),
				(data) => {
					// TO DO: NEVER DISABLE THOSE
					// eslint-disable-next-line no-debugger
					setAccessoryData({ deviceId: parseInt('' + data), currentName: '' });
					setHealthCheckOpen(true);
				},
				(err) => {
					notificationService.sendError(err.Message);
				},
			);
		} else {
			notificationService.sendError(t('device-management.health-check-error'));
		}
	};

	useEffect(() => {
		RxUtils.promisify(irDeviceService.getDevice(deviceID), (data: IrDevice) => {
			setIrDeviceData(data);
		});
	}, [editAccessoryOpen, deleteRefresh, healthCheckOpen, editNameOpen]);

	useEffect(() => {
		if (irDeviceData?.name) {
			dispatch(setBreadcrumb({ name: irDeviceData?.name, ':id': irDeviceData.id }));
		}
	}, [irDeviceData]);

	return (
		<Stack data-testid="device-management-wrapper-id">
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<Typography variant="h1" sx={{ color: 'text.secondary' }}>
					{irDeviceData?.name}
				</Typography>

				<Stack direction="row" alignSelf="flex-end">
					<Button variant="outlined" sx={{ marginRight: '1rem' }} onClick={() => setEditNameOpen(true)}>
						<Edit width={19} height={19} fill="#01884C" style={{ fontSize: '1rem', marginRight: 8 }} />
						<Tr.IrPortal path="device-management.rename-device-title" />
					</Button>

					<Button variant="outlined" onClick={addNewDevice}>
						<AddIcon sx={{ marginRight: 1 }} />
						<Tr.IrPortal path="device-management.add-accessory" />
					</Button>
				</Stack>
			</Stack>

			<Grid data-testid="device-wrapper-id" container columnSpacing={2}>
				<Grid item xs={6} height="70vh">
					<GenericCard sx={{ height: '100%', overflowY: 'scroll' }}>
						<Stack>
							<Stack paddingBottom={1}>
								<Typography variant="h4" sx={{ marginBottom: '1rem', textTransform: 'none' }} color="primary.main">
									<Tr.IrPortal path="device-management.device-accessories" />
								</Typography>
							</Stack>
							<Typography variant="body2" sx={{ marginBottom: '1rem' }}>
								<Tr.IrPortal path="device-management.add-calibration-message" />
							</Typography>
							<Stack sx={{ height: '100%' }}>
								{irDeviceData?.accessories &&
									irDeviceData?.accessories.map((item) => (
										<AccessoryList
											accessoryName={item.accessoryName}
											addCalibrate={addCalibrate}
											restoreAccessory={restoreAccessory}
											deleteCalibrate={openDeleteDialog}
											editAccessory={editAccessory}
											lastCalibration={item.lastCalibrationDate}
											archivedDate={item.archivedDate}
											calibrationCheck={item.nextCalibrationDate}
											calibrationValue={item.calibrationValue}
											healthCheck={healthCheck}
											isError={false}
											error={item.comment}
											statusCalibration={item.accessoryStatus}
											statusHealth={item.healthCheckStatus}
											id={item.accessoryId}
											deviceId={item.deviceId}
											key={item.accessoryId}
										/>
									))}
							</Stack>
						</Stack>
					</GenericCard>
				</Grid>
				<Grid item xs={6} height="70vh">
					<GenericCard sx={{ height: '100%' }}>
						<Typography variant="h4" sx={{ marginBottom: '1.5rem', textTransform: 'none' }} color="primary.main">
							<Tr.IrPortal path="device-management.device-specification" />
						</Typography>

						<DeviceSpecificationInfo title={t('device-management.device-name')} value={irDeviceData?.name || ''} />
						<DeviceSpecificationInfo title={t('device-management.manufacturer')} value={irDeviceData?.manufacturer || ''} />
						{editNameOpen && irDeviceData?.name && (
							<IrEditDeviceNameDialog
								currentName={irDeviceData?.name}
								currentManufacturer={irDeviceData?.manufacturer}
								onClose={(success?: boolean) => (success ? navigate(0) : setEditNameOpen(false))}
								deviceId={irDeviceData.id}
							/>
						)}
						{editAccessoryOpen && accessoryData?.deviceId && (
							<EditAccessoryDialog
								currentName={accessoryData?.currentName}
								onClose={(success?: boolean) => (success ? navigate(0) : setEditAccessoryOpen(false))}
								deviceId={accessoryData.deviceId}
							/>
						)}
						{healthCheckOpen && accessoryData?.deviceId && (
							<HealthCheckUploadDialog
								onClose={(success?: boolean) => (success ? navigate(0) : setHealthCheckOpen(false))}
								deviceId={accessoryData.deviceId}
							/>
						)}
						{irDeviceData && irDeviceData?.accessories && irDeviceData?.accessories?.length > 0 && (
							<Button
								disabled={
									irDeviceData?.accessories &&
									!irDeviceData?.accessories.filter((accessory) => accessory.accessoryStatus !== 'Archived').length
								}
								variant="outlined"
								sx={{ width: '12rem' }}
								onClick={() => deleteDevice()}
							>
								<Tr.IrPortal path="device-management.deactivate-device" />
							</Button>
						)}
					</GenericCard>
				</Grid>
			</Grid>
		</Stack>
	);
};
