import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileService } from '@services';
import { findMax, findMin, RxUtils, SpectraColorCodes, Tr } from '@utils';
import { useNmrParser } from '@hooks/useNmrParser';
import { Chart } from '@components/common';
import { Stack } from '@mui/material';

interface IOverlaySpectrum {
	subtitleVisibility?: boolean;
	sampleResultSpectrumUrl: string;
	ermSpectrumUrl: string;
	referenceMaterialId?: number;
	isTheoretical?: boolean;
}

export const OverlaySpectrum: FC<IOverlaySpectrum> = ({
	subtitleVisibility,
	ermSpectrumUrl,
	sampleResultSpectrumUrl,
	referenceMaterialId,
	isTheoretical,
}) => {
	const [sampleData, setSampleData] = useState<string>();
	const [ermData, setErmData] = useState<string>();
	const { t } = useTranslation('portal');
	const chartClassName = `c${referenceMaterialId}-overlay-chart`;

	const { xData: sampleXData, yData: sampleYData } = useNmrParser(sampleData || undefined);
	const { xData: ermXData, yData: ermYData } = useNmrParser(ermData || undefined);

	useEffect(() => {
		sampleResultSpectrumUrl &&
			RxUtils.promisify(fileService.getFileFromUrl(sampleResultSpectrumUrl), (data) => {
				setSampleData(data);
			});

		ermSpectrumUrl &&
			RxUtils.promisify(fileService.getFileFromUrl(ermSpectrumUrl), (data) => {
				setErmData(data);
			});
	}, [sampleResultSpectrumUrl, ermSpectrumUrl]);

	const sampleMaxVal = sampleYData ? findMax(sampleYData) : 0;
	const sampleMinVal = sampleYData ? findMin(sampleYData) : 0;
	const sampleDiffMinMax = sampleMaxVal - sampleMinVal;

	const ermMaxVal = ermYData ? findMax(ermYData) : 0;
	const ermMinVal = ermYData ? findMin(ermYData) : 0;
	const ermDiffMinMax = ermMaxVal - ermMinVal;
	return (
		<Stack sx={{ border: '1px solid', borderColor: isTheoretical ? 'info.700' : 'grey.200', borderRadius: '8px' }}>
			<Chart
				subTitle={<Tr.Portal path="analysis-result.intensity" />}
				subtitleVisibility={!!subtitleVisibility}
				className={chartClassName}
				enableZoom={false}
				data={[
					{
						y: sampleYData?.map((item: number) => (item - sampleMinVal) / sampleDiffMinMax),
						x: sampleXData,
						type: 'scatter',
						mode: 'lines',
						marker: { color: SpectraColorCodes.RICH_GREEN },
						direction: 'counterclockwise',
						name: t('analysis-result.input-spectrum'),
					},
					{
						y: ermYData?.map((item: number) => (item - ermMinVal) / ermDiffMinMax),
						x: ermXData,
						type: 'scatter',
						mode: 'lines',
						marker: { color: SpectraColorCodes.VIBRANT_MAGENTA },
						direction: 'counterclockwise',
						name: t('analysis-result.predicted-spectrum'),
					},
				]}
				useResizeHandler
				style={{ width: '100%', height: '100%' }}
				layout={{
					margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
					legend: {
						y: 5,
						xanchor: 'center',
						x: 0.5,
						orientation: 'h',
						bgcolor: 'transparent',
						borderwidth: 1,
						bordercolor: '#E1E1EA',
						font: { color: '#0F1A2E', size: 12 },
					},
					autosize: true,
					xaxis: {
						range: [10, 0],
						showdividers: false,
						showline: true,
						zeroline: false,
						gridcolor: '#A9A9BA',
						griddash: 'dot',
					},
					yaxis: {
						showline: true,
					},
				}}
			/>
		</Stack>
	);
};
