import { Badge, IconButton } from '@mui/material';
import { FC, useRef, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { NotificationCenter } from '..';
import { useService } from '@hooks/useService';
import { notificationService } from '@services/core';
import { useSelector } from 'react-redux';
import { useNotificationSelector } from '@store/slices/notification.slice';

const NotificationIcon: FC = () => <NotificationsNoneIcon sx={{ color: 'white', width: '1.3rem', height: '1.3rem' }} />;

export const NotificationBell: FC = () => {
	const bellRef = useRef<HTMLButtonElement>(null);
	const [open, setOpen] = useState(false);
	const { trigger } = useService(() => notificationService.getNotifications(1, 1, false, false), []); // This is for loading notification initially
	const { unreadNotificationCount } = useSelector(useNotificationSelector);

	return (
		<>
			<IconButton data-testid="notification-bell-id" ref={bellRef} onClick={() => setOpen(!open)} sx={{ marginRight: '12px' }}>
				{unreadNotificationCount ? (
					<Badge color="error" variant="standard" badgeContent={unreadNotificationCount}>
						<NotificationIcon />
					</Badge>
				) : (
					<NotificationIcon />
				)}
			</IconButton>
			<NotificationCenter
				open={open}
				anchor={bellRef.current as HTMLElement}
				setOpen={() => setOpen(!open)}
				// This is a bad way to do it.
				// TODO: Find a better way to rerender when notifications are marked read!
				triggerRerender={() => trigger()}
			/>
		</>
	);
};
