import { useHasPermissions } from '@hooks/useHasPermissions';
import { useRestrictedComponent } from '@hooks/useRestrictedComponent';
import { PatchType } from '@models/request-response';
import { PropertyOf } from '@models/request-response/http-patch';
import { LabPermission, UserStoreModel } from '@models/user';
import { Button, Stack, Typography } from '@mui/material';
import { Lab, isTransmissionSpectraEnabled } from '@services/core';
import { labService } from '@services/core/lab/lab.service';
import { setBreadcrumb, setRefreshUser, setUser, userSelector } from '@store/slices/common.slice';
import { setIrTransmissionSpectra } from '@store/ir-slices/ir.slice';
import { DataTestId } from '@utils/DataTestId';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { DeactivationConfirmPopUp } from '../DeactivationConfirmation';
import { InfoMessageBox } from '../InfoMessageBox';
import { ReactivateDialog, RenameLabDialog } from '../LabDialogs';
import { LabInformationDetail } from './LabInformationDetail';
import { LabUsersTable } from './LabUsersTable';

export const CommonLabDetails: FC = () => {
	const loc = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation('common');

	const user = useSelector(userSelector);
	const [currentLab, setCurrentLab] = useState<Lab>();
	const [isDeactivateModalOpened, setIsDeactiveModalOpened] = useState(false);
	const [isRenameModalOpened, setIsRenameModalOpened] = useState(false);
	const [isReactivateModalOpened, setIsReactivateModalOpened] = useState(false);

	const [isUserListChanged, setIsUserListChanged] = useState<boolean>(true);
	const [hasDeactivatedUsers, setHasDeactivatedUsers] = useState<boolean>(false);
	const { orgId, id } = useParams();

	const refreshUserCb = (lab: Lab) => {
		const labsWithoutChanged = user?.laboratories?.filter((l) => l.name !== lab.name) ?? [];
		const userExistsInLab = user?.laboratories?.find((l) => l.name === lab.name);
		if (userExistsInLab) {
			const labToChange: Lab = {
				...lab,
				id: userExistsInLab.id,
			};
			const currentSelectedLab = user?.laboratories?.find((l) => String(l.id) === user.currentLabId);

			const newSelectedLab =
				(!currentSelectedLab?.isActive || !currentSelectedLab) && labToChange.isActive
					? String(labToChange.id)
					: user?.currentLabId;

			dispatch(
				setUser({
					...user,
					currentLabId: newSelectedLab ?? user?.currentLabId,
					laboratories: [...labsWithoutChanged, labToChange],
				} as UserStoreModel),
			);
			dispatch(setRefreshUser());
		}
	};

	const handleLabDataChanged = (cb?: (lab: Lab) => void) => {
		if (id && parseInt(id)) {
			const intId = parseInt(id);
			RxUtils.promisify(labService.get(+intId), (value) => {
				setCurrentLab({ ...(value as Lab), id: intId });
				cb?.(value);
			});
		}
	};

	useEffect(() => {
		handleLabDataChanged();
	}, [loc, isUserListChanged]);

	useEffect(() => {
		if (currentLab) {
			dispatch(
				setBreadcrumb({
					orgName: currentLab?.organizationName ?? '',
					name: currentLab.name,
					':id': orgId ?? user?.organizationId ?? '',
				}),
			);
		}
	}, [currentLab?.name]);

	useEffect(() => {
		if (currentLab) {
			const spectraSetting = isTransmissionSpectraEnabled(currentLab);
			dispatch(setIrTransmissionSpectra(spectraSetting));
		}
	}, [currentLab?.settings]);

	const isAbleToDeactivateLab = useHasPermissions(LabPermission.DEACTIVATE);
	const isAbleToRenameLab = useHasPermissions(LabPermission.EDIT);

	return (
		<Stack>
			{id && currentLab?.name && isRenameModalOpened && (
				<RenameLabDialog
					id={parseInt(id)}
					initialName={currentLab?.name}
					isOpen={isRenameModalOpened}
					onCancel={() => setIsRenameModalOpened(false)}
					onSave={() => {
						handleLabDataChanged();
						setIsRenameModalOpened(false);
					}}
				/>
			)}
			{id && (
				<ReactivateDialog
					id={parseInt(id)}
					labName={currentLab?.name ?? ''}
					isOpen={isReactivateModalOpened}
					onCancel={() => setIsReactivateModalOpened(false)}
					onSave={() => {
						handleLabDataChanged(refreshUserCb);
						setIsReactivateModalOpened(false);
					}}
				/>
			)}
			{currentLab && (
				<DeactivationConfirmPopUp
					title={<Tr.Common path={'lab-details.confirmation'} />}
					subTitle={
						<>
							<Stack>
								<Typography variant="subtitle1">
									{t('lab-details.deactivate-title', { labName: currentLab.name })}
								</Typography>
							</Stack>
							<Stack>
								<Typography variant="pg-m">{t('lab-details.deactivate-message', { labName: currentLab.name })}</Typography>

								<Typography variant="pg-m">{t('lab-details.loss-access', { labName: currentLab.name })}</Typography>
							</Stack>
							{hasDeactivatedUsers ? (
								<Stack
									sx={{
										direction: 'column',
										marginTop: '1rem',
										justifyContent: 'center',
										width: 1,
										padding: 2,
										borderRadius: '12px',
										backgroundColor: 'secondary.50',
									}}
								>
									<InfoMessageBox message={t('lab-details.deactivate-lab-user-info')} />
								</Stack>
							) : null}
						</>
					}
					message={t('lab-details.deactivate-comment')}
					visible={isDeactivateModalOpened}
					onConfirm={() => {
						if (id) {
							RxUtils.promisify(
								labService.changeLaboratory(+id, [
									{
										op: PatchType.REPLACE,
										path: PropertyOf<Lab>('isActive'),
										value: false,
									},
								]),
								() => {
									handleLabDataChanged(refreshUserCb);
								},
							);
							setIsDeactiveModalOpened(false);
						}
					}}
					onCancel={() => setIsDeactiveModalOpened(false)}
				/>
			)}
			<Stack direction="row" justifyContent="space-between" data-testid="lab-details-wrapper-id">
				<Typography
					variant="h1"
					color={currentLab?.isActive ? 'primary.main' : 'grey.800'}
					sx={{
						marginBottom: 4,
					}}
				>
					{currentLab?.name}
				</Typography>
				<Stack direction="row">
					{currentLab && currentLab.isActive !== undefined && isAbleToDeactivateLab ? (
						currentLab?.isActive ? (
							<Button
								variant="text"
								color="error"
								data-testid={DataTestId.getStaticTestId('deactivate-button-test-id')}
								onClick={() => {
									setIsDeactiveModalOpened(true);
								}}
								disableElevation
							>
								<Tr.Common path="lab-details.deactivate" />
							</Button>
						) : (
							<Button
								variant="contained"
								data-testid={DataTestId.getStaticTestId('reactivate-button-test-id')}
								onClick={() => setIsReactivateModalOpened(true)}
								disableElevation
							>
								<Tr.Common path="lab-details.reactivate" />
							</Button>
						)
					) : (
						<></>
					)}

					{currentLab?.isActive && isAbleToRenameLab && (
						<Button
							data-testid="rename-lab-test-id"
							variant="contained"
							onClick={() => setIsRenameModalOpened(true)}
							sx={{ marginLeft: 2 }}
						>
							<Tr.Common path="lab-details.rename" />
						</Button>
					)}
				</Stack>
			</Stack>
			<Stack sx={{ marginBottom: 8 }}>
				<LabInformationDetail labData={currentLab} setLabData={setCurrentLab} />
			</Stack>
			<Stack>
				<Stack direction="row" justifyContent="space-between">
					<Typography
						variant="h2"
						color={currentLab?.isActive ? 'primary.main' : 'grey.800'}
						sx={{
							marginBottom: 2,
						}}
					>
						<Tr.Common path="lab-details.lab-users" />
					</Typography>
				</Stack>
				{useRestrictedComponent(
					LabPermission.LIST_USER,
					<LabUsersTable
						hasDeactivatedUsers={(value) => setHasDeactivatedUsers(value)}
						triggerRequest={isUserListChanged}
						refreshDetails={() => setIsUserListChanged((prev) => !prev)}
					/>,
				)}
			</Stack>
		</Stack>
	);
};
