import { FC } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common.slice';
import { Box, Stack } from '@mui/material';
import { LogoMLetter } from '@components/svg';
import { UserAvatar } from '../UserAvatar';
import { AvatarIcon } from '@components/common/UserAvatar/AvatarIcon';
import { NotificationBell } from '../NotificationCenter/NotificationBell/NotificationBell';
import { useLocation } from 'react-router';
import { LabDropdown } from '@components/portal/LabDropdown';

export const Topbar: FC = () => {
	const user = useSelector(userSelector);
	const location = useLocation();

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ backgroundColor: 'primary.main', height: 50 }}
			data-testid="top-bar-wrapper"
		>
			<Box sx={{ paddingX: 2 }}>
				<LogoMLetter sx={{ width: 32 }} />
			</Box>
			<Stack direction="row" alignItems="center">
				<NotificationBell />
				{location?.pathname?.includes('portal') && !location.pathname.includes('portal/manage-organization') ? (
					<LabDropdown />
				) : null}

				<UserAvatar username={user?.name && user?.surname ? `${user.name} ${user.surname}` : user?.email}>
					<AvatarIcon user={user} />
				</UserAvatar>
			</Stack>
		</Stack>
	);
};
