import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIrFilterSearchParams, useService, getIrParams } from '@hooks';
import { PortalPageRoutes, SortOrder } from '@models';
import { irAccessoryService, irDeviceService } from '@services';
import { archivedDeviceColumns } from '@components/irportal';
import { DataTableBackend } from '@components/common/DataTableBackend';
import { RxUtils } from '@utils/Rx';
import { useNavigate } from 'react-router-dom';

export const ArchivedDeviceTable: FC = () => {
	const { filtering, setFiltering, searchParams } = useIrFilterSearchParams('archivedDate', SortOrder.DESC, 'archived');
	const [typedString, setTypedString] = useState('');
	const navigate = useNavigate();
	const { t: tPortal, ready } = useTranslation('irportal');
	const translate = (path: string) => (ready ? tPortal(path) : '');

	const { data: deviceData, loading } = useService(() => {
		setTypedString(getIrParams(searchParams).getQuery());
		return irDeviceService.getAllDevices(searchParams);
	}, [searchParams]);

	const handleRestoreClick = (value: number) => {
		RxUtils.promisify(irAccessoryService.getRestoreAccessory(value), (data) => {
			navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${data.deviceId}`);
		});
	};

	return (
		<>
			<DataTableBackend
				data={deviceData ? deviceData.data : ([] as any)}
				columns={archivedDeviceColumns(tPortal, handleRestoreClick)}
				sorting={{
					sortBy: getIrParams(searchParams).getSortBy(),
					sortOrder: getIrParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getIrParams(searchParams).getPageIndex(), totalPages: deviceData?.totalPages ?? 1 }}
				searchText={typedString}
				searchPlaceholder={translate('device-management.irdevice-search-placeholder')}
				onSearchTextChange={setTypedString}
				sx={{ visibility: loading ? 'hidden' : 'visible', marginX: 2 }}
				headerCellProps={{ sx: { minWidth: 60 } }}
				searchFieldProps={{ sx: { maxWidth: 600, width: 600 } }}
				onFilter={setFiltering}
				filteringSelections={filtering}
			/>
		</>
	);
};
