import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RankBasedMixtureData, SampleSpectrumInfo } from '@services';
import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { Tr, getTransMissionData, SpectraColorCodes, mixtureColorCodes } from '@utils';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/ir-slices/ir.slice';
import { useDispatch, useSelector } from 'react-redux';

interface IStackedSpectrumProps {
	createdReport?: boolean;
	subtitleVisibility?: boolean;
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
}

export const IrStackedSpectrum: FC<IStackedSpectrumProps> = ({
	subtitleVisibility,
	createdReport = false,
	rankedRMData,
	userSpectrumInfo,
}) => {
	const [chartDataList, setChartDataList] = useState<any>([]);
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};

	const { t } = useTranslation('irportal');

	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			const chartDataAry: any = [];
			const userChartData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.input-spectrum'),
				yaxis: 'y',
			};
			const combinedChartData = {
				y: displaySettingsToggle
					? getTransMissionData([...rankedRMData['combined_spectrum_y']])
					: rankedRMData['combined_spectrum_y'],
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: 'Combined Spectrum',
				yaxis: 'y' + (2 + rankedRMData?.mixtures?.length),
			};
			if (rankedRMData?.mixtures) {
				for (let i = 0; i < rankedRMData?.mixtures?.length; i++) {
					const mixture = rankedRMData?.mixtures[`${i}`];
					const mixtureChartData = {
						y: displaySettingsToggle ? getTransMissionData([...mixture.scaled_spectrum_y]) : mixture.scaled_spectrum_y,
						x: rankedRMData.spectrum_x,
						type: 'scatter',
						mode: 'lines',
						marker: { color: mixtureColorCodes[`${i}`] },
						direction: 'counterclockwise',
						name: mixture.substanceName,
						yaxis: 'y' + (i + 2),
					};
					chartDataAry.push(mixtureChartData);
				}
			}
			chartDataAry.push(userChartData);
			if (rankedRMData?.mixtures?.length > 1) {
				chartDataAry.push(combinedChartData);
			}
			setChartDataList(chartDataAry);
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle]);

	//TODO change the generation of the graphs to dynamic y axis rather hardcoded.
	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }}>
			{chartDataList && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={`c-seperated-${rankedRMData.rank}-chart`}
					enableZoom={false}
					hideZoomIcon={createdReport}
					data={chartDataList}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					displaySettingsToggle={handleDisplaySettings}
					layout={{
						margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
						hovermode: 'x unified',
						legend: {
							traceorder: 'reversed',
							yanchor: 'bottom',
							y: 1.1,
							xanchor: 'center',
							x: 0.4,
							orientation: 'h',
							bgcolor: 'transparent',
							font: { color: SpectraColorCodes.RICH_BLUE, size: 12 },
						},
						autosize: true,
						yaxis: {
							domain: chartDataList?.length > 2 ? [0.1, 0.2] : [0, 0.5],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis2: {
							domain: chartDataList?.length > 2 ? [0.2, 0.3] : [0.5, 1],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis3: {
							domain: [0.3, 0.4],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis4: {
							domain: [0.4, 0.5],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis5: {
							domain: [0.5, 0.6],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis6: {
							domain: [0.6, 0.7],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis7: {
							domain: [0.7, 0.8],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis8: {
							domain: [0.8, 0.9],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						yaxis9: {
							domain: [0.9, 1],
							showline: true,
							zeroline: false,
							showticklabels: false,
							griddash: 'dashdot',
						},
						xaxis: {
							showdividers: false,
							showline: true,
							zeroline: false,
							range: userSpectrumInfo.sample_x_range,
							gridcolor: SpectraColorCodes.GRAY,
							griddash: 'dot',
						},

						shapes: [],
					}}
				/>
			)}
		</Stack>
	);
};
