import { ExtendedColumn } from '@models/table';
import { Stack } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useMemo } from 'react';
import { DateUtils } from '@utils/Type';
import { Body1 } from '../TableComponents';
import { IrDrmWave, IrWaveReportType, irDrmWaveService } from '@services/ir-drm-pipeline';
import { IrDrmWaveReportStage } from './IrWaveReportStatus.enum';
import { IrWaveStatusColorMap, IrWaveStatusMap } from './IrWaveReportHelper';
import { ReportStatus } from './ReportStatus';
import { RxUtils } from '@utils/Rx';
import { downloadFileFromLink } from '@utils/Download';
import { DataTestId } from '@utils/DataTestId';
import { FileDownloadButton, FileDownloadIcon } from '@components/common';
import { DownloadableFileExtension } from '@models/file';
type TDownloadButton = {
	type?: DownloadableFileExtension;
	wave: IrDrmWave;
	reportType: IrWaveReportType;
	uploadStage?: boolean;
};

const DownloadButton = ({ type = DownloadableFileExtension.CSV, wave, reportType, uploadStage = false }: TDownloadButton) => {
	const reportStatus = IrWaveStatusMap[wave.status];
	const buttonColor =
		uploadStage && reportStatus.stage === IrDrmWaveReportStage.RELEASED
			? IrWaveStatusColorMap[reportStatus.stage]
			: IrWaveStatusColorMap[reportStatus.status];
	const handleClick = () => {
		wave.id &&
			RxUtils.promisify(irDrmWaveService.getReportLinkById(wave.id, reportType), (res) => {
				downloadFileFromLink(res.uri, res.fileName);
			});
	};
	return <FileDownloadButton sx={{ color: buttonColor }} onClick={handleClick} extension={type} />;
};

export const IrReportColumns: () => ExtendedColumn<IrDrmWave>[] = () => {
	return useMemo<ExtendedColumn<IrDrmWave>[]>(
		() => [
			{
				accessor: 'status',
				disableSortBy: true,
				Header: (
					<Stack marginLeft={1}>
						<Tr.Common path="status" />
					</Stack>
				),
				Cell: ({ value }) => (
					<Stack marginLeft={1}>
						<ReportStatus status={value} />
					</Stack>
				),
			},
			{
				accessor: 'name',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.ir-wave" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value || '-'}</Body1>,
			},
			{
				accessor: 'startDate',
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.upload-date" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy', '-') : '-'}</Body1>,
			},
			{
				accessor: 'uploader',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.uploader" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ?? '-'}</Body1>,
			},
			{
				accessor: 'releasedBy',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.released-by" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ? value : '-'}</Body1>,
			},
			{
				accessor: 'releaseDate',
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.release-date" />
					</Stack>
				),
				Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy', '-') : '-'}</Body1>,
			},
			{
				accessor: 'id',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.upload-report" />
					</Stack>
				),
				Cell: ({ row }) => (
					<Stack
						direction="row"
						alignItems="center"
						spacing={0.75}
						data-testid={DataTestId.getStaticTestId(`upload-files-${row.index}-id`)}
					>
						<FileDownloadIcon />
						<DownloadButton wave={row.original} reportType={IrWaveReportType.UPLOAD_CSV} uploadStage />
						<DownloadButton
							wave={row.original}
							reportType={IrWaveReportType.UPLOAD_PDF}
							type={DownloadableFileExtension.PDF}
							uploadStage
						/>
					</Stack>
				),
			},
			{
				accessor: 'orderCount',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Admin path="drm-pipeline.reports-page.release-report" />
					</Stack>
				),
				Cell: ({ row }) => (
					<>
						{IrWaveStatusMap[row.values.status].stage === IrDrmWaveReportStage.RELEASED ? (
							<Stack direction="row" alignItems="center" spacing={0.75}>
								<FileDownloadIcon />
								<DownloadButton wave={row.original} reportType={IrWaveReportType.RELEASE_CSV} />
								<DownloadButton
									wave={row.original}
									reportType={IrWaveReportType.RELEASE_PDF}
									type={DownloadableFileExtension.PDF}
								/>
							</Stack>
						) : (
							'-'
						)}
					</>
				),
			},
		],
		[],
	);
};
