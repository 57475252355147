import qs from 'qs';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { catchError, finalize, from, map, of, throwError } from 'rxjs';
import { config } from '@config';
import { DataTestId, TypeUtils } from '@utils';
import { logger, notificationService, NotificationType, loadingSpinnerOverlayService } from '@services';
import { getSpecialError, HttpError } from './http-error.class';
import { authService } from '../auth';
import { getStore } from '@store/index';

export type HttpHeaders = { [header: string]: string };
export type HttpParams = { [param: string]: any } | URLSearchParams;
export interface HttpRequestGenericOptions {
	params?: HttpParams;
	headers?: HttpHeaders;
	body?: any;
	disableLoader?: boolean;
	noAuth?: boolean;
	responseType?: AxiosRequestConfig['responseType'];
	errorHandler?: 'notification' | 'silent' | 'unhandle';
}

class HttpService {
	private store = getStore().store;

	private setupInterceptors() {
		axios.interceptors.request.use(async (axiosConfig) => {
			const targetUrl = [axiosConfig.baseURL, axiosConfig.url].filter(Boolean).join('/');
			if (axiosConfig.withCredentials) {
				if (targetUrl.includes(config.apiUrl)) {
					const token = await authService.getIdToken();
					if (token && axiosConfig.headers) axiosConfig.headers.Authorization = `Bearer ${token}`;
				}
				axiosConfig.withCredentials = false;
			}
			if (this.store.getState().Common.user?.organizationId && axiosConfig.headers && !DataTestId.isInTestEnvironment()) {
				const currentLabId = this.store.getState().Common.user?.currentLabId;
				axiosConfig.headers.LaboratoryId = currentLabId
					? currentLabId
					: String(this.store.getState().Common?.user.laboratories?.[0]?.id);
			}

			return Promise.resolve(axiosConfig);
		});
		axios.defaults.validateStatus = (status) => `${status}`.startsWith('2');

		axios.interceptors.response.use(
			async (response) => Promise.resolve(response),
			async (error) => Promise.reject(error),
		);
	}

	constructor() {
		this.setupInterceptors();
	}

	public renewInterceptors() {
		this.setupInterceptors();
	}

	public get<T = any>(baseURL: string, path?: string, opts: HttpRequestGenericOptions = {}) {
		return this.sendRequest<T>('GET', baseURL, path, opts);
	}

	public post<T = any>(baseURL: string, path?: string, opts: HttpRequestGenericOptions = {}) {
		return this.sendRequest<T>('POST', baseURL, path, opts);
	}

	public put<T = any>(baseURL: string, path?: string, opts: HttpRequestGenericOptions = {}) {
		return this.sendRequest<T>('PUT', baseURL, path, opts);
	}

	public patch<T = any>(baseURL: string, path?: string, opts: HttpRequestGenericOptions = {}) {
		return this.sendRequest<T>('PATCH', baseURL, path, opts);
	}

	public delete<T = any>(baseURL: string, path?: string, opts: HttpRequestGenericOptions = {}) {
		return this.sendRequest<T>('DELETE', baseURL, path, opts);
	}

	public sendRequest<T = any>(
		method: Method,
		baseURL: string,
		path = '',
		{ headers, params, body, disableLoader, noAuth = false, responseType, errorHandler = 'unhandle' }: HttpRequestGenericOptions = {},
	) {
		!disableLoader && loadingSpinnerOverlayService.increment();

		return from(
			axios.request<T>({
				method,
				baseURL,
				url: path,
				data: body,
				params,
				headers,
				responseType,
				withCredentials: !noAuth,
				paramsSerializer: (ps: HttpParams) => {
					if (ps instanceof URLSearchParams) return ps.toString();
					return qs.stringify(ps, { arrayFormat: 'repeat' });
				},
			}),
		).pipe(
			map((response) => response?.data),
			catchError((error: AxiosError | Error) => {
				if (axios.isAxiosError(error) && error.response) {
					const response = error.response.data;
					return throwError(() =>
						TypeUtils.transform(HttpError, {
							ErrorCode: TypeUtils.isPositiveInteger(response?.ErrorCode) ? +response.ErrorCode : response?.ErrorCode,
						}),
					);
				}
				return throwError(() => error);
			}),
			catchError((err: AxiosError | Error | HttpError) => {
				if (errorHandler === 'notification') {
					const specialError = getSpecialError(err);
					notificationService.send({
						type: NotificationType.ERROR,
						message: specialError ? specialError.message : err.message,
						messageTranslationOptions:
							err instanceof HttpError ? err.messageTranslationOptions : specialError ? specialError.language : undefined,
					});
					return throwError(() => err);
				} else if (errorHandler === 'silent') {
					logger.error(err);
					return of();
				} else {
					return throwError(() => err);
				}
			}),
			finalize(() => !disableLoader && loadingSpinnerOverlayService.decrement()),
		);
	}
}

export const httpService = new HttpService();
