import { concatMap, map, tap } from 'rxjs';

import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { IUploadedFile, PagedResult, PatchType } from '@models';
import { IrSubstance } from '../ir-drm/ir-substance.class';
import { irConfig } from '../irConfig';
import { IrAnalysisResult } from './ir-analysis-result.class';
import { IrAnalysis } from './ir-analysis.class';
import { IAnalysisReportImages } from '@services/nmr';
import { IRAnalysisFile, IRAnalysisPagedRequest, IRAnalysisReviewLevel, IRAnalysisReview, IrAnalysisAddRmRequest } from '@services/ir';

class IrAnalysisService {
	private getUrl() {
		return `${irConfig.url}/analysis`;
	}

	get(id: number) {
		return httpService
			.get<IrAnalysis>(this.getUrl(), `${id}`, {
				errorHandler: 'notification',
				headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(IrAnalysis, result)),
			);
	}
	getFiles(analysisId: number) {
		return httpService.get<IRAnalysisFile[]>(this.getUrl(), `${analysisId}/files`, { errorHandler: 'silent' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getFiles.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transformFromExist(new Array<IRAnalysisFile>(), result)),
		);
	}

	downloadFile(analysisId: number, fileId: number) {
		return httpService.get<string>(this.getUrl(), `${analysisId}/files/${fileId}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.downloadFile.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}
	create(analysis?: Partial<IrAnalysis>) {
		return httpService.post<IrAnalysis>(this.getUrl(), '', { body: analysis, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrAnalysis, result)),
		);
	}

	update(analysis: Partial<IrAnalysis>, disableLoader = false) {
		return httpService
			.put<IrAnalysis>(this.getUrl(), '', { body: analysis, disableLoader, errorHandler: 'notification' }) // it might be silent because of auto-saves
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.update.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(IrAnalysis, result)),
			);
	}

	getResult(id: number) {
		return httpService.get<IrAnalysisResult>(this.getUrl(), `${id}/results`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getResult.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(IrAnalysisResult, result)),
		);
	}

	getAnalysisRMs(analysisId: number) {
		return httpService
			.get<IrSubstance[]>(this.getUrl(), `${analysisId}/referenceMaterials`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.getAnalysisRMs.name}]`, result)));
	}

	addRMtoAnalysis(analysisId: number, data: IrAnalysisAddRmRequest) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/referenceMaterials`, { errorHandler: 'notification', body: data })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.addRMtoAnalysis.name}]`, result)));
	}
	removeRMfromAnalysis(analysisId: number, referenceMaterialId: number) {
		return httpService
			.delete<unknown>(this.getUrl(), `${analysisId}/referenceMaterials/${referenceMaterialId}`, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.addRMtoAnalysis.name}]`, result)));
	}

	loadDRMFromFavorites(analysisId: number, favoriteId: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${analysisId}/favoriteSubstance/${favoriteId}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result: any) => logger.debug(`[${this.constructor.name}.${this.loadDRMFromFavorites.name}]`, result)));
	}

	sendAnalysisImage(analysisId: number, images: IAnalysisReportImages) {
		return httpService
			.post<string>(this.getUrl(), `${analysisId}/report`, {
				body: images,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.sendAnalysisImage.name}]`, result)));
	}

	getAll({ completed, pageIndex, pageSize, query, sortBy, sortOrder, filteringData }: Partial<IRAnalysisPagedRequest>) {
		const params = new URLSearchParams();
		params.append('completed', `${completed ?? ''}`);
		params.append('pageIndex', `${pageIndex || ''}`);
		params.append('pageSize', `${pageSize || ''}`);
		params.append('query', `${query || ''}`);
		params.append('sortBy', `${sortBy || ''}`);
		params.append('sortOrder', `${sortOrder || ''}`);

		filteringData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] && params.append(entry[0], `${entry[1]}`);
		});

		return this.getAllWithSearchParams(params);
	}
	getAllWithSearchParams(urlParams: URLSearchParams) {
		return httpService
			.get<PagedResult<IrAnalysis[]>>(this.getUrl(), ``, {
				params: urlParams,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<IrAnalysis>(IrAnalysis), result)),
			);
	}

	getSpectrumData(id: number) {
		return httpService.get(this.getUrl(), `${id}/spectrumdata`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getResult.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => result),
		);
	}

	runAnalysis(id: number, disableLoader = false) {
		return httpService.get(this.getUrl(), `${id}/runanalysis`, { disableLoader, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getResult.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => result),
		);
	}

	uploadFile(analysisId: number, file: IUploadedFile) {
		const formData = new FormData();
		formData.append('data', file.file);
		return httpService
			.post<{ id: number; uri: string }>(this.getUrl(), `${analysisId}/files`, {
				body: formData,
				disableLoader: true,
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.uploadFile.name}]`, result)));
	}

	getReview(id: number, reviewLevel: IRAnalysisReviewLevel) {
		return httpService
			.get<IRAnalysisReview>(this.getUrl(), `${id}/review`, {
				params: { reviewLevel },
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getReview.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(IRAnalysisReview, result)),
			);
	}

	sendReview(id: number, decision: boolean, comment?: string, rank?: number) {
		return httpService
			.post<unknown>(this.getUrl(), `${id}/review`, {
				body: {
					decision: decision,
					comment: comment,
					rank,
				},
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.sendReview.name}]`, result)));
	}
	deleteAnalysis(id: number) {
		return httpService
			.delete(this.getUrl(), `${id}`)
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.deleteAnalysis.name}]`, result)));
	}

	comment(id: number, comment: string) {
		return httpService.patch(this.getUrl(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/Comment',
					value: comment,
				},
			],
			disableLoader: true,
			errorHandler: 'notification',
		});
	}
}

export const irAnalysisService = new IrAnalysisService();
