import { FC } from 'react';
import { DataTestId, Tr } from '@utils';
import { Card, Typography } from '@mui/material';
import { AnalysisTypeIndicator } from '../AnalysisTypeIndicator';
import { AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';

interface IAlgorithmSummary {
	measurementType: QualitativeOrQuantitative;
	targetType: string;
}

export const AlgorithmSummary: FC<IAlgorithmSummary> = ({ measurementType, targetType }) => (
	<Card
		variant="elevation"
		id="algorithm-summary-id"
		sx={{ padding: '2rem 1.5rem 2.5rem' }}
		data-testid={DataTestId.getStaticTestId('algorithm-summary-test-id')}
	>
		<Typography variant="h3" sx={{ color: 'primary.main' }} marginBottom={2}>
			<Tr.Portal path="analysis-result.algorithm-summary" />
		</Typography>
		<Typography variant="pg-m">
			{measurementType === QualitativeOrQuantitative.QUALITATIVE ? (
				<>
					<Tr.Portal path="analysis-result.qualitative-algorithm-summary-content-first" />
					<b style={{ display: 'inline-block' }}>
						<AnalysisTypeIndicator
							measurementType={measurementType}
							targetType={targetType}
							sx={{ display: 'inline-block', color: 'primary.text', fontWeight: 'bold' }}
						/>
					</b>
					<Tr.Portal path="analysis-result.qualitative-algorithm-summary-content-next" />
				</>
			) : (
				<>
					<Tr.Portal path="analysis-result.quantitative-algorithm-summary-content-first" />
					<b style={{ display: 'inline-block' }}>
						<AnalysisTypeIndicator
							measurementType={measurementType}
							targetType={targetType}
							variant="pg-m"
							sx={{ display: 'inline', color: 'primary.text', fontWeight: 'bold' }}
						/>
					</b>
					{targetType === AnalysisTargetType.NON_TARGETED ? (
						<Tr.Portal path="analysis-result.quantitative-non-targeted-algorithm-summary-content-next" />
					) : (
						<Tr.Portal path="analysis-result.quantitative-algorithm-summary-content-next" />
					)}
				</>
			)}
		</Typography>
	</Card>
);
