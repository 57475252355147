import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Stack, StackProps, Typography, circularProgressClasses } from '@mui/material';
import { NmrAnalysisStage, getNMRAnalysisStageIcon, getNMRAnalysisStageIndex, getNMRStageMessage, getNMRStageColor } from '@services';

interface IAnalysisCircularProgressProps extends StackProps {
	analysisStage: NmrAnalysisStage;
}
const STEP_COUNT = 6;

export const AnalysisCircularProgress: FC<IAnalysisCircularProgressProps> = ({ analysisStage, ...props }) => {
	const circularProgressValue = (getNMRAnalysisStageIndex(analysisStage) / STEP_COUNT) * 100;
	const { t } = useTranslation('portal');

	const circleProps = {
		size: '10rem',
		thickness: 4.75,
	};

	return (
		<Stack
			{...props}
			justifyContent="center"
			alignItems="center"
			sx={{
				...props.sx,
				backgroundColor: 'grey.50',
				borderRadius: 1.5,
				position: 'relative',
				height: '12rem',
			}}
		>
			<CircularProgress
				variant="determinate"
				value={circularProgressValue}
				{...circleProps}
				sx={{
					color: getNMRStageColor(analysisStage),
					zIndex: 1,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: 'round',
					},
				}}
			/>
			<CircularProgress
				variant="determinate"
				value={100}
				{...circleProps}
				sx={{
					position: 'absolute',
					inset: 'auto',
					color: 'grey.100',
				}}
			/>
			<Stack
				sx={{
					width: '30%',
					position: 'absolute',
					inset: 'auto',
					textAlign: 'center',
					alignItems: 'center',
				}}
				gap={1}
			>
				{getNMRAnalysisStageIcon(analysisStage)}
				<Typography variant="label-s">{getNMRStageMessage(analysisStage, t)}</Typography>
				<Typography variant="label-xs" data-testid="stage-count-test-id" color="grey.400">
					{getNMRAnalysisStageIndex(analysisStage)}/{STEP_COUNT}
				</Typography>
			</Stack>
		</Stack>
	);
};
