import { setRefreshUser, userSelector } from '@store/slices/common.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useSignalRHubConnection } from './useSignalRHubConnection';
import { HubConnection } from '@microsoft/signalr';
import { UserHub } from '@models/user';
import { config } from '@config';
import { authService, httpService } from '@services/core';

export const useUserHub = () => {
	const user = useSelector(userSelector);
	const dispatch = useDispatch();

	useSignalRHubConnection({
		hubUrl: config.userHubUrl,
		body: (connection: HubConnection) => {
			connection.send(UserHub.CHECK_USER, user?.username);
			connection.on(UserHub.USER_UPDATED, () => {
				authService.refreshToken(() => {
					dispatch(setRefreshUser());
					httpService.renewInterceptors();
				});
			});
		},
		shouldStartConnection: () => !!user?.username,
		deps: [!!user?.username],
	});
};
