import { AnalysisResultTheoreticalSearchStatus, AnalysisTargetType, QualitativeOrQuantitative } from '@models/analysis';
import { Type } from 'class-transformer';
import { NmrDrmDetail } from '../nmr-drm';
import { NmrAnalysisStage } from './nmr-analysis-stage.enum';
import { NmrAnalysisResultStatus } from './nmr-analysis-result-status.enum';
import { SupportedTechnology } from './technology.enum';
import { AnalysisType } from '@models/analysis/analysis-type.enum';
import { SpectrumData } from './nmr-quantitive-spectrum.class';
import { QualityGrade } from '@models/reference-material';

export enum NMRAnalysisResultMatchType {
	MATCH = 'Match',
	QUESTIONABLE_MATCH = 'QuestionableMatch',
	NOMATCH = 'NoMatch',
	ONLYTHEORETICALMATCH = 'OnlyTheoreticalMatch',
}

export enum NMRMatchCategory {
	SATISFACTORY = 'Satisfactory',
	QUESTIONABLE = 'Questionable',
	UNSATISFATORY = 'Unsatisfactory',
}

export class NmrAnalysisResult {
	analysisId: number;
	reviewerName: string;
	matchType: NMRAnalysisResultMatchType;
	comment: string;
	status: NmrAnalysisResultStatus;
	resultMatches: NmrReferenceMaterialResult[];
	referenceMaterials: NmrDrmDetail[];
	certifiedReferenceMaterial: NmrDrmDetail;
	integrationErrorMessage: string;
	theoreticalSearchStatus: AnalysisResultTheoreticalSearchStatus;
	isDrmResultGenerated: boolean;
	molecularWeight: string;
	sampleWeight: string;
	solventWeight: string;
	massFraction: string;
	concentration: string;
	spectrumData: SpectrumData;
	unitOfConcentration: string;
	calibrationName: string;

	nmrAnalysis: {
		title: string;
		projectId: string;
		secondApproval: boolean;
		stage: NmrAnalysisStage;
		operatorName: string;
		operatorId: string;
		integrationId: string;
		isCompleted: boolean;
		measurementExecutorId: string;
		frequency: string;
		solvent: string;
		details: string;
		technology: SupportedTechnology;
		measurementDeviceBrand: string;
		targetType: AnalysisTargetType;
		measurementType: QualitativeOrQuantitative;
		productAndBatchNumber: string;
		measurementId: string;
		sampleName: string;
		type: AnalysisType;
		measurementDeviceName: string;
	};

	@Type(() => Date)
	confirmationDate: Date;

	public isRejected(): boolean {
		return this.isRejectedByLabAdmin() || this.isRejectedByLabMember();
	}

	//Analysis Status//
	public isError(): boolean {
		return this.status === NmrAnalysisResultStatus.TECHNICAL_ERROR;
	}

	public isExecutedSuccessful(): boolean {
		return this.status !== NmrAnalysisResultStatus.TECHNICAL_ERROR;
	}

	public isSatisfied(): boolean {
		return this.status === NmrAnalysisResultStatus.SATISFIED;
	}

	public isUnSatisfied(): boolean {
		return this.status === NmrAnalysisResultStatus.UNSATISFIED;
	}

	public isQuestionable(): boolean {
		return this.status === NmrAnalysisResultStatus.QUESTIONABLE;
	}

	public isTheoreticalMatch(): boolean {
		return this.status === NmrAnalysisResultStatus.ONLY_THEORETICAL;
	}

	//Analysis Stages//
	public isExecuting(): boolean {
		return this.nmrAnalysis.stage === NmrAnalysisStage.EXECUTING;
	}

	public isApprovedByLabAdmin(): boolean {
		return this.nmrAnalysis.stage === NmrAnalysisStage.APPROVED_BY_LAB_ADMIN;
	}

	public isConfirmedByLabMember(): boolean {
		return this.nmrAnalysis.stage === NmrAnalysisStage.CONFIRMED_BY_LAB_MEMBER;
	}

	public isExecuted(): boolean {
		return this.nmrAnalysis.stage === NmrAnalysisStage.EXECUTED;
	}

	public isRejectedByLabMember(): boolean {
		return this.nmrAnalysis.stage === NmrAnalysisStage.REJECTED_BY_LAB_MEMBER;
	}

	public isRejectedByLabAdmin(): boolean {
		return this.nmrAnalysis.stage === NmrAnalysisStage.REJECTED_BY_LAB_ADMIN;
	}

	public isMatched(): boolean {
		return this.matchType !== NMRAnalysisResultMatchType.NOMATCH;
	}

	public isTargeted(): boolean {
		return this.nmrAnalysis.targetType === AnalysisTargetType.TARGETED;
	}

	//Analysis Types//
	public isQualitative(): boolean {
		return this.nmrAnalysis.measurementType === QualitativeOrQuantitative.QUALITATIVE;
	}

	public isQuantitative(): boolean {
		return this.nmrAnalysis.measurementType === QualitativeOrQuantitative.QUANTITATIVE;
	}

	public isQuantitativeNonTargeted(): boolean {
		return (
			this.nmrAnalysis.measurementType === QualitativeOrQuantitative.QUANTITATIVE &&
			this.nmrAnalysis.targetType === AnalysisTargetType.NON_TARGETED
		);
	}

	public isCompleted(): boolean {
		return this.nmrAnalysis.isCompleted;
	}
}

export class NmrReferenceMaterialResult {
	id: number;
	@Type(() => Date)
	createTime: Date;
	createdBy: string;
	@Type(() => Date)
	lastUpdateTime: Date;
	lastUpdatedBy: string;
	analysisId: number;
	substanceName: string;
	casNumber: string;
	identityConfirmed: boolean;
	matchFactor: number;
	referenceMaterialId: number;
	atoms: NmrResultAtoms[];
	moleculeImage: string;
	rmSpectrum: string;
	sampleSpectrum: string;
	concentration: string;
	massFraction: string;
	sampleWeight: string;
	solventWeight: string;
	isFalsePositive: boolean;
	isQuestionableHCount: boolean;
	isQuestionableMaxDa: boolean;
	isQuestionableNmrPurity: boolean;
	comments: string;
	category: NMRMatchCategory;
	isTheoretical: boolean;
	theoreticalSpectrumId: number;
	qualityGrade?: QualityGrade;
	isActive?: boolean;

	isSatisfactory(): boolean {
		return this.category === NMRMatchCategory.SATISFACTORY;
	}

	isQuestionable(): boolean {
		return this.category === NMRMatchCategory.QUESTIONABLE;
	}

	isUnSatisfactory(): boolean {
		return this.category === NMRMatchCategory.UNSATISFATORY;
	}
}

export enum CommentTypeEnum {
	CHEM_SHIFT = 'ChemShift',
	INTEGRAL = 'Integral',
	SIGNAL = 'Signal',
}

export interface ICommentType {
	comment: string;
	type: CommentTypeEnum;
}

export class NmrResultAtoms {
	id: number;
	absoluteIntegral: string;
	atomNumber: string;
	hMark: string;
	comments: ICommentType[];

	eHx: string;
	rmHShift: string;
	rmHCalculatedShift: string;
	rmHMultiplicity: string;
	rmFrequency: string;
	rmChemShift: string;
	rmCouplingConstant: string;
	rmNumberOfH: string;
	rmHVolume: string;
	rmChemicalGroup: string;

	// These are temporary fields to show atoms in table

	analyzeEHx: string;
	sampleHShift: string;
	sampleHCalculatedShift: string;
	sampleFrequency: string;
	sampleChemShift: string;
	sampleCouplingConstant: number;
	sampleHVolume: string;
	sampleHMultiplicity: string;
	sampleNumberOfH: string;
	sampleChemicalGroup: string;

	@Type(() => Date)
	createTime: Date;

	@Type(() => Date)
	lastUpdateTime: Date;
}
