import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { IrPeaksQuantificationInfo, RankBasedMixtureData, SampleSpectrumInfo, fileService } from '@services';
import { RxUtils, Tr, fixedWith3 } from '@utils';
import { Cell, HeaderCell } from '../IrIdentificationTableOfAssignment/IrTableOfAssignmentHelperComponents';
import { IrAnalysisResultContext } from '@routes/IrPortal/AnalysisResult/ir/Iranalysis-result-context';
import { IrDataTable } from '../IrDataTable';

interface IQuantitativeTableOfAssignment {
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
}

export const IrQuantificationTableOfAssignment: FC<IQuantitativeTableOfAssignment> = ({ rankedRMData, userSpectrumInfo }) => {
	const [irResultAtoms, setIrResultAtoms] = useState<IrPeaksQuantificationInfo[]>([]);
	const [referencePeakRatio, setReferencePeakRatio] = useState('');
	const [userPeakRatio, setUserPeakRatio] = useState('');
	const irResultAtom: IrPeaksQuantificationInfo[] = [];

	const { analysisResultData } = useContext(IrAnalysisResultContext);
	useEffect(() => {
		if (analysisResultData?.peakJson) {
			RxUtils.promisify(fileService.getFileFromUrl(analysisResultData?.peakJson || ''), (data) => {
				setReferencePeakRatio(data['reference_peak_ratio']);
				setUserPeakRatio(data['user_peak_ratio']);
				const resultData = data['peack_json'];
				resultData.map((item: any, index: number) => {
					const irResultAtomData: IrPeaksQuantificationInfo = {
						rmPeakXminXmax: item.peak_xmin_xmax,
						rmPeakArea: item.reference_peak_area,
						userPeakArea: item.user_peak_area,
						peakNumber: index + 1,
					};
					irResultAtom.push(irResultAtomData);
				});

				setIrResultAtoms(irResultAtom);
			});
		}
	}, [rankedRMData, userSpectrumInfo, analysisResultData]);
	const columns = useMemo<Column<IrPeaksQuantificationInfo>[]>(() => {
		return [
			{
				accessor: 'peakNumber',
				Header: (
					<HeaderCell>
						<Tr.IrPortal path="analysis-result.peak-number-label" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value || '---'}</Cell>,
			},
			{
				accessor: 'rmPeakXminXmax',
				Header: (
					<HeaderCell>
						<Tr.IrPortal path="analysis-result.peak-range" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{value || '---'}</Cell>,
			},
			{
				accessor: 'rmPeakArea',
				Header: (
					<HeaderCell>
						<Tr.IrPortal path="analysis-result.peak-area" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{fixedWith3(value) || '---'}</Cell>,
			},
			{
				accessor: 'userPeakArea',
				Header: (
					<HeaderCell>
						<Tr.IrPortal path="analysis-result.peak-area" />
					</HeaderCell>
				),
				disableSortBy: true,
				Cell: ({ value }) => <Cell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{fixedWith3(value) || '---'}</Cell>,
			},
		];
	}, [userSpectrumInfo]);

	return userSpectrumInfo || analysisResultData?.peakJson ? (
		<IrDataTable
			mainHeader={[
				{ label: '', colspan: 2 },
				{ label: rankedRMData?.mixtures[0].substanceName, colspan: 0 },
				{ label: analysisResultData?.irAnalysis?.title, colspan: 0 },
			]}
			mainFooter={[
				{ label: 'Ratio:', colspan: 2, alignFooter: 'right' },
				{ label: referencePeakRatio, colspan: 0, alignFooter: 'left' },
				{ label: userPeakRatio, colspan: 0, alignFooter: 'left' },
			]}
			columns={columns}
			data={irResultAtoms}
			sx={{
				'& 	.MuiTableCell-root': {
					borderRight: '1px solid',
					borderColor: 'grey.200',
					padding: 0,
					textAlign: 'center',
					'&:last-child': {
						borderRight: 'none',
					},
				},
				'& 	.MuiTableCell-head': {
					padding: 1.2,
					div: {
						display: 'inline-block',
					},
				},
				'& .MuiTableBody-root 	.MuiTableRow-root': {
					'&:last-child': {
						'& .MuiTableCell-root': {
							borderBottom: 'none',
						},
					},
					'& .MuiTableCell-root': {
						padding: '0',
					},
					height: '2.5rem',
				},
			}}
		/>
	) : (
		<></>
	);
};
