import { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, TypographyProps } from '@mui/material';
import { DateUtils, RxUtils, Tr } from '@utils';
import { PageRoutes, ExtendedColumn, SortOrder, AdminPermission, FilterKey } from '@models';
import { useFilterSearchParams, getParams, useService, useRestrictedComponent } from '@hooks';
import { NmrDrmDetail, nmrDrmService, nmrFilterService } from '@services';
import { DataTableBackend, OverflowText, QualityGradeChip, RouterLinkButton } from '@components/common';
import { getQualityGrader } from '@utils/QualityGrade';

const cellWidth = { minWidth: 100, maxWidth: 400, width: '100%' };

const Body1: FC<TypographyProps & { active?: boolean }> = ({ sx, active = true, ...props }) => (
	<Typography variant="pg-s" sx={{ minWidth: 100, color: getColor(active), padding: 0, ...sx }} {...props} />
);

const getColor = (active: boolean) => (active ? 'text.primary' : 'grey.400');

const StatusButton: FC<{ isActive: boolean; label: ReactNode }> = ({ isActive, label }) => {
	return (
		<Button
			sx={{ backgroundColor: isActive ? 'primary.main' : 'grey.400', pointerEvents: 'none', height: '24px', padding: 0.75 }}
			variant="contained"
			disableElevation
		>
			<Typography variant="subtitle3" sx={{ lineHeight: '80%' }}>
				{label}
			</Typography>
		</Button>
	);
};

export const NmrDrmDatabase = () => {
	const { searchParams, filtering, setFiltering } = useFilterSearchParams('systemCreationDate', SortOrder.DESC);
	const [typedString, setTypedString] = useState('');

	const { t } = useTranslation('admin');

	const { data: ermList } = useService(() => {
		setTypedString(getParams(searchParams).getQuery());
		const res = nmrDrmService.searchAllWithUrlParams(searchParams);
		return res;
	}, [searchParams]);

	const { data: filterData } = useService(() => nmrFilterService.drmDatabaseFilter());
	if (filterData?.options) {
		filterData.options.map((option) => {
			if (option?.key === FilterKey.STATUS) {
				option.single = true;
			}
		});
	}

	const columns = useMemo<ExtendedColumn<NmrDrmDetail>[]>(
		() => [
			{
				accessor: 'isActive',
				Header: <Tr.Admin path="substance-management.status" />,
				disableSortBy: true,
				Cell: ({ value }) => (
					<StatusButton
						isActive={value}
						label={value ? <Tr.Admin path="substance-management.active" /> : <Tr.Admin path="substance-management.inactive" />}
					/>
				),
			},
			{
				accessor: 'ermProductNumber',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.drm-reference-number" />,
				Cell: ({ value, row }) => (
					<OverflowText variant="pg-s" sx={{ color: getColor(row.original.isActive), ...cellWidth }}>
						{value || '-'}
					</OverflowText>
				),
			},
			{
				accessor: 'qualityGrade',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.quality-grade" />,
				Cell: ({ row }) => (
					<>
						<QualityGradeChip
							isReferenceActive={row.original.isActive}
							qualityGrade={getQualityGrader(row.original.qualityGrade)}
						/>
					</>
				),
			},
			{
				accessor: 'substance',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.substance" />,
				Cell: ({ value, row }) => (
					<OverflowText
						data-testid="substance-detail-id"
						enableTooltip={true}
						sx={{ color: getColor(row.original.isActive) }}
						variant="pg-s"
					>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'casNumber',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.cas-number" />,
				Cell: ({ value, row }) => <Body1 active={row.original.isActive}>{value}</Body1>,
			},
			{
				accessor: 'version',
				disableSortBy: true,
				Header: <Tr.Admin path="substance-management.version" />,
				Cell: ({ value, row }) => <Body1 active={row.original.isActive}>{value || '-'}</Body1>,
			},
			{
				accessor: 'systemCreationDate',
				Header: <Tr.Admin path="substance-management.creation-date" />,
				Cell: ({ value, row }) => (
					<Body1 active={row.original.isActive}>{value ? DateUtils.getFormattedDate(new Date(value)) : '-'}</Body1>
				),
			},
			{
				accessor: 'id',
				sticky: true,
				Header: <></>,
				disableSortBy: true,
				Cell: ({ value }) => (
					<Stack direction="row" justifyContent="flex-end" sx={{ mr: 2 }}>
						{useRestrictedComponent(
							AdminPermission.VIEW_DRM,
							<RouterLinkButton
								data-testid="goto-erm-test-id"
								variant="outlined"
								size="small"
								disableElevation
								href={`/admin/${PageRoutes.DRM_MANAGEMENT}/${PageRoutes.NMR}/${PageRoutes.EDIT_DRM}/${value}`}
							>
								<Tr.Admin path="substance-management.view-details" />
							</RouterLinkButton>,
						)}
					</Stack>
				),
			},
		],
		[],
	);

	function onSaveAsCsv(): void {
		RxUtils.promisify(
			nmrDrmService.exportAsCsv({ query: getParams(searchParams).getQuery(), filteringData: filtering }),
			(blob: Blob) => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const a = document.createElement('a');
				a.href = url;
				a.download = 'dRM Database ' + new Date().toISOString().split('T')[0] + '.csv';
				document.body.appendChild(a);
				a.click();
				a.remove();
			},
		);
	}

	return (
		<>
			<DataTableBackend
				data={ermList?.data || []}
				columns={columns}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: ermList?.totalPages ?? 1 }}
				searchText={typedString}
				onSearchTextChange={setTypedString}
				searchPlaceholder={t('substance-management.search-placeholder')}
				searchFieldProps={{ sx: { maxWidth: 700, width: 700 }, 'aria-label': 'erm-db-search-id' }}
				showExportButton={true}
				exportButtonText={t('substance-management.export-csv')}
				onExportButtonClick={() => onSaveAsCsv()}
				filterData={filterData?.options}
				onFilter={setFiltering}
				filteringSelections={filtering}
				sx={{ marginX: 2 }}
			/>
		</>
	);
};
