import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { RxUtils, Tr, analyticsAnalysisEvent } from '@utils';
import { IPortalLayoutTitleProps, PortalLayoutTitle } from '@components/portal';
import { ReactComponent as Delete } from '@material-symbols/svg-600/outlined/delete.svg';
import { alertService, irAnalysisService, notificationService } from '@services';
import { IrAnalysisStepButton } from '../AnalyticalProcedure/IrAnalysisStepButton';
import { useIrAnalysis } from '@hooks/ir/useIrAnalysis';
import { FormikApiType } from '@components/common';

interface IAnalysisTitleProps extends IPortalLayoutTitleProps {
	hasFile?: boolean;
	savedTime?: Date;
	formik: FormikApiType;
}

export const IrAnalysisTitle: FC<IAnalysisTitleProps> = ({ formik, hasFile, savedTime, sx, ...props }) => {
	const { id = '' } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { t } = useTranslation('irportal');
	const { analysisType, isAnalysisEditable } = useIrAnalysis();

	return (
		<PortalLayoutTitle
			sx={{ lineBreak: 'anywhere', padding: '10px 0', position: 'sticky', backgroundColor: 'grey.50', top: 0, zIndex: 2, ...sx }}
			title={formik?.values.title || <Tr.IrPortal path="new-analysis.title" />}
			savedTime={savedTime}
			id={id}
			{...props}
		>
			{hasFile && (
				<Stack direction="row" spacing={2}>
					<Stack>
						<Button
							variant="outlined"
							color="primary"
							size="medium"
							data-testid="reset-button-id"
							disabled={!isAnalysisEditable}
							onClick={() => {
								alertService.send({
									onConfirm: () => {
										formik?.resetForm({ values: { ...formik?.values, title: '' } });
										RxUtils.promisify(
											irAnalysisService.deleteAnalysis(+id),
											() => {
												analyticsAnalysisEvent.RemoveAnalysis(id);
												notificationService.sendSuccess(t('new-analysis.success-file-delete'));
												navigate('/dashboard');
											},
											() => notificationService.sendSuccess(t('new-analysis.error-file-delete')),
										);
									},
									content: (
										<Typography variant="inherit">
											<Tr.IrPortal path="new-analysis.delete-message" />
										</Typography>
									),
									titleText: <Tr.IrPortal path="new-analysis.warning" />,
									confirmTextComponent: <Tr.IrPortal path="new-analysis.delete" />,
									closeTextComponent: <Tr.Common path="cancel" />,
								});
							}}
							sx={{ minWidth: 180 }}
							startIcon={<Delete width={20} height={20} fill="#01884C" />}
						>
							<Tr.IrPortal path="new-analysis.delete-analysis" />
						</Button>
					</Stack>
					<Stack>{analysisType && <IrAnalysisStepButton formik={formik} />}</Stack>
				</Stack>
			)}
		</PortalLayoutTitle>
	);
};
