import Plotly from 'plotly.js-dist-min';
import {
	fileService,
	irAnalysisService,
	IrAnalysisResult,
	RankBasedMixtureData,
	IrPeaksInfo,
	RmSpectrumInfoPurity,
	SampleSpectrumInfoPurity,
	IrPeaksQuantificationInfo,
	IrAnalysisReportImages,
	IrReportImageModel,
	IrPurityCheckPeakjson,
	IrImpurityPeak,
	IrPdfReportImpurityPeak,
	IrResultImpurites,
} from '@services';
import { PromiseUtils } from '@utils/PromiseUtils';
import { RxUtils } from '@utils/Rx';
import { SpectraColorCodes, getTransMissionData, mixtureColorCodes } from '@utils';
import { getPeakHighligtShapes } from '@utils/Ir/graphUtils';

export interface IrSettings {
	transmissionSpectra?: boolean;
	chart?: string;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SmilesDrawer = require('smiles-drawer') as any;
const PROMISE_RESOLVER = 'Molecular structure promise resolved.';

interface ICordinate {
	x: number[];
	y: number[];
}

interface ICordinatePeak {
	peakarray: any[];
	referencePeakRatio: string;
	userPeakRatio: string;
}

const BASE64_KEY = 'data:image/png;base64,';

const drawer = new SmilesDrawer.SmiDrawer({
	width: 450,
	height: 450,
	compactDrawing: false,
	themes: {
		light: {
			C: '#000',
			O: '#000',
			N: '#000',
			F: '#000',
			CL: '#000',
			BR: '#000',
			I: '#000',
			P: '#000',
			S: '#000',
			B: '#000',
			SI: '#000',
			H: '#000',
			BACKGROUND: '#fff',
		},
	},
});

const drawSpectrum = async (ermChartElement: HTMLElement, sampleData: ICordinate, transmissionSpectra: boolean) => {
	return Plotly.newPlot(
		ermChartElement,
		[
			{
				x: sampleData.x,
				y: transmissionSpectra ? getTransMissionData([...sampleData.y]) : sampleData.y,
				type: 'scatter',
				mode: 'lines',
				marker: { color: 'gray' },
				direction: 'counterclockwise',
			},
		],
		{
			autosize: true,
			width: 700,
			height: 400,
			margin: { pad: 0, t: 0, r: 50, b: 50, l: 70 },
			xaxis: {
				range: [4000, 0],
				showdividers: false,
				showline: true,
				zeroline: false,
				title: 'Wavenumber [cm⁻¹]',
			},
			yaxis: {
				showdividers: false,
				showline: true,
				zeroline: false,
				title: transmissionSpectra ? 'Transmission [%]' : 'Absorbance [A.U.]',
			},
		},
	);
};

const getJSONDataPeak = async (url: string, analysisResult: IrAnalysisResult): Promise<ICordinatePeak> => {
	return new Promise<{ peakarray: any[]; referencePeakRatio: string; userPeakRatio: string }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const irResultAtom: IrPeaksInfo[] = [];
			const irResultAtomforQuanti: IrPeaksQuantificationInfo[] = [];
			const resultData = data['peack_json'];
			const referencePeakRatio = data['reference_peak_ratio'];
			const userPeakRatio = data['user_peak_ratio'];

			if (analysisResult.isQuantification()) {
				resultData.map((item: any, index: number) => {
					const irResultAtomData: IrPeaksQuantificationInfo = {
						rmPeakXminXmax: item.peak_xmin_xmax,
						rmPeakArea: item.reference_peak_area,
						userPeakArea: item.user_peak_area,
						peakNumber: index + 1,
					};
					irResultAtomforQuanti.push(irResultAtomData);
				});
			} else {
				const irResultAtomData: IrPeaksInfo = {
					rmPeakPosition: resultData.reference_peak_pos,
					rmintensity: resultData.reference_peak_intensity,
					samplePeakPosition: resultData.sample_peak_pos,
					sampleintensity: resultData.sample_peak_intensity,
					peakPosition: resultData.peak_pos,
				};
				irResultAtom.push(irResultAtomData);
			}

			resolve({
				peakarray: analysisResult.isQuantification() ? irResultAtomforQuanti : irResultAtom,
				referencePeakRatio: referencePeakRatio,
				userPeakRatio: userPeakRatio,
			});
		});
	});
};

const getJSONData = async (url: string): Promise<ICordinate> => {
	return new Promise<{ x: number[]; y: number[] }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const yData = data['user_spectrum'].y;
			const xData = [data['user_spectrum'].x.start, data['user_spectrum'].x.stop];
			const xAry: number[] = [];
			for (let i = xData[0]; i >= xData[1]; i--) {
				xAry.push(i);
			}
			resolve({
				x: xAry,
				y: yData,
			});
		});
	});
};

export const sendIrImagePurityQuantification = async (
	analysisResult: IrAnalysisResult,
	rankedRMData: RankBasedMixtureData,
	userSpectrumInfoPurityCheck: SampleSpectrumInfoPurity,
	rmSpectrumInfoPurityCheck: RmSpectrumInfoPurity,
	cb: (pdfUrl: string) => void,
	irSettings: IrSettings,
	peakData?: IrPurityCheckPeakjson[],
	impurityPeakData?: IrImpurityPeak[],
	resultImpurities?: IrResultImpurites[],
) => {
	const returnedModel: IrAnalysisReportImages = {
		substanceImages: [],
		mixtureImages: '',
	};
	const { transmissionSpectra } = irSettings;
	for (let i = 0; i < rankedRMData?.mixtures?.length || 0; i++) {
		const mixture = rankedRMData?.mixtures[`${i}`];
		const referenceMaterial = analysisResult?.referenceMaterials.filter((item) => item.casNumber === mixture.casNumber);
		const { smilesCode, casNumber } = referenceMaterial[0];
		const molecularStructureImg = document.createElement('img');
		const stackedElement = document.createElement('div');
		stackedElement.style.height = '200px';

		const ermChartElement = document.createElement('div');
		const purityCheck = analysisResult?.isPurityCheck();
		try {
			const imgPromise = new Promise((resolve, reject) => {
				drawer.draw(
					smilesCode,
					molecularStructureImg,
					'light',
					() => {
						resolve(PROMISE_RESOLVER);
					},
					() => {
						reject();
					},
				);
			});

			await Promise.resolve(imgPromise).catch();
		} catch {
			//
		}

		const tempObj: Partial<IrReportImageModel> = {};
		const peakJsonData = await getJSONDataPeak(analysisResult?.peakJson, analysisResult);
		await PromiseUtils.delay(() => {
			tempObj.moleculeImage = molecularStructureImg.src.replace(BASE64_KEY, '');
			tempObj.casNumber = casNumber;
			if (purityCheck && peakData && impurityPeakData) {
				const impurityPeakPdfReportData: IrPdfReportImpurityPeak[] = [];
				impurityPeakData.map((item) => {
					const data = {
						drmCode: item.impurity_drm_code,
						impurityPeakPosition: item.impurity_peak_pos,
						impurityPeakIntensity: item.impurity_peak_intensity,
						substanceName: item.substance_name,
					};
					impurityPeakPdfReportData.push(data);
				});
				tempObj.impurityPeakJson = [...impurityPeakPdfReportData];
			}
			tempObj.peaktableJson = peakJsonData.peakarray;
			tempObj.referencePeakRatio = peakJsonData.referencePeakRatio;
			tempObj.userPeakRatio = peakJsonData.userPeakRatio;
			returnedModel.substanceImages.push(tempObj);
		}, 500);

		const targetResult = rankedRMData.mixtures.filter((item: { casNumber: string }) => item.casNumber === casNumber);
		const chartDataAry: any = [];
		const userChartData = {
			y: transmissionSpectra ? getTransMissionData([...userSpectrumInfoPurityCheck.sample_y]) : userSpectrumInfoPurityCheck?.sample_y,
			x: userSpectrumInfoPurityCheck.sample_x,
			type: 'scatter',
			mode: 'lines',
			marker: { color: SpectraColorCodes.RICH_GREEN },
			direction: 'counterclockwise',
			name: 'Sample spectrum',
		};
		chartDataAry.push(userChartData);
		const targetSubstanceData = {
			y: transmissionSpectra ? getTransMissionData([...rmSpectrumInfoPurityCheck.spectrum_y]) : rmSpectrumInfoPurityCheck.spectrum_y,
			x: rmSpectrumInfoPurityCheck.spectrum_x,
			type: 'scatter',
			mode: 'lines',
			marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
			direction: 'counterclockwise',
			name: 'Target substance spectrum',
		};
		chartDataAry.push(targetSubstanceData);
		if (resultImpurities) {
			resultImpurities.map((item, index) => {
				const impurityData = item && item?.impuritySpectrumData;
				const substanceName = item?.substanceName || '';

				if (impurityData) {
					const impurityChartData = {
						y: transmissionSpectra ? getTransMissionData([...impurityData['y']]) : impurityData['y'],
						x: userSpectrumInfoPurityCheck?.sample_x,
						type: 'scatter',
						mode: 'lines',
						marker: { color: mixtureColorCodes[`${index}`] },
						direction: 'counterclockwise',
						name: substanceName,
					};

					chartDataAry.push(impurityChartData);
				}
			});
		}

		chartDataAry.map((chartData, j) => {
			Object.assign(chartData, { yaxis: j === 0 ? 'y' : 'y' + (j + 1) });
		});
		const chartDataListGreaterThan2 = chartDataAry?.length > 2;
		for (let j = 0; j < targetResult.length; j++) {
			const sampleData = await getJSONData(analysisResult?.sampleSpectrum);
			await Plotly.newPlot(
				stackedElement,
				chartDataAry,
				{
					margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
					height: 250,
					width: 750,
					autosize: true,
					hovermode: 'x unified',
					legend: {
						traceorder: 'reversed',
						yanchor: 'bottom',
						y: 1.1,
						xanchor: 'center',
						x: 0.4,
						orientation: 'h',
						bgcolor: 'transparent',
						font: { color: '#0F1A2E', size: 12 },
					},
					showlegend: true,
					yaxis: {
						domain: chartDataListGreaterThan2 ? [0.1, 0.2] : [0, 0.5],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
						title: transmissionSpectra ? 'Transmission [%]' : 'Absorbance [A.U.]',
					},
					yaxis2: {
						domain: chartDataListGreaterThan2 ? [0.2, 0.3] : [0.5, 1],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis3: {
						domain: [0.3, 0.4],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis4: {
						domain: [0.4, 0.5],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis5: {
						domain: [0.5, 0.6],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis6: {
						domain: [0.6, 0.7],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis7: {
						domain: [0.7, 0.8],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis8: {
						domain: [0.8, 0.9],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					yaxis9: {
						domain: [0.9, 1],
						showline: true,
						zeroline: false,
						showticklabels: false,
						griddash: 'dashdot',
					},
					xaxis: {
						showdividers: false,
						showline: true,
						zeroline: false,
						range: userSpectrumInfoPurityCheck.sample_x_range,
						gridcolor: SpectraColorCodes.GRAY,
						griddash: 'dot',
						title: 'Wavenumber [cm⁻¹]',
					},
					shapes: analysisResult?.isQuantification() ? getPeakHighligtShapes(analysisResult?.quantificationDetails) : [],
				},
				{},
			);
			await drawSpectrum(ermChartElement, sampleData, transmissionSpectra || false);
			const ermImage = await Plotly.toImage(ermChartElement);
			tempObj.referenceMaterialSpectrum = ermImage.replace(BASE64_KEY, '');
			const stackedImage = await Plotly.toImage(stackedElement, { scale: 2, format: 'png', width: 750, height: 225 });
			const spectrumSeperated = stackedImage.replace(BASE64_KEY, '');
			await PromiseUtils.delay(() => {
				returnedModel.mixtureImages = spectrumSeperated;
			}, 500);
		}
	}
	RxUtils.promisify(irAnalysisService.sendAnalysisImage(analysisResult?.analysisId, returnedModel), (pdfUrl) => cb(pdfUrl));
};
