import { Stack, Typography } from '@mui/material';
import { mainTitleSpacer, Tr } from '@utils';
import { AdminPermission } from '@models/user';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { AddSubstanceOverlay, SubstanceDatabase } from '@components/admin/SubstanceDatabase';

export const SubstanceManagement = () => {
	const hasCreateSubstancePerm = useHasPermissions([AdminPermission.CREATE_SUBSTANCE]);
	return (
		<Stack data-testid="substance-management-wrapper-id" direction="column">
			<Stack direction="row" justifyContent="space-between">
				<Stack>
					<Typography variant="h1" sx={{ color: 'text.secondary', marginBottom: mainTitleSpacer }}>
						<Tr.Admin path="substance-management.title" />
					</Typography>
				</Stack>
				<Stack>{hasCreateSubstancePerm && <AddSubstanceOverlay />}</Stack>
			</Stack>

			<SubstanceDatabase />
		</Stack>
	);
};
