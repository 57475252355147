import { TablePagination } from '@components/common';
import {
	DRMDetail,
	DrmTabs,
	ErmSearchTabValues,
	ReferenceSearchField,
	RequestErm,
	RequestErmListItem,
	SubstanceList,
} from '@components/portal';
import { useDRMDetail, useFilterSearchParams, useService } from '@hooks';
import { Card, Divider, Stack, SxProps, Typography } from '@mui/material';
import {
	NmrDrmDetail,
	nmrFilterService,
	NmrSubstanceListItem,
	NmrSubstanceListResponse,
	nmrSubstanceService,
	notificationService,
} from '@services';
import { DataTestId, getFiltersFromSearchParams, getIsFilterActive, RxUtils, Tr, TypeUtils } from '@utils';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MIN_SEARCH_INPUT_LEN = 1;

export const DRMDivider: FC<{ sx?: SxProps }> = ({ sx }) => (
	<Divider sx={{ borderBottomWidth: 1, borderColor: 'grey.200', marginLeft: '1rem', marginRight: '1rem', ...sx }} />
);

export const SearchReferences: FC = () => {
	const { t } = useTranslation('portal');
	const { filtering, searchParams, setSearchParams } = useFilterSearchParams();
	const { data: filterOptions } = useService(() => nmrFilterService.substanceFilter());
	const [typedString, setTypedString] = useState(searchParams.get('query') || '');
	const [searchResult, setSearchResult] = useState<NmrSubstanceListResponse>();
	const [substanceList, setSubstanceList] = useState<NmrSubstanceListItem[]>([]);
	const [detailData, setDetailData] = useState<NmrDrmDetail>();
	const isQueryParametersActive = typedString.length > 0 || getIsFilterActive(filtering ?? []);
	const { resetDetail, setDRMDetail, ...prop } = useDRMDetail();
	const [isRequestErmModalVisible, setIsRequestErmModalVisible] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [currentTab, setCurrentTab] = useState<Maybe<ErmSearchTabValues>>(!isQueryParametersActive ? ErmSearchTabValues.Recent : null);
	useEffect(() => {
		if (!searchResult) {
			return;
		}

		const resultList = searchResult.substances?.data ? [...searchResult.substances.data] : [];

		const theoreticalIndex = resultList.findIndex((s) => s.isTheoretical);
		if (theoreticalIndex !== -1) {
			resultList[`${theoreticalIndex}`].searchString = typedString;
			resultList[`${theoreticalIndex}`].totalTheoreticalSpectrumCount = searchResult.totalTheoreticalSpectrumCount;
			resultList[`${theoreticalIndex}`].theoreticalStartIndex = theoreticalIndex;
		}

		setSubstanceList(resultList);
	}, [searchResult]);

	useEffect(() => {
		if (typedString.length < MIN_SEARCH_INPUT_LEN) setSubstanceList([]);
	}, [typedString]);

	useEffect(() => {
		DataTestId.resetState();
		if (isQueryParametersActive) {
			getSubstanceList();
		} else if (currentTab === ErmSearchTabValues.All) {
			getSubstanceList();
		} else if (currentTab === ErmSearchTabValues.Recent) {
			getSubstanceList(true);
		} else {
			getSubstanceList();
		}
	}, [currentTab, searchParams]);

	const getSubstanceList = (isLatestUsed?: boolean) => {
		if (isLatestUsed) {
			searchParams.set('isLatestUsed', 'true');
		} else {
			searchParams.set('isLatestUsed', 'false');
		}
		if (typedString) searchParams.set('query', typedString);

		setLoading(true);
		RxUtils.promisify(
			nmrSubstanceService.getSubstances(searchParams),
			(drmData) => {
				setSearchResult(drmData);
			},
			(error) => {
				notificationService.sendError(error.Message || t('search-references.search-error'));
			},
			() => setLoading(false),
		);
	};

	const onSearchClear = () => {
		DataTestId.resetState();
		searchParams.set('query', '');
		searchParams.set('pageIndex', '1');
		setTypedString('');
		setCurrentTab(ErmSearchTabValues.Recent);
		setSearchParams(searchParams);
	};

	const showCountResult = (typedString && !currentTab) || (!typedString && currentTab !== ErmSearchTabValues.Recent);

	const ref = React.createRef<HTMLDivElement>();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const inputChangePreceedor = () => (clearTimeout(timer), setTimer(undefined));
	const onSearch = (query: string) => {
		inputChangePreceedor();
		const currentTimer = setTimeout(() => {
			query === '' ? setCurrentTab(ErmSearchTabValues.Recent) : setCurrentTab(null);
			searchParams.set('query', query);
			searchParams.set('pageIndex', '1');
			setSearchParams(searchParams);
		}, 1000);
		setTimer(() => currentTimer);
	};
	return (
		<Stack
			data-testid="search-references-wrapper-id"
			direction="column"
			sx={{ marginBottom: searchResult?.substances?.data.length ? 2 : 20 }}
		>
			<Stack
				direction="column"
				width={1}
				sx={{
					paddingTop: 5,
					position: 'sticky',
					top: 0,
					backgroundColor: 'background.default',
					zIndex: (theme) => theme.zIndex.modal,
				}}
			>
				<ReferenceSearchField
					placeholder={t('new-analysis.search-message')}
					value={typedString}
					onSearch={onSearch}
					onSearchClear={onSearchClear}
					onChange={(event) => {
						if (!loading) {
							setTypedString(() => event.target.value);

							inputChangePreceedor();
						}
					}}
					inputProps={{ 'data-testid': 'reference-search-input-id', sx: { paddingLeft: 0 } }}
					filteringSelections={filtering}
					searchParams={searchParams}
					onSearchParamsChange={(newSearchParams) => {
						const newFilterValues = getFiltersFromSearchParams(searchParams);
						const isListFiltered = getIsFilterActive(newFilterValues);
						isListFiltered ? setCurrentTab(null) : setCurrentTab(ErmSearchTabValues.Recent);
						setSearchParams(newSearchParams);
					}}
					filterData={filterOptions?.options ?? []}
				/>
				<Stack direction="row" justifyContent="space-between" marginTop={3}>
					<DrmTabs
						value={currentTab}
						onChange={(_, newTab: ErmSearchTabValues) => {
							setCurrentTab(newTab);
							setTypedString('');
						}}
					/>
					{searchResult &&
						searchResult.substances &&
						searchResult.substances.totalPages > 1 &&
						currentTab !== ErmSearchTabValues.Recent && (
							<TablePagination
								count={searchResult.substances?.totalPages}
								page={searchResult.substances?.pageIndex}
								onChange={(page) => {
									searchParams.set('pageIndex', page?.toString());
									setSearchParams(searchParams);
								}}
							/>
						)}
				</Stack>
			</Stack>
			{substanceList.length > 0 ? (
				<Card variant="outlined" sx={{ backgroundColor: 'background.paper' }} data-testid="substance-list-card-test-id">
					{searchResult && searchResult.substances && (
						<Stack sx={{ marginTop: 1 }}>
							<Typography variant="pg-xs" sx={{ marginX: 2 }} data-testid="total-results-id">
								{searchParams.get('query') &&
									!currentTab &&
									`${searchResult.substances.count} ${t('new-analysis.results-for')} "${searchParams.get('query')}"`}
								{!searchParams.get('query') && currentTab !== ErmSearchTabValues.Recent
									? `${searchResult.substances.count} ${t('new-analysis.total-result')}`
									: ''}
							</Typography>
							{showCountResult && <DRMDivider sx={{ marginTop: 1 }} />}
						</Stack>
					)}

					<SubstanceList
						divider={<DRMDivider />}
						substanceList={substanceList}
						actionLabel={t('drm-detail.add-to-favorites')}
						onDrmDetail={(visible: boolean, drm: NmrDrmDetail) => {
							const selectedDrm = TypeUtils.transform(NmrDrmDetail, drm);
							setDRMDetail({
								visible,
								drmId: selectedDrm.id,
								isTheoretical: selectedDrm?.isTheoretical(),
								actionBtnSx: selectedDrm?.isTheoretical() ? { marginTop: 'auto', marginBottom: 3 } : {},
								onRequestDrm: (drmDetail: NmrDrmDetail) => {
									setDetailData(drmDetail);
									setIsRequestErmModalVisible(true);
								},
							});
						}}
						itemSx={{ padding: '2rem 1rem' }}
						onRefreshSubstanceList={() => getSubstanceList(currentTab === ErmSearchTabValues.Recent)}
					/>
				</Card>
			) : null}
			{searchResult?.substances?.data.length === 0 && currentTab !== ErmSearchTabValues.Recent ? (
				<Stack direction="column" rowGap={3} divider={<DRMDivider />}>
					<Typography variant="h4" textAlign="center" color="grey.700" data-testid="search-reference-no-drm-id">
						<Tr.Portal path="new-analysis.empty-search-message" />
					</Typography>
				</Stack>
			) : null}
			<RequestErmListItem />
			{isRequestErmModalVisible && (
				<RequestErm
					open={true}
					details={detailData}
					refreshOnSuccess={false}
					onCancel={() => {
						setIsRequestErmModalVisible(false);
						setDetailData(undefined);
						if (prop.isTheoretical) {
							setDRMDetail({ visible: false, drmId: 0 });
						}
					}}
				/>
			)}
			{useMemo(
				() => (
					<DRMDetail ref={ref} onCloseClick={() => resetDetail()} {...prop} />
				),
				[prop],
			)}
		</Stack>
	);
};
