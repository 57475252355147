import { FC, ReactNode } from 'react';
import { Stack, StackProps, TextField, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';

export type FormikApiType = ReturnType<typeof useFormik>;

type TextInputBaseProps = Omit<TextFieldProps, 'error' | 'helperText'> & {
	name: string;
	value: any;
	touched?: boolean;
	error?: string;
};

const TextInputBase: FC<TextInputBaseProps> = ({ touched, error, ...props }) => {
	return <TextField error={touched && !!error} helperText={touched && error} {...props} />;
};

export const TextInputWithFormikApi: FC<{ api: FormikApiType } & Omit<TextFieldProps, 'error' | 'helperText'> & { name: string }> = ({
	api: { values, errors, touched, handleChange, handleBlur },
	name,
	onChange: inputOnChange,
	onBlur: inputOnBlur,
	inputProps,
	...props
}) => {
	// It creates flexibility for indexing and object parameter accessing (entity.key accesses to value[entity][key])
	const nameAccessor = name?.replace(']', '').split(/[[.]+/);
	return (
		<TextInputBase
			inputProps={{ maxLength: 256, ...inputProps }}
			name={name}
			value={nameAccessor?.reduce((value, entry) => value?.[`${entry}`], values)}
			onChange={(e) => {
				handleChange(e);
				inputOnChange?.(e);
			}}
			onBlur={(e) => {
				handleBlur(e);
				inputOnBlur?.(e);
			}}
			error={nameAccessor?.reduce((value, entry) => value?.[`${entry}`] as FormikErrors<FormikValues>, errors) as unknown as string}
			touched={
				nameAccessor?.reduce((value, entry) => value?.[`${entry}`] as FormikTouched<FormikValues>, touched) as unknown as boolean
			}
			{...props}
		/>
	);
};

export type FormikTextFieldProps = Omit<TextFieldProps, 'title'> & {
	name: string;
	title?: ReactNode;
	formikApi?: FormikApiType;
	typographyProps?: TypographyProps;
	containerProps?: StackProps;
	required?: boolean;
};

export const FormikTextfield: FC<FormikTextFieldProps> = ({
	name,
	title,
	formikApi,
	typographyProps: { sx: typographySx, ...typographyProps } = {},
	containerProps,
	required = false,
	...props
}) => {
	return (
		<Stack direction="column" {...containerProps}>
			{title ? (
				<Typography
					variant="label-s"
					className={required && !props.disabled ? 'required' : ''}
					color={props.disabled ? 'grey.200' : 'grey.800'}
					sx={{
						marginBottom: 1.25,
						...typographySx,
					}}
					{...typographyProps}
				>
					{title}
				</Typography>
			) : null}
			{formikApi && <TextInputWithFormikApi name={name} api={formikApi} {...props} />}
		</Stack>
	);
};
