import { AnalysisResultTheoreticalSearchStatus } from '@models/analysis';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { AnalysisResultContext } from '@routes/Portal/AnalysisResult/nmr/analysis-result-context';
import { FC, useContext, useState } from 'react';
import Close from '@mui/icons-material/Close';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { Tr } from '@utils/Translation';
import { NmrAnalysisStage, nmrAnalysisService } from '@services/nmr';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSignalRHubConnection } from '@hooks/useSignalRHubConnection';
import { config } from '@config';
import { HubConnection } from '@microsoft/signalr';
import { AnalysisTheoreticalHubMethods } from '@models/analysis/analysis-hub-methods.enum';
import { RxUtils } from '@utils/Rx';
import { ConfirmDialogWithInfo } from '@components/common';

export const TheoreticalRunExecutingWarningMessage: FC = () => (
	<>
		<Typography variant="pg-s">
			<Tr.Portal path="theoretical-spectrum-run.cancel-theoretical-search-part1" />
		</Typography>

		<Typography variant="pg-s" fontWeight={700} sx={{ display: 'inline' }}>
			<Tr.Portal path="theoretical-spectrum-run.cancel-theoretical-search-part2" />
		</Typography>

		<Typography variant="pg-s">
			<Tr.Portal path="theoretical-spectrum-run.cancel-theoretical-search-part3" />
		</Typography>
	</>
);

const MATCH_FACTOR_LIMIT = 0.5;
export const AnalysisResultRunTheoretical: FC<{ hideText?: boolean }> = ({ hideText }) => {
	const { analysisResultData, triggerResult, isTheoreticalExecuting, setIsTheoreticalExecuting, theoreticalEnabled } =
		useContext(AnalysisResultContext);
	const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

	useSignalRHubConnection({
		hubUrl: config.analysisTheoreticalHubUrl,
		body: (connection: HubConnection) => {
			connection.send(AnalysisTheoreticalHubMethods.CHECK_THEORETICAL_SPECTRA_RESULT, analysisResultData?.analysisId);
			connection.on(
				AnalysisTheoreticalHubMethods.THEORETICAL_SPECTRA_SEARCH_COMPLETED,
				(stage: NmrAnalysisStage, status: AnalysisResultTheoreticalSearchStatus) => {
					if (stage === NmrAnalysisStage.EXECUTED && status === AnalysisResultTheoreticalSearchStatus.EXECUTED) {
						triggerResult();
					}
				},
			);
		},
		shouldStartConnection: () =>
			theoreticalEnabled &&
			analysisResultData &&
			analysisResultData.theoreticalSearchStatus !== AnalysisResultTheoreticalSearchStatus.EXECUTED,
		deps: [analysisResultData, theoreticalEnabled],
	});

	if (!theoreticalEnabled || !analysisResultData) return null;

	const runTheoreticalButton = () => (
		<Button
			variant="contained"
			type="submit"
			data-testid="run-analysis-button"
			size="medium"
			color="secondary"
			disableElevation
			onClick={() => {
				setIsTheoreticalExecuting(true);
				RxUtils.promisify(nmrAnalysisService.triggerTheoreticalSearch(analysisResultData.analysisId));
			}}
		>
			<Stack direction="row" spacing={1} alignItems="center">
				<Stack>
					<PlayArrow />
				</Stack>
				<Stack>
					<Tr.Portal path="theoretical-spectrum-run.run-analysis" />
				</Stack>
			</Stack>
		</Button>
	);

	const runningTheoreticalProgress = () => (
		<Stack direction="column" sx={{ paddingLeft: '20px', borderLeft: '1px solid #D4C3F8' }}>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="h6" color="text.primary">
					<Tr.Portal path="theoretical-spectrum-run.search-in-progress" />
				</Typography>

				<IconButton
					sx={{ padding: 0 }}
					onClick={() => {
						setIsCancelDialogOpen(true);
					}}
				>
					<Close sx={{ width: '20px', height: '20px', color: 'grey.800' }} />
				</IconButton>
			</Stack>

			<Box sx={{ width: '100%', paddingTop: 1 }}>
				<LinearProgress
					sx={{
						width: '279px',
						height: '8px',
						borderRadius: '3px',
						backgroundColor: 'grey.200',
						'& .MuiLinearProgress-bar': {
							backgroundColor: 'info.700',
						},
					}}
				/>
			</Box>
		</Stack>
	);

	const getBody = () => {
		if (
			analysisResultData.resultMatches?.length === 1 &&
			!analysisResultData.resultMatches[0].isTheoretical &&
			analysisResultData.resultMatches[0].matchFactor < MATCH_FACTOR_LIMIT
		) {
			// no match found case
			return (
				<Stack direction="column" data-testid="analysis-result-run-theoretical-message">
					<Typography variant="subtitle2" lineHeight="160%" color="error.main">
						<Tr.Portal path="theoretical-spectrum-run.no-match-found" />
					</Typography>
					<Typography variant="pg-m" color="grey.800" sx={{ fontWeight: 400, lineHeight: '180%' }}>
						<Tr.Portal path="theoretical-spectrum-run.below-50-message" />
					</Typography>
				</Stack>
			);
		} else {
			return (
				<Stack direction="row" data-testid="analysis-result-run-theoretical-message" sx={{ marginTop: 2 }}>
					<Typography variant="h6" color="text.primary">
						<Tr.Portal path="theoretical-spectrum-run.theoretical-search-title" />
					</Typography>
					<Typography>&nbsp;</Typography>
					<Typography variant="pg-s" color="grey.800" sx={{ fontWeight: 400 }}>
						<Tr.Portal path="theoretical-spectrum-run.theoretical-search-message" />
					</Typography>
				</Stack>
			);
		}
	};

	const getActionButton = () => (isTheoreticalExecuting ? runningTheoreticalProgress() : runTheoreticalButton());

	return (
		<Stack data-testid="analysis-result-run-theoretical">
			{hideText ? (
				<Stack
					width={'100%'}
					direction="row"
					justifyContent="space-between"
					padding={2}
					marginTop={4}
					sx={{ alignSelf: 'center', backgroundColor: 'info.600', borderRadius: 2 }}
				>
					{isTheoreticalExecuting ? (
						<Stack>
							<Typography variant="h6" color="text.primary">
								<Tr.Portal path="theoretical-spectrum-run.searching-theoretical-spectrum-title" />
							</Typography>
							<Typography variant="pg-s" color="grey.800" sx={{ fontWeight: 400, lineHeight: '180%' }}>
								<Tr.Portal path="theoretical-spectrum-run.searching-theoretical-spectrum-library" />
							</Typography>
						</Stack>
					) : (
						<Stack>
							<Typography variant="h6" color="text.primary">
								<Tr.Portal path="theoretical-spectrum-run.theoretical-search-title" />
							</Typography>
							<Typography variant="pg-s" color="grey.800" sx={{ fontWeight: 400, lineHeight: '180%' }}>
								<Tr.Portal path="theoretical-spectrum-run.theoretical-search-message" />
							</Typography>
						</Stack>
					)}

					<Stack sx={{ borderLeft: '1px solid grey.800' }}> {getActionButton()}</Stack>
				</Stack>
			) : (
				<Stack padding={2} marginTop={1} sx={{ backgroundColor: 'info.600', borderRadius: 2 }} width={1}>
					<Stack direction="row" justifyContent="space-between" width={1}>
						{getBody()}

						<Stack sx={{ alignSelf: 'center' }}>{getActionButton()}</Stack>
					</Stack>
				</Stack>
			)}

			<ConfirmDialogWithInfo
				visible={isCancelDialogOpen}
				titleText={<Tr.Portal path="theoretical-spectrum-run.cancel-search" />}
				confirmText={<Tr.Portal path="theoretical-spectrum-run.cancel-search-message" />}
				infoText={<TheoreticalRunExecutingWarningMessage />}
				onClose={() => {
					setIsCancelDialogOpen(false);
				}}
				onConfirm={() => {
					setIsTheoreticalExecuting(false);
					RxUtils.promisify(nmrAnalysisService.cancelTheoreticalSearch(analysisResultData.analysisId), () =>
						setIsCancelDialogOpen(false),
					);
				}}
				closeButtonText={<Tr.Portal path="cancel" />}
				confirmButtonText={<Tr.Portal path="Yes, cancel" />}
			/>
		</Stack>
	);
};
