import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { Tr } from '@utils';
import { AnalysisTargetType } from '@models/analysis';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';
import Science from '@mui/icons-material/Science';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import { useIrAnalysis } from '@hooks/ir/useIrAnalysis';
import { FormikApiType } from '@components/common';

interface IAnalysisStepButtonProps {
	formik: FormikApiType;
}

export const IrAnalysisStepButton: FC<IAnalysisStepButtonProps> = ({ formik }) => {
	const { addedERMs, analysisType, isAnalysisEditable } = useIrAnalysis();

	const checkButtonValidity = () => {
		const { QUANTIFICATION, IDENTIFICATION, PURITYCHECK } = IrAnalysisType;

		if (analysisType === QUANTIFICATION) {
			return !addedERMs || addedERMs?.length === 0;
		}
		if (analysisType === IDENTIFICATION) {
			return !(formik?.values?.selectedLibrary?.length > 0);
		}
		if (analysisType === PURITYCHECK) {
			return !addedERMs || !addedERMs?.some((s) => s.selectedReferenceMaterials?.some((rm) => rm.isTargetSubstance));
		}
	};

	const checkVerificationButtonValidity = () => {
		const { PURITYCHECK, QUANTIFICATION } = IrAnalysisType;

		if (analysisType === PURITYCHECK) {
			return !addedERMs?.length || !addedERMs?.some((s) => s.selectedReferenceMaterials?.some((rm) => rm.isTargetSubstance));
		} else if (analysisType === QUANTIFICATION) {
			if (formik.values.quantificationMethod === 'PeakRatio') {
				return (
					!addedERMs?.length ||
					formik.values.peakMax1 <= formik.values.peakMin1 ||
					formik?.values.peakMax1 - formik.values.peakMin1 <= 5 ||
					formik?.values.peakMax1 - formik.values.peakMin1 >= 200 ||
					formik.values.peakMax2 <= formik.values.peakMin2 ||
					formik?.values.peakMax2 - formik.values.peakMin2 <= 5 ||
					formik?.values.peakMax2 - formik.values.peakMin2 >= 200
				);
			} else {
				return (
					!addedERMs?.length ||
					formik.values.peakMax1 <= formik.values.peakMin1 ||
					formik?.values.peakMax1 - formik.values.peakMin1 <= 5 ||
					formik?.values.peakMax1 - formik.values.peakMin1 >= 200
				);
			}
		}
	};
	const shouldDisable = !formik?.isValid || checkButtonValidity();
	const shouldDisabledVerification = !formik?.isValid || checkVerificationButtonValidity();
	const getButtonLabel = () => {
		const { IDENTIFICATION } = IrAnalysisType;
		if (analysisType === IDENTIFICATION) {
			return !addedERMs || addedERMs?.length === 0 ? (
				<Stack direction="row" spacing={1} alignItems="center">
					<Stack>
						<Science />
					</Stack>
					<Stack>
						<Tr.IrPortal path="new-analysis.search-entire-library" />
					</Stack>
				</Stack>
			) : (
				<>
					<Stack direction="row" spacing={1} alignItems="center">
						<Stack>
							<PlayCircleOutline />
						</Stack>
						<Stack>
							<Tr.IrPortal path="new-analysis.run-analysis" />
						</Stack>
					</Stack>
				</>
			);
		} else {
			return (
				<Stack direction="row" spacing={1} alignItems="center">
					<Stack>
						<PlayCircleOutline />
					</Stack>
					<Stack>
						<Tr.IrPortal path="new-analysis.run-analysis" />
					</Stack>
				</Stack>
			);
		}
	};

	const buttonAction = () => {
		const { IDENTIFICATION } = IrAnalysisType;
		const hasNotErm = !addedERMs || addedERMs?.length === 0;
		if (analysisType === IDENTIFICATION) {
			hasNotErm && formik?.setFieldValue('targetType', AnalysisTargetType.NON_TARGETED);
		}
	};

	return (
		<Button
			disabled={!isAnalysisEditable || (analysisType === IrAnalysisType.IDENTIFICATION ? shouldDisable : shouldDisabledVerification)}
			variant="contained"
			type="submit"
			data-testid="run-analysis-button"
			size="medium"
			color="secondary"
			sx={{
				'&.Mui-disabled': {
					background: '#FFC8324D',
					color: '#663D004D',
				},
			}}
			disableElevation
			onClick={buttonAction}
		>
			{getButtonLabel()}
		</Button>
	);
};
