import { SingleSelectMenu, SingleSelectMenuCheckmark, SingleSelectMenuOnSelectType } from '@components/common/SingleSelectMenu';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { PageRoutes } from '@models/router';
import { IrAdminPermission, NmrAdminPermission } from '@models/user';
import { Stack } from '@mui/material';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { flagsSelector } from '@store/slices/common.slice';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const RoutePermissionMap: Record<
	SupportedTechnology.NMR | SupportedTechnology.IR,
	{ permissionName: string; page: string; flag?: string }
> = {
	[SupportedTechnology.NMR]: {
		permissionName: NmrAdminPermission.VIEW_BACKLOG,
		page: PageRoutes.NMR,
		flag: 'nmr-pipeline',
	},
	[SupportedTechnology.IR]: {
		permissionName: IrAdminPermission.VIEW_DRM_PIPELINE,
		page: PageRoutes.IR,
	},
};

const getSelectedRoute = (pathname: string) => {
	return Object.entries(RoutePermissionMap).filter((route) => pathname.includes(route[1].page))?.[0];
};

export const DrmPipelineRouteHandler: FC = () => {
	const navigate = useNavigate();
	const loc = useLocation();
	const { t } = useTranslation('common');
	const flags = useSelector(flagsSelector);
	const [selectedRoute, setSelectedRoute] = useState(getSelectedRoute(loc.pathname));

	const validRoutes = Object.entries(RoutePermissionMap).filter(
		(value) => useHasPermissions([value[1].permissionName]) && (!value[1].flag || flags[value[1].flag]),
	);

	const pathname = loc.pathname.split('/');
	const isEqualToGrandparent = pathname[pathname.length - 1] === PageRoutes.DRM_PIPELINE;
	useEffect(() => {
		const currentRoute = loc.pathname.split('/');
		const possibleMainRoutes = [PageRoutes.DRM_PIPELINE, ...Object.values(RoutePermissionMap).map((route) => route.page)];

		if (validRoutes && validRoutes?.length > 0 && possibleMainRoutes.includes(currentRoute[currentRoute.length - 1])) {
			navigate(`./${validRoutes[0][1].page}`, { replace: true });
		}
	}, [isEqualToGrandparent]);

	useEffect(() => {
		setSelectedRoute(getSelectedRoute(loc.pathname));
	}, [loc.pathname]);

	if (validRoutes.length === 1) {
		return null;
	}
	const changeSelectedRoute: SingleSelectMenuOnSelectType = (technology) => {
		navigate(`/admin/${PageRoutes.DRM_PIPELINE}/${technology}`);
	};
	return (
		<Stack>
			{selectedRoute?.[1] && (
				<SingleSelectMenu
					items={validRoutes.map((i) => ({
						id: i[1].page,
						label: t(`${i[0].toLowerCase()}-technology`),
					}))}
					onSelectedItemChange={changeSelectedRoute}
					selectedItem={{ id: selectedRoute?.[1].page, label: t(`${selectedRoute?.[0].toLowerCase()}-technology`) }}
					menuItemProps={{
						sx: {
							height: 40,
							width: '172px',
						},
					}}
					sx={{
						width: '172px',
						height: '40px',
					}}
					preceedor={<SingleSelectMenuCheckmark />}
				/>
			)}
		</Stack>
	);
};
