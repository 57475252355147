import { FC, useContext } from 'react';
import { Stack, SxProps, Typography, TypographyProps } from '@mui/material';
import { IrAnalysisResult } from '@services';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IrAnalysisResultContext } from '@routes/IrPortal/AnalysisResult/ir/Iranalysis-result-context';
import { CompletedStatusIcon } from '@components/common';

const statusIconSx: SxProps = { width: 32, height: 32, borderRadius: '50%' };
const StatusLabel: FC<TypographyProps> = ({ children, sx }) => (
	<Typography data-testid="status-label-id" variant="h1" sx={{ color: 'success.main', marginLeft: 2, textAlign: 'center', ...sx }}>
		{children}
	</Typography>
);

export const IrAnalysisResultLabel: FC = () => {
	const { t } = useTranslation('irportal');
	const { analysisResultData } = useContext(IrAnalysisResultContext);
	return <Stack data-testid="analysis-result-label">{analysisResultData && getIrAnalysisResultIcons(analysisResultData, t)}</Stack>;
};

const getIrSatisfiedMessage = (analysisResult: IrAnalysisResult) => {
	if (analysisResult.isPurityCheck()) return `verification`;
	if (analysisResult.isIdentification()) return `identification`;
	return `quantification`;
};

const getIrAnalysisResultIcons = (analysisResultData: IrAnalysisResult, t: TFunction<'portal', undefined>) => {
	if (analysisResultData.isError()) {
		return (
			<Stack data-testid="technical-error-id" width="30em" direction="row" alignItems="center">
				<CompletedStatusIcon.Remove sx={statusIconSx} />
				<StatusLabel sx={{ color: 'grey.400' }}>{t('analysis-result-label.technical-error')}</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isUnSatisfied()) {
		return (
			<Stack data-testid="unsatisfy-match-id" width="30em" direction="row" alignItems="center">
				<CompletedStatusIcon.Close sx={statusIconSx} />
				<StatusLabel sx={{ color: 'error.main' }}>
					{!analysisResultData.isMatched()
						? t('analysis-result-label.no-match-found')
						: t('analysis-result-label.error-in-analysis')}
				</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isQuestionable()) {
		const matchFactor = analysisResultData?.resultMatches[0]?.matchFactor;
		return (
			<Stack data-testid="questionable-match-id" width="30em" direction="row" alignItems="center">
				<CompletedStatusIcon.QuestionMark sx={statusIconSx} />
				<StatusLabel sx={{ color: 'secondary.main' }}>
					{matchFactor && Math.round(matchFactor <= 1 ? matchFactor * 100 : matchFactor) >= 75
						? t('analysis-result-label.questionable-match')
						: t('analysis-result-label.unsatisfactory-match')}
				</StatusLabel>
			</Stack>
		);
	}

	if (analysisResultData.isSatisfied()) {
		return (
			<Stack data-testid="satisfy-label-id" width="35em" direction="row" alignItems="center">
				<CompletedStatusIcon.Done sx={statusIconSx} />
				<StatusLabel sx={{ color: 'primary.main' }}>
					{t(`analysis-result-label.${getIrSatisfiedMessage(analysisResultData)}`)}
				</StatusLabel>
			</Stack>
		);
	}

	if (!analysisResultData.isCompleted())
		return (
			<StatusLabel sx={{ color: 'primary.main', textAlign: 'start' }}>{t('analysis-result-label.analysis-completed')}</StatusLabel>
		);
};
