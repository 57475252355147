import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SearchField } from '@components/common';
import { useTranslation } from 'react-i18next';
import { DataTestId } from '@utils/DataTestId';
import { useService } from '@hooks/useService';
import { nmrDrmPipelineFilterService } from '@services/nmr-drm-pipeline/nmr-drm-pipeline-filter';
import { FilterKey } from '@models/filter';
import { TableFilter } from '@components/common/Filter';
import { useFilterSearchParams } from '@hooks/useFilterSearchParams';
import { SortOrder } from '@models/sorting';
import { useDispatch } from 'react-redux';
import { setProductionFilterData } from '@store/slices/pipeline.slice';
import { userService } from '@services/index';
import { AdminRoleEnum } from '@models/user';
import { getAssigneeFilter } from '@components/admin/NmrPipelineBacklog/nmr-pipeline-helper';

const allowedFilterOptions = [FilterKey.ASSIGNEES.toString(), FilterKey.REJECTION_SEVERITIES.toString(), FilterKey.ORDER_TYPES.toString()];

const UNASSIGNED_WAVE_ID = '0';
export const NmrDrmOrderProductionSearchField: FC = () => {
	const dispatch = useDispatch();
	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');
	const [deletedItem, setDeletedItem] = useState<{ key: string; value: string }>();
	const { searchParams, setSearchParams } = useFilterSearchParams('', SortOrder.RESET);
	const [openFilter, setOpenFilter] = useState(false);

	const { t: translate } = useTranslation('admin');
	const { data: userData } = useService(() => {
		const userParams = new URLSearchParams({ pageIndex: '1' });
		userParams.append('roles', AdminRoleEnum.NMR_ANALYST_QC_INSPECTOR.toString());
		userParams.append('roles', AdminRoleEnum.NMR_QA_REVIEWER.toString());
		return userService.getAllWithParams(userParams, '/merck');
	}, []);

	const { data: filterData } = useService(() => nmrDrmPipelineFilterService.nmrPipelineNmrDrmOrderFilterOptions());
	let filterDataFiltered = filterData?.options?.filter((option) => allowedFilterOptions.includes(option.key)) ?? [];

	if (userData?.data) {
		filterDataFiltered.push(getAssigneeFilter(userData.data));
	}

	filterDataFiltered = filterDataFiltered
		.map((f) => {
			const clone = { ...f };
			if (clone.key === FilterKey.ORDER_TYPES) {
				clone.title = 'other';
				clone.values = f.values?.filter((filterValue) => filterValue.id !== UNASSIGNED_WAVE_ID);
			}
			return clone;
		})
		.reverse();

	const searchFieldRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dispatch(setProductionFilterData({ filterDataFiltered, filterData, deletedItem, setDeletedItem }));
	}, [filterDataFiltered]);

	const onSearchClear = () => {
		searchParams.set('query', '');
		setSearchTextfieldValue('');
		setSearchParams(searchParams);
	};

	return (
		<>
			<SearchField
				onSearch={(query: string) => {
					searchParams.set('query', query);
					searchParams.set('pageIndex', '1');
					searchParams.delete('pageSize');
					setSearchParams(searchParams);
				}}
				placeholder={translate('drm-pipeline.nmr.production.search-placeholder')}
				value={searchTextfieldValue}
				onSearchClear={onSearchClear}
				onChange={(event) => setSearchTextfieldValue(event.target.value)}
				ref={searchFieldRef}
				filter={filterDataFiltered.length > 0}
				inputProps={{
					'data-testid': DataTestId.getStaticTestId('nmr-drm-pipeline-production-search-field'),
					sx: { paddingLeft: 0 },
				}}
				onFilterClick={() => setOpenFilter(!openFilter)}
				sx={{ backgroundColor: 'grey.50', width: (600 / 1440) * window.innerWidth }}
			/>
			{useMemo(
				() =>
					filterDataFiltered.length > 0 && (
						<TableFilter
							setOpen={() => setOpenFilter(!openFilter)}
							open={openFilter}
							anchor={searchFieldRef.current as HTMLElement}
							filterData={filterDataFiltered}
							removeFromChip={deletedItem}
							data-testid="filter-id"
						/>
					),
				[openFilter, deletedItem, filterData],
			)}
		</>
	);
};
