import { Divider, Stack, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import {
	CardBackgroundColorMapping,
	CardTypePathMapping,
	CardTypeValueMapping,
	CardValue,
	CardValueDividerColorMapping,
	OverviewCardType,
	getOverviewCardEditable,
	onPatch,
} from './overview-card-helper';
import { Tr } from '@utils/Translation';
import { NmrDrmOrder, NmrDrmOrderStatus } from '@services/nmr-drm-pipeline';
import AddIcon from '@mui/icons-material/Add';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { SelectMenuItem, SingleSelectMenu, SingleSelectMenuCheckmark, SingleSelectMenuItemType } from '@components/common/SingleSelectMenu';
import { TypeUtils } from '@utils/Type';
import { OverflowText } from '@components/common';
import { useHasPermissions } from '@hooks/useHasPermissions';
import { IErrorResponse } from '@models/request-response';
import { DataTestId } from '@utils/DataTestId';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common.slice';
import { ReactComponent as CheckCircle } from '@material-symbols/svg-600/rounded/check_circle-fill.svg';

const commonCardSx: SxProps = {
	borderRadius: 1,
	paddingY: 2,
	height: 72,
	justifyContent: 'space-between',
	alignItems: 'center',
	cursor: 'pointer',
};

const valuedCardSx: SxProps = {
	paddingX: 1.5,
};

const emptyCardSx: SxProps = {
	backgroundColor: 'grey.50',
	paddingLeft: 3.5,
	paddingRight: 1.5,
};

const iconSx: SxProps = {
	height: 24,
	width: 24,
	color: '#5E5E72',
};

type TOverviewCardProps = {
	cardType: OverviewCardType;
	nmrDrmOrder?: NmrDrmOrder;
	items?: CardValue[];
	successCallback?: () => void;
	errorCallback?: (err: IErrorResponse) => void;
	customOnSelect?: (value: unknown) => void;
};

export const OverviewCard: FC<TOverviewCardProps> = ({ cardType, nmrDrmOrder, items, successCallback, customOnSelect }) => {
	const { label, id } = CardTypeValueMapping[`${cardType}`](nmrDrmOrder);
	const { t: translate } = useTranslation('admin');
	const user = useSelector(userSelector);
	const [menuOpened, setMenuOpened] = useState(false);
	const hasEditPermission = getOverviewCardEditable(useHasPermissions, cardType, nmrDrmOrder, user);
	const isDeclined = nmrDrmOrder?.status === NmrDrmOrderStatus.DECLINED;
	const isReleased = nmrDrmOrder?.status === NmrDrmOrderStatus.RELEASED;
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));

	const toggleMenuOpened = () => {
		if (hasEditPermission && !isDeclined) {
			setMenuOpened((prev) => !prev);
		}
	};

	const menuWidth = (((window.innerWidth - 32) * 1164) / 1440) * 0.25 - 66;

	const onSelectedItemChange = (value: unknown) => {
		if ((value as SelectMenuItem).id !== `${id}`) {
			return customOnSelect
				? customOnSelect(value)
				: onPatch(nmrDrmOrder?.id, value, CardTypePathMapping[`${cardType}`], successCallback);
		}
	};

	const translateFunction = translate;
	return (
		<Stack
			direction="row"
			sx={[
				commonCardSx,
				label ? valuedCardSx : emptyCardSx,
				!hasEditPermission || isDeclined ? { cursor: 'default' } : {},
				nmrDrmOrder ? { backgroundColor: isDeclined ? 'grey.50' : CardBackgroundColorMapping[`${cardType}`](nmrDrmOrder) } : {},
			]}
			width={media ? '100%' : 'auto'}
			data-testid={DataTestId.getStaticTestId(`${cardType.toLowerCase()}-card-id`)}
			onClick={toggleMenuOpened}
		>
			<Stack direction="row" sx={{ height: '100%', width: 'calc(100% - 24px)' }}>
				{label && nmrDrmOrder && (
					<Divider
						orientation="vertical"
						flexItem
						sx={{
							marginRight: 1.5,
							borderRightWidth: 4,
							borderColor:
								nmrDrmOrder.status === NmrDrmOrderStatus.DECLINED
									? 'grey.500'
									: CardValueDividerColorMapping[`${cardType}`](nmrDrmOrder),
						}}
					/>
				)}

				<Stack width="calc(100% - 48px)">
					<SingleSelectMenu
						items={TypeUtils.transformFromExist(new Array<SingleSelectMenuItemType>(), items) ?? []}
						onSelectedItemChange={onSelectedItemChange}
						open={menuOpened}
						menuType="anchorEl"
						AnchorComponent={() => (
							<Typography variant="label-xs" color={isDeclined ? 'grey.500' : 'grey.800'}>
								<Tr.Admin path={`drm-order-details.${cardType.valueOf().toLowerCase()}`} />
							</Typography>
						)}
						preceedor={<SingleSelectMenuCheckmark />}
						selectedItem={{ label: label ?? '', id: id?.toString() ?? undefined }}
						menuProps={{
							sx: {
								transform: 'translateX(-29px)',
							},
							MenuListProps: {
								sx: {
									width: media ? '100%' : menuWidth,
									maxWidth: media ? '100%' : menuWidth,
									textOverflow: 'ellipsis',
								},
							},
						}}
						tFunction={translateFunction}
					/>
					<OverflowText variant="pg-m" color={isDeclined ? 'grey.500' : label ? 'text.primary' : 'grey.800'} enableTooltip={true}>
						{label ? translate(label) : <Tr.Admin path="drm-order-details.unassigned" />}
					</OverflowText>
				</Stack>
			</Stack>

			{hasEditPermission && !isDeclined && (
				<Stack>{label ? <ChangeCircleOutlinedIcon sx={iconSx} /> : <AddIcon sx={iconSx} />}</Stack>
			)}
			{isReleased && cardType === OverviewCardType.Status && (
				<Stack>{<CheckCircle style={{ height: 24, width: 24, fill: '#01884C', transform: 'translateX(1px)' }} />}</Stack>
			)}
		</Stack>
	);
};
