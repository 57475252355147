import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { CalibrationInProgressModal, FormikTextfield, GenericCard, OverflowText } from '@components/common';
import { alertService, notificationService, nmrDeviceService } from '@services';
import { greaterThanMaxFiles, mainTitleSpacer, Tr } from '@utils';
import { DeviceSpecification } from '../DeviceSpecification';
import { useService } from '@hooks/useService';
import { ReactComponent as Counter2 } from '@material-symbols/svg-600/outlined/counter_2-fill.svg';
import { ReactComponent as Counter3 } from '@material-symbols/svg-600/outlined/counter_3-fill.svg';
import { PortalPageRoutes } from '@models/router';
import { DeviceFormType, useDeviceFormik, DeviceFileUpload, onCalibrateDevice, useDeviceFileHook, unknownToFile } from '../DeviceForm';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '@store/slices/common.slice';

export const AddDevice = () => {
	const [rawFiles, setRawFiles] = useState<File[]>([]);
	const [isCalibrationProgress, setIsCalibrationProgress] = useState<boolean>(false);
	const navigate = useNavigate();
	const { t } = useTranslation('portal');
	const { data: deviceNamesResponse } = useService(() => nmrDeviceService.getDeviceNames());

	const formik = useDeviceFormik({
		formType: DeviceFormType.NEW_DEVICE,
		onSubmit: () => {
			notificationService.clear();
			setTimeout(() => {
				setIsCalibrationProgress(true);
			}, 50);
			onCalibrateDevice({
				files,
				successCb: (deviceId) => {
					setIsCalibrationProgress(false);
					navigate(`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${deviceId}/details`);
				},
				finalCb: () => {
					setIsCalibrationProgress(false);
					formik.resetForm();
				},
				formik,
				deviceId: undefined,
				calibrationId: undefined,
				receiverGainRange: receiverGainRange,
			});
		},
	});

	const { files, removeFile, canCalibrate, outOfRangeFiles, setOutOfRangeFiles } = useDeviceFileHook({
		newFile: rawFiles,
		formik,
		deviceNamesResponse,
	});

	const { data: receiverGainRange } = useService(() => nmrDeviceService.receiverGainThreshold(), []);

	const hide = greaterThanMaxFiles(files);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setBreadcrumb({ name: 'Add Device' }));
	}, []);

	const onClickCancel = () => {
		files.length === 0
			? navigate(-1)
			: alertService.send({
					content: <Tr.Portal path="device-management.cancel-content" />,
					titleText: <Tr.Portal path="device-management.cancel-title" />,
					onConfirm: () => {
						navigate(-1);
					},
				});
	};
	return (
		<Stack height="100%">
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: mainTitleSpacer }}>
				<OverflowText
					enableTooltip={true}
					variant="h1"
					sx={{ color: 'text.secondary', width: '100%', maxWidth: 'calc(100% - 25rem)' }}
				>
					<Tr.Portal path="device-management.device-details" />
				</OverflowText>
				<Stack direction="row" alignSelf="flex-end">
					<Button
						data-testid="add-device-cancel-button-id"
						variant="outlined"
						sx={{
							marginRight: '1rem',
						}}
						onClick={onClickCancel}
					>
						<Tr.Portal path="device-management.cancel" />
					</Button>
					<Tooltip
						disableHoverListener={!canCalibrate && formik.isValid}
						data-testid="add-device-tooltip-id"
						title={t('device-management.tooltip')}
					>
						<Button
							disabled={!canCalibrate || !formik.isValid}
							data-testid="add-device-submit-id"
							type="submit"
							variant="contained"
							onClick={formik.submitForm}
							disableElevation
						>
							<Tr.Portal path="device-management.calibrate" />
						</Button>
					</Tooltip>
				</Stack>
			</Stack>
			<form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
				<Grid data-testid="device-wrapper-id" container columnSpacing={2} height="100%">
					<Grid item xs={6} height={1}>
						<GenericCard sx={{ height: '100%' }}>
							<Stack spacing={2}>
								<DeviceFileUpload
									canCalibrate={canCalibrate}
									files={files}
									hide={hide}
									outOfRangeFiles={outOfRangeFiles}
									setOutOfRangeFiles={setOutOfRangeFiles}
									removeFile={removeFile}
									receiverGainRange={receiverGainRange}
									onUploadedFileChange={(data) => setRawFiles(unknownToFile(data))}
								/>
								<Stack>
									<Stack direction="row">
										<Counter2 width={24} height={24} fill="#01884C" />
										<Typography variant="h4" color="text.primary" marginLeft={1}>
											{t('device-management.device-name')}
										</Typography>
									</Stack>
									<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
										{t('device-management.device-name-caption')}
									</Typography>
									<FormikTextfield
										data-testid="add-device-device-name-id"
										formikApi={formik}
										name="deviceName"
										inputProps={{ maxLength: 256 }}
										required
										placeholder={t('device-management.device-name-placeholder')}
										onChange={(event) => formik.setFieldValue('deviceName', event.target.value?.trim())}
									/>
								</Stack>

								<Stack paddingTop={2}>
									<Stack direction="row">
										<Counter3 width={24} height={24} fill="#01884C" />
										<Typography variant="h4" color="text.primary" marginLeft={1}>
											{t('device-management.calibration-name')}
										</Typography>
									</Stack>
									<Typography variant="pg-m" sx={{ marginY: 1.5 }}>
										{t('device-management.calibration-name-caption')}
									</Typography>
									<FormikTextfield
										data-testid="add-device-calibration-name-id"
										formikApi={formik}
										name="calibrationName"
										required
										placeholder={t('device-management.calibration-name-placeholder')}
										containerProps={{ width: 1 }}
										inputProps={{ maxLength: 256 }}
									/>
								</Stack>
							</Stack>
						</GenericCard>
					</Grid>
					<Grid item xs={6} height={1}>
						{files.length > 0 && (
							<GenericCard sx={{ height: '100%' }}>
								<DeviceSpecification formik={formik} file={files[0]} />
							</GenericCard>
						)}
					</Grid>
				</Grid>
			</form>
			{isCalibrationProgress && (
				<CalibrationInProgressModal
					title={<Tr.Portal path="device-management.creating" />}
					message={<Tr.Portal path="device-management.add-device-progress.message" />}
					subtitle={<Tr.Portal path="device-management.add-device-progress.title" />}
				/>
			)}
		</Stack>
	);
};
