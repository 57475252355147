export enum NrmDrmOrderPriority {
	LOW = 'Low',
	MEDIUM = 'Medium',
	HIGH = 'High',
}

export enum NmrDrmOrderStatus {
	DRAFT = 'Draft',
	READY = 'Ready',
	IN_PRODUCTION = 'InProduction',
	WAITING_FOR_MEASUREMENT = 'WaitingForMeasurement',
	QC_INSPECTION = 'QcInspection',
	QA_REVIEW = 'QaReview',
	RELEASED = 'Released',
	DECLINED = 'Declined',
}

export enum NmrDrmOrderRejectionSeverity {
	MINOR = 'Minor',
	MAJOR = 'Major',
	CRITICAL = 'Critical',
}

export enum NmrDrmOrderRejectionReason {
	ASSIGNMENT_CHEMICAL_SHIFT_PROBLEM = 'AssignmentChemicalShiftProblem',
	ASSIGNMENT_INTEGRATION_PROBLEM = 'AssignmentIntegrationProblem',
	ASSIGNMENT_PLAUSIBILITY_PROBLEM = 'AssignmentPlausibilityProblem',
	ASSIGNMENT_MULTIPLICITY_PROBLEM = 'AssignmentMultiplicityProblem',
	ASSIGNMENT_ADDITIONAL_DATA_NEEDED = 'AssignmentAdditionalDataNeeded',
	ASSIGNMENT_OTHER_PROBLEM = 'AssignmentOtherProblem',
	BATCH_NUMBER_INCORRECT = 'BatchNumberIncorrect',
	CHEMICAL_INFO_ERROR = 'ChemicalInfoError',
	CHEMICAL_STRUCTURE_PROBLEM = 'ChemicalStructureProblem',
	MISSING_OR_INCORRECT_DATA_FILE = 'MissingOrIncorrectDataFile',
	MOLECULE_STRUCTURE_PROBLEM = 'MoleculeStructureProblem',
	RE_MEASUREMENT_REQUIRED = 'ReMeasurementRequired',
	SPECTRUS_FILE_PROBLEM = 'SpectrusFileProblem',
	STEREOCHEMISTRY_PROBLEM = 'StereochemistryProblem',
	OTHER = 'Other',
}

export enum NmrDrmOrderStatusEnumValue {
	DRAFT = 0,
	READY = 1,
	IN_PRODUCTION = 2,
	WAITING_FOR_MEASUREMENT = 3,
	QC_INSPECTION = 4,
	QA_REVIEW = 5,
	RELEASED = 6,
	DECLINED = 7,
}

export enum NmrDrmOrderParentStatus {
	BACKLOG = 'Backlog',
	PRODUCTION = 'Production',
	DECLINED = 'Declined',
	RELEASED = 'Released',
	AUDIT_LOGS = 'AuditLogs',
}

export enum NmrDrmOrderFileType {
	SPECTRUS = 0,
	JDX = 1,
	RAW = 2,
	PDF = 3,
	OTHER = 4,
}

export enum NmrDrmOrderRequestType {
	INTERNAL_REQUEST = 'InternalRequest',
	CUSTOMER_REQUEST = 'CustomerRequest',
}

export const NmrDrmOrderStatusParentStatusMap: Record<string, NmrDrmOrderParentStatus> = {
	[NmrDrmOrderStatus.DRAFT]: NmrDrmOrderParentStatus.BACKLOG,
	[NmrDrmOrderStatus.READY]: NmrDrmOrderParentStatus.PRODUCTION,
	[NmrDrmOrderStatus.IN_PRODUCTION]: NmrDrmOrderParentStatus.PRODUCTION,
	[NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT]: NmrDrmOrderParentStatus.PRODUCTION,
	[NmrDrmOrderStatus.QC_INSPECTION]: NmrDrmOrderParentStatus.PRODUCTION,
	[NmrDrmOrderStatus.QA_REVIEW]: NmrDrmOrderParentStatus.PRODUCTION,
	[NmrDrmOrderStatus.RELEASED]: NmrDrmOrderParentStatus.RELEASED,
	[NmrDrmOrderStatus.DECLINED]: NmrDrmOrderParentStatus.DECLINED,
};

export enum NmrDrmOrderStatusChangeErrors {
	WAVE_DOES_NOT_EXIST = '33001',
	DATA_DETAILS_MISSING = '33002',
	ORDER_FILES_MISSING = '33003',
}

export enum NmrDrmOrderDeclineReason {
	ON_HOLD = 'OnHold',
	NOT_SUITABLE = 'NotSuitable',
	MIXTURE = 'Mixture',
}

export enum NmrDrmOrderChangeStatusToWaitingForMeasurementReason {
	HSQC_HMBC_COSY_NEEDED = 'HsqcHmbcCosyNeeded',
	HSQC_HMBC_NEEDED = 'HsqcHmbcNeeded',
	LOT_NOT_RELEASED = 'LotNotReleased',
	RAW_DATA_NOT_AVAILABLE = 'RawDataNotAvailable',
	REMEASUREMENT_NEEDED = 'RemeasurementNeeded',
}

export enum NmrDrmOrderTransitionType {
	REJECT = 'Reject',
	DECLINE = 'Decline',
	ACCEPT = 'Accept',
}

export enum NmrDrmOrderCommentDropdownType {
	ACTIVITIES = 'Activities',
	ORDER = 'OrderComments',
	REQUEST = 'RequestComments',
}
