import { RxUtils } from '@utils/Rx';
import { TypeUtils } from '@utils/Type';
import { concatMap, map, tap } from 'rxjs';
import { httpService, logger, Filter } from '@services/core';
import { nmrConfig } from '../nmrConfig';

class NMRFilterService {
	getURl() {
		return `${nmrConfig.url}`;
	}

	public getAnalysisHistoryFilter(completed = false) {
		return httpService
			.get<Filter>(this.getURl(), `Analysis/filterOptions`, { errorHandler: 'notification', params: { completed } })
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAnalysisHistoryFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	public drmDatabaseFilter() {
		return httpService
			.get<Filter>(this.getURl(), `ReferenceMaterial/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.drmDatabaseFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	public drmRequestFilter() {
		return httpService
			.get<Filter>(this.getURl(), `ReferenceMaterialRequest/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.drmRequestFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}

	public substanceFilter() {
		return httpService
			.get<Filter>(this.getURl(), `Substance/filterOptions`, {
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.substanceFilter.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Filter, result)),
			);
	}
}

export const nmrFilterService = new NMRFilterService();
