import { SubstanceCard } from '@components/admin/SubstanceCard';
import { GenericCard } from '@components/common';
import { useService } from '@hooks/useService';
import { Stack, Typography } from '@mui/material';
import { Substance, substanceService } from '@services/core/substance';
import { NmrDrmOrder, NmrDrmOrderRequestType } from '@services/nmr-drm-pipeline';
import { Tr } from '@utils/Translation';
import { FC, ReactNode } from 'react';
import { Observable } from 'rxjs';

type THeaderChipProps = {
	value: string | ReactNode;
};

const HeaderChip: FC<THeaderChipProps> = ({ value }) => {
	return (
		<Stack padding={0.75} sx={{ border: '1px solid #A9A9BA', borderRadius: 1 }}>
			<Typography variant="label-s" color="grey.800" lineHeight={0.9}>
				{value}
			</Typography>
		</Stack>
	);
};

type TDrmOrderSubstanceDetailHeaderProps = {
	nmrDrmOrder?: NmrDrmOrder;
};

const DrmOrderSubstanceDetailHeader: FC<TDrmOrderSubstanceDetailHeaderProps> = ({ nmrDrmOrder }) => {
	return (
		<Stack direction="row" justifyContent="end" spacing={1}>
			{nmrDrmOrder?.requestType === NmrDrmOrderRequestType.CUSTOMER_REQUEST ? (
				<HeaderChip value={<Tr.Admin path={`drm-order-details.${nmrDrmOrder.requestType.toLowerCase()}`} />} />
			) : null}

			<HeaderChip value="NMR" />
		</Stack>
	);
};

type TDrmOrderSubstanceDetailProps = {
	drmOrder?: NmrDrmOrder;
};

export const DrmOrderSubstanceDetail: FC<TDrmOrderSubstanceDetailProps> = ({ drmOrder }) => {
	const { data: substance } = useService(() => {
		if (drmOrder?.substanceId) {
			return substanceService.get(drmOrder.substanceId);
		}
		return new Observable<Substance>();
	}, [drmOrder?.casNumber]);
	return (
		<GenericCard sx={{ padding: 3 }}>
			<SubstanceCard
				substance={substance}
				lastColumnProps={{ sx: { paddingLeft: 2 } }}
				containerProps={{ spacing: 4 }}
				firstColumnProps={{ xs: 4 }}
				secondColumnProps={{ xs: 8 }}
				molecularFormulaDimensions={{
					height: 201,
					width: 268,
				}}
				explanationGridProps={{ marginTop: 1 / 2, rowGap: 1 }}
				title={<DrmOrderSubstanceDetailHeader nmrDrmOrder={drmOrder} />}
			/>
		</GenericCard>
	);
};
