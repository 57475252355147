import { DataTableBackend } from '@components/common';
import { ExtendedColumn } from '@models/table';
import { Button, Checkbox, Stack, Typography, TypographyProps } from '@mui/material';
import { QuantitivePeakType } from '@services/nmr/nmr-analysis/nmr-quantitive-spectrum.class';
import { Tr } from '@utils/Translation';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddNumberOfProtonsModal } from './AddNumberOfProtonsModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TypeUtils } from '@utils/Type';
import { onPeakSelectionsChange } from './IntegratedTableHelper';
import { useAnalysis } from '@hooks/useAnalysis';
type TSelectableIntegratedPeakTableProps = {
	peakData: QuantitivePeakType[];
	refresh: () => void;
};
export const Body1: FC<TypographyProps & { minWidth?: string }> = ({ sx, minWidth, ...props }) => (
	<Stack minWidth={minWidth ? minWidth : (window.innerWidth * 180) / 1440}>
		<Typography variant="pg-s" fontWeight={400} lineHeight="12px" sx={{ padding: 0, ...sx }} {...props} />
	</Stack>
);
export const SelectableIntegratedPeakTable: FC<TSelectableIntegratedPeakTableProps> = ({ peakData, refresh }) => {
	const { id = '' } = useParams<string>();
	const [selectedPeak, setSelectedPeak] = useState<QuantitivePeakType>();
	const { isAnalysisEditable } = useAnalysis();

	const peakTableColumns = useMemo<ExtendedColumn<QuantitivePeakType>[]>(
		() => [
			{
				accessor: 'isSelected',
				Header: <></>,
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<Stack sx={{ maxWidth: '48px', width: '48px' }}>
						<Checkbox
							checked={value}
							disabled={!isAnalysisEditable}
							onChange={() => {
								const currentPeak = TypeUtils.transform(QuantitivePeakType, { ...row.values, isSelected: value });
								if (value) {
									onPeakSelectionsChange(+id, null, currentPeak, refresh);
								} else {
									setSelectedPeak(currentPeak);
								}
							}}
						/>
					</Stack>
				),
			},

			{
				accessor: 'id',
				disableSortBy: true,
				Header: <Tr.Portal path="new-analysis.entry" />,
				Cell: ({ row }) => <Body1 minWidth="88px">{row?.index + 1}</Body1>,
			},
			{
				accessor: 'chemShift',
				disableSortBy: true,
				Header: <Tr.Portal path="new-analysis.chem-shift" />,
				Cell: ({ value }) => <Body1 minWidth="88px">{value || '-'}</Body1>,
			},
			{
				accessor: 'multiplicity',
				disableSortBy: true,
				Header: <Tr.Portal path="new-analysis.multiplicity" />,
				Cell: ({ value }) => <Body1>{value ? value : '-'}</Body1>,
			},
			{
				accessor: 'couplingConstant',
				disableSortBy: true,
				Header: <Tr.Portal path="new-analysis.coupling-constant-hz" />,
				Cell: ({ value }) => <Body1>{value ? value : '-'}</Body1>,
			},
			{
				accessor: 'numberOfH',
				disableSortBy: true,
				Header: <Tr.Portal path="new-analysis.number-of-h" />,
				Cell: ({ row }) => (
					<Button
						variant="text"
						sx={{ paddingLeft: 0, color: 'grey.800' }}
						onClick={() => setSelectedPeak(TypeUtils.transform(QuantitivePeakType, row.values))}
						disabled={!isAnalysisEditable}
					>
						<EditOutlinedIcon
							sx={{
								width: '20px',
								marginRight: 1,
							}}
						/>
						<Typography variant="pg-s">
							{row.values.numberOfH ? row.values.numberOfH : <Tr.Portal path="new-analysis.click-to-enter-value" />}
						</Typography>
					</Button>
				),
			},
			{
				accessor: 'relativeIntegral',
				Header: <Tr.Portal path="new-analysis.relative-integral" />,
				disableSortBy: true,
				Cell: ({ value }) => <Body1>{value ? value : '-'}</Body1>,
			},
			{
				accessor: 'absoluteIntegral',
				Header: <Tr.Portal path="new-analysis.absolute-integral" />,
				disableSortBy: true,
				Cell: ({ value }) => <Body1>{value ? value : '-'}</Body1>,
			},
		],
		[],
	);
	return (
		<Stack spacing={3} marginTop={3}>
			<Typography variant="pg-m">
				<Tr.Portal path="new-analysis.peak-table-explanation" />
			</Typography>
			{selectedPeak && (
				<AddNumberOfProtonsModal
					onSubmit={() => (refresh(), setSelectedPeak(undefined))}
					peak={selectedPeak}
					onClose={() => setSelectedPeak(undefined)}
				></AddNumberOfProtonsModal>
			)}

			<DataTableBackend
				data={peakData}
				columns={peakTableColumns}
				headerCellProps={{ sx: { minWidth: '48px' } }}
				searchFieldProps={{ sx: { maxWidth: 600, width: 600 } }}
			/>
		</Stack>
	);
};
