import { AnalysisTechnology } from '@services';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';

export type FormFields = Extendable<{
	technology: AnalysisTechnology;
	type?: IrAnalysisType;
	title: string;
	measurementId: string;
	measurementDeviceBrand: string;
	solvent: string;
	quantificationMethod: string;
	device: string;
	selectedLibrary: Array<string>;
	referenceId: string;
	selectedImpurity: string;
	accessory: string;
	productAndBatchNumber: string;
	measurementExecutorId: string;
	projectId: string;
	details: string;
	measurementDate: Date;
	sampleWeight: number;
	peakMin1: number;
	peakMax1: number;
	peakMin2: number;
	peakMax2: number;
	solventWeight: number;
	secondApproval: boolean;
	deviceCalibrationId: number;
}>;

export const initialFormValues = {
	initialValues: {
		technology: 'IR',
		analysisType: null,
		selectedLibrary: [],
		referenceId: null,
		selectedImpurity: null,
		title: '',
		measurementId: '',
		measurementDeviceBrand: '',
		device: '',
		accessory: '',
		productAndBatchNumber: '',
		projectId: '',
		details: '',
		measurementExecutorId: '',
		measurementDate: '',
		sampleWeight: '',
		solventWeight: '',
		peakMin1: '',
		peakMax1: '',
		peakMin2: '',
		peakMax2: '',
		quantificationMethod: 'SinglePeakComparison',
		secondApproval: true,
		deviceCalibrationId: null,
	},
	initialTouched: {
		title: true,
		measurementId: true,
		device: false,
		selectedLibrary: true,
		peakMin1: true,
		peakMax1: true,
		peakMin2: true,
		peakMax2: true,
		accessory: false,
		measurementDeviceBrand: true,
		measurementDate: false,
		deviceCalibrationId: true,
	},
};
