import { FC } from 'react';
import { TFunction } from 'i18next';
import { buttonSpacer, DateUtils, Tr } from '@utils';
import { PortalPageRoutes, ExtendedColumn } from '@models';
import { IrDeviceAccessoryForList, getIrAccessoryCalibrationStatusMessage, getIrAccessoryCalibrationStatusColor } from '@services';
import { Chip, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { OverflowText } from '@components/common';
import { setBreadcrumb } from '@store/slices/common.slice';
import { useDispatch } from 'react-redux';
import { parseISO } from 'date-fns';
import { TableButton as IrTableButton } from '@components/irportal';

const Body1: FC<TypographyProps & { component?: unknown }> = ({ sx, ...props }) => (
	<Typography sx={{ minWidth: 100, color: 'text.primary', padding: 0, ...sx }} {...props} />
);

export const deviceManagementColumns = (t: TFunction): ExtendedColumn<IrDeviceAccessoryForList>[] => {
	const dispatch = useDispatch();
	const baseDeviceManagementColumn: ExtendedColumn<IrDeviceAccessoryForList>[] = [
		{
			accessor: 'accessoryStatus',
			Header: t('device-management.status'),
			Cell: ({ value }) => (
				<>
					<Tooltip placement="top" arrow title={value === null ? 'N/A' : getIrAccessoryCalibrationStatusMessage(value, t) || ''}>
						<Chip
							sx={{
								borderRadius: '0.25rem',
								padding: 0,
								width: '10rem',
								height: '1.87rem',
								' .MuiChip-label': { padding: 0 },
								backgroundColor: getIrAccessoryCalibrationStatusColor(value),
							}}
							label={
								value ? (
									<Typography sx={{ padding: 0, color: '#ffffff', fontWeight: 500 }}>
										{getIrAccessoryCalibrationStatusMessage(value, t)}
									</Typography>
								) : (
									<Typography sx={{ color: 'text.primary' }}>{'N/A'}</Typography>
								)
							}
						/>
					</Tooltip>
				</>
			),
		},
		{
			accessor: 'name',
			Header: <Tr.IrPortal path="device-management.irdevice-name" />,
			Cell: ({ value }) => (
				<OverflowText sx={{ minWidth: 100, color: 'text.primary', padding: 0, fontWeight: 400, maxWidth: 'calc(100% - 3rem)' }}>
					{value}
				</OverflowText>
			),
		},
		{
			accessor: 'accessoryName',
			disableSortBy: true,
			Header: <Tr.IrPortal path="device-management.accessory" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{value ? `${value}` : '-'}</Body1>,
		},
		{
			accessor: 'manufacturer',
			disableSortBy: true,
			Header: <Tr.IrPortal path="device-management.manufacturer" />,
			Cell: ({ value }) => (
				<Body1 sx={{ fontWeight: 400 }}>{`${value?.[0]?.toUpperCase()}${value?.substring(1)?.toLowerCase()}`}</Body1>
			),
		},
		{
			accessor: 'accessoryType',
			Header: <Tr.IrPortal path="device-management.measurementtype-accessory" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{value === null ? '-' : value === 0 ? 'ATR' : 'Transmission'}</Body1>,
		},
		{
			accessor: 'createTime',
			Header: <Tr.IrPortal path="device-management.creation-date" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{DateUtils.getFormattedDate(parseISO(value), 'dd/MM/yyyy')}</Body1>,
		},
		{
			accessor: 'nextCalibrationDate',
			Header: <Tr.IrPortal path="device-management.next-calibration-date" />,
			Cell: ({ value }) => (
				<Body1 sx={{ fontWeight: 400 }}>{value ? DateUtils.getFormattedDate(parseISO(value), 'dd/MM/yyyy') : '-'}</Body1>
			),
		},
		{
			accessor: 'id',
			disableSortBy: true,
			sticky: true,
			Cell: ({ row }) => (
				<Stack sx={{ float: 'right', mr: 2 }}>
					<IrTableButton.GotoDevice
						sx={{ marginLeft: buttonSpacer }}
						data-testid={`go-to-device-${row.index}-id`}
						href={`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${row.original.id}`}
						onClick={() => dispatch(setBreadcrumb({ title: row.values.title }))}
					>
						<Tr.IrPortal path="device-management.go-to-device" />
					</IrTableButton.GotoDevice>
				</Stack>
			),
		},
	];

	return baseDeviceManagementColumn;
};

export const archivedDeviceColumns = (t: TFunction, handleRestoreClick: Function): ExtendedColumn<IrDeviceAccessoryForList>[] => {
	const baseDeviceManagementColumn: ExtendedColumn<IrDeviceAccessoryForList>[] = [
		{
			accessor: 'name',
			Header: <Tr.IrPortal path="device-management.irdevice-name" />,
			Cell: ({ value }) => (
				<OverflowText sx={{ minWidth: 100, color: 'text.primary', padding: 0, fontWeight: 400, maxWidth: 'calc(100% - 3rem)' }}>
					{value}
				</OverflowText>
			),
		},
		{
			accessor: 'accessoryName',
			disableSortBy: true,
			Header: <Tr.IrPortal path="device-management.accessory" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{value ? `${value}` : '-'}</Body1>,
		},
		{
			accessor: 'manufacturer',
			disableSortBy: true,
			Header: <Tr.IrPortal path="device-management.manufacturer" />,
			Cell: ({ value }) => (
				<Body1 sx={{ fontWeight: 400 }}>{`${value?.[0]?.toUpperCase()}${value?.substring(1)?.toLowerCase()}`}</Body1>
			),
		},
		{
			accessor: 'accessoryType',
			Header: <Tr.IrPortal path="device-management.measurementtype-accessory" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{value === 0 ? 'ATR' : 'Transmission'}</Body1>,
		},
		{
			accessor: 'createTime',
			Header: <Tr.IrPortal path="device-management.creation-date" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{DateUtils.getFormattedDate(parseISO(value), 'dd/MM/yyyy')}</Body1>,
		},
		{
			accessor: 'archivedDate',
			Header: <Tr.IrPortal path="device-management.archived-date" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{DateUtils.getFormattedDate(parseISO(value), 'dd/MM/yyyy')}</Body1>,
		},
		{
			accessor: 'id',
			disableSortBy: true,
			sticky: true,
			Cell: ({ row }) => (
				<Stack sx={{ float: 'right', mr: 2 }}>
					<IrTableButton.RestoreDevice
						sx={{ marginLeft: buttonSpacer }}
						data-testid={`restore-device-${row.index}-id`}
						href={`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.RESTORE_DEVICE}/${row.original.id}`}
						onClick={() => handleRestoreClick}
					>
						<Tr.IrPortal path="device-management.go-to-device" />
					</IrTableButton.RestoreDevice>
				</Stack>
			),
		},
	];

	return baseDeviceManagementColumn;
};

export const inactiveDeviceColumns = (): ExtendedColumn<IrDeviceAccessoryForList>[] => {
	const dispatch = useDispatch();
	const baseDeviceManagementColumn: ExtendedColumn<IrDeviceAccessoryForList>[] = [
		{
			accessor: 'name',
			Header: <Tr.IrPortal path="device-management.irdevice-name" />,
			Cell: ({ value }) => (
				<OverflowText sx={{ minWidth: 100, color: 'text.primary', padding: 0, fontWeight: 400, maxWidth: 'calc(100% - 3rem)' }}>
					{value}
				</OverflowText>
			),
		},
		{
			accessor: 'manufacturer',
			disableSortBy: true,
			Header: <Tr.IrPortal path="device-management.manufacturer" />,
			Cell: ({ value }) => (
				<Body1 sx={{ fontWeight: 400 }}>{`${value?.[0]?.toUpperCase()}${value?.substring(1)?.toLowerCase()}`}</Body1>
			),
		},
		{
			accessor: 'createTime',
			Header: <Tr.IrPortal path="device-management.creation-date" />,
			Cell: ({ value }) => <Body1 sx={{ fontWeight: 400 }}>{DateUtils.getFormattedDate(parseISO(value), 'dd/MM/yyyy')}</Body1>,
		},
		{
			accessor: 'id',
			disableSortBy: true,
			sticky: true,
			Cell: ({ row }) => (
				<Stack sx={{ float: 'right', mr: 2 }}>
					<IrTableButton.GotoDevice
						sx={{ marginLeft: buttonSpacer }}
						data-testid={`go-to-device-${row.index}-id`}
						href={`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/${row.original.id}`}
						onClick={() => dispatch(setBreadcrumb({ title: row.values.title }))}
					>
						<Tr.IrPortal path="device-management.go-to-device" />
					</IrTableButton.GotoDevice>
				</Stack>
			),
		},
	];

	return baseDeviceManagementColumn;
};
