import { IrAnalysisType } from '@models/analysis/analysis-type.enum';
import { TypeUtils } from '@utils/Type';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getIrAnalysisValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	yup.object({
		title: yup.string().trim().required(t('new-analysis.title-required')).nullable(),
		selectedLibrary: yup.array().required(),
		peakMin1: yup
			.number()
			.min(651, t('new-analysis.peak-input-minimum-error'))
			.nullable()
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveNumber(originalValue) ? value : NaN))
			.notRequired()
			.when('type', {
				is: (value) => value === IrAnalysisType.QUANTIFICATION,
				then: (schema) => schema.required(t('new-analysis.peak-value-missing-error')),
				otherwise: (schema) => schema.nullable(),
			}),
		peakMax1: yup
			.number()
			.min(651, t('new-analysis.peak-input-minimum-error'))
			.nullable()
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveNumber(originalValue) ? value : NaN))
			.notRequired()
			.when('type', {
				is: (value) => value === IrAnalysisType.QUANTIFICATION,
				then: (schema) => schema.required(t('new-analysis.peak-value-missing-error')),
				otherwise: (schema) => schema.nullable(),
			}),
		peakMin2: yup
			.number()
			.min(651, t('new-analysis.peak-input-minimum-error'))
			.nullable()
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveNumber(originalValue) ? value : NaN))
			.notRequired()
			.when('quantificationMethod', {
				is: (value) => value === 'PeakRatio',
				then: (schema) => schema.required(t('new-analysis.peak-value-missing-error')),
				otherwise: (schema) => schema.nullable(),
			}),
		peakMax2: yup
			.number()
			.min(651, t('new-analysis.peak-input-minimum-error'))
			.nullable()
			.transform((value, originalValue) => (!value || TypeUtils.isPositiveNumber(originalValue) ? value : NaN))
			.notRequired()
			.when('quantificationMethod', {
				is: (value) => value === 'PeakRatio',
				then: (schema) => schema.required(t('new-analysis.peak-value-missing-error')),
				otherwise: (schema) => schema.nullable(),
			}),
	});
