// TODO:use Common table structure
import { FC } from 'react';
import { TFunction } from 'i18next';
import { DateUtils, Tr } from '@utils';
import { PortalPageRoutes, ExtendedColumn, LabPermission } from '@models';
import { IrAnalysisType } from '@models/analysis/analysis-type.enum';
import {
	IrAnalysis,
	IrAnalysisStage,
	IrAnalysisResultStatus,
	getIRAnalysisResultStatusInfo,
	getIRAnalysisRoute,
	getIRAnalysisStageIndex,
	getCompletedIRAnalysisResultStatusIcon,
	getIRStageMessage,
	getIRStageColor,
} from '@services';
import { Chip, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { OverflowText } from '@components/common';
import { AnalysisTypeIndicator } from '../AnalysisTypeIndicator';
import { TableButton } from '../TableButton';
import { useHasPermissions } from '@hooks/useHasPermissions';

type RowType = {
	matchFound: boolean;
	id: number;
	type: IrAnalysisType;
	status: IrAnalysisResultStatus;
	stage: IrAnalysisStage;
};

const Body1: FC<TypographyProps & { component?: unknown }> = ({ sx, ...props }) => (
	<Typography sx={{ minWidth: 100, color: 'text.primary', padding: 0, ...sx }} {...props} />
);

export const onGoingColumns = (t: TFunction, tPortal: TFunction): ExtendedColumn<IrAnalysis>[] => {
	const hasAnalysisOwnerPermission = useHasPermissions(LabPermission.ANALYSIS_OWNER);

	let baseOnGoing: ExtendedColumn<IrAnalysis>[] = [
		{
			accessor: 'stage',
			Header: t('stage'),
			Cell: ({ value }) => (
				<>
					<Tooltip placement="top" arrow title={getIRStageMessage(value, tPortal) || ''}>
						<Chip
							sx={{
								borderRadius: '0.25rem',
								padding: 0,
								width: '2.62rem',
								height: '1.87rem',
								' .MuiChip-label': { padding: 0 },
								backgroundColor: getIRStageColor(value),
							}}
							label={<Typography sx={{ padding: 0 }}>{getIRAnalysisStageIndex(value)}/6</Typography>}
						/>
					</Tooltip>
				</>
			),
		},
		{
			accessor: 'measurementDate',
			Header: t('sample-measurement-date'),
			Cell: ({ value }) => (
				<Body1 sx={{ maxWidth: 75, textAlign: 'center', fontWeight: 400 }}>
					{value ? DateUtils.getFormattedDate(value, 'dd/MM/yyyy') : '-'}
				</Body1>
			),
		},
		{
			accessor: 'title',
			Header: t('title'),
			Cell: ({ value }) => (
				<OverflowText
					enableTooltip={true}
					containerProps={{ width: 'auto !important', maxWidth: 400, minWidth: 200 }}
					sx={{ color: 'primary.main' }}
				>
					{value || '-'}
				</OverflowText>
			),
		},
		{
			accessor: 'type',
			Header: t('analysis-type'),
			Cell: ({ value, row }) =>
				value ? (
					<AnalysisTypeIndicator type={(row.original as RowType).type} variant="pg-s" sx={{ margin: 0, color: 'text.primary' }} />
				) : (
					'-'
				),
		},
	];

	baseOnGoing = hasAnalysisOwnerPermission
		? [
				...baseOnGoing,
				{
					accessor: 'operatorName',
					Header: t('operator-id'),
					Cell: ({ value }) => (
						<Body1>
							{' '}
							<OverflowText enableTooltip={true} containerProps={{ width: 'auto !important', maxWidth: 150 }}>
								{value || '-'}
							</OverflowText>
						</Body1>
					),
				},
			]
		: baseOnGoing;

	baseOnGoing = [
		...baseOnGoing,
		{
			accessor: 'lastActivityDate',
			Header: t('last-activity-date'),
			Cell: ({ value }) => <Body1>{DateUtils.getTimeDifferences(new Date(value))}</Body1>,
		},
		{
			accessor: 'id',
			disableSortBy: true,
			Header: ' ',
			sticky: true,
			Cell: ({ row }) => {
				return (
					<Stack direction="row" justifyContent="flex-end" sx={{ mr: 2 }}>
						<TableButton.GotoAnalysis
							sx={{ width: '7rem' }}
							href={`/${PortalPageRoutes.PORTAL}/${getIRAnalysisRoute(row.original.stage)}/${row.original.id}`}
							data-testid={`ongoing-analysis-go-analysis-${row.index}-id`}
						/>
					</Stack>
				);
			},
		},
	];

	return baseOnGoing;
};

export const completeColumns = (t: TFunction): ExtendedColumn<IrAnalysis>[] => {
	const hasAnalysisOwnerPermission = useHasPermissions(LabPermission.ANALYSIS_OWNER);

	let completeBaseColumn: ExtendedColumn<IrAnalysis>[] = [
		{
			accessor: 'status',
			Header: t('status'),
			Cell: ({ row }) => {
				return (
					<Tooltip arrow title={getIRAnalysisResultStatusInfo(row.original as IrAnalysis)}>
						<Body1
							sx={{
								marginLeft: '0.5rem',
								minWidth: 'fit-content',
								maxWidth: '7.5rem',
								width: 'fit-content',
							}}
						>
							{getCompletedIRAnalysisResultStatusIcon(row.original as IrAnalysis)}
						</Body1>
					</Tooltip>
				);
			},
		},
		{
			accessor: 'completedTime',
			Header: t('completion-time'),
			Cell: ({ value }: { value: Date }) => <Body1>{DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy').toString()}</Body1>,
		},
		{
			accessor: 'title',
			Header: t('title'),
			Cell: ({ value }) => (
				<OverflowText
					enableTooltip={true}
					sx={{
						color: 'primary.main',
						minWidth: 100,
						maxWidth: 400,
					}}
				>
					{value}
				</OverflowText>
			),
		},
		{
			accessor: 'type',
			Header: t('analysis-type'),
			Cell: ({ row }) => (
				<AnalysisTypeIndicator
					variant="pg-s"
					type={(row.original as unknown as RowType).type}
					sx={{ margin: 0, color: 'text.primary' }}
				/>
			),
		},
		{
			accessor: 'technology',
			Header: t('technology'),
			Cell: ({ value }) => <Body1>{value}</Body1>,
		},
		{
			accessor: 'substances',
			Header: t('substance'),
			Cell: ({ value }) => (
				<OverflowText enableTooltip={true} color="text.primary" minWidth="10rem" width="100%" maxWidth="12rem">
					{value || '-'}
				</OverflowText>
			),
		},
	];

	completeBaseColumn = hasAnalysisOwnerPermission
		? [
				...completeBaseColumn,
				{
					accessor: 'operatorName',
					Header: t('operator-id'),
					Cell: ({ value }) => (
						<OverflowText enableTooltip={true} color="text.primary" width="100%" maxWidth="12rem">
							{value || '-'}
						</OverflowText>
					),
				},
			]
		: completeBaseColumn;

	completeBaseColumn = [
		...completeBaseColumn,
		{
			accessor: 'id',
			Header: ' ',
			disableSortBy: true,
			sticky: true,
			Cell: ({ row }) => {
				const showReport = !(row.original as IrAnalysis).isAnalysisRejected() && !(row.original as IrAnalysis).isErrorInAnalysis();
				return (
					<Stack direction="row" justifyContent="flex-end" sx={{ mr: 2 }}>
						{showReport ? (
							<TableButton.GotoAnalysis
								href={`../../${getIRAnalysisRoute(row.original.stage)}/${row.original.id}?openReport=true`}
								data-testid={`completed-analysis-go-analysis-${row.index}-id`}
								sx={{ width: '7rem' }}
							>
								<Tr.IrPortal path="view-report" />
							</TableButton.GotoAnalysis>
						) : (
							<TableButton.ViewSummary
								sx={{ width: '7rem' }}
								data-testid={`completed-analysis-view-summary-${row.index}-id`}
								href={`../../${getIRAnalysisRoute(row.original.stage)}/${row.original.id}`}
							/>
						)}
					</Stack>
				);
			},
		},
	];

	return completeBaseColumn;
};
