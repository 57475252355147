import { FC, ReactNode } from 'react';
import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { AnalysisType } from '@models/analysis/analysis-type.enum';
import { Tr } from '@utils/Translation';
import { OverflowText } from '@components/common';

type MappingType = 'Identification' | 'PurityCheck' | 'Quantification';

const typeTitleMapping: Record<MappingType, ReactNode> = {
	Identification: <Tr.IrPortal path="analysis-result.identification" />,
	PurityCheck: <Tr.IrPortal path="analysis-result.purityCheck" />,
	Quantification: <Tr.IrPortal path="analysis-result.quantification" />,
};

export const AnalysisTypeIndicator: FC<{ type: AnalysisType | string } & TypographyProps> = ({ type, sx, ...props }) =>
	type ? (
		<Tooltip
			PopperProps={{ sx: { zIndex: 1000000 } }}
			title={
				<>
					<Tr.IrPortal path={type?.toLowerCase()} />, <Tr.IrPortal path={type?.toLowerCase?.()} />
				</>
			}
		>
			<Stack>
				<OverflowText
					containerProps={{ display: 'inline-flex', flexShrink: 0, width: 'auto', marginTop: 'auto' }}
					enableTooltip={false}
					sx={{ width: 'fit-content', ...sx }}
					{...props}
				>
					{type ? typeTitleMapping[`${type}`] : ''}
				</OverflowText>
			</Stack>
		</Tooltip>
	) : (
		<Typography sx={sx} {...props}>
			-
		</Typography>
	);
