import { FC } from 'react';
import { Typography } from '@mui/material';

import { Tr } from '@utils/Translation';

export const WhatWeQuantify: FC<{ hideExp?: boolean }> = ({ hideExp = false }) => (
	<>
		<Typography variant="h5" marginBottom={2}>
			<Tr.IrPortal path="new-analysis.step-1-title" />
		</Typography>

		{!hideExp && (
			<Typography variant="body1" color="grey.400" fontSize={14} marginBottom={0.5}>
				<Tr.IrPortal path="new-analysis.step-1-exp" />
			</Typography>
		)}
	</>
);
