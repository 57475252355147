import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { RxUtils, Tr } from '@utils';
import { alertService, organizationService, Organization, notificationService } from '@services';
import { Button, Stack, Typography } from '@mui/material';
import { OrganizationStatus } from '@models/organization';
import { OrganizationFormLayout } from '../Organization';
import { DeactivationConfirmPopUp, FormikApiType } from '@components/common';
import { OrganizationPermission } from '@models/user';
import { useRestrictedComponent } from '@hooks/useRestrictedComponent';
import { getOrganizationValidationSchema } from '@schemas/organization/organization.validation';

interface EditOrganizationLayoutProps {
	organizationData: Organization;
	setDirty: (val: boolean) => void;
	setFormik: (value: FormikApiType) => void;
	refreshOrgData?: () => void;
	parentFormikState?: FormikApiType;
}

export type FormFields = Extendable<Organization>;

export const EditOrganizationLayout: FC<EditOrganizationLayoutProps> = ({
	organizationData,
	setDirty,
	setFormik,
	parentFormikState,
	refreshOrgData,
}) => {
	const { t } = useTranslation('admin');

	const [isDeactivationConfirmVisible, setDeactivationConfirmVisible] = useState(false);

	const formik = useFormik<FormFields>({
		enableReinitialize: true,
		initialValues: {
			isEditing: true,
			...organizationData,
		},
		validationSchema: getOrganizationValidationSchema(t),
		onSubmit: (changes) => {
			RxUtils.promisify(
				organizationService.edit({
					...organizationData,
					...changes,
				}),
				() => {
					setDirty(false);
					notificationService.sendSuccess(t('organization.organization-update', { organizationName: formik.values.name }));
					refreshOrgData?.();
				},
			);
		},
	});

	useEffect(() => {
		formik.validateForm();
	}, [organizationData]);

	useEffect(() => {
		setDirty(formik.dirty);
		if (!parentFormikState) {
			setFormik(formik);
		}
	}, [formik]);

	useEffect(() => {
		setFormik(formik);
	}, [formik.dirty, formik.isValid]);

	return (
		<>
			<DeactivationConfirmPopUp
				title={<Tr.Admin path={'organization.confirmation'} />}
				subTitle={
					<>
						<Stack>
							<Typography variant="h3" lineHeight="120%">
								<Tr.Admin path="organization.deactivate-organization" />
							</Typography>
						</Stack>
						<Stack>
							<Typography variant="pg-m">
								{t('organization.deactivate-message', { organizationName: organizationData.name })}
							</Typography>
						</Stack>
					</>
				}
				message={t('organization.deactivate-comment')}
				visible={isDeactivationConfirmVisible}
				onConfirm={(comment) => {
					RxUtils.promisify(organizationService.changeStatus(organizationData.id, organizationData.status, comment), () =>
						refreshOrgData?.(),
					);
					setDeactivationConfirmVisible(false);
				}}
				onCancel={() => setDeactivationConfirmVisible(false)}
			/>

			<OrganizationFormLayout
				organizationData={organizationData}
				editing={true}
				formik={formik}
				sx={{
					width: 1,
					marginTop: 2,
					paddingBottom: 7,
					boxShadow: '0px 1px 4px 0px rgba(15, 26, 46, 0.15),0px 0px 1px 0px rgba(15, 26, 46, 0.15) ',
				}}
			/>
			<Stack direction="row" justifyContent="space-between" paddingTop={2}>
				{useRestrictedComponent(
					OrganizationPermission.DEACTIVATE,
					<Button
						data-testid="deactivate-button-id"
						variant="text"
						sx={{
							margin: 'auto auto 0 0',
						}}
						disableElevation
						color={organizationData.status === OrganizationStatus.ACTIVE ? 'error' : 'primary'}
						onClick={() =>
							organizationData.status === OrganizationStatus.ACTIVE
								? setDeactivationConfirmVisible(true)
								: alertService.send({
										confirmTextHeader: <Tr.Admin path="organization.reactivate-organization" />,
										titleText: <Tr.Admin path={'organization.confirmation'} />,
										confirmTextComponent: <Tr.Admin path={'organization.confirm-reactivate'} />,
										closeTextComponent: <Tr.Admin path="organization.cancel" />,
										onConfirm: () =>
											RxUtils.promisify(
												organizationService.changeStatus(organizationData.id, organizationData.status),
												() => {
													refreshOrgData?.();
												},
											),
										content: (
											<Tr.Admin
												path="organization.reactivate-message"
												options={{ organizationName: organizationData.name }}
											/>
										),
									})
						}
					>
						<Tr.Admin
							path={
								organizationData.status === OrganizationStatus.ACTIVE
									? 'organization.deactivate-organization'
									: 'organization.reactivate-organization'
							}
						/>
					</Button>,
				)}
			</Stack>
		</>
	);
};
