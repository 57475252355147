import { PageRoutes } from '@models/router';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { setBreadcrumb } from '@store/slices/common.slice';
import { DataTestId } from '@utils/DataTestId';
import { mainTitleSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

enum CustomerManagementTab {
	ORGANIZATIONS = 'organizations',
	USERS = 'users',
}

const routes = [CustomerManagementTab.ORGANIZATIONS, CustomerManagementTab.USERS];

export const CustomerManagement = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('admin');
	const [currentTab, setCurrentTab] = useState<CustomerManagementTab>(CustomerManagementTab.ORGANIZATIONS);
	const dispatch = useDispatch();
	const setTab = useCallback(
		(route: CustomerManagementTab) => {
			setCurrentTab(route);
			navigate(route);
			dispatch(setBreadcrumb({ ':tab': route, tab: t(`customer-management.${route}`) }));
		},
		[t],
	);
	useEffect(() => {
		const pieces = location.pathname.split('/');
		const result = pieces?.[pieces.length - 1];
		const targetTab = routes.find((route) => route === result);
		if (targetTab) {
			setCurrentTab(targetTab);
			dispatch(setBreadcrumb({ ':tab': targetTab, tab: t(`customer-management.${targetTab}`) }));
		} else {
			navigate(`/admin/${PageRoutes.CUSTOMER_MANAGEMENT}/${currentTab}`, { replace: true });
			dispatch(setBreadcrumb({ ':tab': currentTab, tab: t(`customer-management.${currentTab}`) }));
		}
	}, [location.pathname, t]);
	const availableTabs = routes.map((route) => {
		return (
			<Tab
				key={route}
				value={route}
				label={<Tr.Admin path={`customer-management.${route}`} />}
				data-testid={DataTestId.getStaticTestId(`customer-management-${route}-id`)}
			/>
		);
	});
	return (
		<Stack>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ marginBottom: mainTitleSpacer }}
				data-testid="customer-management-title"
			>
				<Typography variant="h1" sx={{ color: 'text.secondary' }}>
					<Tr.Admin path="customer-management.title" />
				</Typography>
			</Stack>

			<Tabs value={currentTab} textColor="primary" sx={{ marginBottom: '2.5rem' }} onChange={(_, tab) => setTab(tab)}>
				{availableTabs}
			</Tabs>
			<Outlet />
		</Stack>
	);
};
