import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { FormikTextfield } from '@components/common';
import { InputErrorRelativePosition, Tr } from '@utils';
import { ILoginUserModel } from '@models';
import { TableButton } from '@components/portal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { PromiseUtils } from '@utils/PromiseUtils';
import { authService, notificationService } from '@services/core';
import { PasswordPattern } from '../Register/PasswordPattern';
import { getSetNewPasswordValidationSchema } from '@schemas/login/set-new-password.validation';

const formikProps = {
	typographyProps: {
		sx: { color: 'grey.800', fontSize: '13px', marginBottom: '5px' },
	},
	sx: { marginBottom: '1rem', ...InputErrorRelativePosition },
	required: true,
};

type FormFields = Extendable<{
	code: string;
	newPassword: string;
	confirmNewPassword: string;
}>;

interface ISetNewPassword {
	userInfo: Maybe<ILoginUserModel>;
	gotoLogin: () => void;
	onResetSuccessfully: () => void;
}

export const SetNewPassword: FC<ISetNewPassword> = ({ gotoLogin, userInfo, onResetSuccessfully }) => {
	const { t } = useTranslation('login');
	const [patternValid, setPatternValid] = useState(false);
	const formik = useFormik<FormFields>({
		initialValues: {
			code: '',
			confirmNewPassword: '',
			newPassword: '',
		},
		validationSchema: getSetNewPasswordValidationSchema(t),
		onSubmit: () => {
			PromiseUtils.runPromise(
				() => authService.submitNewPassword(userInfo?.email || '', `${formik.values.code}`, formik.values.newPassword.trim()),
				() => onResetSuccessfully(),
				(err) => notificationService.sendError(err.message),
			);
		},
	});
	return (
		<Stack data-testid="set-new-password-id">
			<Typography variant="h1">
				<Tr.Login path="new-password.set-new-password" />
			</Typography>
			<Typography variant="pg-m" sx={{ marginBottom: '2.5rem', fontSize: '0.875rem' }}>
				<Tr.Login path="new-password.desc" options={{ email: userInfo?.maskedEmail }} />
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<FormikTextfield
					formikApi={formik}
					name="code"
					title={<Tr.Login path="new-password.code" />}
					placeholder={t('new-password.enter-code')}
					type="number"
					inputProps={{ 'data-testid': 'code' }}
					{...formikProps}
				/>
				<FormikTextfield
					formikApi={formik}
					name="newPassword"
					title={<Tr.Login path="new-password.new-password" />}
					placeholder={t('new-password.enter-new-password')}
					type="password"
					inputProps={{ 'data-testid': 'pass-1' }}
					{...formikProps}
				/>
				<FormikTextfield
					formikApi={formik}
					name="confirmNewPassword"
					title={<Tr.Login path="new-password.confirm-password" />}
					placeholder={t('new-password.re-enter-password')}
					inputProps={{ 'data-testid': 'pass-2' }}
					type="password"
					{...formikProps}
				/>
				<PasswordPattern
					pass1={formik.values.newPassword}
					pass2={formik.values.confirmNewPassword}
					patternIsValid={(isValid) => setPatternValid(isValid)}
				/>
				<Stack sx={{ flexDirection: 'row', marginTop: '2.5rem' }}>
					<Stack
						data-testid="goto-login-id"
						onClick={() => gotoLogin()}
						direction="row"
						sx={{ cursor: 'pointer', alignItems: 'center' }}
					>
						<KeyboardBackspaceIcon color="primary" />
						<Typography variant="label-s" color="primary" marginLeft="0.625rem">
							<Tr.Login path="new-password.back" />
						</Typography>
					</Stack>
					<TableButton.GotoAnalysis
						data-testid="save-new-pass-id"
						type="submit"
						size="small"
						disabled={!patternValid || !formik.isValid}
						sx={{ marginLeft: 'auto', display: 'flex', width: 'fit-content', height: '2.5rem' }}
					>
						<Tr.Login path="new-password.save-password" />
					</TableButton.GotoAnalysis>
				</Stack>
			</form>
		</Stack>
	);
};
