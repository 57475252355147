import { Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import type { TimeRangePickerProps } from 'antd';
import { DataTestId } from '@utils/DataTestId';
interface IPresetTypographyProps {
	label: React.ReactNode;
	value: Dayjs[];
	chosenValue: Dayjs[];
}
export const PresetTypography: FC<IPresetTypographyProps> = ({ label, value, chosenValue }) => {
	const isActive = value
		?.map((date) => date?.format('YYYY-MM-DD'))
		.every((item) => chosenValue?.map((date) => date?.format('YYYY-MM-DD')).includes(item));
	return (
		<Typography
			variant="pg-s"
			color={isActive ? 'primary.main' : 'text.primary'}
			data-testid={DataTestId.getStaticTestId('preset-dates-id')}
		>
			{label}
		</Typography>
	);
};
const MONTHS = {
	THREE: 3,
	SIX: 6,
	YEAR: 12,
};
export const PresetDate = (chosenValue: Dayjs[], clearDates?: () => void): TimeRangePickerProps['presets'] => {
	const currentDay = dayjs();
	const rangePresets: TimeRangePickerProps['presets'] = [
		{
			label: (
				<Typography
					sx={{
						'&:hover': {
							cursor: 'pointer',
						},
					}}
					variant="h6"
				>
					<Tr.Admin path="organization.set-period" />
				</Typography>
			),
			value: [chosenValue?.[0], chosenValue?.[1]],
		},
		{
			label: (
				<PresetTypography
					label={<Tr.Common path="3-months" />}
					value={[dayjs(), dayjs().add(MONTHS.THREE, 'M')]}
					chosenValue={chosenValue}
				/>
			),
			value: [currentDay, dayjs().add(MONTHS.THREE, 'M')],
		},
		{
			label: (
				<PresetTypography
					label={<Tr.Common path="6-months" />}
					value={[dayjs(), dayjs().add(MONTHS.SIX, 'M')]}
					chosenValue={chosenValue}
				/>
			),
			value: [currentDay, dayjs().add(MONTHS.SIX, 'M')],
		},
		{
			label: (
				<PresetTypography
					label={<Tr.Common path="1-year" />}
					value={[dayjs(), dayjs().add(MONTHS.YEAR, 'M')]}
					chosenValue={chosenValue}
				/>
			),
			value: [currentDay, dayjs().add(MONTHS.YEAR, 'M')],
		},
		{
			label: (
				<Typography data-testid={'clear-test-id'} variant="pg-s" position="absolute" bottom={20} onClick={() => clearDates?.()}>
					<Tr.Common path="clear" />
				</Typography>
			),
			value: [null, null],
		},
	];
	return rangePresets;
};
