import { INotification } from './notification.interface';
import { BehaviorSubject, concatMap, map, tap } from 'rxjs';
import { NotificationType } from './notification-type.enum';
import { coreConfig } from '../coreConfig';
import { httpService } from '../http';
import { logger } from '../logger';
import { RxUtils, TypeUtils } from '@utils';
import { AppNotification, PagedResult, PatchType } from '@models';
import { getStore } from '@store';
import { setNotificationCount } from '@store/slices/notification.slice';
import { NOTIF_FILTER_LIST } from './notification-filter.constants';

class NotificationService {
	private getUrl() {
		return `${coreConfig.url}/notification`;
	}
	private _notifications = new BehaviorSubject<Maybe<INotification>>(null);

	// used to filter the 'Refresh Token has expired error
	// can be extended by adding new values to notification-filter.constants.ts file
	private checkFilter(notif: INotification): boolean {
		return NOTIF_FILTER_LIST.some((filter) => {
			return notif[filter.key] === filter.value;
		});
	}

	getSubject = () => this._notifications;

	send = (notif: INotification): unknown => {
		// TODO: Find a better method to filter notifications
		if (this.checkFilter(notif)) {
			return this._notifications.next(null);
		}
		return this._notifications.next(notif);
	};
	clear = (): unknown => this._notifications.next(null);

	sendSuccess = (message: string, params?: Omit<INotification, 'type' | 'message'>): void => {
		this._notifications.next({ type: NotificationType.SUCCESS, message, ...params });
	};

	sendError = (message: string, params?: Omit<INotification, 'type' | 'message'>): void => {
		this._notifications.next({ type: NotificationType.ERROR, message, ...params });
	};

	getNotifications(pageIndex: number, pageSize: number, isRead: boolean, isPermanent?: boolean) {
		return httpService
			.get<PagedResult<AppNotification>>(this.getUrl(), ``, {
				params: {
					pageIndex,
					pageSize,
					isRead,
					isPermanent,
				},
				errorHandler: 'notification',
				disableLoader: true,
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getNotifications.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => {
					!isRead && !isPermanent && getStore().store.dispatch(setNotificationCount({ unreadNotificationCount: result.count }));
					return TypeUtils.transformFromExist(new PagedResult<AppNotification>(AppNotification), result);
				}),
			);
	}

	updateNotificationStatus(id: number, maskAsRead: boolean) {
		return httpService.patch(this.getUrl(), `${id}`, {
			body: [
				{
					op: PatchType.REPLACE,
					path: '/IsRead',
					value: maskAsRead,
				},
			],
			errorHandler: 'notification',
		});
	}
	updateBatchNotificationStatus() {
		return httpService.put(this.getUrl(), undefined, {});
	}
}

export const notificationService = new NotificationService();
