import { createContext } from 'react';
import { IProps, IrProps } from './props';

export const AnalysisContext = createContext<IProps>({
	addedERMs: [],
	updateAddedERMs: () => null,
	quantitativeStep: null,
	updateQuantitativeStep: () => null,
	nmrDevice: null,
	updateNmrDevice: () => null,
	measurementType: null,
	updateMeasurementType: () => null,
	targetType: null,
	updateTargetType: () => null,
	metaData: null,
	updateMetaData: () => null,
	changedPeaks: [],
	updateChangedPeaks: () => null,
	isAnalysisEditable: null,
	updateAnalysisEditable: () => null,
});

export const IrAnalysisContext = createContext<IrProps>({
	addedERMs: [],
	updateAddedERMs: () => null,
	quantitativeStep: null,
	updateQuantitativeStep: () => null,
	irDevice: null,
	updateIrDevice: () => null,
	measurementTypeIr: null,
	analysisType: null,
	updateMeasurementType: () => null,
	updateAnalysisType: () => null,
	irAccessory: null,
	updateIrAccessory: () => null,
	metaData: null,
	updateMetaData: () => null,
	isAnalysisEditable: null,
	updateAnalysisEditable: () => null,
	updateSelectedLibrary: () => null,
	selectedLibrary: [],
	isPeaksChanged: null,
	updatePeaksChanged: () => null,
});
