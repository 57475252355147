import { QualityGrade, QualityGradeTextMapping } from '@models/reference-material';
import { Chip, SxProps, Typography } from '@mui/material';
import { TypeUtils } from '@utils/Type';
import { FC, useMemo } from 'react';

type TQualityGradeChip = {
	isReferenceActive: boolean;
	qualityGrade: QualityGrade;
};

interface IChipStyle {
	typographySx: SxProps;
	chipSx: SxProps;
}

const QualityGradeStyleMapping: Record<QualityGrade, IChipStyle> = {
	[QualityGrade.DRM]: {
		typographySx: {
			color: 'primary.main',
		},
		chipSx: {
			backgroundColor: 'primary.400',
		},
	},
	[QualityGrade.DAS]: {
		typographySx: { color: 'info.dark' },
		chipSx: {
			backgroundColor: '#ECF5FC',
		},
	},
	[QualityGrade.THEORETICAL]: {
		typographySx: { color: 'info.700' },
		chipSx: {
			backgroundColor: 'info.600',
		},
	},
};

export const QualityGradeChip: FC<TQualityGradeChip> = ({ isReferenceActive, qualityGrade }) => {
	const getCurrentStyle: () => IChipStyle = () => {
		if (isReferenceActive) {
			return TypeUtils.returnValueOfKey(qualityGrade, QualityGradeStyleMapping);
		}
		return { typographySx: { color: 'grey.500' }, chipSx: { backgroundColor: 'grey.100' } };
	};

	const style = useMemo(() => getCurrentStyle(), [isReferenceActive, qualityGrade]) ?? {};
	return (
		<Chip
			size="small"
			variant="filled"
			sx={{ ...style?.chipSx }}
			label={
				<Typography variant="subtitle3" sx={{ ...style?.typographySx }}>
					{TypeUtils.returnValueOfKey(qualityGrade, QualityGradeTextMapping)}
				</Typography>
			}
		/>
	);
};
