import { useState } from 'react';
import { NmrCalibrationStatus, notificationService } from '@services';
import { useTranslation } from 'react-i18next';
import { IRecalibrationType } from './DeviceUtils';
import { useSignalRHubConnection } from '@hooks/useSignalRHubConnection';
import { config } from '@config';
import { HubConnection } from '@microsoft/signalr';
import { DeviceCalibrationStatusResponse } from '../DeviceCalibrations';

export const useDeviceStatusHook = (deviceData?: IRecalibrationType, refreshDeviceData?: () => void) => {
	const deviceId = deviceData?.calibrationData?.id;
	const [isCalibrationProgress, setIsCalibrationProgress] = useState(false);
	const { t } = useTranslation('portal');

	useSignalRHubConnection({
		hubUrl: config.deviceHubUrl,
		body: (connection: HubConnection) => {
			setIsCalibrationProgress(true);
			connection.send('CheckDeviceCalibrationStatus', deviceId);
			connection.on('CalibrationCompleted', (response: string) => {
				const calibrationResponse = JSON.parse(response) as DeviceCalibrationStatusResponse;
				if (!calibrationResponse || !calibrationResponse.Statuses) return;

				const calibrating = calibrationResponse.Statuses.some((status) => status === NmrCalibrationStatus.CALIBRATING);
				if (calibrating) return;

				const isCalibrationError = calibrationResponse.Statuses[0] === NmrCalibrationStatus.CALIBRATION_ERROR;
				const isActive = calibrationResponse.Statuses[0] === NmrCalibrationStatus.ACTIVE;

				if (isActive || isCalibrationError) {
					refreshDeviceData?.();
					const errorMessage = calibrationResponse?.ErrorMessage ?? '';
					setIsCalibrationProgress(false);
					isCalibrationError
						? notificationService.sendError(errorMessage)
						: notificationService.sendSuccess(t('device-management.calibrated-successfully'));
				}
			});
		},
		shouldStartConnection: () =>
			!!deviceId &&
			deviceData?.calibrationData?.nmrDeviceCalibrations?.some((cal) => cal.status === NmrCalibrationStatus.CALIBRATING),
		deps: [deviceData?.calibrationData],
	});

	return { isCalibrationProgress, setIsCalibrationProgress };
};
