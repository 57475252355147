import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { TypeUtils } from '@utils/Type';
import { FC } from 'react';

interface ActiveStateColors {
	backgroundColor: string;
	color: string;
}

const StateColorMap: Record<string, ActiveStateColors> = {
	ACTIVE: { backgroundColor: 'primary.main', color: 'white' },
	INACTIVE: { backgroundColor: 'grey.100', color: 'grey.500' },
};

export const ActiveState: FC<{ status: string }> = ({ status }) => {
	const style = TypeUtils.returnValueOfKey<typeof StateColorMap, ActiveStateColors>(status, StateColorMap);
	return (
		<Stack
			sx={{
				...style,
				padding: '6px',
				minWidth: 0,
				minHeight: 0,
				height: 'fit-content',
				width: 'fit-content',
				borderRadius: '4px',
			}}
		>
			<Typography variant="subtitle3" lineHeight="12px" color={style?.color}>
				<Tr.Common path={status?.toLowerCase()} />
			</Typography>
		</Stack>
	);
};
