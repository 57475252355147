import { FC, useCallback } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { notificationService, NotificationType } from '@services';
import { FileValidationStatus } from '@models';
import { nameLengthValidator, Tr } from '@utils';
import { Button, Paper, PaperProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload.svg';

const MAX_FILE_SIZE = 209715200;

export interface IFileUploader {
	multiple?: boolean;
	onUploadedFileChange?: (f?: unknown[]) => void;
	disable?: boolean;
	message?: string;
}

export const IrFileUploader: FC<IFileUploader & PaperProps> = ({
	onUploadedFileChange,
	multiple = false,
	disable = false,
	message,
	sx,
}) => {
	const { t } = useTranslation('irportal');
	const errorMapping: Record<FileValidationStatus, string> = {
		[FileValidationStatus.INVALID]: t('new-analysis.ir-file-type-error'),
		[FileValidationStatus.MAX_SIZE]: t('new-analysis.file-size-error'),
		[FileValidationStatus.NAME_LARGE]: t('new-analysis.file-length-error'),
		[FileValidationStatus.INVALID_DATA_TYPE]: t('new-analysis.file-data-type-error'),
	};
	const onDrop = useCallback<Required<DropzoneOptions>['onDrop']>((file) => {
		if (file) {
			onUploadedFileChange?.(file);
		}
	}, []);
	const onDropRejected = ([{ errors }]: FileRejection[]) => {
		notificationService.send({
			type: NotificationType.ERROR,
			message: errorMapping[errors[0].code],
		});
	};
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: multiple,
		accept: {
			'chemical/x-jcamp-dx': ['.dx', '.spa', '.spc', '.0', '.sp'],
		},
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
		onDropRejected,
	});

	const getBorderColor = () => {
		if (isDragActive) {
			return 'grey.700';
		} else {
			return 'grey.200';
		}
	};

	return (
		<Paper
			{...getRootProps()}
			elevation={0}
			square
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				height: 40,
				paddingX: 1,
				paddingTop: 0.5,
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: getBorderColor(),
				backgroundColor: 'background.paper',
				pointerEvents: disable ? 'none' : 'initial',
				...sx,
			}}
		>
			<input {...getInputProps()} data-testid="file-upload-id" />
			<CloudUpload width={24} height={24} fill="#01884C" />
			<Typography
				variant="body2"
				sx={{
					color: disable ? 'grey.400' : 'grey.800',
					marginLeft: 5,
					marginRight: 5,
					fontSize: 14,
					fontWeight: 700,
				}}
			>
				{message ?? <Tr.IrPortal path="new-analysis.drag-and-drop-file-here-device" />}
			</Typography>
			<Button data-testid="file-upload-button-id" disabled={disable}>
				<AddBoxOutlinedIcon />
			</Button>
		</Paper>
	);
};
