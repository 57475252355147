import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';
import { TableButton } from '@components/portal';
import { Stack, Typography } from '@mui/material';
import { DataTestId, DateUtils, Tr } from '@utils';
import { getParams, useFilterSearchParams, useService } from '@hooks/index';
import { ExtendedColumn, PagedResult, PortalPageRoutes, SortOrder } from '@models';
import { DataTableBackend, MerckCalibrationChip, OverflowText } from '@components/common';
import { nmrDeviceService, NmrDeviceCalibrationForList, NmrCalibrationStatus } from '@services';

export const InactiveDeviceCalibrationTable: FC = () => {
	const [searchText, setSearchText] = useState('');
	const { searchParams } = useFilterSearchParams('createTime', SortOrder.DESC);

	const { data: calibrationData } = useService<PagedResult<NmrDeviceCalibrationForList>>(() => {
		searchParams.set('statuses', NmrCalibrationStatus.INACTIVE);
		setSearchText(getParams(searchParams).getQuery());
		return nmrDeviceService.getAllDeviceCalibrations(searchParams);
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<NmrDeviceCalibrationForList>[]>(
		() => [
			{
				accessor: 'calibrationStatus',
				disableSortBy: true,
				Header: <Tr.Portal path="device-management.status" />,
				Cell: ({ value }) => <MerckCalibrationChip status={value} />,
			},
			{
				accessor: 'calibrationName',
				Header: <Tr.Portal path="device-management.calibration-name" />,
				Cell: ({ value }) => (
					<OverflowText variant="pg-s" enableTooltip={true}>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'name',
				Header: <Tr.Portal path="device-management.name" />,
				Cell: ({ value }) => (
					<OverflowText variant="pg-s" enableTooltip={true}>
						{value}
					</OverflowText>
				),
			},
			{
				accessor: 'model',
				Header: <Tr.Portal path="device-management.manufacturer" />,
				Cell: ({ value }) => (
					<Typography variant="pg-s">{`${value?.[0]?.toUpperCase()}${value?.substring(1)?.toLowerCase()}`}</Typography>
				),
			},
			{
				accessor: 'frequency',
				Header: <Tr.Portal path="device-management.frequency" />,
				Cell: ({ value }) => <Typography variant="pg-s">{value ? `${value} MHz` : '-'}</Typography>,
			},
			{
				accessor: 'calibrationDeactivationTime',
				Header: <Tr.Portal path="device-management.deactivation-date" />,
				Cell: ({ value }) => <Typography variant="pg-s">{DateUtils.getFormattedDate(value, 'dd/MM/yyyy')}</Typography>,
			},
			{
				accessor: 'nmrDeviceId',
				disableSortBy: true,
				sticky: true,
				Cell: ({ value }) => (
					<Stack alignItems="flex-end">
						<TableButton.ViewSummary
							sx={{ fontSize: 14, width: '7rem' }}
							data-testid={DataTestId.getStaticTestId('navigation-btn-id')}
							href={`../../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/${value}`}
						>
							<Tr.Portal path="device-management.go-to-device" />
						</TableButton.ViewSummary>
					</Stack>
				),
			},
		],
		[],
	);

	return (
		<DataTableBackend
			columns={columns}
			searchText={searchText}
			sx={{ marginX: 2, width: 'auto' }}
			onSearchTextChange={setSearchText}
			data={calibrationData?.data || []}
			searchPlaceholder={t('device-management.search-placeholder', { ns: 'portal' })}
			data-testid={DataTestId.getStaticTestId('inactive-device-calibration-table-id')}
			searchFieldProps={{ sx: { width: 1, maxWidth: 800 }, 'aria-label': 'device-search-id' }}
			pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: calibrationData?.totalPages ?? 1 }}
			sorting={{
				sortBy: getParams(searchParams).getSortBy(),
				sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
			}}
		/>
	);
};
