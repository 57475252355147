import { combineReducers } from '@reduxjs/toolkit';
import { reducer as Common } from './slices/common.slice';
import { reducer as Location } from './slices/location.slice';
import { reducer as Notification } from './slices/notification.slice';
import { reducer as Test } from './slices/test.slice';
import { reducer as Pipeline } from './slices/pipeline.slice';
import { reducer as IrSettings } from './ir-slices/ir.slice';

export const rootReducer = combineReducers({
	Common,
	Location,
	Notification,
	Test,
	Pipeline,
	IrSettings,
});
