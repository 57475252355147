import { MetaDataType } from '@hooks/useNmrParser';
import { QualitativeOrQuantitative } from '@models/analysis';
import { NmrCalibrationStatus, NmrDevice } from '@services/nmr';

export type MeasurementTypeError =
	| 'noError'
	| 'noDevice'
	| 'inactiveDevice'
	| 'noActiveCalibration'
	| 'noRG'
	| 'noProbId'
	| 'inactiveDevice'
	| 'waitsCalibration'
	| 'receiverGainThreshold';

export const isRgInsideRange = (nmrDevice: Maybe<NmrDevice>, metaData: Maybe<MetaDataType>) => {
	const receiverGain = metaData?.RG;
	if (!receiverGain || !nmrDevice) {
		return false;
	}
	const numberReceiverGain = Number(receiverGain);
	if (isNaN(numberReceiverGain)) {
		return false;
	}
	const isValid = nmrDevice.nmrDeviceCalibrations?.some(
		(calibration) =>
			numberReceiverGain >= calibration.receiverGainThresholds.min && numberReceiverGain <= calibration.receiverGainThresholds.max,
	);
	return isValid;
};

export const getErrorType = (
	nmrDevice: Maybe<NmrDevice>,
	metaData: Maybe<MetaDataType>,
	measurementType: Maybe<QualitativeOrQuantitative>,
) => {
	let legendType: MeasurementTypeError = 'noError';
	if (measurementType !== QualitativeOrQuantitative.QUANTITATIVE) {
		return legendType;
	}
	const hasAnyActiveCalibration = nmrDevice?.nmrDeviceCalibrations?.some((d) => d.status === NmrCalibrationStatus.ACTIVE);
	const hasAnyCalibrating = nmrDevice?.nmrDeviceCalibrations?.some((d) => d.status === NmrCalibrationStatus.CALIBRATING);
	if (!nmrDevice) {
		legendType = 'noDevice';
	} else if (!nmrDevice.isActive) {
		legendType = 'inactiveDevice';
	} else if (!hasAnyActiveCalibration && !hasAnyCalibrating) {
		legendType = 'noActiveCalibration';
	} else if (!hasAnyActiveCalibration && hasAnyCalibrating) {
		legendType = 'waitsCalibration';
	} else if (!isRgInsideRange(nmrDevice, metaData)) {
		legendType = 'receiverGainThreshold';
	}

	legendType = !metaData?.RG ? 'noRG' : legendType;
	legendType = !metaData?.PROBHD ? 'noProbId' : legendType;

	return legendType;
};

export const isAnalysisPossible = (
	nmrDevice: Maybe<NmrDevice>,
	metaData: Maybe<MetaDataType>,
	measurementType: Maybe<QualitativeOrQuantitative>,
) => {
	if (measurementType !== QualitativeOrQuantitative.QUANTITATIVE) {
		return true;
	}

	if (!nmrDevice || !nmrDevice?.isActive || !nmrDevice?.nmrDeviceCalibrations?.some((d) => d.status === NmrCalibrationStatus.ACTIVE)) {
		return false;
	}

	if (!metaData?.RG || !metaData.PROBHD) {
		return false;
	}
	return isRgInsideRange(nmrDevice, metaData);
};
