import { FC, ReactNode, useState } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import { NMRPeaksTable, StackedSpectrum } from '@components/portal';
import { NmrAnalysisResult } from '@services';
import { Tr } from '@utils/Translation';
import { SpectrumChart } from '@components/common';
import { ChartSelectButton, ChartType } from '../ChartSelectButton';
import { OverlaySpectrum } from '../OverlaySpectrum';
import { TypeUtils } from '@utils/Type';

export const MolecularImage: FC<{ src: string; title: ReactNode }> = ({ src, title }) => (
	<Stack data-testid={`molecular-image-${title}`}>
		<Typography variant="h3" sx={{ color: 'primary.main' }} textAlign="left" marginBottom={2}>
			<Tr.Portal path="analysis-result.molecule-structure" />: <span style={{ color: '#5E5E72' }}>{title}</span>
		</Typography>
		<Stack sx={{ borderRadius: '8px', border: '1px solid', borderColor: 'grey.200', padding: '1rem' }}>
			<img src={src} crossOrigin="use-credentials" style={{ maxWidth: '300px', margin: 'auto' }} id="molecular-image" />
		</Stack>
	</Stack>
);

interface IReferenceMaterialResultViewProps {
	resultData: NmrAnalysisResult;
	selectedErmIndex: number;
	containerSx?: SxProps;
	hasSubtitle?: boolean;
}

export const ReferenceMaterialResultView: FC<IReferenceMaterialResultViewProps> = ({
	selectedErmIndex,
	resultData,
	containerSx,
	hasSubtitle = false,
}) => {
	const [chartType, setChartType] = useState<ChartType>(ChartType.SEPARATE_CHART);

	const { resultMatches } = resultData;
	const isSeparateChart = () => chartType === ChartType.SEPARATE_CHART;
	const isOverlayChart = () => chartType === ChartType.OVERLAY;

	const getSelectedDrm = () => {
		// Security Check
		if (TypeUtils.isNumber(selectedErmIndex)) {
			return resultData.resultMatches[`${selectedErmIndex}`];
		}
	};

	const selectedDrm = getSelectedDrm();
	return resultMatches ? (
		<Stack rowGap={4} sx={{ ...containerSx, marginTop: !resultData.isQualitative() ? '1rem' : '-2rem' }}>
			<Stack direction="row">
				<Stack sx={{ width: '100%' }}>
					{resultData.isQualitative() && (
						<Stack sx={{ marginLeft: 'auto', marginBottom: '1rem' }}>
							<ChartSelectButton onChange={(selected) => setChartType(selected)} isOverlay={isOverlayChart()} />
						</Stack>
					)}

					{isOverlayChart() && resultData.isQualitative() && (
						<OverlaySpectrum
							subtitleVisibility={hasSubtitle}
							ermSpectrumUrl={selectedDrm?.rmSpectrum ?? ''}
							sampleResultSpectrumUrl={selectedDrm?.sampleSpectrum ?? ''}
						/>
					)}

					{isSeparateChart() && resultData.isQualitative() && (
						<StackedSpectrum
							subtitleVisibility={hasSubtitle}
							referenceMaterialId={resultData?.referenceMaterials[0]?.id}
							ermSpectrumUrl={selectedDrm?.rmSpectrum ?? ''}
							sampleResultSpectrumUrl={selectedDrm?.sampleSpectrum ?? ''}
						/>
					)}

					{!resultData.isQualitative() && (
						<Stack
							sx={{
								border: '1px solid black',
								borderColor: 'grey.200',
								borderRadius: '8px',
								overflow: 'hidden',
								padding: '0.5rem 1rem 0 0',
							}}
						>
							<SpectrumChart
								subtitleVisibility={true}
								subTitle={<Tr.Portal path="analysis-result.intensity" />}
								color="#e73c98"
								fileUrl={selectedDrm?.sampleSpectrum}
								layout={{
									margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
									hovermode: 'x unified',
									legend: {
										y: 5,
										xanchor: 'center',
										x: 0.5,
										orientation: 'h',
										bgcolor: 'transparent',
										borderwidth: 1,
										bordercolor: '#E1E1EA',
										font: { color: '#0F1A2E', size: 12 },
									},
									showlegend: true,
									xaxis: {
										range: [10, 0],
										showdividers: false,
										showline: true,
										zeroline: false,
									},
									yaxis: {
										showline: true,
									},
								}}
								enableZoom={false}
								uniqueId="reference-material-chart"
							/>
						</Stack>
					)}
				</Stack>
			</Stack>

			{selectedDrm?.moleculeImage && <MolecularImage src={selectedDrm?.moleculeImage} title={selectedDrm?.substanceName} />}

			<Stack>
				<NMRPeaksTable sampleName={resultData.nmrAnalysis.title} resultData={resultData} selectedErmIndex={selectedErmIndex} />
			</Stack>
		</Stack>
	) : null;
};
