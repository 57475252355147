import { FileUploader } from '@components/common';
import { Stack, Typography } from '@mui/material';
import { FC, SetStateAction } from 'react';
import { DeviceErrorMessage } from './DeviceError';
import { DeviceList } from '@components/portal/DeviceList';
import { CalibrationInstruction } from '../CalibrationInstruction';
import { manipulatedReceiverThreshold } from './DeviceUtils';
import { IFileStateType } from './DeviceFileHook';
import { NmrReceiverGainThresholds } from '@services/nmr';
import { ReactComponent as Counter1 } from '@material-symbols/svg-600/outlined/counter_1-fill.svg';
import { useTranslation } from 'react-i18next';
import { FileValidationStatus, UploadableFileExtensionMapping } from '@models/file';
type TDeviceFileUpload = {
	canCalibrate: boolean;
	files: IFileStateType[];
	hide: boolean;
	onUploadedFileChange: (f?: unknown[] | undefined) => void;
	outOfRangeFiles: string[];
	removeFile: (index: number) => void;
	setOutOfRangeFiles: (value: SetStateAction<string[]>) => void;
	receiverGainRange: NmrReceiverGainThresholds[] | undefined;
};

export const DeviceFileUpload: FC<TDeviceFileUpload> = ({
	hide,
	onUploadedFileChange,
	canCalibrate,
	files,
	outOfRangeFiles,
	removeFile,
	setOutOfRangeFiles,
	receiverGainRange,
}) => {
	const { t } = useTranslation('portal');
	const errorMapping: Record<FileValidationStatus, string> = {
		[FileValidationStatus.INVALID]: t('new-analysis.jdx-file-type-error'),
		[FileValidationStatus.MAX_SIZE]: t('new-analysis.file-size-error'),
		[FileValidationStatus.NAME_LARGE]: t('new-analysis.file-length-error'),
		[FileValidationStatus.INVALID_DATA_TYPE]: t('new-analysis-file-data-type-error'),
	};
	return (
		<Stack>
			<Stack direction="row">
				<Counter1 width={24} height={24} fill="#01884C" />
				<Typography variant="h4" color="text.primary" marginLeft={1}>
					{t('device-management.upload-files')}
				</Typography>
			</Stack>
			<Stack spacing={2}>
				<CalibrationInstruction isCheckCalibration={false} />
				{!hide && (
					<FileUploader
						errorMapping={errorMapping}
						disable={hide}
						multiple={true}
						onUploadedFileChange={onUploadedFileChange}
						sx={{ marginTop: 3 }}
						accept={UploadableFileExtensionMapping.Device}
					/>
				)}
				{files.length > 0 && (
					<DeviceList
						files={files}
						outOfRangeFiles={outOfRangeFiles}
						onRemove={(index) => {
							removeFile(index), setOutOfRangeFiles([]);
						}}
						onUploadedFileChange={onUploadedFileChange}
						manipulatedReceiverThreshold={() =>
							manipulatedReceiverThreshold(undefined, files[0], receiverGainRange) ?? undefined
						}
					/>
				)}
				<DeviceErrorMessage canCalibrate={canCalibrate} files={files} outOfRangeFiles={outOfRangeFiles} />
			</Stack>
		</Stack>
	);
};
