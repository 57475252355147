import { IRouterLinkButtonProps, RouterLinkButton } from '@components/common';
import { Tr } from '@utils/Translation';
import { ExtendedComponent } from 'src/types';

const RouterButton: ExtendedComponent<IRouterLinkButtonProps> = ({ children, sx, ...props }) => (
	<RouterLinkButton
		sx={{
			height: '2rem',
			fontSize: '0.875rem',
			width: '7rem',
			lineHeight: '120%',
			padding: 1,
			borderWidth: '2px',
			borderColor: 'primary.main',
			'&:hover': {
				borderWidth: '2px',
			},
			...sx,
		}}
		variant="outlined"
		size="small"
		disableElevation
		{...props}
	>
		{children}
	</RouterLinkButton>
);

type TableButtonType = 'ViewSummary' | 'GotoAnalysis' | 'Export';
export const TableButton: Record<TableButtonType, ExtendedComponent<IRouterLinkButtonProps>> = {
	ViewSummary: ({ href, sx, children, ...props }) => (
		<RouterButton href={href} sx={sx} {...props}>
			{children || <Tr.Common path="view-summary" />}
		</RouterButton>
	),
	GotoAnalysis: ({ href, sx, children, ...props }) => (
		<RouterButton href={href} sx={sx} variant="contained" {...props}>
			{children || <Tr.Portal path="go-to-analysis" />}
		</RouterButton>
	),
	Export: ({ sx, children, ...props }) => (
		<RouterButton sx={sx} variant="text" {...props}>
			{children || <Tr.Portal path="export" />}
		</RouterButton>
	),
};
