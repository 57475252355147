import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface INotificationStore {
	unreadNotificationCount: Maybe<number>;
}

export const {
	actions: { setNotificationCount },
	reducer,
} = createSlice({
	name: 'notification',
	initialState: {
		unreadNotificationCount: 0,
	},
	reducers: {
		setNotificationCount: (state, data: PayloadAction<Maybe<INotificationStore>>) => {
			state.unreadNotificationCount = data.payload?.unreadNotificationCount || 0;
		},
	},
});

export const useNotificationSelector = (state: RootState) => state.Notification;
