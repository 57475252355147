import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { IFileStateType } from './DeviceFileHook';
import { checkFieldsSame, isEqualMaxFileSize } from '@utils/Device';
import { IRecalibrationType } from './DeviceUtils';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

interface IDeviceErrorMessageProps {
	files: IFileStateType[];
	canCalibrate: boolean;
	outOfRangeFiles: string[];
	deviceData?: IRecalibrationType;
}

export const DeviceErrorMessage: FC<IDeviceErrorMessageProps> = ({ files, outOfRangeFiles, canCalibrate, deviceData }) => {
	const getErrorMessage = () => {
		if (!canCalibrate && outOfRangeFiles.length > 0) {
			return (
				<Tr.Portal
					path={`device-management.calibration-receiver-range-error-${outOfRangeFiles.length === 1 ? 'single' : 'multi'}`}
					options={{ filename: outOfRangeFiles.join(', ') }}
				/>
			);
		} else if (isEqualMaxFileSize(files) && !canCalibrate) {
			if (deviceData?.calibrationData && !checkFieldsSame(deviceData.calibrationData, files))
				return <Tr.Portal path="device-management.calibration-file-device-doesnt-match-error" />;
			return <Tr.Portal path="device-management.calibration-file-error" />;
		}

		return null;
	};
	const errorMessage = getErrorMessage();
	return (
		<Stack sx={{ marginBottom: 32 }}>
			{errorMessage ? (
				<Stack sx={{ padding: 2, backgroundColor: 'error.200', borderRadius: 3, justifyContent: 'start' }}>
					<Stack direction="row" spacing={1.25}>
						<ErrorOutlinedIcon sx={{ height: 22, width: 22, color: 'error.main', marginLeft: 0.25 }} />
						<Typography variant="h5" sx={{ color: 'error.main', transform: 'translateY(-1px)' }}>
							<Tr.Portal path="device-management.warning" />
						</Typography>
					</Stack>
					<Typography variant="pg-s" sx={{ marginTop: 1.25, marginLeft: 4 }}>
						{getErrorMessage()}
					</Typography>
				</Stack>
			) : null}
		</Stack>
	);
};
