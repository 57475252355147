import { TFunction } from 'i18next';
import * as yup from 'yup';
import { getFreeSubscriptionSchema, getPaidSubscriptionSchema } from '@schemas/subscription';
import { getLabValidationSchema } from '@schemas/lab';
import { CreateOrganizationPaidSubscriptionModel } from '@services/core';
import { getOrganizationValidationSchema } from './organization.validation';

export const getCreateOrganizationValidationSchema: (t: TFunction) => yup.AnySchema = (t) =>
	getOrganizationValidationSchema(t).concat(
		yup.object().shape({
			laboratories: yup.array().of(getLabValidationSchema(t)).required(),
			freeSubscription: getFreeSubscriptionSchema(t),
			paidSubscription: yup.lazy((value: CreateOrganizationPaidSubscriptionModel) => {
				if (value.paymentType) {
					return getPaidSubscriptionSchema(t);
				}
				return yup.object();
			}),
		}),
	);
