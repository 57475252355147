import { FileUploader, ModalDialog } from '@components/common';
import { FC, useState } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import {
	DeviceFormType,
	IFileStateType,
	IRecalibrationType,
	manipulatedReceiverThreshold,
	onCalibrationHealthCheck,
	unknownToFile,
	useDeviceFormik,
	useDeviceFileHook,
} from '../DeviceForm';
import { notificationService } from '@services/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeviceList } from '@components/portal/DeviceList';
import { NmrDeviceCalibration, nmrDeviceService } from '@services/nmr';
import { useService } from '@hooks/useService';
import { Tr } from '@utils/Translation';
import { checkFieldsSame, isEqualHealthCheckSize } from '@utils/Device';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { useDeviceOutlet } from '@hooks';
import { FileValidationStatus, UploadableFileExtensionMapping } from '@models/file';

type THealthCheckErrorMessage = {
	files: IFileStateType[];
	canCalibrate: boolean;
	deviceData?: IRecalibrationType;
};

const HealthCheckErrorMessage: FC<THealthCheckErrorMessage> = ({ files, canCalibrate, deviceData }) => {
	const getCalibrationErrorMessage = () => {
		if (files.length === 0) {
			return <Tr.Portal path="device-management.upload-jdx-to-continue" />;
		}
		if (!canCalibrate && isEqualHealthCheckSize(files)) {
			if (deviceData?.calibrationData && !checkFieldsSame(deviceData.calibrationData, files))
				return <Tr.Portal path="device-management.calibration-file-device-doesnt-match-error" />;
			return <Tr.Portal path="device-management.calibration-file-error" />;
		}
		return '';
	};

	const isError = files.length === 0 || (!canCalibrate && isEqualHealthCheckSize(files));
	return (
		<Stack direction="row" marginTop={1} color="error.main" spacing={0.75}>
			{isError && <ErrorOutlinedIcon sx={{ height: 14, width: 14, transform: 'translateY(1px)' }} />}
			<Typography variant="label-s">{getCalibrationErrorMessage()}</Typography>
		</Stack>
	);
};

type THealthCheckModalDialogProps = {
	calibration: NmrDeviceCalibration;
	onClose: () => void;
	refresh: () => void;
};

export const HealthCheckModalDialog: FC<THealthCheckModalDialogProps> = ({ calibration, onClose, refresh }) => {
	const [rawFiles, setRawFiles] = useState<File[]>([]);
	const { deviceData, setIsCalibrationProgress } = useDeviceOutlet();
	const { data: receiverGainRange } = useService(() => nmrDeviceService.receiverGainThreshold(), []);
	const navigate = useNavigate();
	const { t } = useTranslation('portal');
	const errorMapping: Record<FileValidationStatus, string> = {
		[FileValidationStatus.INVALID]: t('new-analysis.file-type-error'),
		[FileValidationStatus.MAX_SIZE]: t('new-analysis.file-size-error'),
		[FileValidationStatus.NAME_LARGE]: t('new-analysis.file-length-error'),
		[FileValidationStatus.INVALID_DATA_TYPE]: t('new-analysis-file-data-type-error'),
	};
	const formik = useDeviceFormik({
		deviceData,
		formType: DeviceFormType.HEALTH_CHECK,
		onSubmit: () => {
			notificationService.clear();

			setTimeout(() => {
				setIsCalibrationProgress(true);
			}, 50);
			onCalibrationHealthCheck({
				files,
				successCb: () => refresh(),
				finalCb: () => {
					setIsCalibrationProgress(false);
					refresh();
				},
				deviceId: deviceData?.id || -1,
				calibrationId: calibration.id,
				navigate,
				t,
			});
		},
	});

	const { files, removeFile, canCalibrate, outOfRangeFiles, setOutOfRangeFiles } = useDeviceFileHook({
		newFile: rawFiles,
		formik,
		deviceData,
		isHealthCheck: true,
	});

	const hide = isEqualHealthCheckSize(files);

	return (
		<form onSubmit={formik.handleSubmit}>
			<ModalDialog
				variant="primary"
				open={true}
				onClose={onClose}
				contentProps={{ sx: { paddingX: 8, paddingBottom: 7, width: 472 } }}
				title={
					<Typography variant="pg-s">
						<Tr.Portal path="device-management.calibration-upload" />
					</Typography>
				}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginTop: 0,
						marginBottom: 5,
						minHeight: 30,
						backgroundColor: 'grey.50',
						paddingY: 1.2,
						paddingX: 2,
						height: 40,
					},
				}}
				PaperProps={{
					sx: {
						maxWidth: '472px',
						minHeight: '474px',
					},
				}}
				iconButtonProps={{
					right: 8,
				}}
			>
				<Stack width={1} direction="column" justifyContent="space-between">
					<Stack alignItems={'center'} spacing={3}>
						<Stack
							sx={{
								height: '80px',
								width: '80px',
								borderRadius: '50%',
								backgroundColor: 'primary.main',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CloudDoneIcon sx={{ height: 40, width: 40, color: 'white' }} />
						</Stack>

						<Stack alignItems={'center'} spacing={1.5}>
							<Typography variant="subtitle1">
								<Tr.Portal path="device-management.health-check" />
							</Typography>
							<Typography variant="pg-m">
								<Tr.Portal path="device-management.health-check-description" />
							</Typography>
						</Stack>

						<Stack width={1} paddingBottom={2}>
							{!hide && (
								<FileUploader
									disable={hide}
									multiple={false}
									onUploadedFileChange={(file) => setRawFiles(unknownToFile(file))}
									message={t('device-management.upload-to-continue')}
									containerProps={{
										spacing: 5,
									}}
									accept={UploadableFileExtensionMapping.Device}
									errorMapping={errorMapping}
								/>
							)}

							{files.length > 0 && (
								<DeviceList
									files={files}
									outOfRangeFiles={outOfRangeFiles}
									onRemove={(index) => {
										removeFile(index), setOutOfRangeFiles([]);
									}}
									onUploadedFileChange={(file) => setRawFiles(unknownToFile(file))}
									manipulatedReceiverThreshold={() =>
										manipulatedReceiverThreshold(deviceData?.calibrationData?.[0], files[0], receiverGainRange) ??
										undefined
									}
								/>
							)}
							<HealthCheckErrorMessage deviceData={deviceData} canCalibrate={canCalibrate} files={files} />
						</Stack>

						<Stack direction="row" alignSelf="flex-end" spacing={1} width={1}>
							<Button data-testid="add-device-cancel-button-id" variant="outlined" onClick={onClose} fullWidth>
								<Tr.Portal path="device-management.cancel" />
							</Button>
							<Button
								disabled={!canCalibrate || !formik.isValid}
								data-testid="add-device-submit-id"
								type="submit"
								variant="contained"
								disableElevation
								onClick={() => (formik.submitForm(), onClose())}
								fullWidth
							>
								<Tr.Portal path="device-management.calibrate" />
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</ModalDialog>
		</form>
	);
};
