import { FormikTextfield } from '@components/common/FormikTextfield';
import { ModalDialog } from '@components/common/ModalDialog';
import { SubscriptionType } from '@models/subscribe';
import { Button, Grid, Stack, StackProps, SxProps, Typography } from '@mui/material';
import { getSubscriptionLimitationsSchema } from '@schemas/subscription/subscription-limitations.validation';
import { Subscription, subscribeService } from '@services/core';
import { RxUtils } from '@utils/Rx';
import { formFieldSpacer } from '@utils/Theme';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormikDatePicker } from '../FormikDatePicker';
const labelStyle: SxProps = {
	fontSize: 14,
	fontWeight: 700,
	color: 'grey.800',
	'&::after': {
		content: '"*"',
		color: 'error.main',
	},
};
const textInputStyle: SxProps = {
	fieldset: {
		borderColor: 'grey.200',
		padding: 0,
		borderWidth: '2px',
	},
	input: {
		backgroundColor: 'grey.50',
		padding: '7px 14px',
	},
	'& .MuiFormHelperText-root.Mui-error': {
		position: 'absolute',
		top: '93%',
	},
};

const fullWidth: StackProps = { width: 1 };

interface IEditSubscriptionModalProps {
	subscriptionData?: Subscription;
	isOpen: boolean;
	onCancel?: () => void;
	onSave?: () => void;
}

type IEditSubscriptionFields = Extendable<Partial<Subscription>>;

export const EditSubscriptionModal: FC<IEditSubscriptionModalProps> = ({ subscriptionData, isOpen = false, onCancel, onSave }) => {
	const { t } = useTranslation('common'); // TO DO: WHY THIS IS IN COMMON?

	const { id } = useParams();
	const formik = useFormik<IEditSubscriptionFields>({
		initialValues: {
			endDate: subscriptionData?.endDate ?? '',
			budget: subscriptionData?.subscriptionType === SubscriptionType.FREE ? subscriptionData?.budget : undefined,
		},
		validationSchema: getSubscriptionLimitationsSchema(t, subscriptionData?.subscriptionType ?? ''),
		onSubmit: (fields: IEditSubscriptionFields) => {
			if (formik.isValid && id && subscriptionData) {
				RxUtils.promisify(
					subscribeService.changeSubscription(
						{
							id: subscriptionData.id,
							...fields,
						},
						+id,
					),
					() => {
						onSave?.();
					},
				);
			}
		},
	});

	return (
		<ModalDialog
			variant="secondary"
			open={isOpen}
			title={
				<Typography variant="pg-s">
					<Tr.Common path="subscription.edit" />
				</Typography>
			}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					maxWidth: '472px',
				},
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<Stack
					data-testid="edit-subscription-test-id"
					sx={{
						paddingX: '68px',
						marginY: 4,
					}}
					spacing={3}
				>
					<Stack direction="row" justifyContent="center" textAlign="center">
						<Typography variant="h4">
							<Tr.Common
								path={`subscription.edit-${(subscriptionData?.subscriptionType === SubscriptionType.FREE
									? SubscriptionType.FREE
									: subscriptionData?.paymentType
								)?.toLocaleLowerCase()}`}
							/>
						</Typography>
					</Stack>
					<Stack spacing={formFieldSpacer}>
						<FormikDatePicker
							formik={formik}
							name="endDate"
							title={t('subscription.end-date')}
							placeholder="dd/mm/yyyy"
							minDate={formik.initialValues.endDate ?? subscriptionData?.startDate}
							onChange={(value) => {
								formik.setFieldValue('endDate', value);
							}}
							required
						/>
						{subscriptionData?.subscriptionType === SubscriptionType.FREE && (
							<FormikTextfield
								formikApi={formik}
								name="budget"
								type="number"
								title={t('subscription.num-of-analyses')}
								placeholder={t('subscription.num-of-analyses')}
								variant="outlined"
								sx={textInputStyle}
								containerProps={fullWidth}
								typographyProps={{ sx: labelStyle }}
								onKeyDown={(event) => {
									if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e') {
										event.preventDefault();
									}
								}}
								required
							/>
						)}

						<Grid container paddingTop={1}>
							<Grid
								item
								xs={6}
								sx={{
									paddingRight: 1,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={true}
									data-testid={'edit-subs-cancel-button-id'}
									disableElevation
									onClick={() => {
										onCancel && onCancel();
									}}
								>
									<Tr.Portal path="cancel" />
								</Button>
							</Grid>

							<Grid
								item
								xs={6}
								sx={{
									paddingLeft: 1,
									paddingBottom: 3,
								}}
							>
								<Button
									fullWidth={true}
									data-testid="edit-subs-approve-button-id"
									variant="contained"
									type="submit"
									disableElevation
									disabled={!formik.dirty || !formik.isValid}
									sx={{ textTransform: 'capitalize' }}
								>
									<Tr.Portal path="save" />
								</Button>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
