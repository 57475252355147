import { FC, ReactNode } from 'react';
import { DataTestId, DateUtils } from '@utils';
import { IrDrmDetail, NmrDrmDetail } from '@services';
import { PageRoutes } from '@models/router';
import { IOverflowTextProps, ListCard, OverflowText } from '@components/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Substance } from '@services/core/substance';
import { Divider, Stack, Typography } from '@mui/material';
import { ReferenceTechnique } from '@services/core/substance/technique.enum';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

type TCardContent = {
	value: Maybe<string | number>;
	title: string | ReactNode;
	overflowTextProps?: Partial<IOverflowTextProps>;
	isActive: boolean;
	disableDivider?: boolean;
};

export const CardContent: FC<TCardContent> = ({ value, title, isActive, overflowTextProps, disableDivider = false }) => {
	return (
		<>
			{value && (
				<Stack direction="row" justifyContent="space-between" width="100%">
					<Stack direction="column" width={96}>
						<Typography variant="pg-s" color={isActive ? 'text.primary' : 'grey.500'}>
							{title}
						</Typography>
						<OverflowText
							variant="pg-s"
							width="100%"
							marginTop={0.5}
							color={isActive ? 'grey.800' : 'grey.500'}
							enableTooltip
							{...overflowTextProps}
						>
							{value}
						</OverflowText>
					</Stack>
					{!disableDivider && <Divider variant="middle" sx={{ color: 'grey.200', borderWidth: 0.5, height: 54 }} />}
				</Stack>
			)}
		</>
	);
};

type TIrDigitalRefernceCardContent = {
	dRM: IrDrmDetail;
};

const IrDigitalReferenceCardContent: FC<TIrDigitalRefernceCardContent> = ({ dRM }) => {
	const { t } = useTranslation('admin');
	const { resolution, samplingTechnique } = dRM;
	const isActive = dRM.isActive;
	return (
		<>
			<>
				<CardContent
					isActive={isActive}
					title={t('substance-detail.sampling-tech')}
					value={samplingTechnique}
					overflowTextProps={{
						'data-testid': DataTestId.getStaticTestId('drm-sampling-tech-id'),
					}}
				/>

				<CardContent
					isActive={isActive}
					title={t('substance-detail.resolution')}
					value={resolution}
					overflowTextProps={{
						'data-testid': DataTestId.getStaticTestId('drm-resolution-id'),
					}}
				/>
			</>
		</>
	);
};

type TNmrDigitalRefernceCardContent = {
	dRM: NmrDrmDetail;
};

const NmrDigitalReferenceCardContent: FC<TNmrDigitalRefernceCardContent> = ({ dRM }) => {
	const { t } = useTranslation('admin');
	const { solvent, frequency, isActive } = dRM;
	return (
		<>
			<CardContent
				isActive={isActive}
				value={solvent}
				title={t('substance-detail.solvent')}
				overflowTextProps={{
					'data-testid': DataTestId.getStaticTestId('drm-solvent-id'),
				}}
			/>

			<CardContent
				isActive={isActive}
				value={frequency}
				title={t('substance-detail.frequency')}
				overflowTextProps={{
					'data-testid': DataTestId.getStaticTestId('drm-frequency-id'),
				}}
			/>
		</>
	);
};

type TDigitalReference = {
	dRM: NmrDrmDetail | IrDrmDetail;
	substance: Substance;
};

const getIfNmr = (drm: NmrDrmDetail | IrDrmDetail) => {
	if (!('samplingTechnique' in drm)) {
		const nmrDrmDetail: NmrDrmDetail = drm;
		return nmrDrmDetail;
	}
	return undefined;
};

const getIfIr = (drm: NmrDrmDetail | IrDrmDetail) => {
	if ('samplingTechnique' in drm) {
		const nmrDrmDetail: IrDrmDetail = drm;
		return nmrDrmDetail;
	}
	return undefined;
};

const getSubstanceTechnique = (substance: Substance, drm: NmrDrmDetail | IrDrmDetail) => {
	if (substance.isTheoretical) {
		return ReferenceTechnique.NMR_THEORETICAL_SPECTRUM;
	}
	const nmrDetail = getIfNmr(drm);

	return nmrDetail ? ReferenceTechnique.NMR_REFERENCE_MATERIAL : ReferenceTechnique.IR_REFERENCE_MATERIAL;
};

export const DigitalReferenceCard: FC<TDigitalReference> = ({ dRM, substance }) => {
	const navigate = useNavigate();
	const { t } = useTranslation('admin');

	const { id: drmId, isActive, ermProductNumber, createTime, technology } = dRM;
	const convertedNmr = getIfNmr(dRM);
	const convertedIr = getIfIr(dRM);
	const { id: substanceId, isTheoretical } = substance;
	const navigateDrmDetail = () => {
		if (isTheoretical) {
			navigate(`/admin/${PageRoutes.SUBSTANCE_MANAGEMENT}/${PageRoutes.SUBSTANCE_DETAILS}/${substanceId}/theoretical/${drmId}`);
		} else {
			navigate(
				`/admin/${PageRoutes.DRM_MANAGEMENT}/${technology === SupportedTechnology.NMR ? technology : SupportedTechnology.IR}/${PageRoutes.EDIT_DRM}/${drmId}`,
			);
		}
	};

	const technique = getSubstanceTechnique(substance, dRM);
	return (
		<ListCard
			title={isTheoretical ? t('substance-detail.theoretical-spectrum') : (ermProductNumber ?? convertedIr?.drmCode)}
			technique={technique}
			isActive={isActive}
			onClick={navigateDrmDetail}
			qualityGrade={dRM?.qualityGrade}
		>
			<Stack direction="row" width={1} data-testid={DataTestId.getStaticTestId('drm-wrapper-id')}>
				{convertedIr && <IrDigitalReferenceCardContent dRM={convertedIr} />}
				{convertedNmr && <NmrDigitalReferenceCardContent dRM={convertedNmr} />}
				<CardContent
					value={DateUtils.getFormattedDate(new Date(createTime), 'MM/yyyy')}
					isActive={convertedIr?.isActive || convertedNmr?.isActive || false}
					title={t('substance-detail.creation-date')}
					overflowTextProps={{ 'data-testid': 'creation-date' }}
					disableDivider={true}
				/>
			</Stack>
		</ListCard>
	);
};
