import { FC } from 'react';
import { Stack, SxProps } from '@mui/material';
import { IrSubstance } from '@services';
import { DRMDivider } from '@routes/Portal/SearchReferences';
import { IrSubstanceListItem } from '../SubstanceListItem';

interface IrSubstanceListProps {
	substanceList: IrSubstance[];
	addedSubstances?: IrSubstance[];
	type: 'add' | 'remove' | 'favorite';
	actionLabel: string;
	disabled?: boolean;
	onSubstanceAdd?: (ermId: number) => void;
	onSubstanceRemove?: (ermId: number) => void;
	onSubstanceListRefresh?: () => void;
	onSubstanceDetail?: (visible: boolean, detailId: number, onSaveFavorite?: () => void) => void;
	substancesToAddFavorite?: number[];
	onCloseFavoriteDialog?: () => void;
	itemSx?: SxProps;
	sx?: SxProps;
	width?: string;
	dataTestId?: string;
}

export const IrSubstanceList: FC<IrSubstanceListProps> = ({
	substanceList,
	type,
	actionLabel,
	onSubstanceAdd,
	onSubstanceRemove,
	onSubstanceListRefresh,
	onSubstanceDetail,
	disabled,
	itemSx,
	dataTestId,
	sx,
}) => {
	return (
		<>
			{substanceList.length > 0 ? (
				<Stack direction="column" sx={sx} divider={<DRMDivider />} data-testid={dataTestId ?? 'substance-list-wrapper-test-id'}>
					{substanceList.map((substance) => (
						<IrSubstanceListItem
							key={type + substance.id}
							substance={substance}
							type={type}
							actionLabel={actionLabel}
							disabled={disabled}
							onSubstanceListRefresh={onSubstanceListRefresh}
							onSubstanceRemove={onSubstanceRemove}
							onSubstanceAdd={onSubstanceAdd}
							onSubstanceDetail={(visible: boolean, detailId: number) => onSubstanceDetail?.(visible, detailId)}
							sx={itemSx}
						/>
					))}
				</Stack>
			) : null}
		</>
	);
};
