import { Checkbox, CheckboxProps, Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IMFATypeSelectionProps {
	title: ReactNode;
	desc: ReactNode;
	checkboxProps?: CheckboxProps;
}

export const MFATypeSelection: FC<IMFATypeSelectionProps & Omit<StackProps, 'title'>> = ({ title, desc, checkboxProps, ...props }) => (
	<Stack {...props} marginTop="2rem">
		<Stack direction="row" alignItems="center" sx={{ position: 'relative', right: 12 }}>
			<Checkbox {...checkboxProps} />
			<Typography variant="h4">{title}</Typography>
		</Stack>
		<Typography variant="pg-m">{desc}</Typography>
	</Stack>
);
