export enum AnalysisType {
	IDENTIFICATION = 'Identification',
	QUANTIFICATION = 'Quantification',
	VERIFICATION = 'Verification',
}

export enum IrAnalysisType {
	IDENTIFICATION = 'Identification',
	QUANTIFICATION = 'Quantification',
	PURITYCHECK = 'PurityCheck',
}
