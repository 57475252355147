import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setUrlHistory, urlHistorySelector, userSelector } from '@store/slices/common.slice';
import { DefaultRoutes } from '@models/router';

export const useNavigateUrl = () => {
	const dispatch = useDispatch();
	const user = useSelector(userSelector);
	const navigate = useNavigate();
	const urlHistory = useSelector(urlHistorySelector);
	const state = useLocation();

	useEffect(() => {
		if (
			!user &&
			state.pathname &&
			urlHistory &&
			!urlHistory.includes(state.pathname) &&
			!Object.values(DefaultRoutes).includes(state.pathname as DefaultRoutes)
		) {
			dispatch(setUrlHistory([state.pathname, ...urlHistory]));
		}

		if (
			state.pathname &&
			urlHistory &&
			state.pathname !== urlHistory[0] &&
			user &&
			!Object.values(DefaultRoutes).includes(urlHistory[0] as DefaultRoutes)
		) {
			navigate(urlHistory[0]);

			dispatch(setUrlHistory(DefaultRoutes.DASHBOARD));
		}
	}, [state]);
};
