import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericCard, InvalidFileUpload, ValidFileUpload } from '@components/common';
import { IFileStateType } from '../Device/DeviceForm/DeviceFileHook';
import { Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { nameLengthValidator } from '@utils/File';
import { useDropzone } from 'react-dropzone';
import { TypeUtils } from '@utils/Type';
import { ICalibrationRange } from '@services/nmr';
import { FileStatus, UploadableFileExtensionMapping } from '@models/file';

interface IDeviceListProps {
	files: IFileStateType[];
	manipulatedReceiverThreshold?: () => {} | undefined;
	outOfRangeFiles: string[];
	onRemove: (index: number) => void;
	onUploadedFileChange?: (f?: unknown[]) => void;
}

const MAX_FILE_SIZE = 209715200;

export const DeviceList: FC<IDeviceListProps> = ({
	files,
	manipulatedReceiverThreshold,
	outOfRangeFiles,
	onRemove,
	onUploadedFileChange,
}) => {
	const { t } = useTranslation('portal');
	const [reUploading, setReUploading] = useState<number>();
	const onDrop = (file: File[]) => {
		if (file && reUploading !== undefined) {
			onRemove(reUploading);
			onUploadedFileChange?.(file);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => onDrop(acceptedFiles),
		multiple: false,
		accept: UploadableFileExtensionMapping.Device,
		useFsAccessApi: false,
		maxSize: MAX_FILE_SIZE,
		validator: nameLengthValidator,
	});

	const ReceiverGainColumn: FC<{ file: IFileStateType }> = ({ file }) => {
		return (
			<Typography
				color={file.fileStatus === FileStatus.INVALID ? 'error.main' : 'primary.main'}
				sx={{
					overflowX: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					paddingLeft: '1rem',
				}}
				variant="label-s"
			>
				<Tr.Portal path="device-management.receiver-gain" /> {file.receiverGain}
			</Typography>
		);
	};

	return (
		<GenericCard sx={{ padding: 0 }}>
			{files.map((file, index) => {
				const manipuledReceiver = manipulatedReceiverThreshold?.() ?? new Array<ICalibrationRange>();
				const manipulatedThreshold = TypeUtils.returnValueOfKey<typeof manipuledReceiver, ICalibrationRange>(
					index,
					manipuledReceiver,
				);
				const { min, max } = manipulatedThreshold || {
					min: 0,
					max: 0,
				};

				return (
					<Tooltip
						key={index}
						open={file.fileStatus === FileStatus.INVALID && outOfRangeFiles.length > 0}
						title={t('device-management.receiver-gain-range-message', { min: min, max: max })}
						placement="right"
						arrow
						PopperProps={{
							sx: {
								' .MuiTooltip-tooltipArrow ': { marginLeft: '0 !important' },
								zIndex: 1000,
							},
						}}
					>
						<>
							{file.fileStatus === FileStatus.VALID ? (
								<ValidFileUpload
									onRemove={onRemove}
									index={index}
									fileName={file.fileName}
									additionalColumn={<ReceiverGainColumn file={file} />}
								/>
							) : (
								<InvalidFileUpload
									fileName={file.fileName}
									getRootProps={getRootProps}
									getInputProps={getInputProps}
									setReUploading={setReUploading}
									additionalColumn={<ReceiverGainColumn file={file} />}
									index={index}
								/>
							)}
						</>
					</Tooltip>
				);
			})}
		</GenericCard>
	);
};
