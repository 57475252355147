import { NmrDrmOrderUploadError, NmrDrmOrderUploadErrorType } from '@models/nmr-drm-order/file-errors';

export const getFileHeaders = async (file: File) => {
	return new Promise<[headers: string[], headerCount: number, rowCount: number]>((resolve, rejects) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const text = <string>reader.result;
			if (!text) rejects('drm-pipeline.error.csv-reading-error');

			const header = text.slice(0, text.indexOf('\n')).trim().split(/[,;]/);
			const rows = text
				.slice(text.indexOf('\n') + 1)
				.split('\n')
				.filter((row) => !!row);

			resolve([header, header.length, rows.length]);
		};
		reader.readAsText(file);
	});
};

const HEADER_REQUIREMENTS = ['CAS Number', 'Physical Product Number', 'Physical Product Quality Grade', 'Site/Supplier'];

export const validateBulkNmrDrmFiles = async (file: File) => {
	const fileHeaders = await getFileHeaders(file);
	const missingHeaders = HEADER_REQUIREMENTS.filter((r) => !fileHeaders[0].some((h) => r.toLowerCase() === h.toLowerCase()));
	if (missingHeaders.length > 0)
		throw new NmrDrmOrderUploadError('drm-pipeline.nmr.backlog.missing', NmrDrmOrderUploadErrorType.HEADERS_MISSING, {
			missingHeaders: missingHeaders.join(', '),
		});
};
