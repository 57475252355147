import { BorderedRadio, HeightTransition, ModalDialog } from '@components/common';
import { Button, Stack, Typography, SxProps } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { DataTestId } from '@utils/DataTestId';
import AddIcon from '@mui/icons-material/Add';
import { useRestrictedComponent } from '@hooks/useRestrictedComponent';
import { NmrAdminPermission } from '@models/user';
import { AddSingleOrder } from './AddSingleOrder/AddSingleOrder';
import { CreateBulkOrder } from './CreateBulkOrder.tsx/CreateBulkOrder';
import { formFieldSpacer } from '@utils/Theme';

enum AddDrmModalCreateType {
	SINGLE,
	BULK,
}

type TPipelineAddDrmOrderProps = {
	buttonSx?: SxProps;
};

export const PipelineAddDrmOrderModal: FC<TPipelineAddDrmOrderProps> = ({ buttonSx }) => {
	const [isAddDrmModalOpen, setIsAddDrmModalOpen] = useState<boolean>(false);
	const [selectedCreateType, setSelectedCreateType] = useState<AddDrmModalCreateType>(AddDrmModalCreateType.SINGLE);
	const [refreshHeightSize, setRefreshHeightSize] = useState<boolean>(false);

	const { t } = useTranslation('admin');

	const onModalClose = () => {
		setSelectedCreateType(AddDrmModalCreateType.SINGLE);
		setIsAddDrmModalOpen(false);
	};

	const onSelectedCreateTypeChange = (createType: AddDrmModalCreateType) => {
		setSelectedCreateType(createType);
	};

	const isSingleSelected = selectedCreateType === AddDrmModalCreateType.SINGLE;

	const CreateTypeButtons = () => {
		return (
			<Stack direction="row" spacing={1.5}>
				<BorderedRadio
					title={t('drm-pipeline.nmr.backlog.single-order')}
					onChange={() => onSelectedCreateTypeChange(AddDrmModalCreateType.SINGLE)}
					checked={isSingleSelected}
				/>
				<BorderedRadio
					title={t('drm-pipeline.nmr.backlog.bulk-order')}
					data-testid="bulk-order-test-id"
					onChange={() => onSelectedCreateTypeChange(AddDrmModalCreateType.BULK)}
					checked={!isSingleSelected}
				/>
			</Stack>
		);
	};
	const onClose = () => {
		setIsAddDrmModalOpen(false);
		onSelectedCreateTypeChange(AddDrmModalCreateType.SINGLE);
	};
	return (
		<>
			{useRestrictedComponent(
				NmrAdminPermission.CREATE_DRM_ORDER,
				<Button
					sx={{
						marginLeft: 'auto',
						padding: '10px 12px 10px',
						float: 'right',
						...buttonSx,
					}}
					onClick={() => setIsAddDrmModalOpen(true)}
					startIcon={<AddIcon />}
					variant="text"
					data-testid={DataTestId.getStaticTestId('open-drm-order-modal-id')}
				>
					<Tr.Admin path="substance-management.add-drm-order" />
				</Button>,
			)}
			{isAddDrmModalOpen && (
				<ModalDialog
					open={isAddDrmModalOpen}
					variant="secondary"
					maxWidth="xs"
					onClose={onModalClose}
					title={<Tr.Common path="add" />}
				>
					<Stack alignItems="center" sx={{ pt: 3, px: 8, pb: 7, width: '100%' }}>
						<Typography variant="h3" textAlign="center" sx={{ mb: 3 }}>
							<Tr.Admin path="drm-pipeline.nmr.backlog.add-drm-order-title" />
						</Typography>

						<Stack spacing={formFieldSpacer} width="100%" data-testid={DataTestId.getStaticTestId('add-drm-order-layout-id')}>
							<CreateTypeButtons />
							<HeightTransition showBiggerComponent={!isSingleSelected} refreshHeightSize={refreshHeightSize}>
								<Stack>
									{isSingleSelected ? (
										<AddSingleOrder onFieldGrow={() => setRefreshHeightSize((prev) => !prev)} onClose={onClose} />
									) : (
										<CreateBulkOrder onClose={onClose} />
									)}
								</Stack>
							</HeightTransition>
						</Stack>
					</Stack>
				</ModalDialog>
			)}
		</>
	);
};
